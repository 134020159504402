import React, { useEffect, useState, useContext, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import axios from "axios";
import { baseUrl } from "../../services/BaseUrl";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import { UserContext } from "../../context/UserContext";
import { GoDash } from "react-icons/go";

const callStyle = {
  padding: 8.5,
  fontSize: 14,
  fontWeight: 550,
  textAlign: "center",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  borderRight: "2px solid #e0e0e0",
};

const renderSelectedInfo = (select, field) => {
  return (
    select?.[field] || (
      <>
        <GoDash style={{ fontSize: "20px" }} />
        <GoDash style={{ fontSize: "20px" }} />
      </>
    )
  );
};

const AssignCallbackLead = ({ callbackLeads , setCallbackLeads}) => {
  const { token } = useContext(UserContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [agentsTeam, setAgentsTeam] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState({});
  
  
  const allLeads = useMemo(() => {
    return callbackLeads
      .flatMap((item) => item.leads)
      .filter((lead) => {
        return lead;
      });
  }, [callbackLeads]);

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/sales-manager/get-agents-names`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setAgentsTeam(response.data);
      } catch (error) {
        console.error("Error fetching team data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamData();
  }, [token]);

  const handleAgentSelect = async (event,leadId) => {
    const agentId = event.target.value;
    const selectedAgent = agentsTeam.find(agent => agent._id === agentId);
    setSelectedAgent(selectedAgent);

    if (selectedAgent) {

      try {
        const payload = {
          userId: selectedAgent._id,
          leadId: leadId,
        };

        // Make the PUT request
        const response = await axios.put(
          `${baseUrl}/api/sales-manager/assign-lead-to-agent`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

         // After successful assignment, update the lead in callbackLeads state
      setCallbackLeads(prevLeads => {
        return prevLeads.map(group => {
          return {
            ...group,
            leads: group.leads.map(lead => {
              if (lead._id === leadId) {
                return { ...lead, AssignedTo: agentId };  // Update the lead's assigned agent
              }
              return lead;
            }),
          };
        });
      });

      } catch (error) {
        console.error('Error assigning lead to agent:', error);
      }
    }
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  return (
    <div className="table-allCallbacks-contaier">
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
          borderRadius: "10px 10px 0 0",
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="email requests table">
          <TableHead>
            <TableRow
              sx={{
                background: "linear-gradient(to right, #fed766, #e8b6ea)",
              }}
            >
              {[
                "Company Name",
                "Show Name",
                "Timezone",
                "City, State",
                "Agent Name",
                "Closer Status",
              ].map((header, index) => (
                <TableCell
                  key={index}
                  sx={{
                    fontSize: 18,
                    fontFamily: '"Playfair Display", serif',
                    letterSpacing: "1.2px",
                    fontWeight: 800,
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    wordBreak: "keep-all",
                    borderRight: "2px solid #e0e0e0",
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {allLeads?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={7}
                  sx={{
                    borderRight: "2px solid #e0e0e0",
                    fontSize: "14px",
                    fontWeight: 550,
                    whiteSpace: "nowrap",
                    wordBreak: "keep-all",
                    padding: "14px",
                    textAlign: "center",
                  }}
                >
                  No Callback leads
                </TableCell>
              </TableRow>
            ) : (
              allLeads
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((lead, index) => {
                  const nextContactDate = new Date(lead["Next Contact"]);
                  const today = new Date();
                  today.setHours(0, 0, 0, 0);
                  const isOld = nextContactDate < today;
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor: isOld ? "#dfdada" : "transparent",
                        opacity: isOld ? 0.6 : 1,
                        pointerEvents: isOld ? "none" : "auto",
                      }}
                    >
                      <TableCell style={callStyle}>
                        {renderSelectedInfo(lead, "Lead")}
                      </TableCell>
                      <TableCell style={callStyle}>
                        {renderSelectedInfo(lead, "Show")}
                      </TableCell>
                      <TableCell style={callStyle}>
                        {renderSelectedInfo(lead, "Timezone")}
                      </TableCell>

                      <TableCell style={callStyle}>
                        {renderSelectedInfo(lead, "cityState")}
                      </TableCell>
                      <TableCell style={callStyle}>
                        <select
                          onChange={(e) => handleAgentSelect(e, lead._id)}
                          value={lead.AssignedTo || ""}
                          style={{
                            padding: "8px 12px",
                            borderRadius: "4px",
                            border: "1px solid #ccc",
                            backgroundColor: "#fff",
                            fontSize: "14px",
                            fontFamily: "'Arial', sans-serif",
                            cursor: "pointer",
                            width: "100%",
                            maxWidth: "200px",
                            boxSizing: "border-box",
                            transition:
                              "border-color 0.3s, background-color 0.3s",
                          }}
                        >
                          {agentsTeam.map((agent) => (
                            <option key={agent._id} value={agent._id}>
                              {agent.name}
                            </option>
                          ))}
                        </select>
                      </TableCell>
                      <TableCell style={callStyle}>
                        {renderSelectedInfo(lead, "closerStatus")}
                      </TableCell>
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{
          backgroundColor: "#fff",
          borderTop: "1px solid #e0e0e0",
        }}
        rowsPerPageOptions={[10, 20]}
        component="div"
        count={allLeads.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default AssignCallbackLead;



