import React, { useEffect, useState } from "react";
import { TextField, MenuItem, Grid, Button } from "@mui/material";
import cityState from "../../utils/Timezones";

const editionYears = ["2024", "2025", "2026"];

const MatchingPopup = ({
  formDataLocator,
  activeShowName,
  handleChangedata,
  handleButtonClick,
  handleClosePopup,
  locatedShows,
  handleLocatedShowClick,
}) => {
  console.log(locatedShows);

  // const allShows = locatedShows.reduce((acc, show) => {
  //   // Add the original show
  //   acc.push({ ...show, type: "Original Show" });

  //   // Add similar shows with data inherited from the original show (excluding showName)
  //   if (show.similarShows) {
  //     acc = acc.concat(
  //       show.similarShows.map((similarShow) => ({
  //         ...similarShow,
  //         ...Object.fromEntries(
  //           Object.entries(show).filter(([key]) => key !== "showName")
  //         ),
  //         type: "Similar Show",
  //       }))
  //     );
  //   }

  //   return acc;
  // }, []);

  const [selectedShow, setSelectedShow] = useState(activeShowName || "");

  useEffect(() => {
    if (activeShowName) {
      setSelectedShow(activeShowName);

      // Find the data for the active show in locatedShows
      const activeShowData = locatedShows.find(
        (show) => show.rawName === activeShowName
      );

      if (activeShowData) {
        // Populate the form with the active show data
        handleLocatedShowClick(activeShowData);
      }
    }
  }, []);

  const handleShowSelect = (event) => {
    const selectedShowName = event.target.value;
    setSelectedShow(selectedShowName);

    // Find the selected show data
    const selectedShowData = locatedShows.find(
      (show) => show.rawName === selectedShowName
    );

    if (selectedShowData) {
      console.log(selectedShowData);

      // Use handleLocatedShowClick to populate form data
      handleLocatedShowClick(selectedShowData);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isValidDateFormat = (date) => {
      return /^\d{4}-\d{2}-\d{2}$/.test(date);
    };

    const isEmpty = Object.values(formDataLocator).some(
      (value) => value === "" || value === null || value === undefined
    );

    if (isEmpty) {
      alert("Please fill in all fields before submitting.");
      return;
    }

    if (
      !isValidDateFormat(formDataLocator["Next Dates"]) ||
      !isValidDateFormat(formDataLocator["End Date"])
    ) {
      alert(
        "Please provide valid dates for 'Next Dates' and 'End Date' in the format YYYY-MM-DD."
      );
      return;
    }

    handleButtonClick();
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        borderRadius: "10px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        width: "80%",
        maxWidth: "600px",
        padding: "20px",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          marginBottom: "20px",
          fontSize: "18px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Edit Show Details
      </div>
      <div className="popup-input-contain" style={{ marginBottom: "1rem" }}>
        <TextField
          name="Use Located Show Data"
          select
          label="Use Located Show Data"
          fullWidth
          style={{ width: "100%" }}
          value={selectedShow}
          onChange={handleShowSelect}
        >
          {locatedShows.map((show, index) => (
            <MenuItem key={index} value={show.rawName}>
              {show.showName}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="edition"
              select
              label="Select Edition"
              fullWidth
              value={formDataLocator.edition}
              onChange={handleChangedata}
              required
            >
              {editionYears.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="Show"
              label="Show Name"
              variant="outlined"
              fullWidth
              value={formDataLocator.Show}
              onChange={handleChangedata}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="Show Url"
              label="Show Url"
              variant="outlined"
              fullWidth
              value={formDataLocator["Show Url"]}
              onChange={handleChangedata}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="Next Dates"
              label="Next Dates"
              variant="outlined"
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={formDataLocator["Next Dates"]}
              onChange={handleChangedata}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="End Date"
              label="End Date"
              variant="outlined"
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={formDataLocator["End Date"]}
              onChange={handleChangedata}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="Duration"
              label="Duration"
              variant="outlined"
              fullWidth
              value={formDataLocator.Duration}
              onChange={handleChangedata}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="Convention Center / Host Hotel"
              label="Recommended"
              variant="outlined"
              fullWidth
              value={formDataLocator["Convention Center / Host Hotel"]}
              onChange={handleChangedata}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="Venue"
              label="Venue"
              variant="outlined"
              fullWidth
              value={formDataLocator.Venue}
              onChange={handleChangedata}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="City, Country"
              select
              label="Select City, Country"
              fullWidth
              value={formDataLocator["City, Country"]}
              onChange={handleChangedata}
              required
            >
              {cityState.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            color="success"
            size="large"
          >
            Submit
          </Button>
          <Button
            type="button"
            onClick={handleClosePopup}
            variant="contained"
            color="error"
            size="large"
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default MatchingPopup;
