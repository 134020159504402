import React, { useState } from "react";
import axios from "axios";
import styles from "./UploadControllers.module.css";
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Button,
} from "@mui/material";
import { baseUrl } from "../../../services/BaseUrl";
import UploadRawShows from "./UploadRawShow";
import * as XLSX from "xlsx";

const UploadRawLeadsSection = ({ showId }) => {
  const [selectedShowsFile, setSelectedShowsFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");

  const handleShowsFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedShowsFile(file);
  };

  const handleShowsUpload = async () => {
    if (selectedShowsFile) {
      const formData = new FormData();
      formData.append("excelFile", selectedShowsFile);
      setLoading(true);
      setProgress(0);
      try {
        const response = await axios.post(
          `${baseUrl}/api/locator/upload-raw-lead/${showId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (event) => {
              const percentCompleted = Math.round(
                (event.loaded * 100) / event.total
              );
              setProgress(percentCompleted);
            },
          }
        );
        console.log(response.data);
        setSelectedShowsFile(null);
      } catch (error) {
        console.error("Error uploading the file:", error);
      } finally {
        setLoading(false);
      }
    } else {
      console.error("No file selected for shows");
    }
  };

  const handleDownloadTemplate = () => {
    const worksheetData = [["companyName", "email", "phone"]];
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Template");

    // Create Excel file and trigger download
    XLSX.writeFile(workbook, "RawLeadsTemplate.xlsx");
  };

  return (
    <div className={styles.container}>
      <p>Only Excel files can be uploaded </p>

      <div className={styles.controllersContainer}>
        <UploadRawShows
          onFileChange={handleShowsFileChange}
          onUpload={handleShowsUpload}
          isLoading={loading}
          progress={progress}
          setProgress={setProgress}
        />
      </div>
      <Button
        variant="contained"
        color="primary"
        sx={{ marginTop: 2 }}
        onClick={handleDownloadTemplate}
      >
        Download Excel Template
      </Button>
    </div>
  );
};

export default UploadRawLeadsSection;
