import React, { useContext, useEffect, useState } from "react";
import "./Locator.css";
import Layout from "../../components/layout/layout";
import SideBar from "../../components/SideBar/SideBar";
import MainContainer from "../../mainContainer/MainContainer";
import LocatorTable from "../../components/LocatorTable/LocatorTable";
import MatchPricingShow from "../../components/MatchPricingShow/MatchPricingShow";
import UploadLocatorShows from "../../components/UploadLocatorShows/UploadLocatorShows";
import CreateRawPricing from "../../components/CreateRawPricing/CreateRawPricing";
import CreatePricing from "../../components/CreatePricing/CreatePricing";
import LocatorReports from "../../components/LocatorReports/LocatorReports";
import LocatedShows from "../../components/LocatedShows/LocatedShows";
import UsersReports from "../../components/UsersReports/UsersReports";
import HistoryOfMatching from "../../components/HistoryOfMatching/HistoryOfMatching";
import MatchGeneratedPricingShow from "../../components/MatchGeneratedricingShow/MatchGeneratedPricingShow";

const Locator = () => {
  //sidebar logic ------------>
  const [currentComponent, setCurrentComponent] = useState(
    <MatchPricingShow />
  );
  const [currentPageTitle, setCurrentPageTitle] = useState(
    "Show Pricing Matcher"
  );

  // Get role from local storage
  const userRole = localStorage.getItem("role");

  //sidebar logic ------------>
  const sidebarButtons = [
    {
      label: "Price Matching",
      component: <MatchPricingShow />,
      pageTitle: "Show Pricing Matcher",
    },
    // {
    //   label: "Generated Price Matching",
    //   component: <MatchGeneratedPricingShow />,
    //   pageTitle: "Generated Show Pricing Matcher",
    // },
    {
      label: "Locator Table",
      component: <LocatorTable />,
      pageTitle: "Show-Hotel Locator",
    },
    {
      label: "History of Matching",
      component: <HistoryOfMatching />,
      pageTitle: "Match Shows Archive",
    },
    {
      label: "Create Raw Pricing",
      component: <CreateRawPricing />,
      pageTitle: "Create Raw Pricing",
    },
    {
      label: "Update Pricing",
      component: <CreatePricing />,
      pageTitle: "Update Pricing",
    },
    {
      label: "Located Shows",
      component: <LocatedShows />,
      pageTitle: "Located Shows",
    },
    {
      label: "Locator Reports",
      component: <LocatorReports />,
      pageTitle: "Locator Reports",
    },
    // ...(userRole === "locator manager"
    //   ? [
    //       {
    //         label: "Users Reports",
    //         component: <UsersReports />,
    //         pageTitle: "Users Reports",
    //       },
    //     ]
    //   : []),
    // {
    //   label: "Upload Shows",
    //   component: <UploadLocatorShows />,
    //   pageTitle: "Show Data Uploader",
    // },
  ];

  //sidebar logic ------------>
  const handleComponentChange = (component, pageTitle) => {
    setCurrentComponent(component);
    setCurrentPageTitle(pageTitle);
  };
  return (
    <Layout>
      <SideBar
        buttons={sidebarButtons}
        onComponentChange={handleComponentChange}
      />
      <MainContainer pageTitle={currentPageTitle}>
        <h2>
          {userRole === "locator manager"
            ? "Locator Manager View"
            : " Locator View"}
        </h2>
        {currentComponent}
      </MainContainer>
    </Layout>
  );
};

export default Locator;
