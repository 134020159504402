import React, { useEffect, useState, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import "./LocatorReports.css";
import { baseUrl } from "../../services/BaseUrl";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import LoadingComponent from "../LoadingComponent/LoadingComponent";

const LocatorReports = () => {
  const { token } = useContext(UserContext);
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReportData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/locator/report`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setReportData(response.data.data);
        setLoading(false);
      } catch (error) {
        setError(error.message || "Error fetching data");
        setLoading(false);
      }
    };

    fetchReportData();
  }, [token]);

  const timezoneReportUs = reportData?.usLeads?.timezoneReport;
  const timezoneReportUk = reportData?.ukLeads?.timezoneReport;
  const statusCountsUs = reportData?.usLeads?.statusCounts;
  const statusCountsUk = reportData?.ukLeads?.statusCounts;
  const unassignedActiveShowLeadsUs =
    reportData?.usLeads?.unassignedActiveShowLeads;
  const unassignedActiveShowLeadsUk =
    reportData?.ukLeads?.unassignedActiveShowLeads;

  if (loading) return <LoadingComponent />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="locator-report-container">
      {reportData?.usLeads && (
        <div className="locator-leads-report-contain">
          <span>US Leads Report</span>
          <div className="locator-leads-report-tables-contain">
            <div className="leads-locator-timezone-container">
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    {[{ label: "Timezone", key: "timezone" }].map(
                      ({ label, key }) => (
                        <TableRow key={label}  sx={{
                          background:
                            "linear-gradient(to right, #fed766, #e8b6ea)",
                        }}>
                          <TableCell
                            sx={{
                              fontSize: 18,
                              fontWeight: 700,
                              borderRight: "2px solid #fff",
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                              padding: "10px 14px",
                              color: "#fff",
                              textTransform: "capitalize",
                              letterSpacing: "1.2px",
                            }}
                          >
                            {label}
                          </TableCell>
                          {timezoneReportUs.map((item, idx) => (
                            <TableCell
                              key={idx}
                              sx={{
                                fontSize: 16,
                                fontWeight: 600,
                                borderRight: "2px solid #fff",
                                whiteSpace: "nowrap",
                                wordBreak: "keep-all",
                                padding: "10px 14px",
                                color: "#fff",
                                textTransform: "capitalize",
                                letterSpacing: "1.2px",
                              }}
                            >
                              {item.timezone}
                            </TableCell>
                          ))}
                        </TableRow>
                      )
                    )}
                  </TableHead>
                  <TableBody>
                    {[ { label: "Total Leads", key: "totalLeads" },
                      { label: "Assigned Leads", key: "assignedLeads" },
                      { label: "Unassigned Leads", key: "unassignedLeads" }].map(
                      ({ label, key }) => (
                        <TableRow key={label}>
                          <TableCell
                            sx={{
                              fontSize: 16,
                              fontWeight: 700,
                              borderRight: "2px solid #e0e0e0",
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                              padding: "10px 14px",
                            }}
                          >
                            {label}
                          </TableCell>
                          {timezoneReportUs.map((item, idx) => (
                            <TableCell
                              key={idx}
                              sx={{
                                fontSize: 16,
                                fontWeight: 600,
                                borderRight: "2px solid #e0e0e0",
                                whiteSpace: "nowrap",
                                wordBreak: "keep-all",
                                padding: "10px 14px",
                              }}
                            >
                              {item[key]}
                            </TableCell>
                          ))}
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="leads-locator-timezone-container">
              <h3>Status Breakdown:</h3>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    {[{ label: "status", key: "status" }].map(
                      ({ label, key }) => (
                        <TableRow key={label} sx={{ background: "#37373F" }}>
                          <TableCell
                            sx={{
                              fontSize: 18,
                              fontWeight: 700,
                              borderRight: "2px solid #fff",
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                              padding: "10px 14px",
                              color: "#fff",
                              textTransform: "capitalize",
                              letterSpacing: "1.2px",
                            }}
                          >
                            {label}
                          </TableCell>
                          {statusCountsUs.map((item, idx) => (
                            <TableCell
                              key={idx}
                              sx={{
                                fontSize: 16,
                                fontWeight: 600,
                                borderRight: "2px solid #fff",
                                whiteSpace: "nowrap",
                                wordBreak: "keep-all",
                                padding: "10px 14px",
                                color: "#fff",
                                textTransform: "capitalize",
                                letterSpacing: "1.2px",
                              }}
                            >
                              {item.status}
                            </TableCell>
                          ))}
                        </TableRow>
                      )
                    )}
                  </TableHead>
                  <TableBody>
                    {[{ label: "Count", key: "count" }].map(
                      ({ label, key }) => (
                        <TableRow key={label}>
                          <TableCell
                            sx={{
                              fontSize: 16,
                              fontWeight: 700,
                              borderRight: "2px solid #e0e0e0",
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                              padding: "10px 14px",
                            }}
                          >
                            {label}
                          </TableCell>
                          {statusCountsUs.map((item, idx) => (
                            <TableCell
                              key={idx}
                              sx={{
                                fontSize: 16,
                                fontWeight: 600,
                                borderRight: "2px solid #e0e0e0",
                                whiteSpace: "nowrap",
                                wordBreak: "keep-all",
                                padding: "10px 14px",
                              }}
                            >
                              {item[key]}
                            </TableCell>
                          ))}
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="leads-locator-timezone-container">
              <h3>Unassigned Active Show Leads:</h3>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    {[{ label: "Timezone", key: "timezone" }].map(
                      ({ label, key }) => (
                        <TableRow
                          key={label}
                          sx={{
                            background:
                              "linear-gradient(to left, rgb(247, 224, 156), #e8b6ea)",
                          }}
                        >
                          <TableCell
                            sx={{
                              fontSize: 18,
                              fontWeight: 700,
                              borderRight: "2px solid #fff",
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                              padding: "10px 14px",
                              color: "#fff",
                              textTransform: "capitalize",
                              letterSpacing: "1.2px",
                            }}
                          >
                            {label}
                          </TableCell>
                          {unassignedActiveShowLeadsUs.map((item, idx) => (
                            <TableCell
                              key={idx}
                              sx={{
                                fontSize: 16,
                                fontWeight: 600,
                                borderRight: "2px solid #fff",
                                whiteSpace: "nowrap",
                                wordBreak: "keep-all",
                                padding: "10px 14px",
                                color: "#fff",
                                textTransform: "capitalize",
                                letterSpacing: "1.2px",
                              }}
                            >
                              {item.timezone}
                            </TableCell>
                          ))}
                        </TableRow>
                      )
                    )}
                  </TableHead>
                  <TableBody>
                    {[{ label: "Count", key: "count" }].map(
                      ({ label, key }) => (
                        <TableRow key={label}>
                          <TableCell
                            sx={{
                              fontSize: 16,
                              fontWeight: 700,
                              borderRight: "2px solid #e0e0e0",
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                              padding: "10px 14px",
                            }}
                          >
                            {label}
                          </TableCell>
                          {unassignedActiveShowLeadsUs.map((item, idx) => (
                            <TableCell
                              key={idx}
                              sx={{
                                fontSize: 16,
                                fontWeight: 600,
                                borderRight: "2px solid #e0e0e0",
                                whiteSpace: "nowrap",
                                wordBreak: "keep-all",
                                padding: "10px 14px",
                              }}
                            >
                              {item[key]}
                            </TableCell>
                          ))}
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="leads-locator-timezone-container">
              <p style={{fontSize:"20px", fontWeight:"700", letterSpacing:"1.4px"}}>Total Leads: {reportData?.usLeads?.totalLeads} </p>
              <p style={{fontSize:"20px", fontWeight:"700", letterSpacing:"1.4px"}}>Leads with Closer Status: {reportData?.usLeads?.closerStatusLeads} </p>
            </div>
          </div>
        </div>
      )}
      {reportData?.ukLeads && (
        <div className="locator-leads-report-contain">
          <span>Uk Leads Report</span>
          <div className="locator-leads-report-tables-contain">
            <div className="leads-locator-timezone-container">
            <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    {[{ label: "Timezone", key: "timezone" }].map(
                      ({ label, key }) => (
                        <TableRow key={label}  sx={{
                          background:
                            "linear-gradient(to right, #fed766, #e8b6ea)",
                        }}>
                          <TableCell
                            sx={{
                              fontSize: 18,
                              fontWeight: 700,
                              borderRight: "2px solid #fff",
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                              padding: "10px 14px",
                              color: "#fff",
                              textTransform: "capitalize",
                              letterSpacing: "1.2px",
                            }}
                          >
                            {label}
                          </TableCell>
                          {timezoneReportUk.map((item, idx) => (
                            <TableCell
                              key={idx}
                              sx={{
                                fontSize: 16,
                                fontWeight: 600,
                                borderRight: "2px solid #fff",
                                whiteSpace: "nowrap",
                                wordBreak: "keep-all",
                                padding: "10px 14px",
                                color: "#fff",
                                textTransform: "capitalize",
                                letterSpacing: "1.2px",
                              }}
                            >
                              {item.timezone}
                            </TableCell>
                          ))}
                        </TableRow>
                      )
                    )}
                  </TableHead>
                  <TableBody>
                    {[ { label: "Total Leads", key: "totalLeads" },
                      { label: "Assigned Leads", key: "assignedLeads" },
                      { label: "Unassigned Leads", key: "unassignedLeads" }].map(
                      ({ label, key }) => (
                        <TableRow key={label}>
                          <TableCell
                            sx={{
                              fontSize: 16,
                              fontWeight: 700,
                              borderRight: "2px solid #e0e0e0",
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                              padding: "10px 14px",
                            }}
                          >
                            {label}
                          </TableCell>
                          {timezoneReportUk.map((item, idx) => (
                            <TableCell
                              key={idx}
                              sx={{
                                fontSize: 16,
                                fontWeight: 600,
                                borderRight: "2px solid #e0e0e0",
                                whiteSpace: "nowrap",
                                wordBreak: "keep-all",
                                padding: "10px 14px",
                              }}
                            >
                              {item[key]}
                            </TableCell>
                          ))}
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="leads-locator-timezone-container">
              <h3>Status Breakdown:</h3>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    {[{ label: "status", key: "status" }].map(
                      ({ label, key }) => (
                        <TableRow key={label} sx={{ background: "#37373F" }}>
                          <TableCell
                            sx={{
                              fontSize: 18,
                              fontWeight: 700,
                              borderRight: "2px solid #fff",
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                              padding: "10px 14px",
                              color: "#fff",
                              textTransform: "capitalize",
                              letterSpacing: "1.2px",
                            }}
                          >
                            {label}
                          </TableCell>
                          {statusCountsUk.map((item, idx) => (
                            <TableCell
                              key={idx}
                              sx={{
                                fontSize: 16,
                                fontWeight: 600,
                                borderRight: "2px solid #fff",
                                whiteSpace: "nowrap",
                                wordBreak: "keep-all",
                                padding: "10px 14px",
                                color: "#fff",
                                textTransform: "capitalize",
                                letterSpacing: "1.2px",
                              }}
                            >
                              {item.status}
                            </TableCell>
                          ))}
                        </TableRow>
                      )
                    )}
                  </TableHead>
                  <TableBody>
                    {[{ label: "Count", key: "count" }].map(
                      ({ label, key }) => (
                        <TableRow key={label}>
                          <TableCell
                            sx={{
                              fontSize: 16,
                              fontWeight: 700,
                              borderRight: "2px solid #e0e0e0",
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                              padding: "10px 14px",
                            }}
                          >
                            {label}
                          </TableCell>
                          {statusCountsUk.map((item, idx) => (
                            <TableCell
                              key={idx}
                              sx={{
                                fontSize: 16,
                                fontWeight: 600,
                                borderRight: "2px solid #e0e0e0",
                                whiteSpace: "nowrap",
                                wordBreak: "keep-all",
                                padding: "10px 14px",
                              }}
                            >
                              {item[key]}
                            </TableCell>
                          ))}
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="leads-locator-timezone-container">
              <h3>Unassigned Active Show Leads:</h3>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    {[{ label: "Timezone", key: "timezone" }].map(
                      ({ label, key }) => (
                        <TableRow
                          key={label}
                          sx={{
                            background:
                              "linear-gradient(to left, rgb(247, 224, 156), #e8b6ea)",
                          }}
                        >
                          <TableCell
                            sx={{
                              fontSize: 18,
                              fontWeight: 700,
                              borderRight: "2px solid #fff",
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                              padding: "10px 14px",
                              color: "#fff",
                              textTransform: "capitalize",
                              letterSpacing: "1.2px",
                            }}
                          >
                            {label}
                          </TableCell>
                          {unassignedActiveShowLeadsUk.map((item, idx) => (
                            <TableCell
                              key={idx}
                              sx={{
                                fontSize: 16,
                                fontWeight: 600,
                                borderRight: "2px solid #fff",
                                whiteSpace: "nowrap",
                                wordBreak: "keep-all",
                                padding: "10px 14px",
                                color: "#fff",
                                textTransform: "capitalize",
                                letterSpacing: "1.2px",
                              }}
                            >
                              {item.timezone}
                            </TableCell>
                          ))}
                        </TableRow>
                      )
                    )}
                  </TableHead>
                  <TableBody>
                    {[{ label: "Count", key: "count" }].map(
                      ({ label, key }) => (
                        <TableRow key={label}>
                          <TableCell
                            sx={{
                              fontSize: 16,
                              fontWeight: 700,
                              borderRight: "2px solid #e0e0e0",
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                              padding: "10px 14px",
                            }}
                          >
                            {label}
                          </TableCell>
                          {unassignedActiveShowLeadsUk.map((item, idx) => (
                            <TableCell
                              key={idx}
                              sx={{
                                fontSize: 16,
                                fontWeight: 600,
                                borderRight: "2px solid #e0e0e0",
                                whiteSpace: "nowrap",
                                wordBreak: "keep-all",
                                padding: "10px 14px",
                              }}
                            >
                              {item[key]}
                            </TableCell>
                          ))}
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="leads-locator-timezone-container">
              <p style={{fontSize:"20px", fontWeight:"700", letterSpacing:"1.4px"}}>Total Leads: {reportData?.ukLeads?.totalLeads} </p>
              <p style={{fontSize:"20px", fontWeight:"700", letterSpacing:"1.4px"}}>Leads with Closer Status: {reportData?.ukLeads?.closerStatusLeads} </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LocatorReports;
