import React, { useState } from "react";
import "./SalesSupport.css";
import Layout from "../../components/layout/layout";
import SideBar from "../../components/SideBar/SideBar";
import MainContainer from "../../mainContainer/MainContainer";
import LiveRequestsLeads from "../../components/LiveRequestsLeads/LiveRequestsLeads";
import EmailRequestSales from "../../components/EmailRequestNew/EmailRequestSales";
import HistoryEmailReqSales from "../../components/HistoryEmailReq/HistoryEmailReqSales";

const SalesSupport = () => {
  
  //sidebar logic ------------>
  const [currentComponent, setCurrentComponent] = useState(
    <LiveRequestsLeads />
  );
  const [currentPageTitle, setCurrentPageTitle] = useState(
    "Live Request Leads"
  );

  //sidebar logic ------------>
  const sidebarButtons = [
    {
      label: "Live Requests",
      component: <LiveRequestsLeads />,
      pageTitle: "Live Requests Leads",
    },
    {
        label: "Email Requests",
        component: <EmailRequestSales />,
        pageTitle: "Email Requests page",
    },
    {
        label: "History Emails",
        component: <HistoryEmailReqSales />,
        pageTitle: "History Email Requests",
    }

  ];

  //sidebar logic ------------>
  const handleComponentChange = (component, pageTitle) => {
    setCurrentComponent(component);
    setCurrentPageTitle(pageTitle);
  };

  return (
    <Layout>
      <SideBar
        buttons={sidebarButtons}
        onComponentChange={handleComponentChange}
      />
      <MainContainer pageTitle={currentPageTitle}>
        <h2>Sales Support View</h2>
        {currentComponent}
      </MainContainer>
    </Layout>
  );
};

export default SalesSupport;
