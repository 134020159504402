import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { baseUrl } from "../../services/BaseUrl";
import { UserContext } from "../../context/UserContext";
import {
  Container,
  Typography,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Modal,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./BugsReports.css";

const BugsReports = () => {
  const [bugReports, setBugReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { token } = useContext(UserContext);

  const [openModal, setOpenModal] = useState(false);
  const [selectedBug, setSelectedBug] = useState(null);
  const [status, setStatus] = useState("");
  const [notes, setNotes] = useState("");

  useEffect(() => {
    const fetchBugReports = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/super/bugs-reports`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setBugReports(response.data);
      } catch (err) {
        setError("Failed to load bug reports.");
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      fetchBugReports();
    }
  }, [token]);

  const handleOpenModal = (bug) => {
    setSelectedBug(bug);
    setStatus(bug.status || "");
    setNotes(bug.notes || "");
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedBug(null);
  };

  const handleUpdateBug = async () => {
    try {
      await axios.put(
        `${baseUrl}/api/super/bug-update/${selectedBug._id}`,
        { status, notes },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setBugReports((prevBugs) =>
        prevBugs.map((bug) =>
          bug._id === selectedBug._id ? { ...bug, status, notes } : bug
        )
      );
      handleCloseModal();
    } catch (error) {
      console.error("Failed to update bug report:", error);
    }
  };

  return (
    <Container className="bugs-container">
      <Typography variant="h4" className="bugs-title">
        Bug Reports
      </Typography>
      {loading ? (
        <div className="loading-container">
          <CircularProgress />
        </div>
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        bugReports.map((bug) => (
          <Accordion key={bug._id} className="bugs-accordion">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              className="bugs-summary"
            >
              <Box className="bugs-summary-content">
                <Typography className="bug-name">{bug.name}</Typography>
                <Typography className="bug-status">
                  <strong>Status:</strong> {bug.status || "N/A"}
                </Typography>
                <Typography className="bug-date">
                  <strong>Date:</strong>{" "}
                  {new Date(bug.createdAt).toLocaleString()}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <strong>Email:</strong> {bug.email}
              </Typography>
              <Typography>
                <strong>Role:</strong> {bug.role}
              </Typography>
              <Typography>
                <strong>Shift:</strong> {bug.shift || "N/A"}
              </Typography>
              <Typography>
                <strong>Report:</strong> {bug.report}
              </Typography>
              <Typography>
                <strong>Notes:</strong> {bug.notes || "N/A"}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleOpenModal(bug)}
                style={{ marginTop: "10px" }}
              >
                Update
              </Button>
            </AccordionDetails>
          </Accordion>
        ))
      )}

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box className="modal-box">
          <Typography variant="h6">Update Bug Report</Typography>
          <FormControl fullWidth style={{ marginTop: "10px" }}>
            <InputLabel>Status</InputLabel>
            <Select value={status} onChange={(e) => setStatus(e.target.value)}>
              <MenuItem value="Open">Open</MenuItem>
              <MenuItem value="In Progress">In Progress</MenuItem>
              <MenuItem value="Resolved">Resolved</MenuItem>
              <MenuItem value="Closed">Closed</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Notes"
            multiline
            rows={4}
            fullWidth
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            style={{ marginTop: "10px" }}
          />
          <Box display="flex" justifyContent="space-between" marginTop="20px">
            <Button variant="contained" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdateBug}
            >
              Save Changes
            </Button>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
};

export default BugsReports;
