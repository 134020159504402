import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Modal,
} from "@mui/material";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import "./OldLeadsTable.css";
import axios from "axios";
import { baseUrl } from "../../services/BaseUrl";
import { UserContext } from "../../context/UserContext";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import { GoDash } from "react-icons/go";
import AnswerSection from "../AnswerSection/AnswerSection";

const callStyle = {
  borderRight: "2px solid #e0e0e0",
  fontSize: "14px",
  fontWeight: 550,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  padding: "8px 10px",
};

const OldLeadsTable = () => {
  const { token } = useContext(UserContext);
  const [existingLeads, setExistingLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [selectedShift, setSelectedShift] = useState("");
  const [agentsTeam, setAgentsTeam] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState({});
  const [selectedLead, setSelectedLead] = useState({
    showId: "",
    leadId: "",
    showName: "",
    companyName: "",
    timeZone: "",
    Status: "",
    "Next Contact": "",
    address: "",
    "End Date": "",
    "Next Dates": "",
    "Check-in": "",
    "Check-out": "",
    "Lead Date": "",
    "Rejection Reason": "",
    Notes: "",
    "DM Name": "",
    "DM Rating": "",
    Email: "",
    "Email Type": "",
    emailRequestNotes: "",
    "Days to Call": "",
    "Last Contact": "",
    "Closing Date": "",
    Hotel: "",
    Budget: "",
    " Distance": "",
    Rooms: "",
    "/ Room Stay": "",
    "Total Stay": "",
    Activities: [],
    roomDetails: [],
    "Closer Note": "",
    closerStatus: "",
  });
  const [answerPageActive, setAnswerPageActive] = useState(false);
  const [selectedShowDataToRender, setSelectedShowDataRender] = useState({});
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleShiftChange = (event) => {
    setSelectedShift(event.target.value);
  };

  const fetchOldLeads = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/sales-manager/get-old-leads`,
        { shift: selectedShift },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setExistingLeads(response.data.leads);
    } catch (error) {
      console.error("Error fetching old leads:", error);
    }
  };

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/sales-manager/get-agents-names`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setAgentsTeam(response.data);
      } catch (error) {
        console.error("Error fetching team data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamData();
  }, [token]);

  useEffect(() => {
    if (selectedShift) {
      fetchOldLeads();
    }
  }, [selectedShift]);

  const handleAgentSelect = async (event, leadId) => {
    const agentId = event.target.value;
    const selectedAgent = agentsTeam.find((agent) => agent._id === agentId);
    setSelectedAgent(selectedAgent);

    if (selectedAgent) {
      try {
        const payload = {
          userId: selectedAgent._id,
          leadId: leadId,
        };

        // Make the PUT request
        const response = await axios.put(
          `${baseUrl}/api/sales-manager/assign-old-lead-to-agent`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // After successful assignment, update the lead in callbackLeads state
        existingLeads((prevLeads) => {
          return prevLeads.map((group) => {
            return {
              ...group,
              leads: group.leads.map((lead) => {
                if (lead._id === leadId) {
                  return { ...lead, AssignedTo: agentId }; // Update the lead's assigned agent
                }
                return lead;
              }),
            };
          });
        });
      } catch (error) {
        console.error("Error assigning lead to agent:", error);
      }
    }
  };

  const handleLeadSelect = (leadId) => {
    setSelectedLeads((prev) =>
      prev.includes(leadId)
        ? prev.filter((id) => id !== leadId)
        : [...prev, leadId]
    );
  };

  const handleBulkAssign = async () => {
    if (!selectedAgent || selectedLeads.length === 0) return;

    try {
      await axios.post(
        `${baseUrl}/api/sales-manager/assign-bulk-leads`,
        {
          agentId: selectedAgent,
          leadIds: selectedLeads,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Update UI
      setExistingLeads((prevLeads) =>
        prevLeads.map((lead) =>
          selectedLeads.includes(lead._id)
            ? { ...lead, AssignedTo: selectedAgent }
            : lead
        )
      );

      setSelectedLeads([]); // Clear selection
      setIsModalOpen(false); // Close modal
    } catch (error) {
      console.error("Error assigning leads:", error);
    }
  };

  const handleAnswerClick = useCallback((lead) => {
    const nextDate = new Date(lead?.["Next Dates"]);
    nextDate.setDate(nextDate.getDate() - 1);

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    setSelectedLead({
      showId: lead?.showId,
      leadId: lead?._id,
      showName: lead?.Show,
      companyName: lead?.Lead,
      timeZone: lead?.Timezone,
      Status: lead?.Status,
      "Next Contact": lead?.["Next Contact"],
      address: lead?.address,
      "End Date": lead?.["End Date"],
      "Next Dates": lead?.["Next Dates"],
      "Check-in": lead?.["Check-in"],
      "Check-out": lead?.["Check-out"],
      "Lead Date": lead?.["Lead Date"],
      "Rejection Reason": lead?.["Rejection Reason"],
      Notes: lead?.Notes,
      "DM Name": lead?.["DM Name"],
      "DM Rating": lead?.["DM Rating"],
      Email: lead?.Email,
      "Email Type": lead?.["Email Type"],
      emailRequestNotes: lead?.emailRequestNotes,
      "Days to Call": lead?.["Days to Call"],
      "Last Contact": lead?.["Last Contact"],
      "Closing Date": lead?.["Closing Date"],
      Hotel: lead?.Hotel,
      Budget: lead?.Budget,
      " Distance": lead?.Max?.[" Distance"],
      Rooms: lead?.Rooms,
      "/ Room Stay": lead?.["/ Room Stay"],
      "Total Stay": lead?.["Total Stay"],
      Activities: lead.Activities,
      roomDetails: lead.roomDetails,
      "Closer Note": lead?.["Closer Note"],
      closerStatus: lead?.closerStatus,
    });
    setAnswerPageActive(true);
  }, []);

  const handleCloseAnswerPage = useCallback(() => {
    setAnswerPageActive(false);
  }, []);

  const handleUpdateLead = async () => {
    try {
      const response = await axios.put(
        `${baseUrl}/api/sales-manager/update-old-lead/${selectedLead.leadId}`,
        selectedLead,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Lead updated successfully", response.data);

        // Remove the updated lead from existingLeads state
        setExistingLeads((prevLeads) =>
          prevLeads.filter((lead) => lead._id !== selectedLead.leadId)
        );
      }
    } catch (error) {
      console.error("Error updating lead:", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const currentLeads = existingLeads.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  //   if (loading) {
  //     return <LoadingComponent />;
  //   }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="existing-leads-container">
      <div style={{display:"flex" , alignItems:"center" , gap:"2rem"}}>
        <FormControl
          sx={{ marginBottom: 2, width: "400px", marginTop: 4 }}
          className="shift-select"
        >
          <InputLabel>Select Shift</InputLabel>
          <Select value={selectedShift} onChange={handleShiftChange}>
            <MenuItem value="us">US Shift</MenuItem>
            <MenuItem value="uk">UK Shift</MenuItem>
          </Select>
        </FormControl>
        <Button
          onClick={() => setIsModalOpen(true)}
          disabled={selectedLeads.length === 0}
        >
          Assign Bulk
        </Button>
      </div>
      {!answerPageActive ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  background:
                    "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
                }}
              >
                {[
                  "Lead Name",
                  "Show Name",
                  "Timezone",
                  "Phone",
                  "Email",
                  "Status",
                  "DM Name",
                  "Answer",
                  "Old Agent Name",
                  "Assign Agent",
                  "Select",
                ].map((header, index) => (
                  <TableCell
                    key={header}
                    sx={{
                      color: "#fff",
                      borderRight: "2px solid #fff",
                      fontSize: "16px",
                      fontFamily: '"Playfair Display", serif',
                      whiteSpace: "nowrap",
                      wordBreak: "keep-all",
                      textAlign: index === 5 || index === 6 ? "center" : "left",
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {currentLeads.map((lead, index) => (
                <TableRow key={lead._id}>
                  <TableCell sx={callStyle}>
                    {lead.Lead || (
                      <>
                        <GoDash style={{ fontSize: "20px" }} />
                        <GoDash style={{ fontSize: "20px" }} />
                      </>
                    )}
                  </TableCell>
                  <TableCell sx={callStyle}>
                    {lead.Show || (
                      <>
                        <GoDash style={{ fontSize: "20px" }} />
                        <GoDash style={{ fontSize: "20px" }} />
                      </>
                    )}
                  </TableCell>
                  <TableCell sx={callStyle}>
                    {lead.Timezone || (
                      <>
                        <GoDash style={{ fontSize: "20px" }} />
                        <GoDash style={{ fontSize: "20px" }} />
                      </>
                    )}
                  </TableCell>
                  <TableCell sx={callStyle}>
                    {lead.Phone || (
                      <>
                        <GoDash style={{ fontSize: "20px" }} />
                        <GoDash style={{ fontSize: "20px" }} />
                      </>
                    )}
                  </TableCell>
                  <TableCell sx={callStyle}>
                    {lead.Email || (
                      <>
                        <GoDash style={{ fontSize: "20px" }} />
                        <GoDash style={{ fontSize: "20px" }} />
                      </>
                    )}
                  </TableCell>
                  <TableCell sx={callStyle}>
                    {lead.Status || (
                      <>
                        <GoDash style={{ fontSize: "20px" }} />
                        <GoDash style={{ fontSize: "20px" }} />
                      </>
                    )}
                  </TableCell>
                  <TableCell sx={callStyle}>
                    {lead["DM Name"] || (
                      <>
                        <GoDash style={{ fontSize: "20px" }} />
                        <GoDash style={{ fontSize: "20px" }} />
                      </>
                    )}
                  </TableCell>
                  <TableCell sx={callStyle}>
                    <button
                      style={{
                        padding: "10px 20px",
                        backgroundColor: "#4f637d",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        fontSize: "14px",
                      }}
                      onClick={() => handleAnswerClick(lead)}
                    >
                      Answer
                    </button>
                  </TableCell>
                  <TableCell sx={callStyle}>
                    {lead["agentName"] || (
                      <>
                        <GoDash style={{ fontSize: "20px" }} />
                        <GoDash style={{ fontSize: "20px" }} />
                      </>
                    )}
                  </TableCell>
                  <TableCell style={callStyle}>
                    <select
                      onChange={(e) => handleAgentSelect(e, lead._id)}
                      value={lead.AssignedTo || ""}
                      style={{
                        padding: "8px 12px",
                        borderRadius: "4px",
                        border: "1px solid #ccc",
                        backgroundColor: "#fff",
                        fontSize: "14px",
                        fontFamily: "'Arial', sans-serif",
                        cursor: "pointer",
                        width: "100%",
                        maxWidth: "200px",
                        boxSizing: "border-box",
                        transition: "border-color 0.3s, background-color 0.3s",
                      }}
                    >
                      <option value="" disabled>
                        Select Agent
                      </option>
                      {lead.AssignedTo &&
                        agentsTeam.some(
                          (agent) => agent._id === lead.AssignedTo
                        ) && (
                          <option value={lead.AssignedTo} selected>
                            {
                              agentsTeam.find(
                                (agent) => agent._id === lead.AssignedTo
                              )?.name
                            }
                          </option>
                        )}
                      {agentsTeam.map((agent) => (
                        <option key={agent._id} value={agent._id}>
                          {agent.name}
                        </option>
                      ))}
                    </select>
                  </TableCell>
                  <TableCell>
                    <input
                      type="checkbox"
                      checked={selectedLeads.includes(lead._id)}
                      onChange={() => handleLeadSelect(lead._id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
            <div
              className="modal-content"
              style={{
                background: "white",
                padding: "20px",
                borderRadius: "8px",
                width: "400px",
                margin: "auto",
                textAlign: "center",
                marginTop: "10rem",
              }}
            >
              <h2 style={{ marginBottom: "2rem" }}>Assign Leads to an Agent</h2>
              <FormControl fullWidth>
                <InputLabel>Select Agent</InputLabel>
                <Select
                  value={selectedAgent}
                  onChange={(e) => setSelectedAgent(e.target.value)}
                >
                  {agentsTeam.map((agent) => (
                    <MenuItem key={agent._id} value={agent._id}>
                      {agent.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                onClick={handleBulkAssign}
                variant="contained"
                color="primary"
                sx={{ marginTop: 2 }}
              >
                Assign Leads
              </Button>
            </div>
          </Modal>
        </TableContainer>
      ) : (
        selectedLead && (
          <AnswerSection
            handleCloseAnswerPage={handleCloseAnswerPage}
            handleUpdateLead={handleUpdateLead}
            selectedLead={selectedLead}
            setSelectedLead={setSelectedLead}
            selectedShowDataToRender={selectedShowDataToRender}
          />
        )
      )}

      <TablePagination
        sx={{
          backgroundColor: "#fff",
          borderTop: "1px solid #e0e0e0",
        }}
        rowsPerPageOptions={[8, 10]}
        component="div"
        count={existingLeads.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default OldLeadsTable;
