import React, { useState, useEffect } from "react";
import "./Tabs.css";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { GoDash } from "react-icons/go";
import RequestsWebSocket from "../RequestsWebSocket/RequestsWebSocket";

const callStyle = {
  borderRight: "2px solid #e0e0e0",
  fontSize: "14px",
  fontWeight: 550,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  padding: "6px 10px",
  textAlign: "left",
};

const optionsReason = [
  { label: "Competition Rate", value: "Competition Rate" },
  { label: "Official Housing Only", value: "Official Housing Only" },
  { label: "Recently Booked", value: "Recently Booked" },
  { label: "Early Booked", value: "Early Booked" },
  { label: "Not Interested", value: "Not Interested" },
  { label: "All Set", value: "All Set" },
  { label: "Airbnb", value: "Airbnb" },
  { label: "Local", value: "Local" },
  { label: "Homeowner", value: "Homeowner" },
  { label: "Free Rooms", value: "Free Rooms" },
  { label: "Next Show", value: "Next Show" },
  { label: "Bad Experience 3rd Party", value: "Bad Experience 3rd Party" },
  { label: "Escalate", value: "Escalate" },
  { label: "Try Again", value: "Try Again" },
];
const optionsEmailType = [
  { label: "Not yet", value: "Not yet" },
  {
    label: "Pricing (1 Day Before, Last Day)",
    value: "Pricing (1 Day Before, Last Day)",
  },
  {
    label: "Pricing (2 Day Before, Last Day)",
    value: "Pricing (2 Day Before, Last Day)",
  },
  {
    label: "Pricing (3 Day Before, Last Day)",
    value: "Pricing (3 Day Before, Last Day)",
  },
  {
    label: "Pricing (Same Day, Last Day)",
    value: "Pricing (Same Day, Last Day)",
  },
  {
    label: "Pricing (1 Day Before, Day After)",
    value: "Pricing (1 Day Before, Day After)",
  },
  {
    label: "Pricing (2 Day Before, Day After)",
    value: "Pricing (2 Day Before, Day After)",
  },
  {
    label: "Pricing (2 Day Before, 2 Day After)",
    value: "Pricing (2 Day Before, 2 Day After)",
  },
  {
    label: "Contact Card for Future Reference",
    value: "Contact Card for Future Reference",
  },
  { label: "Mixed", value: "Mixed" },
];
const optionsPreferences = [
  { label: "King Bed", value: "King Bed" },
  { label: "2 Queen Beds", value: "2 Queen Beds" },
  { label: "King & Queens", value: "King & Queens" },
  { label: "Triple", value: "Triple" },
  { label: "1 Bed Suite", value: "1 Bed Suite" },
  { label: "2 Bed Suite", value: "2 Bed Suite" },
  { label: "1-Bedroom Apt/Suite", value: "1-Bedroom Apt/Suite" },
  { label: "2-Bedroom Apt/Suite", value: "2-Bedroom Apt/Suite" },
  { label: "3-Bedroom Apt/Suite", value: "3-Bedroom Apt/Suite" },
  { label: "Airbnb", value: "Airbnb" },
  { label: "Villa", value: "Villa" },
];
const optionsAmenities = [
  { label: "Room Only", value: "Room Only" },
  { label: "Breakfast", value: "Breakfast" },
  { label: "HB", value: "HB" },
  { label: "FB", value: "FB" },
  { label: "AI", value: "AI" },
  { label: "Kitchenette", value: "Kitchenette" },
  { label: "Balcony", value: "Balcony" },
  { label: "Others", value: "Others" },
];
const optionsActivities = [
  { label: "Transfers", value: "Transfers" },
  { label: "Car Rental", value: "Car Rental" },
  { label: "Air Ticket", value: "Air Ticket" },
  { label: "Platform Access", value: "Platform Access" },
];

const Tabs = ({
  selectedLead,
  setSelectedLead,
  sendEmailRequest,
  userRole,
  selectedShowDataToRender,
  selectedShowDataToRenderH,
}) => {
  const [value, setValue] = React.useState("1");
  const [emailError, setEmailError] = useState("");
  const [alertEmail, setAlertEmail] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [selectedActivities, setSelectedActivities] = useState(
    selectedLead.Activities || []
  );
  const [selectedPreferences, setSelectedPreferences] = useState("");
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [isEmailButtonDisabled, setIsEmailButtonDisabled] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    // Email validation logic
    if (name === "Email") {
      // Basic email validation regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        setEmailError("Please enter a valid email address [must contain @]");
      } else {
        setEmailError(""); // Clear error if the email is valid
      }
    }

    if (name === "Email Type") {
      if (
        value !== "Pricing (1 Day Before, Last Day)" &&
        value !== "Contact Card for Future Reference" &&
        value !== "Pricing (Same Day, Last Day)"
      ) {
        setAlertEmail("You must enter number of rooms.");
      } else {
        setAlertEmail("");
      }
    }

    // Prevent negative numbers for specific fields ("DM Rating", "Rooms", "Total Stay")
    if (
      type === "number" &&
      (name === "DM Rating" ||
        name === "Rooms" ||
        name === "Total Stay" ||
        name === "Budget")
    ) {
      const numValue = Number(value);
      if (numValue < 0) {
        // Update the state with 0 instead of modifying value directly
        setSelectedLead((prevState) => ({
          ...prevState,
          [name]: 0,
        }));
        return; // Exit early to avoid further state updates
      }
    }

    setSelectedLead((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (event) => {
    const { checked, name } = event.target;

    setSelectedActivities((prevActivities) => {
      let updatedActivities;
      if (checked) {
        // Add the activity to the array
        updatedActivities = [...prevActivities, name];
      } else {
        // Remove the activity from the array
        updatedActivities = prevActivities.filter(
          (activity) => activity !== name
        );
      }
      // Update the selectedLead with the new Activities list
      setSelectedLead((prevState) => ({
        ...prevState,
        Activities: updatedActivities,
      }));
      return updatedActivities; // Update local selectedActivities state
    });
  };

  // Handle changes in Preferences or Amenities
  const handlePreferencesChange = (e) => {
    setSelectedPreferences(e.target.value);
  };

  const handleAmenitiesChange = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setSelectedAmenities((prevAmenities) => [...prevAmenities, value]);
    } else {
      setSelectedAmenities((prevAmenities) =>
        prevAmenities.filter((amenity) => amenity !== value)
      );
    }
  };

  // Add room details to the selectedLead
  // const handleAddRoomDetails = () => {
  //   if (selectedPreferences && selectedAmenities.length > 0) {
  //     const newRoomDetails = {
  //       [`Room${(selectedLead.roomDetails || []).length + 1}`]: {
  //         Preferences: selectedPreferences,
  //         Amenities: selectedAmenities,
  //       },
  //     };

  //     setSelectedLead((prevState) => ({
  //       ...prevState,
  //       roomDetails: [...(prevState.roomDetails || []), newRoomDetails],
  //     }));

  //     setSelectedPreferences("");
  //     setSelectedAmenities([]);
  //   }
  // };
  const handleAddRoomDetails = (selectedLead) => {
    const newRoom = {
      Preferences: selectedPreferences,
      Amenities: selectedAmenities,
    };

    setSelectedLead((prevState) => ({
      ...prevState,
      roomDetails: [...prevState.roomDetails, newRoom],
    }));

    setSelectedPreferences("");
    setSelectedAmenities([]);
  };

  const leadDateTime = selectedLead?.["Lead Date"];
  const formattedDateTime = leadDateTime
    ? leadDateTime.slice(0, 19).replace("Z", "")
    : "";

  const sendEmailRequestButton = () => {
    sendEmailRequest();

    setIsButtonDisabled(true);

    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };

  const handleSendEmailToClientClick = () => {
    setSelectedLead((prevState) => ({
      ...prevState,
      clientEmailRequest: true,
    }));
    setIsEmailButtonDisabled(true); // Disable the button after clicking
  };

  const renderInputField = (label, type, name, id, value, extraProps = {}) => {
    const today = new Date().toISOString().split("T")[0];
    return (
      <Grid item xs={6}>
        <TextField
          fullWidth
          label={label}
          variant="outlined"
          type={type}
          name={name}
          id={id}
          value={value || ""}
          onChange={handleInputChange}
          InputLabelProps={
            type === "date" || type === "email" ? { shrink: true } : undefined
          }
          InputProps={
            type === "number" ? { inputProps: { min: 0 } } : undefined
          }
          inputProps={type === "date" ? { min: today } : undefined}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "#bd904e", fontSize: "16px" },
              "&:hover fieldset": { borderColor: "#a67c3e" },
              "&.Mui-focused fieldset": {
                borderColor: "#8c5e2f",
                boxShadow: "0 0 5px rgba(189, 144, 78, 0.5)",
              },
              "& input": {
                padding: "6px 12px",
                height: "38px",
                fontSize: "14px",
              },
            },
          }}
          {...extraProps}
        />
      </Grid>
    );
  };

  const renderSelectField = (
    label,
    name,
    id,
    value,
    options,
    extraProps = {}
  ) => {
    const isPreferences = label === "Preferences";

    return (
      <Grid item xs={isPreferences ? 12 : 6}>
        <TextField
          fullWidth
          select
          label={label}
          name={name}
          id={id}
          value={value || ""}
          onChange={isPreferences ? handlePreferencesChange : handleInputChange}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "#bd904e", fontSize: "16px" },
              "&:hover fieldset": { borderColor: "#a67c3e" },
              "&.Mui-focused fieldset": {
                borderColor: "#8c5e2f",
                boxShadow: "0 0 5px rgba(189, 144, 78, 0.5)",
              },
            },
            "& .MuiSelect-select": {
              paddingTop: "6px", // Adjust padding inside select
              paddingBottom: "6px", // Adjust padding inside select
              height: "38px",
            },
            "& .MuiInputLabel-root": {
              fontSize: "14px", // Reduced font size for label
            },
          }}
          {...extraProps}
        >
          <MenuItem value="" disabled>
            Select a reason
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        typography: "body1",
        backgroundColor: "white",
        color: "black",
      }}
    >
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: "2px solid #37373f",
            borderColor: "divider",
            background: "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
            padding: 1,
          }}
        >
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {[
              "Client Communication",
              ...(userRole !== "agent" ? ["Follow-Up & Timeline"] : []),
              "Booking & Preferences",
              ...(userRole !== "agent" ? ["Lead Information"] : []),
              "Pricing Table",
            ].map((label, index) => (
              <Tab
                key={index}
                label={label}
                value={(index + 1).toString()}
                sx={{
                  color: "#fff",
                  fontFamily: '"Playfair Display", serif',
                  letterSpacing: 1,
                  fontSize: 16,
                  fontWeight: 700,
                  "&:hover": {
                    color: "#fff",
                  },
                  "&.Mui-selected": {
                    color: "#fff",
                  },
                }}
              />
            ))}
          </TabList>
        </Box>
        <TabPanel value="1">
          <div className="tab-panel-container">
            <div className="tab-panel-inputs">
              <Grid container spacing={2}>
                {renderInputField(
                  "Notes",
                  "text",
                  "Notes",
                  "notes",
                  selectedLead?.Notes
                )}
                {renderInputField(
                  "Contact Name",
                  "text",
                  "DM Name",
                  "contact-name",
                  selectedLead?.["DM Name"]
                )}
                {renderInputField(
                  "DM Rating",
                  "number",
                  "DM Rating",
                  "dm-rating",
                  selectedLead?.["DM Rating"]
                )}
                {renderInputField(
                  "Email",
                  "email",
                  "Email",
                  "email",
                  selectedLead?.Email,
                  {
                    helperText: emailError && (
                      <span style={{ color: "red" }}>{emailError}</span>
                    ),
                    error: !!emailError,
                  }
                )}
              </Grid>
            </div>
            <div className="tab-panel-emailreq-inputs">
              <h3
                style={{
                  letterSpacing: 1,
                  color: "#cebfbf",
                  marginBottom: 5,
                }}
              >
                Email Request:
              </h3>
              <div className="tab-panel-inputs">
                <div className="input-tab-group">
                  <label
                    htmlFor="Email Type"
                    style={{
                      color: "#fff",
                    }}
                  >
                    Email Type :
                  </label>
                  <select
                    name="Email Type"
                    id="Email Type"
                    className="tab-input input-dropdown"
                    value={selectedLead?.["Email Type"]}
                    onChange={handleInputChange}
                  >
                    <option value="" hidden>
                      Select a type...
                    </option>
                    {optionsEmailType.map((optionItem) => (
                      <option key={optionItem.value} value={optionItem.value}>
                        {optionItem.label}
                      </option>
                    ))}
                  </select>
                  {alertEmail && <p className="message-error">{alertEmail}</p>}
                </div>
                <div className="input-tab-group">
                  <label
                    htmlFor="Email Notes"
                    style={{
                      color: "#fff",
                    }}
                  >
                    Email Notes :
                  </label>
                  <input
                    type="text"
                    name="emailRequestNotes"
                    id="Email Notes"
                    className="tab-input"
                    value={selectedLead?.emailRequestNotes}
                    onChange={handleInputChange}
                  />
                </div>
                <button
                  style={{
                    letterSpacing: 1,
                    padding: "8px 25px",
                    cursor: isButtonDisabled ? "not-allowed" : "pointer",
                    borderRadius: "4px",
                    fontSize: "16px",
                    fontWeight: "700",
                    background: isButtonDisabled ? "#dcdcdc" : "#cebfbf", // Change background color when disabled
                    color: isButtonDisabled ? "#a1a1a1" : "#000", // Change text color when disabled
                    opacity: isButtonDisabled ? 0.6 : 1,
                  }}
                  onClick={sendEmailRequestButton}
                  disabled={isButtonDisabled}
                >
                  Send Email Request
                </button>
              </div>
            </div>
          </div>
        </TabPanel>
        {(userRole === "sales manager" || userRole === "agent closer") && (
          <TabPanel value="2" sx={{ height: "100%" }}>
            <div className="tab-panel-container">
              <div className="tab-panel-inputs">
                <div className="input-tab-group">
                  <label htmlFor="daysToCall" disabled>
                    Days to Call :
                  </label>
                  <input
                    type="number"
                    name="Days to Call"
                    id="daysToCall"
                    className="tab-input"
                    min="0"
                    disabled
                    value={selectedLead?.["Days to Call"]}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="input-tab-group">
                  <label htmlFor="lastContact">Last Contact : </label>
                  <input
                    type="text"
                    name="Last Contact"
                    id="lastContact"
                    className="tab-input"
                    value={selectedLead?.["Last Contact"]}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="input-tab-group">
                  <label htmlFor="closingDate">Closing Date : </label>
                  <input
                    type="date"
                    name="Closing Date"
                    id="closingDate"
                    className="tab-input"
                    value={selectedLead?.["Closing Date"]}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
        )}
        <TabPanel value={userRole === "agent" ? "2" : "3"}>
          <div className="tab-panel-container">
            <div className="tab-panel-inputs-tab2">
              <Grid container spacing={2}>
                {renderInputField(
                  "Hotel",
                  "text",
                  "Hotel",
                  "hotel",
                  selectedLead?.Hotel
                )}
                {renderInputField(
                  "Budget",
                  "number",
                  "Budget",
                  "budget",
                  selectedLead?.Budget
                )}
                {renderInputField(
                  "Max. Distance",
                  "text",
                  " Distance",
                  " Distance",
                  selectedLead?.[" Distance"]
                )}
                {renderInputField(
                  "Rooms",
                  "number",
                  "Rooms",
                  "rooms",
                  selectedLead?.Rooms
                )}
                {renderInputField(
                  "Check-in",
                  "date",
                  "Check-in",
                  "checkIn",
                  selectedLead?.["Check-in"]
                )}
                {renderInputField(
                  "Check-out",
                  "date",
                  "Check-out",
                  "checkOut",
                  selectedLead?.["Check-out"]
                )}

                <Grid container spacing={2} item xs={12}>
                  {/* Preferences field */}
                  <Grid item xs={4}>
                    {renderSelectField(
                      "Preferences",
                      "Preferences",
                      "Preferences",
                      selectedPreferences,
                      optionsPreferences
                    )}
                  </Grid>

                  {/* Amenities with checkboxes */}
                  <Grid item xs={8}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mb={-2}
                    >
                      <Typography variant="h6" gutterBottom>
                        Amenities:
                      </Typography>
                      <button
                        style={{
                          marginLeft: "8px",
                          padding: "8px 25px",
                          color: "#fff",
                          background: "#4D3E4E",
                          borderRadius: "8px",
                        }}
                        onClick={() => handleAddRoomDetails(selectedLead)}
                      >
                        Add
                      </button>
                    </Box>
                    <div>
                      {optionsAmenities.map((option) => (
                        <FormControlLabel
                          key={option.value}
                          control={
                            <Checkbox
                              name={option.value}
                              value={option.value}
                              checked={selectedAmenities.includes(option.value)}
                              onChange={handleAmenitiesChange}
                              color="primary"
                            />
                          }
                          label={option.label}
                        />
                      ))}
                    </div>
                  </Grid>
                </Grid>
                {selectedLead?.roomDetails?.length > 0 && (
                  <div
                    style={{
                      width: "100%",
                      border: "1.5px solid #37373F",
                      padding: "6px",
                      borderRadius: "6px",
                      margin: "8px 0 0 8px",
                    }}
                  >
                    {selectedLead?.roomDetails?.map((room, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <h3 style={{ color: "#655272", fontSize: "22px" }}>
                          Room {index + 1}:
                        </h3>{" "}
                        <span style={{ lineHeight: "18px" }}>
                          <strong>Preferences:</strong>
                          {room.Preferences}
                        </span>
                        ,
                        <span style={{ lineHeight: "18px" }}>
                          <strong> Amenities:</strong>
                          {room.Amenities.join(", ")}
                        </span>
                      </div>
                    ))}
                  </div>
                )}

                {/* New checkbox section */}
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom mb={-1}>
                    Activities:
                  </Typography>
                  <div>
                    {optionsActivities.map((option) => (
                      <FormControlLabel
                        key={option.value}
                        control={
                          <Checkbox
                            name={option.value}
                            checked={selectedActivities.includes(option.value)}
                            onChange={handleCheckboxChange}
                            color="primary"
                          />
                        }
                        label={option.label}
                      />
                    ))}
                  </div>
                </Grid>
                {renderInputField(
                  "/ Room Stay",
                  "text",
                  "/ Room Stay",
                  "roomStay",
                  selectedLead?.["/ Room Stay"]
                )}
                {renderInputField(
                  "Total Stay",
                  "number",
                  "Total Stay",
                  "totalStay",
                  selectedLead?.["Total Stay"]
                )}
              </Grid>
            </div>
            <RequestsWebSocket
              selectedLead={selectedLead}
              setSelectedLead={setSelectedLead}
            />
          </div>
        </TabPanel>
        {(userRole === "sales manager" || userRole === "agent closer") && (
          <TabPanel value="4">
            <div className="tab-panel-container">
              <div className="tab-panel-inputs">
                <div className="input-tab-group">
                  <label htmlFor="leadDateTime">Lead Date: </label>
                  <input
                    type="datetime-local"
                    name="Lead Date"
                    id="leadDateTime"
                    className="tab-input"
                    disabled
                    value={formattedDateTime}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
        )}
        <TabPanel value={userRole === "agent" ? "3" : "5"}>
          <div style={{ display: "flex", justifyContent: "spa" }}>
            <h3 style={{ letterSpacing: 1, color: "#e8b6ea" }}>
              Address of Show:
              <span style={{ fontWeight: "normal", color: "#555" }}>
                {selectedLead?.address || selectedShowDataToRender?.show?.Venue}
              </span>
            </h3>
            <button
              style={{
                marginLeft: "8px",
                padding: "8px 25px",
                color: "#fff",
                background: "#4D3E4E",
                borderRadius: "8px",
              }}
              onClick={handleSendEmailToClientClick}
              disabled={isEmailButtonDisabled}
            >
              Send Email To Client
            </button>
          </div>
          <div className="table-in-peicing-tab">
            {selectedLead && (
              <TableContainer
                component={Paper}
                sx={{
                  marginTop: 2,
                  overflow: "auto",
                  maxHeight: "45vh",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        background:
                          "linear-gradient(to right, #fed766, #e8b6ea)",
                      }}
                    >
                      {[
                        "Hotel Name",
                        "Stars",
                        "Selling Price",
                        "Boarding Type",
                        "Distance from Venue",
                        "Room Type",
                        "Online Pricing",
                        "Hotel Address",
                        "Free Cancellation Until",
                        "Amenities",
                      ].map((header, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            borderRight: "2px solid #fff",
                            fontSize: "16px",
                            fontFamily: '"Playfair Display", serif',
                            whiteSpace: "nowrap",
                            wordBreak: "keep-all",
                          }}
                        >
                          {header}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(
                      selectedLead?.Pricings ||
                      selectedShowDataToRender?.pricings ||
                      selectedShowDataToRenderH?.Pricing
                    )?.map((pricing, index) =>
                      pricing?.Hotels?.map((row, hotelIndex) => (
                        <TableRow key={`${index}-${hotelIndex}`}>
                          <TableCell
                            sx={{
                              borderRight: "2px solid #e0e0e0",
                              fontSize: "14px",
                              fontWeight: 550,
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                              padding: "12px",
                            }}
                          >
                            {row["Hotel Name"] || (
                              <>
                                <GoDash style={{ fontSize: "20px" }} />
                                <GoDash style={{ fontSize: "20px" }} />
                              </>
                            )}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderRight: "2px solid #e0e0e0",
                              fontSize: "14px",
                              fontWeight: 550,
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                              padding: "12px",
                            }}
                          >
                            {row["Stars"] || (
                              <>
                                <GoDash style={{ fontSize: "20px" }} />
                                <GoDash style={{ fontSize: "20px" }} />
                              </>
                            )}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderRight: "2px solid #e0e0e0",
                              fontSize: "14px",
                              fontWeight: 550,
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                              padding: "12px",
                            }}
                          >
                            {row["Average Nightly Rate with Markup"] || (
                              <>
                                <GoDash style={{ fontSize: "20px" }} />
                                <GoDash style={{ fontSize: "20px" }} />
                              </>
                            )}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderRight: "2px solid #e0e0e0",
                              fontSize: "14px",
                              fontWeight: 550,
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                              padding: "12px",
                            }}
                          >
                            {row["Boarding Type"] || (
                              <>
                                <GoDash style={{ fontSize: "20px" }} />
                                <GoDash style={{ fontSize: "20px" }} />
                              </>
                            )}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderRight: "2px solid #e0e0e0",
                              fontSize: "14px",
                              fontWeight: 550,
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                              padding: "12px",
                            }}
                          >
                            {row["Distance"] || (
                              <>
                                <GoDash style={{ fontSize: "20px" }} />
                                <GoDash style={{ fontSize: "20px" }} />
                              </>
                            )}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderRight: "2px solid #e0e0e0",
                              fontSize: "14px",
                              fontWeight: 550,
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                              padding: "12px",
                            }}
                          >
                            {row["Room Type"] || (
                              <>
                                <GoDash style={{ fontSize: "20px" }} />
                                <GoDash style={{ fontSize: "20px" }} />
                              </>
                            )}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderRight: "2px solid #e0e0e0",
                              fontSize: "14px",
                              fontWeight: 550,
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                              padding: "12px",
                            }}
                          >
                            {row["Online Pricing"] || (
                              <>
                                <GoDash style={{ fontSize: "20px" }} />
                                <GoDash style={{ fontSize: "20px" }} />
                              </>
                            )}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderRight: "2px solid #e0e0e0",
                              fontSize: "14px",
                              fontWeight: 550,
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                              padding: "12px",
                            }}
                          >
                            {row["Address"] || (
                              <>
                                <GoDash style={{ fontSize: "20px" }} />
                                <GoDash style={{ fontSize: "20px" }} />
                              </>
                            )}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderRight: "2px solid #e0e0e0",
                              fontSize: "14px",
                              fontWeight: 550,
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                              padding: "12px",
                            }}
                          >
                            {row["Free Cancellation Until"] || (
                              <>
                                <GoDash style={{ fontSize: "20px" }} />
                                <GoDash style={{ fontSize: "20px" }} />
                              </>
                            )}
                          </TableCell>
                          <TableCell sx={callStyle}>
                            <TableContainer
                              component={Paper}
                              sx={{
                                borderRadius: "8px 8px 0 0 ",
                              }}
                            >
                              <Table>
                                <TableHead>
                                  <TableRow
                                    sx={{
                                      background:
                                        "linear-gradient(to right, #fed766, #e8b6ea)",
                                    }}
                                  >
                                    {[
                                      "Air Conditioned",
                                      "Gym",
                                      "Internet",
                                      "Parking",
                                      "24-Hour Reception",
                                      "Restaurant",
                                      "Spa",
                                      "Swimming Pool",
                                    ].map((header, index) => (
                                      <TableCell
                                        key={header}
                                        sx={{
                                          color: "#fff",
                                          borderRight: "2px solid #fff",
                                          fontSize: "16px",
                                          fontFamily:
                                            '"Playfair Display", serif',
                                          whiteSpace: "nowrap",
                                          wordBreak: "keep-all",
                                        }}
                                      >
                                        {header}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell sx={callStyle}>
                                      {row.Amenities[0]?.airConditioned
                                        ? "Yes"
                                        : "No"}
                                    </TableCell>
                                    <TableCell sx={callStyle}>
                                      {row.Amenities[0]?.gym ? "Yes" : "No"}
                                    </TableCell>
                                    <TableCell sx={callStyle}>
                                      {row.Amenities[0]?.internet
                                        ? "Yes"
                                        : "No"}
                                    </TableCell>
                                    <TableCell sx={callStyle}>
                                      {row.Amenities[0]?.parking ? "Yes" : "No"}
                                    </TableCell>
                                    <TableCell sx={callStyle}>
                                      {row.Amenities[0]?.reception24Hour
                                        ? "Yes"
                                        : "No"}
                                    </TableCell>
                                    <TableCell sx={callStyle}>
                                      {row.Amenities[0]?.restaurant
                                        ? "Yes"
                                        : "No"}
                                    </TableCell>
                                    <TableCell sx={callStyle}>
                                      {row.Amenities[0]?.spa ? "Yes" : "No"}
                                    </TableCell>
                                    <TableCell sx={callStyle}>
                                      {row.Amenities[0]?.swimmingPool
                                        ? "Yes"
                                        : "No"}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default Tabs;
