import React , {useContext,useState,useEffect} from "react";
import "./RequestsWebSocket.css";
import axios from 'axios';
import {baseUrl} from "../../services/BaseUrl"
import { UserContext } from "../../context/UserContext";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";


const RequestsWebSocket = ({ selectedLead, setSelectedLead }) => {
    const { token } = useContext(UserContext);
    const [open, setOpen] = useState(false);
    const [requestedData, setRequestedData] = useState(" ");
  
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSendRequest = async (requestSelected) => {
      console.log("requestSelected",requestSelected);
      
      try {
        const response = await axios.post(
          `${baseUrl}/api/agents/send-request`,
          {
            showId: selectedLead.showId,
            companyId: selectedLead.leadId,
            request: requestSelected,
            requestedData:requestedData,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("response", response);
        handleClose()
        setRequestedData(" ")
      } catch (error) {
        console.error("Error sending request:", error);
      }
    };

  return (
    <div className="tab-panel-requests-inputs">
      <button
        style={{
          background: "#4F637D",
          color: "#fff",
          padding: "10px 20px",
          borderRadius: "10px",
        }}
        onClick={()=>handleSendRequest("Live Request")}
      >
        Live Request
      </button>
      <button
        style={{
          background: "#4F637D",
          color: "#fff",
          padding: "10px 20px",
          borderRadius: "10px",
        }}
        onClick={()=>handleSendRequest("Callback Request")}
      >
        Callback Request
      </button>
      <button
        style={{
          background: "#4F637D",
          color: "#fff",
          padding: "10px 20px",
          borderRadius: "10px",
        }}
        onClick={handleOpen}
      >
        Custom Request
      </button>

      <Modal open={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: "10px",
            padding: 2,
            boxShadow: 24,
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Custom Request Form
          </Typography>

          <TextField
            fullWidth
            label="Requested Data"
            variant="outlined"
            value={requestedData}
            onChange={(e) => setRequestedData(e.target.value)}
            sx={{ mb: 2 }}
          />

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              onClick={()=>handleSendRequest("Custom Request")}
              variant="contained"
              sx={{ backgroundColor: "#4F637D", color: "#fff" }}
            >
              Send Request
            </Button>
            <Button
              onClick={handleClose}
              variant="outlined"
              sx={{ color: "#4F637D" }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default RequestsWebSocket;

