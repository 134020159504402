import React from "react";
import { CircularProgress } from '@mui/material'; 

const LoadingSection = () => {
  return (
    <div
      style={{
        textAlign: "center",
        padding: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <CircularProgress /> <span style={{ color: "#fff" }}>Loading...</span>
    </div>
  );
};

export default LoadingSection;
