import React, { useState, useEffect,useContext } from "react";
import "./MainContainer.css";
import LoginTimer from "../components/LoginTimer/LoginTimer";
import { UserContext } from "../context/UserContext";

const MainContainer = ({ children, pageTitle }) => {
  const [userName, setUserName] = useState("");
  const [role, setRole] = useState("");
  const { token } = useContext(UserContext);
  const { logout } = useContext(UserContext);

  useEffect(() => {
    const storedGitName = localStorage.getItem("name");
    const storedRole = localStorage.getItem("role");

    if (storedGitName) {
      setUserName(storedGitName);
    }
    if (storedRole) {
      setRole(storedRole);
    }
  }, []);

  const title = React.Children.toArray(children)[0];
  const content = React.Children.toArray(children).slice(1);

  // const renderWelcomeMessage = role !== "super user";

  const handleLogout = () => {
    logout();
  };

  return (
    <div className="mainSection-container">
      <div className="mainSection-header-container">
        <>{title}</>
        <h2>{pageTitle}</h2>
      </div>
      <>
        <div className="top-line"></div>
        <div className="lettel-bar-container">
          <p className="lettel-bar-text">
            Hello <span className="span-lettel-bar">{userName}</span>, welcome
            to the CRM Website!
          </p>
          <div className="lettel-bar-info-contain">
            <LoginTimer />
            <button
            className="lettel-bar-info-btn"
            onClick={handleLogout}
             >Logout</button>
          </div>
        </div>
      </>

      <div className="bottom-line"></div>
      <div className="mainSection-content-container">{content}</div>
    </div>
  );
};

export default MainContainer;
