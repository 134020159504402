import React, { useEffect, useState, useContext } from "react";
import "./AllCallbacks.css";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import { baseUrl } from "../../services/BaseUrl";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import LoadingSection from "../LoadingComponent/LoadingSection";
import AssignCallbackLead from "./AssignCallbackLead";
import AllCallbakLeads from "./AllCallbakLeads";
import CallbackRequests from "./CallbackRequests";

const AllCallbacks = () => {
  const { token } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentDate, setCurrentDate] = useState("");
  const [callbackLeads, setCallbackLeads] = useState([]);
  const [callbackRequests, setCallbackRequests] = useState([]);
  const today = new Date().toISOString().split('T')[0];
  const [selectedDate, setSelectedDate] = useState(today);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
    setCurrentDate(event.target.value)
  };

  useEffect(() => {
    const fetchCallbackRequests = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${baseUrl}/api/support/live-requests`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const filteredRequests = response.data.filter(
          (request) =>
            request.Request === "Callback Request"
        );
        setCallbackRequests(filteredRequests);
      } catch (err) {
        setError("Error fetching live requests");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCallbackRequests();
  }, [token]);

  useEffect(() => {
    const date = new Date();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    setCurrentDate(formattedDate);
  }, []);

  useEffect(() => {
    const fetchCallbackLeads = async () => {
      if (!token) {
        console.error("No token available for authorization.");
        return;
      }

      try {
        setLoading(true);
        const response = await axios.post(
          `${baseUrl}/api/sales-manager/get-callback-leads`,
          {date:selectedDate},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data);
        
        setCallbackLeads(response.data);
        setLoading(false)
      } catch (error) {
        console.error("Error fetching callback leads:", error);
        setLoading(false)
      }
    };

    fetchCallbackLeads();
  }, [token,selectedDate]);

  // if (loading) {
  //   return <LoadingComponent />;
  // }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="allcallback-sales-manager-section">
      <div className="callback-sales-container">
        <h3
          style={{
            textTransform: "capitalize",
            marginBottom: "12px",
            letterSpacing: "1.5px",
          }}
        >
          Callback Requests
        </h3>
        <CallbackRequests callbackRequests={callbackRequests} />
      </div>
      <div className="callback-sales-container">
        <h3
          style={{
            textTransform: "capitalize",
            marginBottom: "12px",
            letterSpacing: "1.5px",
          }}
        >
          Callback Leads of{" "}
          <span style={{ color: "#e1b5d7" }}>{currentDate}</span>
        </h3>
        <div className="callback-sales-contain">
          <div className="assigned-leads-filters-container">
            <div className="assigned-lead-filtetr">
              <label
                htmlFor="dateInput"
                style={{
                  color: "#e1b5d7",
                  fontWeight: 600,
                  fontSize: 18,
                  letterSpacing: 1.2,
                  fontFamily: '"Playfair Display", serif',
                  marginRight: "10px",
                }}
              >
                Choose a Date:
              </label>
              <input
                type="date"
                id="dateInput"
                name="dateInput"
                value={selectedDate}
                onChange={handleDateChange}
                style={{
                  textTransform: "uppercase",
                  fontFamily: '"Playfair Display", serif',
                  padding: "6px 25px",
                  fontSize: "16px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  letterSpacing: 1.5,
                }}
              />
            </div>
          </div>
          {loading ? (
            <LoadingSection />
          ) : (
            <AssignCallbackLead callbackLeads={callbackLeads} setCallbackLeads={setCallbackLeads} />
          )}
        </div>
      </div>
      {/* <div className="callback-sales-container">
        <h3
          style={{
            textTransform: "capitalize",
            marginBottom: "12px",
            letterSpacing: "1.5px",
          }}
        >
          All Callback Leads
        </h3>
        <div className="callback-sales-contain">
          <AllCallbakLeads callbackLeads={callbackLeads}/>
        </div>
      </div> */}
    </div>
  );
};

export default AllCallbacks;
