import React, { useState, useEffect, useContext } from "react";
import "./HistoryEmailReq.css";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TextField,
  TablePagination,
  Dialog, DialogActions, DialogContent, DialogTitle
} from "@mui/material";
import { UserContext } from "../../context/UserContext";
import axios from "axios";
import { baseUrl } from "../../services/BaseUrl";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import { GiStaryu } from "react-icons/gi";
import { GoDash } from "react-icons/go";
import { FaEdit } from "react-icons/fa";

const callStyle = {
  fontSize: 16,
  fontWeight: 700,
  textAlign: "center",
  borderRight: "2px solid #e0e0e0",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  padding: "9px",
};

const renderSelectRawInfo = (renderData, field) => {
  return renderData?.[field] || (
    <>
      <GoDash style={{ fontSize: "20px" }} />
      <GoDash style={{ fontSize: "20px" }} />
    </>
  );
};

const HistoryEmailReq = () => {
  const { token } = useContext(UserContext);
    const [selectedShift, setSelectedShift] = useState(() => {
      return localStorage.getItem("selectedShift");
    });
  const [historyDate, setHistoryDate] = useState(new Date().toISOString().split('T')[0])
  const [historyEmailData, setHistoryEmailData] = useState([])
  const [searchQuery, setSearchQuery] = useState("");
  const [emailQuery, setEmailQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [openEmailEdit, setOpenEmailEdit] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  

  const fetchHistoryEmailData = async (date) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${baseUrl}/api/email-team/emails-history/shift/${selectedShift}`, 
        { date: date },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setHistoryEmailData(response.data);
    } catch (error) {
      console.error("Error fetching email history", error);
      setHistoryEmailData([])
    }finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHistoryEmailData(historyDate);
  }, [historyDate]);

  const handleDateChange = (event) => {
    setHistoryDate(event.target.value);
  };

   const filteredHistoryEmailData = historyEmailData.filter((data) => {
    const matchesCompanyName = data.companyData.Lead.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesEmail = data.Email.toLowerCase().includes(emailQuery.toLowerCase());
    return matchesCompanyName && matchesEmail;
  });  

  const handleOpenDialog = (request) => {
    setSelectedRequest(request);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedRequest(null);
  };

  const updateEmailFeedback = async (feedback) => {    
    if (!selectedRequest) return;

    try {
      const response = await axios.post(
        `${baseUrl}/api/email-team/update-dynamic-email-feedback`,
        {
          showId: selectedRequest.ShowId,
          leadId: selectedRequest.CompanyId,
          emailFeedback: feedback,
          requestId: selectedRequest._id,
          shift:selectedShift.toLowerCase(),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Feedback sent:", response.data);
      handleCloseDialog()
      fetchHistoryEmailData(historyDate);
    } catch (error) {
      console.error("Error sending feedback:", error);
    }
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenEmailEdit = (request) => {
    setSelectedRequest(request);
    setEmail(request.Email || request.companyData.Email);
    setOpenEmailEdit(true);
  };
  const handelCloseEditEmail = () =>{
    setOpenEmailEdit(false);
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value); // Update email on change
  };

  const handleSaveEmail = async () => {
    if (!email || !selectedRequest) return; // Don't proceed if no email or request selected
    setError(null);    
    const requestId = selectedRequest._id
    const leadId = selectedRequest.CompanyId

    try {
      // Call your backend to save the email
      const response = await axios.put(
        `${baseUrl}/api/email-team/update-email/${requestId}/${leadId}`, // Adjust API endpoint accordingly
        { email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('Email updated:', response.data);

      setSelectedRequest((prevRequest) => ({
        ...prevRequest,
        Email: email,
      }));
      handelCloseEditEmail()

    } catch (error) {
      setError(error.message);
      console.error('Error updating email:', error);
    }
  };
  
  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <div className="email-request-page-container">
      <h2 className="email-request-page-header">
        History Email Requests Table:
      </h2>
      <div className="email-req-table-filters">
        <div className="search-email-req">
          <TextField
            type="date"
            value={historyDate}
            onChange={handleDateChange}
            label="Select Date"
            variant="outlined"
            InputLabelProps={{ shrink: true, style: { color: "#e1b5d7", fontSize: "20px", fontWeight: "700", letterSpacing: "1px" } }}
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "white",
                borderRadius: "10px",
                color: "#333", fontSize: "18px", borderRadius: "8px"
              },
            }}
            inputProps={{ max: new Date().toISOString().split("T")[0] }}
            fullWidth
          />
        </div>
        <div className="search-email-req">
          <TextField
            label="Search by Company Name"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
            InputLabelProps={{ style: { color: "#e1b5d7", fontSize: "16px", letterSpacing: "1px" } }}
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "white",
                borderRadius: "10px"
              },
            }}
          />
        </div>
        <div className="search-email-req">
          <TextField
            label="Search by Email"
            variant="outlined"
            value={emailQuery}
            onChange={(e) => setEmailQuery(e.target.value)}
            fullWidth
            InputLabelProps={{ style: { color: "#e1b5d7", fontSize: "16px", letterSpacing: "1px" } }}
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "white",
                borderRadius: "10px",
              },
            }}
          />
        </div>
      </div>
      <div className="email-req-table-contain">
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
            borderRadius: "16px 16px 0 0",
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="email requests table">
            <TableHead>
              <TableRow
                sx={{
                  background: "linear-gradient(to right, #fed766, #e8b6ea)",
                }}
              >
                {[
                  "Company Name",
                  "Show Name",
                  "Agent Name",
                  "State",
                  "Team",
                  "Send Feedback",
                  "Details"
                ].map((header) => (
                  <TableCell
                    key={header}
                    sx={{
                      fontSize: 19,
                      fontFamily: '"Playfair Display", serif',
                      letterSpacing: "1.2px",
                      fontWeight: 800,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      wordBreak: "keep-all",
                      borderRight: "2px solid #fff",
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredHistoryEmailData.length === 0 ? (<TableRow>
                <TableCell
                  colSpan={7}
                  sx={{
                    fontSize: 16,
                    fontWeight: 700,
                    textAlign: "center",
                    borderRight: "2px solid #e0e0e0",
                    padding: "10px",
                  }}
                >
                  No email requests found
                </TableCell>
              </TableRow>) : (filteredHistoryEmailData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((request) => (
                <TableRow
                  key={request._id}
                  sx={{
                    background:
                      request.State === "Sent"
                        ? "linear-gradient(to left, white, white, #285c4c)"
                        : request.State === "Error Sent"
                          ? "linear-gradient(to left, white, white, #940000)"
                          : "inherit",
                    borderBottom: "2px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  <TableCell
                    style={{
                      fontSize: 16,
                      fontWeight: 700,
                      textAlign: "center",
                      borderRight: "2px solid rgb(195, 189, 189)",
                      whiteSpace: "nowrap",
                      wordBreak: "keep-all",
                      padding: "10px",
                      color:
                        request.State === "Sent"
                          ? "white"
                          : request.State === "Error Sent"
                            ? "white"
                            : "inherit",
                    }}

                  >
                    {renderSelectRawInfo(request?.companyData, "Lead")}
                  </TableCell>
                  <TableCell
                    style={callStyle}
                  >
                    {renderSelectRawInfo(request?.showData, "Show")}
                  </TableCell>
                  <TableCell
                    style={callStyle}
                  >
                    {renderSelectRawInfo(request, "agentName")}
                  </TableCell>
                  <TableCell
                    style={callStyle}
                  >
                    {renderSelectRawInfo(request, "State")}
                  </TableCell>
                  <TableCell
                    style={callStyle}
                  >
                    {renderSelectRawInfo(request, "teamName")}
                  </TableCell>
                  <TableCell
                    style={callStyle}
                  >
                    {new Date(request?.["answeredTime"]).toLocaleDateString(
                      [],
                      {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      }
                    ) || (
                      <>
                        <GoDash style={{ fontSize: "20px" }} />
                        <GoDash style={{ fontSize: "20px" }} />
                      </>
                    )}
                  </TableCell>
                  <TableCell style={callStyle}>
                    <button
                      className="table-email-button"
                    onClick={() => handleOpenDialog(request)}
                    >
                      <p className="table-email-button-text">
                        View Details
                      </p>
                    </button>
                  </TableCell>
                </TableRow>
              )))}

            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{
            backgroundColor: "#fff",
            borderTop: "1px solid #e0e0e0",
          }}
          rowsPerPageOptions={[8, 20]}
          component="div"
          count={historyEmailData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
        {/* Popup Dialog for showing company and show ID */}
        <Dialog
          fullScreen
          open={openDialog}
          onClose={handleCloseDialog}
          PaperProps={{
            style: {
              Width: "100%",
              height: "100vh",
              overflowY: "auto",
              overflowX:"hidden",
              padding: "15px 30px",
            },
          }}
        >
          <div className="Dialog-header-contain">
            <button className="close-dialog-button" onClick={handleCloseDialog}>
              Close
            </button>
          </div>
          <div className="Dialog-card-container">
            <div className="Dialog-card-header">
              <GiStaryu style={{ fontSize: 24 }} />
              <h3 style={{ letterSpacing: 1.5 }}>Email Request Details</h3>
            </div>
            <div className="Dialog-card-contain">
              {selectedRequest && (
                <>
                  <div className="Dialog-card-Details">
                    <div className="dialod-card-data-email">
                    <p>
                      <span>Request:</span>
                      {renderSelectRawInfo(selectedRequest, "Request")}
                    </p>
                    <p>
                      <span>Request Date:</span>
                      {new Date(selectedRequest?.submittedTime).toLocaleString()}
                    </p>
                    </div>
                    <p>
                      <span>Agent Name:</span>
                      {renderSelectRawInfo(selectedRequest, "agentName")}
                    </p>
                    <p>
                      <span>Show Name:</span>
                      {renderSelectRawInfo(selectedRequest?.showData, "Show")}
                    </p>
                    <p>
                      <span>Company Name:</span>
                      {renderSelectRawInfo(selectedRequest?.companyData, "Lead")}
                    </p>

                    <p>
                      <span>Email:</span>
                      {selectedRequest?.Email || selectedRequest?.companyData?.Email}
                      <span style={{ cursor: 'pointer' }}><FaEdit onClick={() => handleOpenEmailEdit(selectedRequest)}/></span>
                    </p>
                    <p>
                      <span>Requested Data:</span>
                      {renderSelectRawInfo(selectedRequest, "RequestedData")}
                    </p>
                    <p>
                      <span>Email Notes:</span>
                      {renderSelectRawInfo(selectedRequest, "notes")}
                    </p>
                    <p>
                      <span>Contact Name:</span>
                      {renderSelectRawInfo(selectedRequest, "ContactName")}
                    </p>
                    <div className="dialod-card-data-email">
                      
                    </div>
                    <div className="dialod-card-data-email">
                    {!(selectedRequest.RequestedData === 'Pricing (Same Day, Last Day)' || 
                     selectedRequest.RequestedData === 'Pricing (1 Day Before, Last Day)' || 
                     selectedRequest.RequestedData === 'Contact Card for Future Reference') && (
                      <>
                        <p>
                          <span>Check-In:</span>
                          {renderSelectRawInfo(selectedRequest?.companyData, "Check-in")}
                        </p>
                        <p>
                          <span>Check-Out:</span>
                          {renderSelectRawInfo(selectedRequest?.companyData, "Check-out")}
                        </p>
                      </>
                    )}
                    <p>
                      <span>No Of Rooms:</span>
                      {selectedRequest?.companyData?.Rooms || 0}
                    </p>
                    </div>
                    <div className="dialod-card-data-email">
                    <p>
                      <span>State:</span>
                      {selectedRequest?.State}
                    </p>
                    <p>
                      <span>Send Feedback Date:</span>
                      {new Date(selectedRequest?.answeredTime).toLocaleString()}
                    </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="Dialog-feedback-buttons">
                <button
                style={{
                  marginTop: "1rem",
                  marginRight: "0.5rem",
                  backgroundImage: "linear-gradient(90deg, #32CD32, #228B22)",
                  border: "none",
                }}
              >
                <p
                  style={{ fontSize: 15, textTransform: "capitalize" }}
                  onClick={() => updateEmailFeedback("Sent")}
                >
                  Send Correct Email
                </p>
              </button>
              <button
                style={{
                  marginTop: "1rem",
                  marginLeft: "0.5rem",
                  backgroundImage: "linear-gradient(90deg, #FF6347, #B22222)",
                  border: "none",
                }}
              >
                <p
                  style={{ fontSize: 15, textTransform: "capitalize" }}
                  onClick={() => updateEmailFeedback("Error Sent")}
                >
                  Send Error Email
                </p>
              </button>
          </div>
            <div className="dialog-hotels-table-container">
              <TableContainer
                component={Paper}
                sx={{
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
                  borderRadius: "16px",
                   maxHeight: "80vh",
                  overflow:"auto",
                }}
              >
                <Table sx={{ minWidth: 650 }} aria-label="email requests table">
                  <TableHead>
                    <TableRow
                      sx={{
                        background: "linear-gradient(to right, #fed766, #e8b6ea)",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                      }}
                    >
                      {[
                        "Hotel Name",
                        "Address",
                        "Stars",
                        "Selling price",
                        "Boarding Type",
                        "Distance",
                        "Room Type",
                        "Free Cancellation Until",
                        "Online Price",
                      ].map((header) => (
                        <TableCell
                          key={header}
                          sx={{
                            fontSize: 19,
                            fontFamily: '"Playfair Display", serif',
                            letterSpacing: "1.2px",
                            fontWeight: 800,
                            textAlign: "center",
                            whiteSpace: "nowrap",
                            wordBreak: "keep-all",
                            borderRight: "2px solid #fff",
                          }}
                        >
                          {header}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedRequest?.emailPricingFeedback.length === 0  ? (
                    selectedRequest?.pricingData?.map((pricingItem) => (
                      pricingItem.Hotels.map((hotel) => (
                        <TableRow
                          key={hotel._id}
                          sx={{
                            borderBottom: "2px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                                                <TableCell
                        style={callStyle}
                      >
                        {renderSelectRawInfo(hotel, "Hotel Name")}
                      </TableCell>
                      <TableCell
                        style={callStyle}
                      >
                        {renderSelectRawInfo(hotel, "Address")}
                      </TableCell>
                      <TableCell
                        style={callStyle}
                      >
                        {renderSelectRawInfo(hotel, "Stars")}
                      </TableCell>
                      <TableCell
                        style={callStyle}
                      >
                        {renderSelectRawInfo(hotel, "Average Nightly Rate with Markup")}
                      </TableCell>
                      <TableCell
                        style={callStyle}
                      >
                        {renderSelectRawInfo(hotel, "Room Type")}
                      </TableCell>
                      <TableCell
                        style={callStyle}
                      >
                        {renderSelectRawInfo(hotel, "Distance")}
                      </TableCell>
                      <TableCell
                        style={callStyle}
                      >
                        {renderSelectRawInfo(hotel, "Room Type")}
                      </TableCell>
                      <TableCell
                        style={callStyle}
                      >
                        {renderSelectRawInfo(hotel, "Free Cancellation Until")}
                      </TableCell>
                      <TableCell
                        style={callStyle}
                      >
                        {renderSelectRawInfo(hotel, "Online Price")}
                      </TableCell>
                    </TableRow>
                      ))))
                    ) : (selectedRequest?.emailPricingFeedback?.map((hotel)=>(
                      <TableRow
                      key={hotel._id}
                      sx={{
                        borderBottom: "2px solid rgba(224, 224, 224, 1)",
                      }}
                    >
                        <TableCell
                        style={callStyle}
                      >
                        {renderSelectRawInfo(hotel, "Hotel Name")}
                      </TableCell>
                      <TableCell
                        style={callStyle}
                      >
                        {renderSelectRawInfo(hotel, "Address")}
                      </TableCell>
                      <TableCell
                        style={callStyle}
                      >
                        {renderSelectRawInfo(hotel, "Stars")}
                      </TableCell>
                      <TableCell
                        style={callStyle}
                      >
                        {renderSelectRawInfo(hotel, "SellingPrice")}
                      </TableCell>
                      <TableCell
                        style={callStyle}
                      >
                        {renderSelectRawInfo(hotel, "Room Type")}
                      </TableCell>
                      <TableCell
                        style={callStyle}
                      >
                        {renderSelectRawInfo(hotel, "Distance")}
                      </TableCell>
                      <TableCell
                        style={callStyle}
                      >
                        {renderSelectRawInfo(hotel, "Room Type")}
                      </TableCell>
                      <TableCell
                        style={callStyle}
                      >
                        {renderSelectRawInfo(hotel, "Free Cancellation Until")}
                      </TableCell>
                      <TableCell
                        style={callStyle}
                      >
                        {renderSelectRawInfo(hotel, "Online Price")}
                      </TableCell>
                    </TableRow>
                    )))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
        </Dialog>
                {/* Edit Email Dialog */}
      <Dialog open={openEmailEdit}>
        <DialogTitle>Edit Email</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Email"
            fullWidth
            variant="outlined"
            value={email} // Display current email in the input
            onChange={handleEmailChange} // Update email state when the user types
            error={!!error}
            helperText={error}
          />
        </DialogContent>
        <DialogActions>
          <button onClick={handelCloseEditEmail} style={{background:"red", padding:"10px 20px", color:"#fff", borderRadius:"8px"}}>
            Cancel
          </button>
          <button
            onClick={handleSaveEmail}
            style={{background:"blue", padding:"10px 20px",color:"#fff", borderRadius:"8px"}}
          >
            Save
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default HistoryEmailReq;


