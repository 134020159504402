import React, { useState, useEffect, useContext, useCallback } from "react";
import "./EmailRequestNew.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Dialog,
  DialogActions, DialogContent, DialogTitle,TextField
} from "@mui/material";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import { UserContext } from "../../context/UserContext";
import axios from "axios";
import { baseUrl } from "../../services/BaseUrl";
import { GiStaryu } from "react-icons/gi";
import { GoDash } from "react-icons/go";
import { FaEdit } from "react-icons/fa";

const callStyle = {
  padding: 8.5,
  fontSize: 16,
  fontWeight: 700,
  textAlign: "center",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  borderRight: "2px solid #e0e0e0",
};

const renderHotelInfo = (hotel, field) => {
  return hotel?.[field] || (
    <>
      <GoDash style={{ fontSize: "20px" }} />
      <GoDash style={{ fontSize: "20px" }} />
    </>
  );
};
 
const EmailRequestNew = () => {
  const { token } = useContext(UserContext);
  const [selectedShift, setSelectedShift] = useState(() => {
    return localStorage.getItem("selectedShift");
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [emailReqData, setEmailReqData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [openEmailEdit, setOpenEmailEdit] = useState(false);
  const [email, setEmail] = useState('');

  const handlePostRequest = useCallback(async () => {
    if (!selectedShift) {
      console.error("No shift selected.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${baseUrl}/api/email-team/get-email-requests`,
        { shift: selectedShift },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const sortedData = response.data.sort((a, b) => {
        const dateA = new Date(a.submittedTime);
        const dateB = new Date(b.submittedTime);
        return dateB - dateA;
      });

      setEmailReqData(sortedData);
    } catch (error) {
      console.error("Error making the request:", error.message);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [selectedShift, token]);

  useEffect(() => {
    if (selectedShift) {
      handlePostRequest();
    }
  }, [selectedShift, handlePostRequest]);

  const handleOpenDialog = (request) => {
    setSelectedRequest(request);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedRequest(null);
  };

  const updateEmailFeedback = async (feedback) => {
    if (!selectedRequest) return;

    try {
      const response = await axios.post(
        `${baseUrl}/api/email-team/update-dynamic-email-feedback`,
        {
          showId: selectedRequest.ShowId,
          leadId: selectedRequest.CompanyId,
          emailFeedback: feedback,
          requestId: selectedRequest._id,
          shift:selectedShift.toLowerCase(),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Feedback sent:", response.data);
      setEmailReqData((prevRequests) =>
        prevRequests.map((req) =>
          req.CompanyId === selectedRequest.CompanyId
            ? {
                ...req,
                companyData: { ...req.companyData, emailFeedback: feedback },
                hidden: true,
              }
            : req
        )
      );

      handleCloseDialog()
    } catch (error) {
      console.error("Error sending feedback:", error);
    }
  };
  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenEmailEdit = (request) => {
    setSelectedRequest(request);
    setEmail(request.Email || request.companyData.Email);
    setOpenEmailEdit(true);
  };
  const handelCloseEditEmail = () =>{
    setOpenEmailEdit(false);
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value); // Update email on change
  };

  const handleSaveEmail = async () => {
    if (!email || !selectedRequest) return;
    setError(null);    
    const requestId = selectedRequest._id
    const leadId = selectedRequest.CompanyId
    const shift = selectedShift.toLowerCase()

    try {
      // Call your backend to save the email
      const response = await axios.put(
        `${baseUrl}/api/email-team/update-email/${shift}/${requestId}/${leadId}`,
        { email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // You may want to update the state to reflect the changes in your UI
      setSelectedRequest((prevRequest) => ({
        ...prevRequest,
        Email: email,
      }));
      handelCloseEditEmail()

    } catch (error) {
      setError(error.message);
      console.error('Error updating email:', error);
    }
  };
 
  const visibleRequests = emailReqData.filter((req) => !req.hidden);

  if (loading) {
    return <LoadingComponent />;
  }
  

  return (
    <div className="email-request-page-container">
      <h2 className="email-request-page-header">
        {selectedShift} Email Requests Table:
      </h2>
      <div className="email-req-table-contain">
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: "70vh",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
            borderRadius: "16px 16px 0 0",
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="email requests table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#f6f6f6" }}>
                {[
                  "Show Name",
                  "Company Name",
                  "Agent Name",
                  "State",
                  "Request Date",
                  "Details",
                ].map((header, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      fontSize: 19,
                      fontFamily: '"Playfair Display", serif',
                      letterSpacing: "1.2px",
                      fontWeight: 800,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      wordBreak: "keep-all",
                      borderRight: "2px solid #e0e0e0",
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {emailReqData.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={5}
                    sx={{
                      borderRight: "2px solid #e0e0e0",
                      fontSize: "14px",
                      fontWeight: 550,
                      whiteSpace: "nowrap",
                      wordBreak: "keep-all",
                      padding: "14px",
                      textAlign: "center",
                    }}
                  >
                    No Email Request
                  </TableCell>
                </TableRow>
              ) : (
                visibleRequests
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((emailData, index) => (
                    <TableRow key={index}>
                      <TableCell style={callStyle}>
                        {emailData.showData.Show}
                      </TableCell>
                      <TableCell style={callStyle}>
                        {emailData.companyData.Lead}
                      </TableCell>
                      <TableCell style={callStyle}>
                        {emailData.agentName}
                      </TableCell>
                      <TableCell style={callStyle}>{emailData.State}</TableCell>
                      <TableCell style={callStyle}>
                        {new Date(emailData.submittedTime).toLocaleString()}
                      </TableCell>
                      <TableCell style={callStyle}>
                        <button
                          className="table-email-button"
                          onClick={() => handleOpenDialog(emailData)}
                        >
                          <p className="table-email-button-text">
                            View Details
                          </p>
                        </button>
                      </TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{
            backgroundColor: "#fff",
            borderTop: "1px solid #e0e0e0",
          }}
          rowsPerPageOptions={[8, 20]}
          component="div"
          count={emailReqData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        {/* Popup Dialog for showing company and show ID */}
        <Dialog
          fullScreen
          open={openDialog}
          onClose={handleCloseDialog}
          PaperProps={{
            style: {
              Width: "100%",
              height: "100vh",
              margin: 0,
              overflow: "auto",
              padding: "15px 30px",
            },
          }}
        >
          <div className="Dialog-header-contain">
            <button className="close-dialog-button" onClick={handleCloseDialog}>
              Close
            </button>
          </div>
          <div className="Dialog-card-container">
            <div className="Dialog-card-header">
              <GiStaryu style={{ fontSize: 24 }} />
              <h3 style={{ letterSpacing: 1.5 }}>Email Request Details</h3>
            </div>
            <div className="Dialog-card-contain">
              {selectedRequest && (
                <>
                  <div className="Dialog-card-Details">
                    <div className="dialod-card-data-email">
                    <p>
                      <span>Request:</span>
                      {selectedRequest?.Request}
                    </p>
                    <p>
                      <span>Request Date:</span>
                      {new Date(selectedRequest?.submittedTime).toLocaleString()}
                    </p>
                    </div>
                    <p>
                      <span>Agent Name:</span>
                      {selectedRequest.agentName}
                    </p>
                    <p>
                      <span>Show Name:</span>
                      {selectedRequest.showData.Show}
                    </p>
                    <p>
                      <span>Company Name:</span>
                      {selectedRequest.companyData.Lead}
                    </p>
                    <p>
                      <span>Email:</span>
                      {selectedRequest.Email || selectedRequest.companyData.Email}
                      <span style={{ cursor: 'pointer' }}><FaEdit onClick={() => handleOpenEmailEdit(selectedRequest)}/></span>
                    </p>
                    <p>
                      <span>Requested Data:</span>
                      {selectedRequest.RequestedData}
                    </p>
                    <p>
                      <span>Email Notes:</span>
                      {selectedRequest?.notes}
                    </p>
                    <p>
                      <span>Contact Name:</span>
                      {selectedRequest.ContactName || selectedRequest?.companyData?.["DM Name"]}
                    </p>
                    <div className="dialod-card-data-email">
                    {!(selectedRequest.RequestedData === 'Pricing (Same Day, Last Day)' || 
                     selectedRequest.RequestedData === 'Pricing (1 Day Before, Last Day)' || 
                     selectedRequest.RequestedData === 'Contact Card for Future Reference') && (
                      <>
                        <p>
                          <span>Check-In:</span>
                          {selectedRequest?.companyData?.["Check-in"]}
                        </p>
                        <p>
                          <span>Check-Out:</span>
                          {selectedRequest?.companyData?.["Check-out"]}
                        </p>
                      </>
                    )}
                    <p>
                      <span>No Of Rooms:</span>
                      {selectedRequest?.companyData?.Rooms || 0}
                    </p>
                    </div>
                    <div className="dialod-card-data-email">
                    <p>
                      <span>State:</span>
                      {selectedRequest?.State}
                    </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="Dialog-feedback-buttons">
             <button
              style={{
                marginTop: "1rem",
                marginRight: "0.5rem",
                backgroundImage: "linear-gradient(90deg, #32CD32, #228B22)",
                border: "none",
              }}
            >
              <p
                style={{ fontSize: 15, textTransform: "capitalize" }}
                onClick={() => updateEmailFeedback("Sent")}
              >
                Send Correct Email
              </p>
            </button>
            <button
              style={{
                marginTop: "1rem",
                marginLeft: "0.5rem",
                backgroundImage: "linear-gradient(90deg, #FF6347, #B22222)",
                border: "none",
              }}
            >
              <p
                style={{ fontSize: 15, textTransform: "capitalize" }}
                onClick={() => updateEmailFeedback("Error Sent")}
              >
                Send Error Email
              </p>
            </button>
          </div>
          <div className="dialog-hotels-table-container">
            <TableContainer
              component={Paper}
              sx={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
                borderRadius: "16px",
              }}
            >
              <Table sx={{ minWidth: 650 }} aria-label="email requests table">
                <TableHead>
                  <TableRow
                    sx={{
                      background: "linear-gradient(to right, #fed766, #e8b6ea)",
                    }}
                  >
                    {[
                      "Hotel Name",
                      "Address",
                      "Stars",
                      "Selling price",
                      "Boarding Type",
                      "Distance",
                      "Room Type",
                      "Free Cancellation Until",
                      "Online Price",
                    ].map((header) => (
                      <TableCell
                        key={header}
                        sx={{
                          fontSize: 19,
                          fontFamily: '"Playfair Display", serif',
                          letterSpacing: "1.2px",
                          fontWeight: 800,
                          textAlign: "center",
                          whiteSpace: "nowrap",
                          wordBreak: "keep-all",
                          borderRight: "2px solid #fff",
                        }}
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedRequest?.emailPricingFeedback.length === 0  ? (
                  selectedRequest?.pricingData?.map((pricingItem) => (
                    pricingItem.Hotels.map((hotel) => (
                      <TableRow
                        key={hotel._id}
                        sx={{
                          borderBottom: "2px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                                             <TableCell
                      style={callStyle}
                    >
                      {renderHotelInfo(hotel, "Hotel Name")}
                    </TableCell>
                    <TableCell
                      style={callStyle}
                    >
                      {renderHotelInfo(hotel, "Address")}
                    </TableCell>
                    <TableCell
                      style={callStyle}
                    >
                      {renderHotelInfo(hotel, "Stars")}
                    </TableCell>
                    <TableCell
                      style={callStyle}
                    >
                      {renderHotelInfo(hotel, "SellingPrice")}
                    </TableCell>
                    <TableCell
                      style={callStyle}
                    >
                      {renderHotelInfo(hotel, "Room Type")}
                    </TableCell>
                    <TableCell
                      style={callStyle}
                    >
                      {renderHotelInfo(hotel, "Distance")}
                    </TableCell>
                    <TableCell
                      style={callStyle}
                    >
                      {renderHotelInfo(hotel, "Room Type")}
                    </TableCell>
                    <TableCell
                      style={callStyle}
                    >
                      {renderHotelInfo(hotel, "Free Cancellation Until")}
                    </TableCell>
                    <TableCell
                      style={callStyle}
                    >
                      {renderHotelInfo(hotel, "Online Price")}
                    </TableCell>
                  </TableRow>
                    ))))
                  ) : (selectedRequest?.emailPricingFeedback?.map((hotel)=>(
                    <TableRow
                    key={hotel._id}
                    sx={{
                      borderBottom: "2px solid rgba(224, 224, 224, 1)",
                    }}
                  >
                     <TableCell
                      style={callStyle}
                    >
                      {renderHotelInfo(hotel, "Hotel Name")}
                    </TableCell>
                    <TableCell
                      style={callStyle}
                    >
                      {renderHotelInfo(hotel, "Address")}
                    </TableCell>
                    <TableCell
                      style={callStyle}
                    >
                      {renderHotelInfo(hotel, "Stars")}
                    </TableCell>
                    <TableCell
                      style={callStyle}
                    >
                      {renderHotelInfo(hotel, "SellingPrice")}
                    </TableCell>
                    <TableCell
                      style={callStyle}
                    >
                      {renderHotelInfo(hotel, "Room Type")}
                    </TableCell>
                    <TableCell
                      style={callStyle}
                    >
                      {renderHotelInfo(hotel, "Distance")}
                    </TableCell>
                    <TableCell
                      style={callStyle}
                    >
                      {renderHotelInfo(hotel, "Room Type")}
                    </TableCell>
                    <TableCell
                      style={callStyle}
                    >
                      {renderHotelInfo(hotel, "Free Cancellation Until")}
                    </TableCell>
                    <TableCell
                      style={callStyle}
                    >
                      {renderHotelInfo(hotel, "Online Price")}
                    </TableCell>
                  </TableRow>
                  )))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Dialog>

        {/* Edit Email Dialog */}
      <Dialog open={openEmailEdit}>
        <DialogTitle>Edit Email</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Email"
            fullWidth
            variant="outlined"
            value={email} // Display current email in the input
            onChange={handleEmailChange} // Update email state when the user types
            error={!!error}
            helperText={error}
          />
        </DialogContent>
        <DialogActions>
          <button onClick={handelCloseEditEmail} style={{background:"red", padding:"10px 20px", color:"#fff", borderRadius:"8px"}}>
            Cancel
          </button>
          <button
            onClick={handleSaveEmail}
            style={{background:"blue", padding:"10px 20px",color:"#fff", borderRadius:"8px"}}
          >
            Save
          </button>
        </DialogActions>
      </Dialog>

      </div>
    </div>
  );
};

export default EmailRequestNew;
