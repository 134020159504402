import React, { useState, useContext } from "react";
import "./RegisterUser.css";
import { FormControl, Select, MenuItem } from "@mui/material";
import axios from "axios";
import { baseUrl } from "../../services/BaseUrl";
import { UserContext } from "../../context/UserContext";

const selectRoleOptions = [
  { label: "super user", value: "super user" },
  { label: "agent", value: "agent" },
  { label: "agent junior", value: "agent junior" },
  { label: "agent closer", value: "agent closer" },
  { label: "team leader", value: "team leader" },
  { label: "sales manager", value: "sales manager" },
  { label: "sales support", value: "sales support" },
  { label: "sales support manager", value: "sales support manager" },
  { label: "email team manager", value: "email team manager" },
  { label: "customer support", value: "customer support" },
  { label: "locator", value: "locator" },
  { label: "leads junior", value: "leads junior" },
  { label: "leads senior", value: "leads senior" },
  { label: "email team", value: "email team" },
  { label: "leads manager", value: "leads manager" },
  { label: "pricing manager", value: "pricing manager" },
  { label: "pricing junior", value: "pricing junior" },
  { label: "pricing senior", value: "pricing senior" },
  { label: "lead generator", value: "lead generator" },
  { label: "locator manager", value: "locator manager" },
  { label: "operations manager", value: "operations manager" },
];

const selectTimeZoneOptions = [
  { label: "UK", value: "uk" },
  { label: "US", value: "us" },
];

const RegisterUser = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    role: "",
    timezone: "",
  });
  const [emailError, setEmailError] = useState("");
  const { token } = useContext(UserContext);

  const isValidEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "email") {
      setEmailError("");
    }
  };

  const handleRoleChange = (e) => {
    const selectedRole = e.target.value;
    setFormData({ ...formData, role: selectedRole, timezone: "" });
  };

  const handleTimezoneChange = (e) => {
    setFormData({ ...formData, timezone: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValidEmail(formData.email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }
    if (!formData.timezone) {
      formData.timezone = "dynamic";
    }
    try {
      const response = await axios.post(
        `${baseUrl}/api/users/register`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFormData({
        name: "",
        email: "",
        password: "",
        role: "",
        timezone: "",
      });
    } catch (error) {
      console.error("Signup error:", error);
    }
  };

  const rolesWithTimeZoneShiftBased = [
    "agent",
    "agent junior",
    "sales manager",
    "agent closer",
    "team leader",
  ];

  return (
    <div className="register-user-contanier">
      <h1 className="register-header">Create Your Account!</h1>
      <h4 className="register-text">
        Once registered, you will have access to all the CRM features associated
        with your role.
      </h4>
      <div className="register-form-contanier">
        <form className="form-control-container" onSubmit={handleSubmit}>
          <div className="inputs-form-controls">
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              placeholder="Enter Username..."
              name="name"
              id="name"
              value={formData.username}
              onChange={handleChangeInput}
            />
          </div>
          <div className="inputs-form-controls">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              placeholder="Enter Email..."
              name="email"
              id="email"
              value={formData.email}
              onChange={handleChangeInput}
            />
            {emailError && <p className="message-error">{emailError}</p>}
          </div>
          <div className="inputs-form-controls">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              placeholder="Enter Password..."
              name="password"
              id="password"
              value={formData.password}
              onChange={handleChangeInput}
            />
          </div>
          <div className="select-dropdown-control">
            <label htmlFor="role-select">Role:</label>
            <FormControl fullWidth>
              <Select
                labelId="role-label"
                id="role-select"
                value={formData.role}
                onChange={handleRoleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  "& .MuiSelect-select": {
                    padding: "15px 20px",
                    border: "1px solid #a093b8",
                    borderRadius: "10px",
                    backgroundColor: "#fff",
                    color: formData.role === "" ? "#757575" : "black",
                    letterSpacing: "1px",
                    fontSize: "16px",
                    fontWeight: formData.role === "" ? 550 : 500,
                  },
                  "&:focus .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#3b364c",
                  },
                  "& .MuiSelect-icon": {
                    color: "#a093b8",
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <p>Select an option</p>
                </MenuItem>
                {selectRoleOptions.map((optionItem) => (
                  <MenuItem
                    key={optionItem.value}
                    value={optionItem.value}
                    sx={{
                      "&:hover": {
                        color: "#ffffff",
                        backgroundColor: "#3b364c !important",
                      },
                      "&.Mui-selected": {
                        color: "#ffffff",
                        backgroundColor: "#3b364c !important",
                      },
                    }}
                  >
                    {optionItem.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {rolesWithTimeZoneShiftBased.includes(formData.role) && (
            <div className="select-dropdown-control">
              <label htmlFor="timeZone-select">TimeZone:</label>
              <FormControl fullWidth>
                <Select
                  labelId="timeZone-label"
                  id="timeZone-select"
                  value={formData.timezone}
                  onChange={handleTimezoneChange}
                  displayEmpty
                  sx={{
                    "& .MuiSelect-select": {
                      padding: "15px 20px",
                      border: "1px solid #a093b8",
                      borderRadius: "10px",
                      backgroundColor: "#fff",
                      // color: "#757575",
                      color: formData.timezone === "" ? "#757575" : "black",
                      letterSpacing: "1px",
                      fontSize: "16px",
                      fontWeight: formData.timezone === "" ? 550 : 500,
                    },
                    "&:focus .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#3b364c",
                    },
                    "& .MuiSelect-icon": {
                      color: "#a093b8",
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    <p>Select an option</p>
                  </MenuItem>
                  {selectTimeZoneOptions.map((optionItem) => (
                    <MenuItem
                      key={optionItem.value}
                      value={optionItem.value}
                      sx={{
                        "&:hover": {
                          color: "#ffffff",
                          backgroundColor: "#3b364c !important",
                        },
                        "&.Mui-selected": {
                          color: "#ffffff",
                          backgroundColor: "#3b364c !important",
                        },
                      }}
                    >
                      {optionItem.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          <div className="buttons-form-controls">
            <button
              type="submit"
              className="linear-button"
              disabled={
                !formData.name ||
                !formData.email ||
                !formData.password ||
                !formData.role ||
                (rolesWithTimeZoneShiftBased.includes(formData.role) &&
                  !formData.timezone)
              }
            >
              <p className="button-text">Create Account</p>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default React.memo(RegisterUser);
