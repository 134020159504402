import React, { useEffect, useState, useContext } from "react";
import "./UsersReports.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { baseUrl } from "../../services/BaseUrl";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import { GoDash } from "react-icons/go";
import OperationUserReport from "./OperationUserReport"
import { FaArrowLeftLong } from "react-icons/fa6";


const callStyle = {
  padding: 8.5,
  fontSize: 16,
  fontWeight: 700,
  textAlign: "center",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  borderRight: "2px solid #e0e0e0",
};

const renderSelectedInfo = (select, field) => {
  return (
    select?.[field] || (
      <>
        <GoDash style={{ fontSize: "20px" }} />
        <GoDash style={{ fontSize: "20px" }} />
      </>
    )
  );
};

const UsersReports = () => {
  const { token } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [usersReportData, setUsersReportData] = useState([]);
  const [userReportActive, setUserReportActive] = useState(false);
  const [selectReport, setSelectReport] = useState(null);

  useEffect(() => {
    const fetchUserReport = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${baseUrl}/api/super/get-operation-users`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUsersReportData(response.data.users);
        setLoading(false);
      } catch (err) {
        setError("Failed to load user data.");
        setLoading(false);
      }
    };

    fetchUserReport();
  }, [token]);

  const handleClickViewReport = (userId, userReport) => {
    setSelectReport(userReport)
    setUserReportActive(true)
  }
  
  const handleCloseViewReport = () =>{
    setUserReportActive(false)
    setSelectReport(null)
  }

  if (loading) {
    return <LoadingComponent />;
  }

  if (error) {
    return (
      <div className="user-report-section">
        <div className="answer-header-container">
          <FaArrowLeftLong
            onClick={handleCloseViewReport}
            style={{ cursor: "pointer", fontSize: 22 }}
          />
          <h3 style={{ letterSpacing: "1.2px", textTransform: "capitalize" }}>
            Report Page
          </h3>
        </div>
        <h2>{error}</h2>
      </div>
    );
  }

  return (
    <>
    {!userReportActive ? (
      <div className="users-reports-container">
      <div className="leads-locator-timezone-container">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  background: "linear-gradient(to right, #fed766, #e8b6ea)",
                }}
              >
                {[
                  "User Name",
                  "Role",
                  "Email",
                  // "Logged In",
                  "Last Seen",
                  "Actions",
                ].map((header, index) => (
                  <TableCell
                    key={header}
                    sx={{
                      fontSize: 16,
                      fontWeight: 600,
                      borderRight: "2px solid #fff",
                      whiteSpace: "nowrap",
                      wordBreak: "keep-all",
                      padding: "10px 14px",
                      color: "#fff",
                      textTransform: "capitalize",
                      letterSpacing: "1.2px",
                      textAlign: "center",
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {usersReportData.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    sx={{
                      borderRight: "2px solid #e0e0e0",
                      fontSize: "14px",
                      fontWeight: 550,
                      whiteSpace: "nowrap",
                      wordBreak: "keep-all",
                      padding: "14px",
                      textAlign: "center",
                    }}
                  >
                    No Users Report
                  </TableCell>
                </TableRow>
              ) : (
                usersReportData.map((report, index) => (
                  <TableRow key={index}>
                    <TableCell style={callStyle}>
                      {renderSelectedInfo(report, "name")}
                    </TableCell>
                    <TableCell style={callStyle}>
                      {renderSelectedInfo(report, "role")}
                    </TableCell>
                    <TableCell style={callStyle}>
                      {renderSelectedInfo(report, "email")}
                    </TableCell>
                    <TableCell style={callStyle}>
                      {renderSelectedInfo(
                        report,
                        report.lastSeen ? "lastSeen" : "updatedAt"
                      )}
                    </TableCell>
                    <TableCell style={callStyle}>
                      <button
                        style={{
                          backgroundColor: "#F0C2B9",
                          color: " #333",
                          padding: "7px 10px",
                          fontSize: "14px",
                          fontWeight: "750",
                          letterSpacing: "1px",
                          borderRadius: "5px",
                        }}
                        onClick={()=>handleClickViewReport (report._id,report)}
                      >
                        View Report
                      </button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
    ):(
        selectReport && (
          <OperationUserReport selectReport={selectReport} handleCloseViewReport={handleCloseViewReport}/>
        )
      )
    }
    </>
  );
};

export default UsersReports;
