import React, { useState, useContext, useEffect, useCallback } from "react";
import "./NewCloserLeads.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Snackbar,
  Alert,
} from "@mui/material";
import { GoDash } from "react-icons/go";
import { UserContext } from "../../context/UserContext";
import axios from "axios";
import { baseUrl } from "../../services/BaseUrl";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import AnswerSection from "../AnswerSection/AnswerSection";

const callStyle = {
  borderRight: "2px solid #e0e0e0",
  fontSize: "14px",
  fontWeight: 550,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  padding: "7px 10px",
};

const NewCloserLeads = () => {
  const { token } = useContext(UserContext);
  const [initialLeadsData, setInitialLeadsData] = useState([]);
  const [answerPageActive, setAnswerPageActive] = useState(false);
  const [selectedLead, setSelectedLead] = useState({
    showId: "",
    leadId: "",
    showName: "",
    companyName: "",
    timeZone: "",
    Status: "",
    "Next Contact": "",
    address: "",
    "End Date": "",
    "Next Dates": "",
    "Check-in": "",
    "Check-out": "",
    "Lead Date": "",
    "Rejection Reason": "",
    Notes: "",
    "DM Name": "",
    "DM Rating": "",
    Email: "",
    "Email Type": "",
    emailRequestNotes: "",
    "Days to Call": "",
    "Last Contact": "",
    "Closing Date": "",
    Hotel: "",
    Budget: "",
    " Distance": "",
    Rooms: "",
    "/ Room Stay": "",
    "Total Stay": "",
    Activities: [],
    roomDetails: [],
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [selectedShowDataToRender, setSelectedShowDataRender] = useState({});
  

  const fetchLeadsData = useCallback(async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/closers/leads-to-close`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setInitialLeadsData(response.data);
    } catch (error) {
      setError("Error fetching leads data");
    } finally {
      setLoading(false);
    }
  }, [baseUrl, token]);

  useEffect(() => {
    if (token) {
      fetchLeadsData();
    }
  }, [token, fetchLeadsData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 8));
    setPage(0);
  };

  // Flatten the leads data for pagination
  const flattenedLeadsData = initialLeadsData.flatMap((data) =>
    data.leads.map((lead) => ({
      ...lead,
      showName: data.show.Show,
      cityCountry: data.show["City, Country"],
    }))
  );

  const paginatedLeadsData = flattenedLeadsData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

    useEffect(() => {
      const selectedShow = initialLeadsData.find((lead) =>
        lead.leads.some((leadData) => leadData.showId === selectedLead.showId)
      );
  
      if (selectedShow) {
        setSelectedShowDataRender(selectedShow);
      }
    }, [selectedLead, initialLeadsData]);

  const handleAnswerClick = useCallback((lead) => {
    const nextDate = new Date(lead?.["Next Dates"]);
    nextDate.setDate(nextDate.getDate() - 1);

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-based
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    setSelectedLead({
      showId: lead?.showId,
      leadId: lead?._id,
      showName: lead?.showName,
      companyName: lead?.Lead,
      timeZone: lead?.Timezone,
      Status: lead?.Status,
      "Next Contact": lead?.["Next Contact"],
      address: lead?.address,
      "End Date": lead?.["End Date"],
      "Next Dates": lead?.["Next Dates"],
      "Check-in": lead?.["Check-in"],
      "Check-out": lead?.["Check-out"],
      "Lead Date": lead?.["Lead Date"],
      "Rejection Reason": lead?.["Rejection Reason"],
      Notes: lead?.Notes,
      "DM Name": lead?.["DM Name"],
      "DM Rating": lead?.["DM Rating"],
      Email: lead?.Email,
      "Email Type": lead?.["Email Type"],
      emailRequestNotes: lead?.emailRequestNotes,
      "Days to Call": lead?.["Days to Call"],
      "Last Contact": lead?.["Last Contact"],
      "Closing Date": lead?.["Closing Date"],
      Hotel: lead?.Hotel,
      Budget: lead?.Budget,
      " Distance": lead?.Max?.[" Distance"],
      Rooms: lead?.Rooms,
      "/ Room Stay": lead?.["/ Room Stay"],
      "Total Stay": lead?.["Total Stay"],
      Activities: lead.Activities,
        roomDetails: lead.roomDetails,
    });
    setAnswerPageActive(true);
  }, []);

  const handleCloseAnswerPage = useCallback(() => {
    setAnswerPageActive(false);
  }, []);

  const handleUpdateLead = async () => {
    try {
      const response = await axios.put(
        `${baseUrl}/api/closers/shows/${selectedLead.showId}/companies/${selectedLead.leadId}`,
        selectedLead,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Lead updated successfully", response.data);
        setAlertMessage("Lead updated successfully!");

        setTimeout(() => {
          setSelectedLead((prevSelectedLead) => ({
            ...prevSelectedLead,
          }));

          fetchLeadsData();
        }, 1000);
      }
    } catch (error) {
      setError("Error updating lead");
      console.error("Error updating lead:", error);
      setAlertMessage("The lead was not updated successfully!");
    }
  };

  const handleCloseAlert = () => {
    setAlertMessage("");
  };

  if (loading) return <LoadingComponent />;
  if (error) return <div>{error}</div>;

  return (
    <>
      <Snackbar
        open={Boolean(alertMessage)}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity="success"
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      {!answerPageActive ? (
        <div className="component-container">
          <div className="agent-leads-table-container">
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      background:
                        "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
                    }}
                  >
                    {[
                      "Company Name",
                      "Show Name",
                      "City, Country",
                      "Phone",
                      "Timezone",
                      "Status",
                      "Answer",
                    ].map((header, index) => (
                      <TableCell
                        key={header}
                        sx={{
                          color: "#fff",
                          borderRight: "2px solid #fff",
                          fontSize: "16px",
                          fontFamily: '"Playfair Display", serif',
                          textAlign: index === 6 ? "center" : "left",
                        }}
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedLeadsData.map((lead, index) => (
                    <TableRow key={lead._id || index}>
                      <TableCell sx={callStyle}>
                        {lead.Lead || (
                          <>
                            <GoDash style={{ fontSize: "20px" }} />
                            <GoDash style={{ fontSize: "20px" }} />
                          </>
                        )}
                      </TableCell>
                      <TableCell sx={callStyle}>
                        {lead.Show || (
                          <>
                            <GoDash style={{ fontSize: "20px" }} />
                            <GoDash style={{ fontSize: "20px" }} />
                          </>
                        )}
                      </TableCell>
                      <TableCell sx={callStyle}>
                        {lead.cityCountry || (
                          <>
                            <GoDash style={{ fontSize: "20px" }} />
                            <GoDash style={{ fontSize: "20px" }} />
                          </>
                        )}
                      </TableCell>
                      <TableCell sx={callStyle}>
                        {lead.Phone ? (
                          <a
                            href={`tel:${lead.Phone}`}
                            style={{ color: "inherit", textDecoration: "none" }}
                          >
                            {lead.Phone || (
                              <>
                                <GoDash style={{ fontSize: "20px" }} />
                                <GoDash style={{ fontSize: "20px" }} />
                              </>
                            )}
                          </a>
                        ) : (
                          "N/A"
                        )}
                      </TableCell>
                      <TableCell sx={callStyle}>
                        {lead.Timezone || (
                          <>
                            <GoDash style={{ fontSize: "20px" }} />
                            <GoDash style={{ fontSize: "20px" }} />
                          </>
                        )}
                      </TableCell>
                      <TableCell sx={callStyle}>
                        {lead.Status || (
                          <>
                            <GoDash style={{ fontSize: "20px" }} />
                            <GoDash style={{ fontSize: "20px" }} />
                          </>
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderRight: "2px solid #e0e0e0",
                          fontSize: "14px",
                          fontWeight: 550,
                          whiteSpace: "nowrap",
                          wordBreak: "keep-all",
                          padding: "7px 10px",
                          textAlign: "center",
                        }}
                      >
                        <button
                          style={{
                            padding: "10px 20px",
                            backgroundColor: "#4f637d",
                            color: "#fff",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                            fontSize: "14px",
                          }}
                          onClick={() => handleAnswerClick(lead)}
                        >
                          Answer
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              sx={{
                backgroundColor: "#fff",
                borderTop: "1px solid #e0e0e0",
              }}
              rowsPerPageOptions={[8, 10]}
              component="div"
              count={flattenedLeadsData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      ) : (
        selectedLead && (
          <AnswerSection
            token={token}
            handleCloseAnswerPage={handleCloseAnswerPage}
            handleUpdateLead={handleUpdateLead}
            selectedLead={selectedLead}
            setSelectedLead={setSelectedLead}
            selectedShowDataToRender={selectedShowDataToRender}
          />
        )
      )}
    </>
  );
};

export default NewCloserLeads;
