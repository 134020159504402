import React, { useState, useEffect, useContext } from "react";
import "./IncompleteLeadsTable.css";
import {
  Grid,
  Box,
  Typography,
  Table,
  TextField,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Alert,
  Snackbar,
} from "@mui/material";
import { UserContext } from "../../context/UserContext";
import { baseUrl } from "../../services/BaseUrl";
import axios from "axios";

const callStyle = {
  borderRight: "2px solid #e0e0e0",
  fontSize: "14px",
  fontWeight: 550,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  padding: "8px 10px",
};

const IncompleteLeadsTable = () => {
  const { token } = useContext(UserContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [createdLeadsCount, setCreatedLeadsCount] = useState(0);
  const [MissingData, setMissingData] = useState([]);
  const [filters, setFilters] = useState({
    AllMissingData: false,
    Email: false,
    Phone: false,
  });
  // Alert state
  const [alertMessage, setAlertMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('');

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    const newFilters = { AllMissingData: false, Email: false, Phone: false };

    if (id === "missing-all") {
      newFilters.AllMissingData = checked;
    } else if (id === "missing-phone") {
      newFilters.Phone = checked;
    } else if (id === "missing-email") {
      newFilters.Email = checked;
    }

    setFilters(newFilters);
  };

  const handleSubmitFilters = async () => {
    try {
      let filterCriteria = [];
      if (filters.AllMissingData) {
        filterCriteria = ["Phone", "Email"];
      } else {
        if (filters.Phone) filterCriteria.push("Phone");
        if (filters.Email) filterCriteria.push("Email");
      }

      const response = await axios.post(
        `${baseUrl}/api/companies/leads-with-missing-data`,
        { filters: filterCriteria },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const filteredData = response.data.filter((data) => {
        if (filters.AllMissingData) {
          return (
            data.Phone === "N/A" || !data.Phone || data.Email === "Unknown"
          );
        }

        if (filters.Phone && (data.Phone === "N/A" || !data.Phone)) {
          return true;
        }
        if (filters.Email && data.Email === "Unknown") {
          return true;
        }

        return false;
      });

      setMissingData(filteredData);
    } catch (error) {
      console.error("Error fetching filtered leads:", error);
    }
  };

  const fetchCreatedLeadsCount = async () => {
    try {
      const response = await fetch(
        `${baseUrl}/api/companies/created-leads-count`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = await response.json();
      setCreatedLeadsCount(data.createdLeadsCount);
    } catch (error) {
      console.error("Error fetching created leads count:", error);
    }
  };

  useEffect(() => {
    fetchCreatedLeadsCount();
    handleSubmitFilters();
  }, [token, filters]);

  const handlePhoneChange = (e, id) => {
    const updatedPhone = e.target.value;

    // Update the MissingData state with the new phone value
    setMissingData((prevData) =>
      prevData.map((lead) =>
        lead._id === id ? { ...lead, Phone: updatedPhone } : lead
      )
    );
  };

  const handleEmailChange = (e, id) => {
    const updatedEmail = e.target.value;

    // Update the MissingData state with the new email value
    setMissingData((prevData) =>
      prevData.map((lead) =>
        lead._id === id ? { ...lead, Email: updatedEmail } : lead
      )
    );
  };

  const handleUpdateLeads = async () => {
    const payload = MissingData.filter((lead) => {
      const isPhoneValid =
        lead.Phone &&
        lead.Phone.trim() !== "" &&
        lead.Phone.trim().toLowerCase() !== "n/a" &&
        lead.Phone.trim().toLowerCase() !== "unknown";

      const isEmailValid =
        lead.Email &&
        lead.Email.trim() !== "" &&
        lead.Email.trim().toLowerCase() !== "n/a" &&
        lead.Email.trim().toLowerCase() !== "unknown";

      return isPhoneValid && isEmailValid;
    }).map((lead) => ({
      _id: lead._id,
      edition: lead.edition,
      Phone: lead.Phone,
      Email: lead.Email,
    }));

    // Check if the payload is empty
    if (payload.length === 0) {
      setAlertMessage("You must enter phone & email");
      setAlertOpen(true);
      setAlertSeverity("error")
      return;
    }

    try {
      const response = await axios.post(
        `${baseUrl}/api/companies/update-Bulk-of-leads`,
        { payload },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        setAlertMessage("Leads updated successfully!");
      setAlertOpen(true);
      setAlertSeverity("success")
        await handleSubmitFilters();
        await fetchCreatedLeadsCount();
      }
    } catch (error) {
      console.error("Error updating leads:", error);
    }
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleHideLead = async (leadId) => {
    try {
      const response = await axios.patch(
        `${baseUrl}/api/companies/hide-lead/${leadId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      handleUpdateLeads();
      handleSubmitFilters();
    } catch (error) {
      console.error("Error hiding lead:", error);
    }
  };


  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="Incomplete-Leads-contanier">
      <Snackbar
        open={alertOpen}
        autoHideDuration={4000} onClose={handleAlertClose}
      >
        <Alert severity={alertSeverity}>{alertMessage}</Alert>
      </Snackbar>
      <h2 className="Incomplete-Leads-header">Filters Leads :</h2>
      <div className="Incomplete-Leads-filters">
        <div className="checkbox-incomplete-leads">
          <input
            type="checkbox"
            id="missing-all"
            checked={filters.AllMissingData}
            onChange={handleCheckboxChange}
            className="check-box-input"
          />
          <label htmlFor="missing-phone" className="check-box-label">
            All Missing Data
          </label>
        </div>
        <div className="checkbox-incomplete-leads">
          <input
            type="checkbox"
            id="missing-phone"
            checked={filters.Phone}
            onChange={handleCheckboxChange}
            className="check-box-input"
          />
          <label htmlFor="missing-phone" className="check-box-label">
            Missing Phone
          </label>
        </div>
        <div className="checkbox-incomplete-leads">
          <input
            type="checkbox"
            id="missing-email"
            checked={filters.Email}
            onChange={handleCheckboxChange}
            className="check-box-input"
          />
          <label htmlFor="missing-email" className="check-box-label">
            Missing Email
          </label>
        </div>
      </div>
      <div className="update-leads-grid-contanier">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <Box
              sx={{
                padding: 0.5,
                textAlign: "center",
                border: "1px solid #e0e0e0",
                borderRadius: 1,
              }}
            >
              <Typography variant="subtitle1" sx={{ lineHeight: 1.4 }}>
                Created Leads Count
              </Typography>
              <Typography variant="p" sx={{ lineHeight: 1.4 }}>
                {createdLeadsCount}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <button
          style={{
            letterSpacing: 1,
            padding: "10px 25px",
            cursor: "pointer",
            borderRadius: "4px",
            fontSize: "16px",
            fontWeight: "700",
            background: "#cebfbf",
            color: "#000",
            opacity: 1,
            whiteSpace: "nowrap",
            wordBreak: "keep-all",
          }}
          onClick={handleUpdateLeads}
        >
          Update Leads
        </button>
      </div>
      <div
        className="Incomplete-Leads-table-contanier"
        style={{ borderRadius: "8px" }}
      >
        <TableContainer
          component={Paper}
          sx={{ borderRadius: "8px", overflowX: "auto", maxHeight: "82vh" }}
          className="custom-scrollbar"
        >
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  background:
                    "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
                }}
              >
                {[
                  "NO",
                  "Lead Name",
                  "Phone",
                  "Email",
                  "Dm Name",
                  "Title",
                  "Lead Status",
                  " Hide",
                ].map((header, index) => (
                  <TableCell
                    key={header}
                    sx={{
                      color: "#fff",
                      borderRight: "2px solid #fff",
                      fontSize: "16px",
                      fontFamily: '"Playfair Display", serif',
                      whiteSpace: "nowrap",
                      wordBreak: "keep-all",
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {MissingData.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ).map((lead, index) => (
                <TableRow key={lead._id}>
                  <TableCell sx={callStyle}>{index + 1}</TableCell>
                  <TableCell sx={callStyle}>{lead.Lead}</TableCell>
                  <TableCell sx={callStyle}>
                    <TextField
                      variant="outlined"
                      value={lead.Phone}
                      size="small"
                      sx={{ width: "200px" }}
                      onChange={(e) => handlePhoneChange(e, lead._id)}
                    />
                  </TableCell>
                  <TableCell sx={callStyle}>
                    <TextField
                      value={lead.Email}
                      variant="outlined"
                      size="small"
                      sx={{ width: "200px" }}
                      onChange={(e) => handleEmailChange(e, lead._id)} // Example handler for email change
                    />
                  </TableCell>
                  <TableCell sx={callStyle}>
                    <TextField
                      value={lead.Dm_Name || ""}
                      variant="outlined"
                      size="small"
                      sx={{ width: "200px" }}
                      // onChange={(e) => handleEmailChange(e, lead._id)}  // Example handler for email change
                    />
                  </TableCell>
                  <TableCell sx={callStyle}>{lead.Title}</TableCell>
                  <TableCell sx={callStyle}>{lead.Lead_Status}</TableCell>
                  <TableCell sx={callStyle}>
                  <button
                            style={{
                              padding: "10px 15px",
                              backgroundColor: "#4f637d",
                              color: "#fff",
                              border: "none",
                              borderRadius: "5px",
                              cursor: "pointer",
                              fontSize: "14px",
                            }}
                            onClick={() => handleHideLead(lead._id)}
                          >
                            Hide
                          </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{
            backgroundColor: "#fff",
            borderTop: "1px solid #e0e0e0",
          }}
          rowsPerPageOptions={[10, 20]}
          component="div"
          count={MissingData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
};

export default IncompleteLeadsTable;

// import React, { useState, useEffect , useContext} from "react";
// import "./IncompleteLeadsTable.css";
// import { Grid, Box, Typography,  Table,TextField,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper} from "@mui/material";
//   import { UserContext } from "../../context/UserContext";
//   import { baseUrl } from "../../services/BaseUrl";
//   import axios from "axios";

//   const callStyle = {
//     borderRight: "2px solid #e0e0e0",
//     fontSize: "14px",
//     fontWeight: 550,
//     whiteSpace: "nowrap",
//     wordBreak: "keep-all",
//     padding: "8px 10px",
//   };

// const IncompleteLeadsTable = () => {
//   const { token } = useContext(UserContext);
//   const [createdLeadsCount, setCreatedLeadsCount] = useState(0);
//   const [allMissingData , setAllMissingData] = useState([])

//   const [filters, setFilters] = useState({
//     AllMissingData: false,
//     Email: false,
//     Phone: false,
//   });

//   // Function to handle checkbox state changes
//   const handleCheckboxChange = (event) => {
//     const { id, checked } = event.target;
//     if (id === "missing-phone") {
//       setFilters({ ...filters, Phone: checked });
//     } else if (id === "missing-email") {
//       setFilters({ ...filters, Email: checked });
//     } else if (id === "missing-all") {
//       setFilters({
//         AllMissingData: checked,
//       });
//     }
//   };

//   // Fetch leads based on filters
//   const handleSubmitFilters = async () => {
//     try {
//       const filterCriteria = [];
//       if (filters.Phone) filterCriteria.push("Phone");
//       if (filters.Email) filterCriteria.push("Email");
//       if (filters.AllMissingData) {
//         filterCriteria.push("Phone");
//         filterCriteria.push("Email");
//       }
//       // Example filtering request to the backend
//       const response = await axios.post(
//         `${baseUrl}/api/companies/leads-with-missing-data`,
//         { filters: filterCriteria },
//         { headers: { Authorization: `Bearer ${token}` } }
//       );
//       setAllMissingData(response.data);
//     } catch (error) {
//       console.error("Error fetching filtered leads:", error);
//     }
//   };

//   // Fetch created leads count
//   const fetchCreatedLeadsCount = async () => {
//     try {
//       const response = await fetch(`${baseUrl}/api/companies/created-leads-count`, {
//         method: "GET",
//         headers: { Authorization: `Bearer ${token}` },
//       });
//       const data = await response.json();
//       setCreatedLeadsCount(data.createdLeadsCount);
//     } catch (error) {
//       console.error("Error fetching created leads count:", error);
//     }
//   };

//   useEffect(() => {
//     fetchCreatedLeadsCount();
//     handleSubmitFilters();
//   }, [token, filters]);

//     // Filter leads based on phone column (empty or 'N/A')
//     const filteredData = allMissingData.filter(
//         (lead) => lead.Phone === "" || lead.Phone === "N/A"
//       )

//   console.log("filteredData", filteredData);
//   console.log("allMissingData", allMissingData);

//   return (
//     <div className="Incomplete-Leads-contanier">
//       <h2 className="Incomplete-Leads-header">Filters Leads :</h2>
//       <div className="Incomplete-Leads-filters">
//         <div className="checkbox-incomplete-leads">
//           <input
//             type="checkbox"
//             id="missing-all"
//             checked={filters.AllMissingData}
//             onChange={handleCheckboxChange}
//             className="check-box-input"
//           />
//           <label htmlFor="missing-phone" className="check-box-label">
//             All Missing Data
//           </label>
//         </div>
//         <div className="checkbox-incomplete-leads">
//           <input
//             type="checkbox"
//             id="missing-phone"
//             checked={filters.Phone}
//             onChange={handleCheckboxChange}
//             className="check-box-input"
//           />
//           <label htmlFor="missing-phone" className="check-box-label">
//             Missing Phone
//           </label>
//         </div>
//         <div className="checkbox-incomplete-leads">
//           <input
//             type="checkbox"
//             id="missing-email"
//             checked={filters.Email}
//             onChange={handleCheckboxChange}
//             className="check-box-input"
//           />
//           <label htmlFor="missing-email" className="check-box-label">
//             Missing Email
//           </label>
//         </div>
//       </div>
//       <div className="update-leads-grid-contanier">
//         <Grid container spacing={1}>
//           <Grid item xs={12} sm={4} md={3} lg={2}>
//             <Box
//               sx={{
//                 padding: 0.5,
//                 textAlign: "center",
//                 border: "1px solid #e0e0e0",
//                 borderRadius: 1,
//               }}
//             >
//               <Typography variant="subtitle1" sx={{ lineHeight: 1.4 }}>
//                 Created Leads Count
//               </Typography>
//               <Typography variant="p" sx={{ lineHeight: 1.4 }}>
//                 {createdLeadsCount}
//               </Typography>
//             </Box>
//           </Grid>
//         </Grid>
//         <button
//           style={{
//             letterSpacing: 1,
//             padding: "10px 25px",
//             cursor: "pointer",
//             borderRadius: "4px",
//             fontSize: "16px",
//             fontWeight: "700",
//             background: "#cebfbf", // Change background color when disabled
//             color: "#000", // Change text color when disabled
//             opacity: 1,
//             whiteSpace: "nowrap",
//             wordBreak: "keep-all",
//           }}
//         >
//           Update Leads
//         </button>
//       </div>
//       <div
//         className="Incomplete-Leads-table-contanier"
//         //  style={{maxHeight: "61vh", overflow: "auto", borderRadius: "8px" ,overflowX:"auto"}}
//         style={{ borderRadius: "8px" }}
//       >
//         <TableContainer
//           component={Paper}
//           sx={{ borderRadius: "8px", overflowX: "auto", maxHeight: "61vh" }}
//           className="custom-scrollbar"
//         >
//           <Table>
//             <TableHead>
//               <TableRow
//                 sx={{
//                   background:
//                     "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
//                   color: "#fff",
//                 }}
//               >
//                 <TableCell
//                   sx={{
//                     color: "#fff",
//                     borderRight: "2px solid #fff",
//                     fontSize: "16px",
//                     fontFamily: '"Playfair Display", serif',
//                   }}
//                 >
//                   NO
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "#fff",
//                     borderRight: "2px solid #fff",
//                     fontSize: "16px",
//                     fontFamily: '"Playfair Display", serif',
//                   }}
//                 >
//                   Lead
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "#fff",
//                     borderRight: "2px solid #fff",
//                     fontSize: "16px",
//                     fontFamily: '"Playfair Display", serif',
//                   }}
//                 >
//                   Phone
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "#fff",
//                     borderRight: "2px solid #fff",
//                     fontSize: "16px",
//                     fontFamily: '"Playfair Display", serif',
//                   }}
//                 >
//                   Email
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "#fff",
//                     borderRight: "2px solid #fff",
//                     fontSize: "16px",
//                     fontFamily: '"Playfair Display", serif',
//                   }}
//                 >
//                   Timezone
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "#fff",
//                     borderRight: "2px solid #fff",
//                     fontSize: "16px",
//                     fontFamily: '"Playfair Display", serif',
//                   }}
//                 >
//                   Dm Name
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "#fff",
//                     borderRight: "2px solid #fff",
//                     fontSize: "16px",
//                     fontFamily: '"Playfair Display", serif',
//                   }}
//                 >
//                   Title
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "#fff",
//                     borderRight: "2px solid #fff",
//                     fontSize: "16px",
//                     fontFamily: '"Playfair Display", serif',
//                     whiteSpace: "nowrap",
//                     wordBreak: "keep-all",
//                   }}
//                 >
//                   Lead Status
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "#fff",
//                     borderRight: "2px solid #fff",
//                     fontSize: "16px",
//                     fontFamily: '"Playfair Display", serif',
//                   }}
//                 >
//                   Done
//                 </TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//             {allMissingData.map((lead, index) => (
//                 <TableRow key={lead._id}>
//                   <TableCell sx={callStyle}>{index + 1}</TableCell>
//                   <TableCell sx={callStyle}>{lead.Lead}</TableCell>
//                   <TableCell sx={callStyle}>{lead.Phone}</TableCell>
//                   <TableCell sx={callStyle}>{lead.Email}</TableCell>
//                   <TableCell sx={callStyle}>{lead.Timezone}</TableCell>
//                   <TableCell sx={callStyle}>{lead.Dm_Name}</TableCell>
//                   <TableCell sx={callStyle}>{lead.Title}</TableCell>
//                   <TableCell sx={callStyle}>{lead.Lead_Status}</TableCell>
//                   <TableCell sx={callStyle}>{lead.Done}</TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </div>
//     </div>
//   );
// };

// export default IncompleteLeadsTable;

{
  /* <div className="checkbox-incomplete-leads">
<input
  type="checkbox"
  id="missing-email"
  className="check-box-input"
/>
<label htmlFor="missing-email" className="check-box-label">
  Missing Phone & Email
</label>
</div> */
}

// import React, { useState, useEffect } from "react";
// import "./IncompleteLeadsTable.css";
// import { Grid, Box, Typography, TextField } from "@mui/material";
// import {
// Table,
// TableBody,
// TableCell,
// TableContainer,
// TableHead,
// TableRow,
// Paper,
// } from "@mui/material";
// import { FaCheckSquare } from "react-icons/fa";
// import initialLeadsData from "../../Data/IncompleteLeadsTable.json";

// const IncompleteLeadsTable = () => {
//   const [incompleteLeads, setIncompleteLeads] = useState(
//     () =>
//       JSON.parse(localStorage.getItem("incompleteLeads")) || initialLeadsData
//   );
//   const [historyLeads, setHistoryLeads] = useState(
//     () => JSON.parse(localStorage.getItem("historyLeads")) || []
//   );
//   const [missingPhoneFilter, setMissingPhoneFilter] = useState(false);
//   const [missingEmailFilter, setMissingEmailFilter] = useState(false);
//   const [showHistoryLeads, setShowHistoryLeads] = useState(false);
//   const [newPhoneValues, setNewPhoneValues] = useState({});
//   const [newEmailValues, setNewEmailValues] = useState({});

//   useEffect(() => {
//     localStorage.setItem("incompleteLeads", JSON.stringify(incompleteLeads));
//     localStorage.setItem("historyLeads", JSON.stringify(historyLeads));
//   }, [incompleteLeads, historyLeads]);

//   const filteredLeads = incompleteLeads.filter((lead) => {
//     if (missingPhoneFilter && missingEmailFilter) {
//       return !lead.phone && !lead.email;
//     } else if (missingPhoneFilter) {
//       return !lead.phone;
//     } else if (missingEmailFilter) {
//       return !lead.email;
//     } else {
//       return false;
//     }
//   });

//   const handleCheckClick = (leadId) => {
//     const leadToUpdate = incompleteLeads.find((lead) => lead.id === leadId);

//     // Create a new lead object with updated values
//     const updatedLead = {
//       ...leadToUpdate,
//       phone:
//         newPhoneValues[leadId] !== undefined
//           ? newPhoneValues[leadId]
//           : leadToUpdate.phone,
//       email:
//         newEmailValues[leadId] !== undefined
//           ? newEmailValues[leadId]
//           : leadToUpdate.email,
//     };

//     // Check if the lead already exists in the history
//     const existingLeadInHistory = historyLeads.find(
//       (lead) => lead.id === leadId
//     );

//     // If the lead is not in history or if the values have changed, update the history
//     if (
//       !existingLeadInHistory ||
//       existingLeadInHistory.phone !== updatedLead.phone ||
//       existingLeadInHistory.email !== updatedLead.email
//     ) {
//       // Update history leads with either the existing lead (if it exists) or add the updated lead
//       setHistoryLeads((prevHistory) => {
//         const updatedHistory = prevHistory.filter((lead) => lead.id !== leadId); // Remove the old lead if it exists
//         return [...updatedHistory, updatedLead]; // Add the updated lead
//       });
//     }

//     setIncompleteLeads((prevLeads) =>
//       prevLeads.filter((lead) => lead.id !== leadId)
//     );

//     setNewPhoneValues((prev) => ({ ...prev, [leadId]: undefined }));
//     setNewEmailValues((prev) => ({ ...prev, [leadId]: undefined }));
//   };

//   const handlePhoneChange = (leadId, value) => {
//     setNewPhoneValues((prev) => ({
//       ...prev,
//       [leadId]: value,
//     }));
//   };

//   const handleEmailChange = (leadId, value) => {
//     setNewEmailValues((prev) => ({
//       ...prev,
//       [leadId]: value,
//     }));
//   };

//   const handleHistoryCheckboxChange = (event) => {
//     const isChecked = event.target.checked;
//     setShowHistoryLeads(isChecked);

//     if (isChecked) {
//       setMissingPhoneFilter(false);
//       setMissingEmailFilter(false);
//     }
//   };

//   return (
//     <div className="Incomplete-Leads-contanier">
//       <h2 className="Incomplete-Leads-header">Filters Leads :</h2>
//       <div className="Incomplete-Leads-filters">
// <div className="checkbox-incomplete-leads">
//   <input
//     type="checkbox"
//     id="missing-phone"
//     className="check-box-input"
//     checked={missingPhoneFilter}
//     onChange={() => {
//       setShowHistoryLeads(false);
//       setMissingPhoneFilter(!missingPhoneFilter)            }}
//   />
//   <label htmlFor="missing-phone" className="check-box-label">
//     Missing Phone
//   </label>
// </div>
// <div className="checkbox-incomplete-leads">
//   <input
//     type="checkbox"
//     id="missing-email"
//     className="check-box-input"
//     checked={missingEmailFilter}
//     onChange={() => {
//       setShowHistoryLeads(false);
//       setMissingEmailFilter(!missingEmailFilter);
//     }}
//   />
//   <label htmlFor="missing-email" className="check-box-label">
//     Missing Email
//   </label>
// </div>
//         <div className="checkbox-incomplete-leads">
//           <input
//             type="checkbox"
//             id="history-leads"
//             className="check-box-input"
//             checked={showHistoryLeads}
//             onChange={handleHistoryCheckboxChange}
//           />
//           <label htmlFor="history-leads" className="check-box-label">
//             History Leads
//           </label>
//         </div>
//       </div>
// <Grid container spacing={1}>
//   <Grid item xs={12} sm={4} md={3} lg={2}>
//     <Box
//       sx={{
//         padding: 0.5,
//         textAlign: "center",
//         border: "1px solid #e0e0e0",
//         borderRadius: 1,
//       }}
//     >
//       <Typography variant="subtitle1" sx={{ lineHeight: 1.4 }}>
//         Created Leads Count
//       </Typography>
//       <Typography variant="p" sx={{ lineHeight: 1.4 }}>
//         {historyLeads.length}
//       </Typography>
//     </Box>
//   </Grid>
// </Grid>
//       <div
//         className="Incomplete-Leads-table-contanier"
//         // style={{maxHeight: "61vh", overflow: "auto", borderRadius: "8px" ,overflowX:"auto"}}
//         style={{borderRadius: "8px"}}

//       >
//         <TableContainer
//           component={Paper}
//           sx={{ borderRadius: "8px" ,overflowX:"auto" , maxHeight:"61vh"}}
//           className="custom-scrollbar"
//         >
//           <Table>
//             <TableHead>
//               <TableRow
//                 sx={{
//                   background:
//                     "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
//                   color: "#fff",
//                 }}
//               >
//                 <TableCell
//                   sx={{
//                     color: "#fff",
//                     borderRight: "2px solid #fff",
//                     fontSize: "16px",
//                     fontFamily: '"Playfair Display", serif',
//                   }}
//                 >
//                   NO
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "#fff",
//                     borderRight: "2px solid #fff",
//                     fontSize: "16px",
//                     fontFamily: '"Playfair Display", serif',
//                   }}
//                 >
//                   Lead
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "#fff",
//                     borderRight: "2px solid #fff",
//                     fontSize: "16px",
//                     fontFamily: '"Playfair Display", serif',
//                   }}
//                 >
//                   Phone
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "#fff",
//                     borderRight: "2px solid #fff",
//                     fontSize: "16px",
//                     fontFamily: '"Playfair Display", serif',
//                   }}
//                 >
//                   Email
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "#fff",
//                     borderRight: "2px solid #fff",
//                     fontSize: "16px",
//                     fontFamily: '"Playfair Display", serif',
//                   }}
//                 >
//                   Timezone
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "#fff",
//                     borderRight: "2px solid #fff",
//                     fontSize: "16px",
//                     fontFamily: '"Playfair Display", serif',
//                   }}
//                 >
//                   Dm Name
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "#fff",
//                     borderRight: "2px solid #fff",
//                     fontSize: "16px",
//                     fontFamily: '"Playfair Display", serif',
//                   }}
//                 >
//                   Title
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "#fff",
//                     borderRight: "2px solid #fff",
//                     fontSize: "16px",
//                     fontFamily: '"Playfair Display", serif',
//                     whiteSpace: "nowrap",
//                     wordBreak: "keep-all",
//                   }}
//                 >
//                   Lead Status
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "#fff",
//                     borderRight: "2px solid #fff",
//                     fontSize: "16px",
//                     fontFamily: '"Playfair Display", serif',
//                   }}
//                 >Done</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {(showHistoryLeads ? historyLeads : filteredLeads).map(
//                 (lead, index) => (
//                   <TableRow key={lead.id}>
//                     <TableCell
//                       sx={{
//                         borderRight: "2px solid #e0e0e0",
//                         fontSize: "14px",
//                         fontWeight: 550,
//                       }}
//                     >
//                       {index + 1}
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         borderRight: "2px solid #e0e0e0",
//                         fontSize: "14px",
//                         fontWeight: 550,
//                         whiteSpace: "nowrap",
//                         wordBreak: "keep-all",
//                       }}
//                     >
//                       {lead.lead}
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         borderRight: "2px solid #e0e0e0",
//                         fontSize: "14px",
//                         fontWeight: 550,
//                       }}
//                     >
//                       <TextField
//                         variant="outlined"
//                         size="small"
//                         sx={{ width: "200px" }}
//                         value={newPhoneValues[lead.id] || lead.phone || ""}
//                         onChange={(e) =>
//                           handlePhoneChange(lead.id, e.target.value)
//                         }
//                       />
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         borderRight: "2px solid #e0e0e0",
//                         fontSize: "14px",
//                         fontWeight: 550,
//                       }}
//                     >
//                       <TextField
//                         variant="outlined"
//                         size="small"
//                         sx={{ width: "200px" }}
//                         value={newEmailValues[lead.id] || lead.email || ""}
//                         onChange={(e) =>
//                           handleEmailChange(lead.id, e.target.value)
//                         }
//                       />
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         borderRight: "2px solid #e0e0e0",
//                         fontSize: "14px",
//                         fontWeight: 550,
//                         whiteSpace: "nowrap",
//                         wordBreak: "keep-all",
//                       }}
//                     >
//                       {lead.timezone}
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         borderRight: "2px solid #e0e0e0",
//                         fontSize: "14px",
//                         fontWeight: 550,
//                         whiteSpace: "nowrap",
//                         wordBreak: "keep-all",
//                       }}
//                     >
//                       {lead.dm_name}
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         borderRight: "2px solid #e0e0e0",
//                         fontSize: "14px",
//                         fontWeight: 550,
//                         whiteSpace: "nowrap",
//                         wordBreak: "keep-all",
//                       }}
//                     >
//                       {lead.title}
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         borderRight: "2px solid #e0e0e0",
//                         fontSize: "14px",
//                         fontWeight: 550,
//                         whiteSpace: "nowrap",
//                         wordBreak: "keep-all",
//                       }}
//                     >
//                       {lead.lead_status}
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         borderRight: "2px solid #e0e0e0",
//                         fontSize: "18px",
//                         fontWeight: 550,
//                         color: "green",
//                         cursor: "pointer",
//                         textAlign:"center"
//                       }}
//                     >
//                       <FaCheckSquare
//                         onClick={() => handleCheckClick(lead.id)}
//                       />
//                     </TableCell>
//                   </TableRow>
//                 )
//               )}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </div>
//     </div>
//   );
// };

// export default IncompleteLeadsTable;

// import React, { useState, useEffect } from "react";
// import "./IncompleteLeadsTable.css";
// import { Grid, Box, Typography, TextField } from "@mui/material";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
// } from "@mui/material";
// import { FaCheckSquare } from "react-icons/fa";
// // import initialLeadsData from "../../Data/showsData.json"; // Ensure the correct name here

// const IncompleteLeadsTable = () => {
//   const initialLeadsData = {
//     shows: [
//       {
//         id: 1,
//         showName: "TechCon 2024",
//         date: "March 12-14, 2024",
//         address: "Moscone Center, 747 Howard St",
//         location: {
//           convention: "Moscone Center",
//           city: "San Francisco",
//           state: "CA",
//           country: "USA",
//         },
//         isActive: false,
//         leads: [
//           {
//             leadId: 101,
//             leadName: "Alice Johnson",
//             email: "alice.johnson@example.com",
//             phone: "123-456-7890",
//           },
//           {
//             leadId: 102,
//             leadName: "Bob Smith",
//             email: "",
//             phone: "234-567-8901",
//           },
//           {
//             leadId: 103,
//             leadName: "Charlie Brown",
//             email: "charlie.brown@example.com",
//             phone: "",
//           },
//         ],
//       },
//       {
//         id: 2,
//         showName: "Global Business Summit",
//         date: "May 3-5, 2025",
//         address: "Javits Center, 429 11th Ave",
//         location: {
//           convention: "Javits Center",
//           city: "New York",
//           state: "NY",
//           country: "USA",
//         },
//         isActive: true,
//         leads: [
//           {
//             leadId: 201,
//             leadName: "Carol Lee",
//             email: "carol.lee@example.com",
//             phone: "",
//           },
//           {
//             leadId: 202,
//             leadName: "David Brown",
//             email: "",
//             phone: "",
//           },
//           {
//             leadId: 203,
//             leadName: "Eva Green",
//             email: "eva.green@example.com",
//             phone: "345-678-9012",
//           },
//         ],
//       },
//       {
//         id: 3,
//         showName: "CES 2024",
//         date: "January 9-12, 2024",
//         address: "Las Vegas Convention Center, 3150 Paradise Rd",
//         location: {
//           convention: "Las Vegas Convention Center",
//           city: "Las Vegas",
//           state: "NV",
//           country: "USA",
//         },
//         isActive: false,
//         leads: [
//           {
//             leadId: 301,
//             leadName: "Emma Davis",
//             email: "emma.davis@example.com",
//             phone: "456-789-0123",
//           },
//           {
//             leadId: 302,
//             leadName: "Frank Wilson",
//             email: "",
//             phone: "",
//           },
//           {
//             leadId: 303,
//             leadName: "Gina Smith",
//             email: "gina.smith@example.com",
//             phone: "567-890-1234",
//           },
//         ],
//       },
//       {
//         id: 4,
//         showName: "Healthcare Innovation Summit",
//         date: "April 8-10, 2024",
//         address: "Orange County Convention Center, 9800 International Dr",
//         location: {
//           convention: "Orange County Convention Center",
//           city: "Orlando",
//           state: "FL",
//           country: "USA",
//         },
//         isActive: true,
//         leads: [
//           {
//             leadId: 401,
//             leadName: "Grace Martinez",
//             email: "",
//             phone: "678-901-2345",
//           },
//           {
//             leadId: 402,
//             leadName: "Henry Garcia",
//             email: "henry.garcia@example.com",
//             phone: "",
//           },
//           {
//             leadId: 403,
//             leadName: "Isla Brown",
//             email: "",
//             phone: "",
//           },
//         ],
//       },
//     ],
//   };

//   const [incompleteLeads, setIncompleteLeads] = useState(() =>
//     initialLeadsData.shows
//       .filter(show => show.isActive) // Only include shows where isActive is true
//       .flatMap(show => show.leads)
//       .filter(lead => lead.email === "" || lead.phone === "")
//   );

//   const [historyLeads, setHistoryLeads] = useState(
//     () => JSON.parse(localStorage.getItem("historyLeads")) || []
//   );

//   const [missingPhoneFilter, setMissingPhoneFilter] = useState(false);
//   const [missingEmailFilter, setMissingEmailFilter] = useState(false);
//   const [showHistoryLeads, setShowHistoryLeads] = useState(false);
//   const [newPhoneValues, setNewPhoneValues] = useState({});
//   const [newEmailValues, setNewEmailValues] = useState({});

//   useEffect(() => {
//     localStorage.setItem("incompleteLeads", JSON.stringify(incompleteLeads));
//     localStorage.setItem("historyLeads", JSON.stringify(historyLeads));
//   }, [incompleteLeads, historyLeads]);

//   const filteredLeads = incompleteLeads.filter((lead) => {
//     if (showHistoryLeads) {
//       return true; // Show all leads if history is checked
//     }

//     // Check filters if history leads are not shown
//     if (missingPhoneFilter && missingEmailFilter) {
//       return !lead.phone && !lead.email;
//     } else if (missingPhoneFilter) {
//       return !lead.phone;
//     } else if (missingEmailFilter) {
//       return !lead.email;
//     } else {
//       return true; // Show all leads if no filters are applied
//     }
//   });

//   const handleCheckClick = (leadId) => {
//     const leadToUpdate = incompleteLeads.find((lead) => lead.leadId === leadId);

//     const updatedLead = {
//       ...leadToUpdate,
//       phone:
//         newPhoneValues[leadId] !== undefined
//           ? newPhoneValues[leadId]
//           : leadToUpdate.phone,
//       email:
//         newEmailValues[leadId] !== undefined
//           ? newEmailValues[leadId]
//           : leadToUpdate.email,
//     };

//     const existingLeadInHistory = historyLeads.find(
//       (lead) => lead.leadId === leadId
//     );

//     if (
//       !existingLeadInHistory ||
//       existingLeadInHistory.phone !== updatedLead.phone ||
//       existingLeadInHistory.email !== updatedLead.email
//     ) {
//       setHistoryLeads((prevHistory) => {
//         const updatedHistory = prevHistory.filter(
//           (lead) => lead.leadId !== leadId
//         );
//         return [...updatedHistory, updatedLead];
//       });
//     }

//     setIncompleteLeads((prevLeads) =>
//       prevLeads.filter((lead) => lead.leadId !== leadId)
//     );

//     setNewPhoneValues((prev) => ({ ...prev, [leadId]: undefined }));
//     setNewEmailValues((prev) => ({ ...prev, [leadId]: undefined }));
//   };

//   const handlePhoneChange = (leadId, value) => {
//     setNewPhoneValues((prev) => ({
//       ...prev,
//       [leadId]: value,
//     }));
//   };

//   const handleEmailChange = (leadId, value) => {
//     setNewEmailValues((prev) => ({
//       ...prev,
//       [leadId]: value,
//     }));
//   };

//   const handleHistoryCheckboxChange = (event) => {
//     setShowHistoryLeads(event.target.checked);
//     if (event.target.checked) {
//       setMissingPhoneFilter(false);
//       setMissingEmailFilter(false);
//     }
//   };

//   return (
//     <div className="Incomplete-Leads-contanier">
//       <h2 className="Incomplete-Leads-header">Filters Leads :</h2>
//       <div className="Incomplete-Leads-filters">
//         <div className="checkbox-incomplete-leads">
//           <input
//             type="checkbox"
//             id="missing-phone"
//             className="check-box-input"
//             checked={missingPhoneFilter}
//             onChange={() => {
//               setShowHistoryLeads(false);
//               setMissingPhoneFilter(!missingPhoneFilter);
//             }}
//           />
//           <label htmlFor="missing-phone" className="check-box-label">
//             Missing Phone
//           </label>
//         </div>
//         <div className="checkbox-incomplete-leads">
//           <input
//             type="checkbox"
//             id="missing-email"
//             className="check-box-input"
//             checked={missingEmailFilter}
//             onChange={() => {
//               setShowHistoryLeads(false);
//               setMissingEmailFilter(!missingEmailFilter);
//             }}
//           />
//           <label htmlFor="missing-email" className="check-box-label">
//             Missing Email
//           </label>
//         </div>
//         <div className="checkbox-incomplete-leads">
//           <input
//             type="checkbox"
//             id="history-leads"
//             className="check-box-input"
//             checked={showHistoryLeads}
//             onChange={handleHistoryCheckboxChange}
//           />
//           <label htmlFor="history-leads" className="check-box-label">
//             History Leads
//           </label>
//         </div>
//       </div>
//       <Grid container spacing={1}>
//         <Grid item xs={12} sm={4} md={3} lg={2}>
//           <Box
//             sx={{
//               padding: 0.5,
//               textAlign: "center",
//               border: "1px solid #e0e0e0",
//               borderRadius: 1,
//             }}
//           >
//             <Typography variant="subtitle1" sx={{ lineHeight: 1.4 }}>
//               Created Leads Count
//             </Typography>
//             <Typography variant="p" sx={{ lineHeight: 1.4 }}>
//               {historyLeads.length}
//             </Typography>
//           </Box>
//         </Grid>
//       </Grid>
//       <div
//         className="Incomplete-Leads-table-contanier"
//         // style={{maxHeight: "61vh", overflow: "auto", borderRadius: "8px" ,overflowX:"auto"}}
//         style={{ borderRadius: "8px" }}
//       >
//         <TableContainer
//           component={Paper}
//           sx={{ borderRadius: "8px", overflowX: "auto", maxHeight: "61vh" }}
//           className="custom-scrollbar"
//         >
//           <Table>
//             <TableHead>
//               <TableRow
//                 sx={{
//                   background:
//                     "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
//                   color: "#fff",
//                 }}
//               >
//                 <TableCell
//                   sx={{
//                     color: "#fff",
//                     borderRight: "2px solid #fff",
//                     fontSize: "16px",
//                     fontFamily: '"Playfair Display", serif',
//                   }}
//                 >
//                   NO
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "#fff",
//                     borderRight: "2px solid #fff",
//                     fontSize: "16px",
//                     fontFamily: '"Playfair Display", serif',
//                   }}
//                 >
//                   Lead
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "#fff",
//                     borderRight: "2px solid #fff",
//                     fontSize: "16px",
//                     fontFamily: '"Playfair Display", serif',
//                   }}
//                 >
//                   Phone
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "#fff",
//                     borderRight: "2px solid #fff",
//                     fontSize: "16px",
//                     fontFamily: '"Playfair Display", serif',
//                   }}
//                 >
//                   Email
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "#fff",
//                     borderRight: "2px solid #fff",
//                     fontSize: "16px",
//                     fontFamily: '"Playfair Display", serif',
//                   }}
//                 >
//                   Timezone
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "#fff",
//                     borderRight: "2px solid #fff",
//                     fontSize: "16px",
//                     fontFamily: '"Playfair Display", serif',
//                   }}
//                 >
//                   Dm Name
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "#fff",
//                     borderRight: "2px solid #fff",
//                     fontSize: "16px",
//                     fontFamily: '"Playfair Display", serif',
//                   }}
//                 >
//                   Title
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "#fff",
//                     borderRight: "2px solid #fff",
//                     fontSize: "16px",
//                     fontFamily: '"Playfair Display", serif',
//                     whiteSpace: "nowrap",
//                     wordBreak: "keep-all",
//                   }}
//                 >
//                   Lead Status
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "#fff",
//                     borderRight: "2px solid #fff",
//                     fontSize: "16px",
//                     fontFamily: '"Playfair Display", serif',
//                   }}
//                 >
//                   Done
//                 </TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {(showHistoryLeads ? historyLeads : filteredLeads).map(
//                 (lead, index) => (
//                   <TableRow key={lead.leadId}>
//                     <TableCell
//                       sx={{
//                         borderRight: "2px solid #e0e0e0",
//                         fontSize: "14px",
//                         fontWeight: 550,
//                       }}
//                     >
//                       {index + 1}
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         borderRight: "2px solid #e0e0e0",
//                         fontSize: "14px",
//                         fontWeight: 550,
//                         whiteSpace: "nowrap",
//                         wordBreak: "keep-all",
//                       }}
//                     >
//                       {lead.leadName}
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         borderRight: "2px solid #e0e0e0",
//                         fontSize: "14px",
//                         fontWeight: 550,
//                       }}
//                     >
//                       <TextField
//                         variant="outlined"
//                         size="small"
//                         sx={{ width: "200px" }}
//                         value={newPhoneValues[lead.leadId] || lead.phone || ""}
//                         onChange={(e) =>
//                           handlePhoneChange(lead.leadId, e.target.value)
//                         }
//                       />
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         borderRight: "2px solid #e0e0e0",
//                         fontSize: "14px",
//                         fontWeight: 550,
//                       }}
//                     >
//                       <TextField
//                         variant="outlined"
//                         size="small"
//                         sx={{ width: "200px" }}
//                         value={newEmailValues[lead.leadId] || lead.email || ""}
//                         onChange={(e) =>
//                           handleEmailChange(lead.leadId, e.target.value)
//                         }
//                       />
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         borderRight: "2px solid #e0e0e0",
//                         fontSize: "14px",
//                         fontWeight: 550,
//                         whiteSpace: "nowrap",
//                         wordBreak: "keep-all",
//                       }}
//                     >
//                       {lead.timezone}
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         borderRight: "2px solid #e0e0e0",
//                         fontSize: "14px",
//                         fontWeight: 550,
//                         whiteSpace: "nowrap",
//                         wordBreak: "keep-all",
//                       }}
//                     >
//                       {lead.dm_name}
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         borderRight: "2px solid #e0e0e0",
//                         fontSize: "14px",
//                         fontWeight: 550,
//                         whiteSpace: "nowrap",
//                         wordBreak: "keep-all",
//                       }}
//                     >
//                       {lead.title}
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         borderRight: "2px solid #e0e0e0",
//                         fontSize: "14px",
//                         fontWeight: 550,
//                         whiteSpace: "nowrap",
//                         wordBreak: "keep-all",
//                       }}
//                     >
//                       {lead.lead_status}
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         borderRight: "2px solid #e0e0e0",
//                         fontSize: "18px",
//                         fontWeight: 550,
//                         color: "green",
//                         cursor: "pointer",
//                         textAlign: "center",
//                       }}
//                     >
//                       <FaCheckSquare
//                         onClick={() => handleCheckClick(lead.leadId)}
//                       />
//                     </TableCell>
//                   </TableRow>
//                 )
//               )}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </div>
//     </div>
//   );
// };

// export default IncompleteLeadsTable;
