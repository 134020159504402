import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useContext,
} from "react";
import "./TeamMembers.css";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import { GoDotFill } from "react-icons/go";
import { baseUrl } from "../../services/BaseUrl";
import { useNavigate } from "react-router-dom";
import UserReport from "../UserReport/UserReport";
import AgentMemberHistory from "../AgentMemberHistory/AgentMemberHistory";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

const TeamMembers = () => {
  const { token } = useContext(UserContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [teamMembers, setTeamMembers] = useState([]);
  const [leadsCount, setLeadsCount] = useState(null);
  const [teamMemberActive, setTeamMemberActive] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const date = new Date();
    const options = { day: "numeric", month: "long", year: "numeric" };
    setCurrentDate(date.toLocaleDateString("en-US", options));
  }, []);

  useEffect(() => {
    const date = new Date();
    const options = { day: "numeric", month: "long", year: "numeric" };
    setCurrentDate(date.toLocaleDateString("en-US", options));
  }, []);

  // useEffect(() => {
  //   const fetchTeamMembers = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${baseUrl}/api/sales-manager/get-team`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );
  //       // Sorting the team members so loggedIn: true comes first
  //       const sortedTeamMembers = response.data.agents.sort((a, b) => {
  //         if (a.loggedIn === b.loggedIn) return 0;
  //         return a.loggedIn ? -1 : 1;
  //       });
  //       setTeamMembers(sortedTeamMembers);
  //       setLoading(false);

  //     } catch (error) {
  //       console.error("Error fetching team members:", error);
  //       setError("Failed to fetch team members. Please try again.");
  //       setLoading(false);
  //     }
  //   };

  //   const fetchLeadsCount = async () => {
  //     try {
  //         const response = await axios.get(`${baseUrl}/api/sales-manager/leads-count`, {
  //             headers: {
  //                 'Authorization': `Bearer ${token}`,
  //             },
  //         });
  //         setLeadsCount(response.data);
  //     } catch (err) {
  //         setError(err.response ? err.response.data : err.message);
  //     }
  // };

  // fetchLeadsCount();

  //   fetchTeamMembers();
  // }, [token]);

  const fetchTeamMembers = useCallback(async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/sales-manager/get-team`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const sortedTeamMembers = response.data.agents.sort((a, b) => {
        if (a.loggedIn === b.loggedIn) return 0;
        return a.loggedIn ? -1 : 1;
      });
      setTeamMembers(sortedTeamMembers);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching team members:", error);
      setError("Failed to fetch team members. Please try again.");
      setLoading(false);
    }
  }, [token]);

  const fetchLeadsCount = useCallback(async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/sales-manager/leads-count`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLeadsCount(response.data);
    } catch (err) {
      setError(err.response ? err.response.data : err.message);
    }
  }, [token]);

  useEffect(() => {
    fetchLeadsCount();
    fetchTeamMembers();
  }, [fetchLeadsCount, fetchTeamMembers]);

  const formatDate = useCallback((isoString) => {
    const date = new Date(isoString);
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return date.toLocaleString("en-US", options);
  }, []);

  const formatTime = useCallback((isoString) => {
    const date = new Date(isoString);
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  }, []);

  const handleViewLeadsClick = (member, memberId) => {
    setSelectedMember(member);
    navigate(`/agent-member-history/${memberId}`, {
      state: { member },
    });
  };

  const handleViewReportClick = (member, memberId) => {
    setSelectedMember(member);
    setTeamMemberActive(true);
  };

  const handleCloseReportPage = () => {
    setTeamMemberActive(false);
    setSelectedMember(null);
  };

  const handleSwapAgentHistory = async () => {
    if (!selectedMember?._id || !selectedMemberId) {
      alert("Please select both users to swap.");
      return;
    }

    try {
      const response = await axios.post(
        `${baseUrl}/api/sales-manager/swap-agent-history`,
        {
          oldUserId: selectedMember?._id,
          newUserId: selectedMemberId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Swap Agent History Response: ", response.data);
      // Handle success (e.g., show a success message, close modal, etc.)
      setOpenModal(false); // Close modal after the request
    } catch (error) {
      console.error("Error swapping agent history:", error);
      // Handle error (e.g., show an error message)
      alert("Failed to swap agent history. Please try again.");
    }
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setSelectedMember(null);
    setSelectedMemberId(null);
  };

  const handleMemberSelect = (event) => {
    setSelectedMemberId(event.target.value);
  };

  if (loading) return <LoadingComponent />;
  if (error) return <div>{error}</div>;

  return (
    <>
      {!teamMemberActive ? (
        <div className="sales-manager-team-manager-section">
          <h2 className="sale-manager-date-header">
            <span>{currentDate}</span>
          </h2>
          <div className="sales-manager-leads-count-container">
            <div className="leads-count-box">
              <p className="leads-count-box-p">
                Remaining UK Leads :{" "}
                <span>{leadsCount.unassignedLeadsCount}</span>
              </p>
            </div>
            <div className="leads-count-box">
              <p className="leads-count-box-p">
                Assigned Leads : <span>{leadsCount.assignedLeadsCount}</span>
              </p>
            </div>
          </div>
          <div className="team-members-container">
            {teamMembers.map((member, index) => (
              <div className="team-member-box" key={index}>
                <div className="team-member-box-info">
                  <p style={{ textTransform: "capitalize" }}>{member.name}</p>
                  <p style={{ textTransform: "capitalize" }}>{member.role}</p>
                  <p>{member.email}</p>
                  <p>
                    <GoDotFill
                      style={{
                        color: member.loggedIn ? "green" : "red",
                        fontSize: "22px",
                      }}
                    />
                  </p>
                  <p>
                    Last Seen: <span>{formatDate(member.lastSeen)}</span>
                  </p>
                  <p>
                    <span>{formatTime(member.lastSeen)}</span>
                  </p>
                </div>
                <div className="team-member-box-buttons">
                  <button
                    onClick={() => handleViewLeadsClick(member, member._id)}
                  >
                    View Leads
                  </button>
                  <button
                    onClick={() => handleViewReportClick(member, member._id)}
                  >
                    Reports
                  </button>
                  <button
                    onClick={() => {
                      setSelectedMember(member);
                      setOpenModal(true);
                    }}
                  >
                    Assign History
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        selectedMember && (
          <UserReport
            handleCloseReportPage={handleCloseReportPage}
            member={selectedMember}
            LoadingComponent={LoadingComponent}
            token={token}
          />
        )
      )}

      <Dialog
        open={openModal}
        onClose={handleModalClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Assign Agent History to another agent</DialogTitle>
        <DialogContent>
          {selectedMember ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="select-member-label">
                    Select Member
                  </InputLabel>
                  <Select
                    labelId="select-member-label"
                    value={selectedMemberId || ""}
                    onChange={handleMemberSelect}
                    label="Select Member"
                  >
                    {teamMembers.map((member) => (
                      <MenuItem key={member._id} value={member._id}>
                        {member.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          ) : (
            <Typography>No user selected</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="primary">
            Close
          </Button>
          <Button onClick={handleSwapAgentHistory} color="primary">
            Swap Agent
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TeamMembers;
