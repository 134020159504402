import React, { useState, useCallback, useContext, useEffect } from "react";
import "./Agent.css";
import Layout from "../../components/layout/layout";
import SideBar from "../../components/SideBar/SideBar";
import MainContainer from "../../mainContainer/MainContainer";
import NewLeads from "../../components/NewLeads/NewLeads";
import HistoryLeads from "../../components/HistoryLeads/HistoryLeads";
import CallbackLeads from "../../components/CallbackLeads/CallbackLeads";
import { UserContext } from "../../context/UserContext";
import axios from "axios";
import { baseUrl } from "../../services/BaseUrl";
import HotelScraper from "../../components/Requests/HotelScraper";
import AgentOldLeadsTable from "../../components/AgentOldLeads/AgentOldLeadsTable";

const Agent = () => {
  //sidebar logic ------------>
  const [currentComponent, setCurrentComponent] = useState(<NewLeads />);
  const [currentPageTitle, setCurrentPageTitle] = useState("New Leads");
  //////////////////////////////////////////////////////////////////////
  const { token } = useContext(UserContext);
  // history logic agent view -------------------->
  const [leadsHistory, setLeadsHistory] = useState([]);
  // Fetch leads history data
  const fetchLeadsHistory = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/agents/get-old-companies`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLeadsHistory(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchLeadsHistory();
  }, []);

  //sidebar logic ------------>
  const sidebarButtons = [
    {
      label: "New Leads",
      component: <NewLeads />,
      pageTitle: "New Leads",
    },
    {
      label: "History Leads",
      component: (
        <HistoryLeads
          leadsHistory={leadsHistory}
          setLeadsHistory={setLeadsHistory}
        />
      ),
      pageTitle: "History Leads",
    },
    {
      label: "Callback Leads",
      component: <CallbackLeads leadsCallFromHistory={leadsHistory} />,
      pageTitle: "Callback Leads",
    },

    {
      label: "Old Leads",
      component: <AgentOldLeadsTable />,
      pageTitle: "Old Leads",
    },

    // {
    //   label: "Hotel Scraper",
    //   component: <HotelScraper />,
    //   pageTitle: "Hotel Scraper",
    // },
  ];

  //sidebar logic ------------>
  const handleComponentChange = useCallback(
    (component, pageTitle) => {
      if (pageTitle === "History Leads") {
        fetchLeadsHistory();
      }
      setCurrentComponent(component);
      setCurrentPageTitle(pageTitle);
    },
    [leadsHistory]
  );

  return (
    <Layout>
      <SideBar
        buttons={sidebarButtons}
        onComponentChange={handleComponentChange}
      />
      <MainContainer pageTitle={currentPageTitle}>
        <h2> Agent View </h2>
        {currentComponent}
      </MainContainer>
    </Layout>
  );
};

export default Agent;
