import React, { useContext, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { baseUrl } from "../../services/BaseUrl";
import { UserContext } from "../../context/UserContext";

const SetUserPasswordModal = ({ open, handleClose, userId, userEmail }) => {
  const [newPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const { token } = useContext(UserContext);

  const handleSubmit = async () => {
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const response = await axios.put(
        `${baseUrl}/api/super/set-user-password`,
        { userId, newPassword },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSuccess(response.data.message);
      setNewPassword("");
    } catch (err) {
      setError(
        err.response?.data?.message ||
          "An error occurred. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Set Password for {userEmail}
        </Typography>

        {error && (
          <Typography color="error" variant="body2" gutterBottom>
            {error}
          </Typography>
        )}

        {success && (
          <Typography color="success.main" variant="body2" gutterBottom>
            {success}
          </Typography>
        )}

        <TextField
          label="New Password"
          type="password"
          fullWidth
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          sx={{ my: 2 }}
        />

        <button
          style={{
            padding: "10px 20px",
            backgroundColor: "#4f637d",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "14px",
          }}
          fullWidth
          onClick={handleSubmit}
          disabled={loading || !newPassword}
        >
          {loading ? <CircularProgress size={24} /> : "Set Password"}
        </button>
      </Box>
    </Modal>
  );
};

export default SetUserPasswordModal;
