import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from "react";
import "./NewLeads.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { GrAdd } from "react-icons/gr";
import AnswerSection from "../AnswerSection/AnswerSection";
import { baseUrl } from "../../services/BaseUrl";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import LoadingSection from "../LoadingComponent/LoadingSection";
import { FcExpand } from "react-icons/fc";

const styleRaw = {
  borderRight: "2px solid #e0e0e0",
  fontSize: "14px",
  fontWeight: 550,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  padding: "7px 10px",
};

const leadStyle = {
  borderRight: "2px solid #e0e0e0",
  fontSize: "14px",
  fontWeight: 550,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  padding: "7px 10px",
  cursor: "pointer",
  color: "blue",
};

const NewLeads = () => {
  const { token } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [requestTimeZone, setRequestTimeZone] = useState([]);
  const [selectedTimezone, setSelectedTimezone] = useState("");
  const [initialLeadsData, setInitialLeadsData] = useState([]);
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [answerPageActive, setAnswerPageActive] = useState(false);
  const [selectedLead, setSelectedLead] = useState({
    showId: "",
    leadId: "",
    showName: "",
    companyName: "",
    timeZone: "",
    Status: "",
    "Next Contact": "",
    address: "",
    "End Date": "",
    "Next Dates": "",
    "Check-in": "",
    "Check-out": "",
    "Lead Date": "",
    "Rejection Reason": "",
    Notes: "",
    "DM Name": "",
    "DM Rating": "",
    Email: "",
    "Email Type": "",
    emailRequestNotes: "",
    "Days to Call": "",
    "Last Contact": "",
    "Closing Date": "",
    Hotel: "",
    Budget: "",
    " Distance": "",
    Rooms: "",
    "/ Room Stay": "",
    "Total Stay": "",
    Activities: [],
    roomDetails: [],
    clientEmailRequest: false,
    clientEmailStatus: "",
  });
  const [selectedStatus, setSelectedStatus] = useState(
    selectedLead?.Status || ""
  );
  const [changedLeads, setChangedLeads] = useState(new Set());

  useEffect(() => {
    const requestData = async () => {
      try {
        const timezoneResponse = await axios.get(
          `${baseUrl}/api/shows/all-timezones`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setRequestTimeZone(timezoneResponse.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    };

    requestData();
  }, [token]);

  const uniqueTimezones = useMemo(() => {
    return requestTimeZone.filter((item) => item);
  }, [requestTimeZone]);

  const fetchDataByTimezone = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${baseUrl}/api/agents/get-filtered-companies`,
        { Timezone: selectedTimezone },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setError(null);
      setLoading(false);
      setInitialLeadsData(response.data);
    } catch (err) {
      setLoading(false); // Ensure loading is set to false in case of error
      if (err.response) {
        if (err.request.status === 404) {
          setError("Theres is no leads in active shows with this timezone.");
        } else {
          setError("Error fetching data.");
        }
      } else {
        // Handle network or other types of errors
        setError("Network error or request failed.");
      }
    }
  };

  useEffect(() => {
    if (selectedTimezone) {
      fetchDataByTimezone();
    }
  }, [selectedTimezone, token]);

  const filteredLeads = useMemo(() => {
    if (
      !initialLeadsData ||
      !Array.isArray(initialLeadsData) ||
      !selectedTimezone
    ) {
      return [];
    }

    return initialLeadsData.flatMap((show) =>
      show.Leads.map((lead) => ({
        CompanyName: lead.Lead,
        showName: show.Show,
        city: lead.cityState,
        phone: lead.Phone,
        timezone: lead.Timezone,
        Status: lead.Status,
        "Next Contact": lead["Next Contact"],
        leadId: lead._id,
        address: show.Venue,
        showId: show._id,
        updatedAt: lead.updatedAt,
        nextContact: lead.NextContact,
        emailFeedback: lead.emailFeedback,
        "End Date": show["End Date"],
        "Next Dates": show["Next Dates"],
        "Check-in": lead["Check-in"],
        "Check-out": lead["Check-out"],
        "Lead Date": lead["Lead Date"],
        "Rejection Reason": lead["Rejection Reason"],
        Notes: lead.Notes,
        "DM Name": lead["DM Name"],
        "DM Rating": lead["DM Rating"],
        Email: lead.Email,
        "Email Type": lead["Email Type"],
        emailRequestNotes: lead.emailRequestNotes,
        "Days to Call": lead["Days to Call"],
        "Last Contact": lead["Last Contact"],
        "Closing Date": lead["Closing Date"],
        Hotel: lead.Hotel,
        Budget: lead.Budget,
        " Distance": lead.Max[" Distance"],
        Rooms: lead.Rooms,
        "/ Room Stay": lead["/ Room Stay"],
        "Total Stay": lead["Total Stay"],
        Activities: lead.Activities,
        roomDetails: lead.roomDetails,
        Pricings: show.Pricings,
        clientEmailRequest: lead.clientEmailRequest,
        clientEmailStatus: lead.clientEmailStatus,
      }))
    );
  }, [initialLeadsData, selectedTimezone]);

  const groupByShowName = (leads) => {
    return leads.reduce((acc, lead) => {
      const { showName } = lead;
      if (!acc[showName]) {
        acc[showName] = [];
      }
      acc[showName].push(lead);
      return acc;
    }, {});
  };

  const groupedLeads = groupByShowName(filteredLeads);

  const handleUpdateLead = async () => {
    try {
      const response = await axios.put(
        `${baseUrl}/api/agents/shows/${selectedLead.showId}/companies/${selectedLead.leadId}`,
        selectedLead,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Lead updated successfully", response.data);
        setChangedLeads((prev) => new Set(prev.add(selectedLead.leadId)));
        const updatedLeadsData = initialLeadsData.map((show) => {
          if (show._id === selectedLead.showId) {
            return {
              ...show,
              Leads: show.Leads.map((lead) => {
                if (lead._id === selectedLead.leadId) {
                  return { ...lead, ...selectedLead }; // Update the lead
                }
                return lead;
              }),
            };
          }
          return show;
        });

        setInitialLeadsData(updatedLeadsData);

        // Remove the lead from the list after 3 seconds
        setTimeout(() => {
          const filteredData = updatedLeadsData.map((show) => ({
            ...show,
            Leads: show.Leads.filter(
              (lead) => lead._id !== selectedLead.leadId
            ),
          }));

          setInitialLeadsData(filteredData);
        }, 2000);
      }
    } catch (error) {
      setError("Error updating lead");
      console.error("Error updating lead:", error);
    }
  };

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const handleTimezoneChange = useCallback((event) => {
    setSelectedTimezone(event.target.value);
    setPage(0);
  }, []);

  const handleAnswerClick = useCallback((lead) => {
    const nextDate = new Date(lead?.["Next Dates"]);
    nextDate.setDate(nextDate.getDate() - 1);

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-based
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    setSelectedLead({
      showId: lead?.showId,
      leadId: lead?.leadId,
      showName: lead?.showName,
      companyName: lead?.CompanyName,
      timeZone: lead?.timezone,
      Status: lead?.Status,
      "Next Contact": lead?.["Next Contact"],
      address: lead?.address,
      "End Date": lead?.["End Date"],
      "Next Dates": lead?.["Next Dates"],
      "Check-in": formatDate(nextDate),
      "Check-out": lead?.["End Date"],
      "Lead Date": lead?.["Lead Date"],
      "Rejection Reason": lead?.["Rejection Reason"],
      Notes: lead?.Notes,
      "DM Name": lead?.["DM Name"],
      "DM Rating": lead?.["DM Rating"],
      Email: lead?.Email,
      "Email Type": lead?.["Email Type"],
      emailRequestNotes: lead?.emailRequestNotes,
      "Days to Call": lead?.["Days to Call"],
      "Last Contact": lead?.["Last Contact"],
      "Closing Date": lead?.["Closing Date"],
      Hotel: lead?.Hotel,
      Budget: lead?.Budget,
      " Distance": lead?.[" Distance"],
      Rooms: lead?.Rooms,
      "/ Room Stay": lead?.["/ Room Stay"],
      "Total Stay": lead?.["Total Stay"],
      Activities: lead?.Activities,
      roomDetails: lead.roomDetails,
      Pricings: lead?.Pricings,
      clientEmailRequest: lead?.clientEmailRequest,
      clientEmailStatus: lead?.clientEmailStatus,
    });
    setAnswerPageActive(true);
  }, []);

  const handleCloseAnswerPage = useCallback(() => {
    setAnswerPageActive(false);
  }, []);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  if (error) {
    return (
      <div className="component-container">
        <div className="agent-leads-request-container">
          <div className="request-label-contanier">
            <label
              htmlFor="timezone-select"
              style={{
                color: "#e1b5d7",
                fontWeight: 600,
                fontSize: 18,
                letterSpacing: 1,
                fontFamily: '"Playfair Display", serif',
              }}
            >
              Select TimeZone:
            </label>
            <select
              id="timezone-select"
              value={selectedTimezone}
              onChange={handleTimezoneChange}
            >
              <option value="" hidden>
                All Timezones
              </option>
              {uniqueTimezones.map((timezone, index) => (
                <option key={index} value={timezone}>
                  {timezone}
                </option>
              ))}
            </select>
          </div>
          <button className="add-leads-button">
            <GrAdd style={{ fontWeight: 550 }} />
            <p>Add Leads</p>
          </button>
        </div>
        <div className="agent-leads-table-container">
          <h3 style={{ color: "#fff" }}>{error}</h3>
        </div>

        {/* <div className="agent-leads-table-container"><h3 style={{color:"#fff"}}>Theres is no leads in active shows with this timezone</h3></div> */}
      </div>
    );
  }

  return (
    <>
      {!answerPageActive ? (
        <div className="component-container">
          <div className="agent-leads-request-container">
            <div className="request-label-contanier">
              <label
                htmlFor="timezone-select"
                style={{
                  color: "#e1b5d7",
                  fontWeight: 600,
                  fontSize: 18,
                  letterSpacing: 1,
                  fontFamily: '"Playfair Display", serif',
                }}
              >
                Select TimeZone:
              </label>
              <select
                id="timezone-select"
                value={selectedTimezone}
                onChange={handleTimezoneChange}
              >
                <option value="" hidden>
                  All Timezones
                </option>
                {uniqueTimezones.map((timezone, index) => (
                  <option key={index} value={timezone}>
                    {timezone}
                  </option>
                ))}
              </select>
            </div>
            <button className="add-leads-button">
              <GrAdd style={{ fontWeight: 550 }} />
              <p>Add Leads</p>
            </button>
          </div>
          <div className="agent-leads-table-container">
            {loading ? (
              <LoadingSection />
            ) : (
              <>
                {Object.keys(groupedLeads).map((showName, index) => (
                  <Accordion key={index} expanded>
                    <AccordionSummary
                      expandIcon={<FcExpand style={{ fontSize: "22px" }} />}
                      aria-controls={`panel-${showName}-content`}
                      id={`panel-${showName}-header`}
                    >
                      <Typography component="div">
                        <h2 style={{ fontSize: "18px", fontWeight: "800" }}>
                          Show Name:{" "}
                          <span
                            style={{
                              fontSize: "18px",
                              color: "#e8b6ea",
                              fontWeight: "800",
                            }}
                          >
                            {showName}
                          </span>
                        </h2>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow
                              sx={{
                                background:
                                  "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
                              }}
                            >
                              {[
                                "Company Name",
                                "City, Country",
                                "Phone",
                                "Timezone",
                                "Status",
                                "Answer",
                              ].map((header, index) => (
                                <TableCell
                                  key={header}
                                  sx={{
                                    color: "#fff",
                                    borderRight: "2px solid #fff",
                                    fontSize: "16px",
                                    fontFamily: '"Playfair Display", serif',
                                    textAlign: index === 6 ? "center" : "left",
                                  }}
                                >
                                  {header}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {groupedLeads[showName]
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((lead, index) => (
                                <TableRow
                                  key={lead.leadId}
                                  sx={{
                                    backgroundColor: changedLeads.has(
                                      lead.leadId
                                    )
                                      ? "#33333344"
                                      : "transparent",
                                  }}
                                >
                                  <TableCell
                                    style={leadStyle}
                                    onClick={() => {
                                      if (lead?.CompanyName) {
                                        const query = encodeURIComponent(
                                          `${lead.CompanyName} Marketing Manager LinkedIn`
                                        );
                                        window.open(
                                          `https://www.google.com/search?q=${query}`,
                                          "_blank"
                                        );
                                      }
                                    }}
                                  >
                                    {lead.CompanyName}
                                  </TableCell>
                                  <TableCell style={styleRaw}>
                                    {lead.city}
                                  </TableCell>
                                  <TableCell style={styleRaw}>
                                    {lead.phone && lead.phone?.length > 0 ? (
                                      index === 0 ? (
                                        <>{lead.phone}</>
                                      ) : (
                                        <>
                                          {"*".repeat(
                                            Math.max(0, lead.phone?.length - 4)
                                          )}
                                          {lead.phone.slice(-4)}
                                        </>
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  <TableCell style={styleRaw}>
                                    {lead.timezone}
                                  </TableCell>
                                  <TableCell style={styleRaw}>
                                    {lead.Status}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      borderRight: "2px solid #e0e0e0",
                                      fontSize: "14px",
                                      fontWeight: 550,
                                      whiteSpace: "nowrap",
                                      wordBreak: "keep-all",
                                      padding: "7px 10px",
                                      textAlign: "center",
                                    }}
                                  >
                                    <button
                                      style={{
                                        padding: "7px 20px",
                                        backgroundColor: "#4f637d",
                                        color: "#fff",
                                        border: "none",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                        fontSize: "14px",
                                      }}
                                      onClick={() => handleAnswerClick(lead)}
                                    >
                                      Answer
                                    </button>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        sx={{
                          backgroundColor: "#fff",
                          borderTop: "1px solid #e0e0e0",
                        }}
                        rowsPerPageOptions={[8, 16]}
                        component="div"
                        count={groupedLeads[showName].length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                      {/* /////////////////////////////////////////////////////////////////////// */}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </>
            )}
          </div>
        </div>
      ) : (
        selectedLead && (
          <AnswerSection
            token={token}
            handleCloseAnswerPage={handleCloseAnswerPage}
            handleUpdateLead={handleUpdateLead}
            selectedLead={selectedLead}
            setSelectedLead={setSelectedLead}
          />
        )
      )}
    </>
  );
};

export default NewLeads;
