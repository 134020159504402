import React, { useContext, useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  TextField,
  Button,
  Box,
  Tooltip,
} from "@mui/material";
import "./LocatorTable.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import ShowDataToLocator from "../ShowDataToLocator/ShowDataToLocator";
import { baseUrl } from "../../services/BaseUrl";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import { GoDash } from "react-icons/go";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import { useNavigate } from "react-router-dom";

const callStyle = {
  padding: 7,
  fontSize: 14,
  textAlign: "center",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  borderRight: "2px solid #e0e0e0",
  fontWeight: "550",
};

const renderSelectedInfo = (select, field) => {
  if (field === "priority") {
    return select?.[field] ? "Yes" : "No"; // Convert true/false to Yes/No
  }

  if (field === "isActive") {
    return select?.[field] ? "Yes" : "No"; // Convert true/false to Yes/No
  }

  if (field === "Show" && select?.timezonesCount) {
    return (
      <Tooltip
        title={
          <div style={{ padding: "10px", maxWidth: "250px" }}>
            <h2 style={{ marginBottom: "10px", fontSize: "16px" }}>
              Unassigned Counts
            </h2>
            {Object.entries(select.timezonesCount).map(([key, value]) => (
              <div
                key={key}
                style={{
                  fontSize: "14px",
                  color: "#fff",
                  marginBottom: "5px",
                }}
              >
                {key.toUpperCase()}: {value}
              </div>
            ))}
          </div>
        }
        arrow
        placement="top"
        PopperProps={{
          container: document.body, // Ensure it renders in the body
          modifiers: [
            {
              name: "preventOverflow",
              options: {
                boundary: "window", // Prevents cutting off
                rootBoundary: "viewport",
              },
            },
            {
              name: "flip",
              options: {
                fallbackPlacements: ["bottom"],
              },
            },
          ],
        }}
      >
        <span
          style={{
            color: "blue",
            cursor: "pointer",
          }}
        >
          {select[field]}
        </span>
      </Tooltip>
    );
  }

  return (
    select?.[field] || (
      <>
        <GoDash style={{ fontSize: "20px" }} />
        <GoDash style={{ fontSize: "20px" }} />
      </>
    )
  );
};

const LocatorTable = () => {
  const navigate = useNavigate();
  const [editionYear, setEditionYear] = useState("");
  const [activeStatus, setActiveStatus] = useState("");
  const [priorityStatus, setPriorityStatus] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [locatorData, setLocatorData] = useState([]);

  const [filteredEvents, setFilteredEvents] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [active, setActive] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [viewActive, setViewActive] = useState(false);
  const [rowOfLocator, setRowOfLocator] = useState([]);
  const { token } = useContext(UserContext);
  const [userRole, setUserRole] = useState(null);
  const [dublicatedLeads, setDublicatedLeads] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedShow, setSelectedShow] = useState(null);
  const [formData, setFormData] = useState({
    Show: "",
    ShowUrl: "",
    ConventionCenter: "",
    edition: "",
    isActive: false,
    priority: false,
  });

  useEffect(() => {
    const role = localStorage.getItem("role");
    setUserRole(role);
  }, []);

  const fetchLocatorData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/locator/locator-data`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLocatorData(response.data);
    } catch (error) {
      console.error("Error fetching staging data:", error);
    }
  };

  useEffect(() => {
    fetchLocatorData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 15));
    setPage(0);
  };

  const handleChangeActive = (event) => {
    setActive(event.target.value === "Yes");
  };

  const handleViewPage = (showId) => {
    const fetchLeadsOfTheShow = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/locator/leads/${showId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setRowOfLocator(response.data);
        setViewActive(true);
        // setLoading(false);
      } catch (error) {
        console.error(`Error fetching Leads :`, error);
        // setLoading(false);
      }
    };

    fetchLeadsOfTheShow();
  };

  const handleBackTable = () => {
    setViewActive(false);
  };

  const months = [
    { value: "", label: "All Months" },
    { value: "January", label: "January" },
    { value: "February", label: "February" },
    { value: "March", label: "March" },
    { value: "April", label: "April" },
    { value: "May", label: "May" },
    { value: "June", label: "June" },
    { value: "July", label: "July" },
    { value: "August", label: "August" },
    { value: "September", label: "September" },
    { value: "October", label: "October" },
    { value: "November", label: "November" },
    { value: "December", label: "December" },
  ];

  const handleEdtionChange = (event) => {
    setEditionYear(event.target.value);
  };

  const handleStatusChange = (event) => {
    setActiveStatus(event.target.value);
  };

  const handlePriorityChange = (event) => {
    setPriorityStatus(event.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  useEffect(() => {
    if (!locatorData) return;

    const filtered = locatorData.filter((event) => {
      let eventMonth = "";

      if (event["Next Dates"]) {
        const dateObj = new Date(event["Next Dates"]);
        eventMonth = dateObj.toLocaleString("en-US", { month: "long" });
      }

      return (
        (!editionYear || event.edition == editionYear) &&
        (activeStatus === "" ||
          event.isActive === (activeStatus === "Active")) &&
        (priorityStatus === "" ||
          event.priority === (priorityStatus === "yes")) &&
        (!selectedMonth || eventMonth === selectedMonth)
      );
    });

    setFilteredEvents(filtered);
  }, [editionYear, activeStatus, priorityStatus, selectedMonth, locatorData]);

  const handleViewDublicaedLeads = (show, showId) => {
    const fetchDublicatedLeadsOfTheShow = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/locator/duplicated/${showId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setDublicatedLeads(response.data);

        // Only navigate after the leads have been fetched
        const encodedShow = encodeURIComponent(show);
        navigate(`/leads-of-show/${encodedShow}`, {
          state: { showLeads: response.data, show, showId, type: "dublicated" },
        });
      } catch (error) {
        console.error(`Error fetching Leads of ${show}:`, error);
      }
    };

    fetchDublicatedLeadsOfTheShow();
  };

  const handleEditClick = (show) => {
    console.log(show);

    setSelectedShow(show);
    setFormData({
      Show: show.Show || "",
      ShowUrl: show["Show Url"] || "",
      ConventionCenter: show["Convention Center / Host Hotel"] || "",
      edition: show.edition || "",
      isActive: show.isActive || false,
      priority: show.priority || false,
    });
    setOpenEditModal(true);
  };

  const handleModalClose = () => {
    setOpenEditModal(false);
    setSelectedShow(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        `${baseUrl}/api/locator/update-locator/${selectedShow._id}`,
        formData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      fetchLocatorData();
      handleModalClose();
    } catch (error) {
      console.error("Error updating show:", error);
    }
  };

  // if (loading) {
  //   return <LoadingComponent />;
  // }

  return (
    <div className="locator-section">
      {!viewActive ? (
        <>
          <div className="edition-years-filter-container">
            <FormControl sx={{ width: "250px" }}>
              <InputLabel id="edition-year-select-label">
                Edition Year
              </InputLabel>
              <Select
                labelId="edition-year-select-label"
                id="edition-year-select"
                value={editionYear}
                label="Edition Year"
                onChange={handleEdtionChange}
              >
                <MenuItem value={2024}>2024</MenuItem>
                <MenuItem value={2025}>2025</MenuItem>
                <MenuItem value={2026}>2026</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ width: "250px" }}>
              <InputLabel id="status-select-label">Active Status</InputLabel>
              <Select
                labelId="status-select-label"
                id="status-select"
                value={activeStatus}
                label="Active Status"
                onChange={handleStatusChange}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Not Active">Not Active</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ width: "250px" }}>
              <InputLabel id="priority-select-label">Priority</InputLabel>
              <Select
                labelId="priority-select-label"
                id="priority-select"
                value={priorityStatus}
                label="Priority"
                onChange={handlePriorityChange}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ width: "250px" }}>
              <InputLabel id="month-select-label">Month</InputLabel>
              <Select
                labelId="month-select-label"
                id="month-select"
                value={selectedMonth}
                label="Month"
                onChange={handleMonthChange}
              >
                {months.map((month) => (
                  <MenuItem key={month.value} value={month.value}>
                    {month.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="locator-table-container">
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      background:
                        "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
                    }}
                  >
                    {userRole === "locator manager" && (
                      <>
                        <TableCell
                          sx={{
                            color: "#fff",
                            borderRight: "2px solid #e0e0e0",
                            borderLeft: "2px solid #e0e0e0",
                            fontSize: "16px",
                            fontFamily: '"Playfair Display", serif',
                            whiteSpace: "nowrap",
                          }}
                        >
                          View
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#fff",
                            borderRight: "2px solid #e0e0e0",
                            borderLeft: "2px solid #e0e0e0",
                            fontSize: "16px",
                            fontFamily: '"Playfair Display", serif',
                            whiteSpace: "nowrap",
                          }}
                        >
                          Duplicates
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#fff",
                            borderRight: "2px solid #e0e0e0",
                            borderLeft: "2px solid #e0e0e0",
                            fontSize: "16px",
                            fontFamily: '"Playfair Display", serif',
                            whiteSpace: "nowrap",
                          }}
                        >
                          Upload
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#fff",
                            borderRight: "2px solid #e0e0e0",
                            borderLeft: "2px solid #e0e0e0",
                            fontSize: "16px",
                            fontFamily: '"Playfair Display", serif',
                            whiteSpace: "nowrap",
                          }}
                        >
                          Edit
                        </TableCell>
                      </>
                    )}
                    {[
                      "Show Name",
                      "Raw Name",
                      "Active",
                      "Priority",
                      "Notes",
                      "Show Url",
                      "Next Dates",
                      "End Date",
                      "Duration",
                      "Convention Center / Host Hotel",
                      "City State",
                      "Venue",
                      "Total Leads",
                      "New Count",
                      "In Works",
                      "Done Count",
                      "HQ",
                      "HQ Price Sky/Conv",
                      "Locate By",
                      "Platform",
                      "Exhibitor List",
                      "Housing Link",
                      "Locate Date",
                      "Stage",
                      "Pricing Status",
                      "Lead Status",
                    ].map((header) => (
                      <TableCell
                        key={header}
                        sx={{
                          color: "#fff",
                          borderRight: "2px solid #e0e0e0",
                          borderLeft: "2px solid #e0e0e0",
                          fontSize: "16px",
                          fontFamily: '"Playfair Display", serif',
                          whiteSpace: "nowrap",
                        }}
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredEvents
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row) => (
                      <TableRow key={row._id}>
                        {userRole === "locator manager" && (
                          <TableCell style={callStyle}>
                            <button
                              onClick={() => handleViewPage(row._id)}
                              style={{
                                padding: "10px 20px",
                                backgroundColor: "#4f637d",
                                color: "#fff",
                                border: "none",
                                borderRadius: "5px",
                                cursor: "pointer",
                                fontSize: "14px",
                              }}
                            >
                              View
                            </button>
                          </TableCell>
                        )}
                        {userRole === "locator manager" && (
                          <TableCell style={callStyle}>
                            <button
                              style={{
                                padding: "10px 20px",
                                backgroundColor: "#4f637d",
                                color: "#fff",
                                border: "none",
                                borderRadius: "5px",
                                cursor: "pointer",
                                fontSize: "14px",
                              }}
                              onClick={() =>
                                handleViewDublicaedLeads(row.Show, row._id)
                              }
                            >
                              Duplicates
                            </button>
                          </TableCell>
                        )}
                        {userRole === "locator manager" && (
                          <TableCell style={callStyle}>
                            <button
                              // onClick={() => handleViewPage(row._id)}
                              style={{
                                padding: "10px 20px",
                                backgroundColor: "#4f637d",
                                color: "#fff",
                                border: "none",
                                borderRadius: "5px",
                                cursor: "pointer",
                                fontSize: "14px",
                              }}
                            >
                              Upload
                            </button>
                          </TableCell>
                        )}
                        {userRole === "locator manager" && (
                          <TableCell style={callStyle}>
                            <button
                              onClick={() => handleEditClick(row)}
                              style={{
                                padding: "10px 20px",
                                backgroundColor: "#4f637d",
                                color: "#fff",
                                border: "none",
                                borderRadius: "5px",
                                cursor: "pointer",
                                fontSize: "14px",
                              }}
                            >
                              Edit
                            </button>
                          </TableCell>
                        )}
                        <TableCell style={callStyle}>
                          {renderSelectedInfo(row, "Show")}
                        </TableCell>
                        <TableCell style={callStyle}>
                          {renderSelectedInfo(row, "rawName")}
                        </TableCell>
                        <TableCell style={callStyle}>
                          {renderSelectedInfo(row, "isActive")}
                        </TableCell>
                        <TableCell style={callStyle}>
                          {renderSelectedInfo(row, "priority")}
                        </TableCell>
                        <TableCell style={callStyle}>
                          {renderSelectedInfo(row, "notes")}
                        </TableCell>
                        <TableCell style={callStyle}>
                          <a
                            href={renderSelectedInfo(row, "Show Url")}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: "underline" }}
                          >
                            {renderSelectedInfo(row, "Show Url")}
                          </a>
                        </TableCell>
                        <TableCell style={callStyle}>
                          {renderSelectedInfo(row, "Next Dates")}
                        </TableCell>
                        <TableCell style={callStyle}>
                          {renderSelectedInfo(row, "End Date")}
                        </TableCell>
                        <TableCell style={callStyle}>
                          {renderSelectedInfo(row, "Duration")}
                        </TableCell>
                        <TableCell style={callStyle}>
                          {renderSelectedInfo(
                            row,
                            "Convention Center / Host Hotel"
                          )}
                        </TableCell>
                        <TableCell style={callStyle}>
                          {renderSelectedInfo(row, "City, Country")}
                        </TableCell>
                        <TableCell style={callStyle}>
                          {renderSelectedInfo(row, "Venue")}
                        </TableCell>
                        <TableCell style={callStyle}>
                          {renderSelectedInfo(row, "totalLeads")}
                        </TableCell>
                        <TableCell style={callStyle}>
                          {renderSelectedInfo(row, "newLeads")}
                        </TableCell>
                        <TableCell style={callStyle}>
                          {renderSelectedInfo(row, "assignedLeads")}
                        </TableCell>
                        <TableCell style={callStyle}>
                          {renderSelectedInfo(row, "doneWork")}
                        </TableCell>
                        <TableCell style={callStyle}>
                          {renderSelectedInfo(row, "HQ")}
                        </TableCell>
                        <TableCell style={callStyle}>
                          {renderSelectedInfo(row, "HQ Price Sky/Conv")}
                        </TableCell>
                        <TableCell style={callStyle}>
                          {renderSelectedInfo(row, "MadeBy")}
                        </TableCell>
                        <TableCell style={callStyle}>
                          {renderSelectedInfo(row, "Platform")}
                        </TableCell>
                        <TableCell style={callStyle}>
                          {renderSelectedInfo(row, "Exhibitor List")}
                        </TableCell>
                        <TableCell style={callStyle}>
                          {renderSelectedInfo(row, "Housing Link")}
                        </TableCell>
                        <TableCell style={callStyle}>
                          {renderSelectedInfo(row, "Locate Date")}
                        </TableCell>
                        <TableCell style={callStyle}>
                          {renderSelectedInfo(row, "Stage")}
                        </TableCell>
                        <TableCell style={callStyle}>
                          {renderSelectedInfo(row, "Pricing Status")}
                        </TableCell>
                        <TableCell style={callStyle}>
                          {renderSelectedInfo(row, "Lead Status")}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              style={{ background: "#fff", width: "100%" }}
              rowsPerPageOptions={[6, 10]}
              component="div"
              count={filteredEvents?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </>
      ) : (
        <ShowDataToLocator
          handleBackTable={handleBackTable}
          rowOfLocator={rowOfLocator}
        />
      )}
      <Modal open={openEditModal} onClose={handleModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <h2>Edit Show</h2>
          <form onSubmit={handleFormSubmit}>
            <TextField
              fullWidth
              margin="normal"
              label="Show Name"
              name="Show"
              value={formData.Show}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Show URL"
              name="ShowUrl"
              value={formData.ShowUrl}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Convention Center"
              name="ConventionCenter"
              value={formData.ConventionCenter}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Edition"
              name="edition"
              value={formData.edition}
              onChange={handleInputChange}
            />

            {/* Active Status */}
            <FormControl fullWidth margin="normal">
              <InputLabel>Active</InputLabel>
              <Select
                name="isActive"
                value={formData.isActive}
                onChange={handleSelectChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>

            {/* Priority */}
            <FormControl fullWidth margin="normal">
              <InputLabel>Priority</InputLabel>
              <Select
                name="priority"
                value={formData.priority}
                onChange={handleSelectChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>

            <Button type="submit" variant="contained" sx={{ mt: 2 }}>
              Save Changes
            </Button>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default LocatorTable;
