import React, { useState, useCallback } from "react";
import "./SuperUser.css";
import Layout from "../../components/layout/layout";
import SideBar from "../../components/SideBar/SideBar";
import MainContainer from "../../mainContainer/MainContainer";
import RegisterUser from "../../components/RegisterUser/RegisterUser";
import UsersPanel from "../../components/UsersPanel/UsersPanel";
import UsersReports from "../../components/UsersReports/UsersReports";
import BugsReports from "../../components/BugsReports/BugsReports";
import ShowDashboard from "../../components/ShowDashboard/ShowDashboard";

const SuperUser = () => {
  //sidebar logic ------------>
  const [currentComponent, setCurrentComponent] = useState(<RegisterUser />);
  const [currentPageTitle, setCurrentPageTitle] = useState("Register New User");

  //sidebar logic ------------>
  const sidebarButtons = [
    {
      label: "Register User",
      component: <RegisterUser />,
      pageTitle: "Register New User",
    },
    { label: "Users", component: <UsersPanel />, pageTitle: "Users Panel" },
    {
      label: "Users Reports",
      component: <UsersReports />,
      pageTitle: "Users Reports",
    },
    {
      label: "Bugs Reports",
      component: <BugsReports />,
      pageTitle: "Bugs Reports",
    },
    {
      label: "Show Dashboard",
      component: <ShowDashboard />,
      pageTitle: "Show Dashboard",
    },
  ];

  //sidebar logic ------------>
  const handleComponentChange = useCallback((component, pageTitle) => {
    setCurrentComponent(component);
    setCurrentPageTitle(pageTitle);
  }, []);

  return (
    <Layout>
      <SideBar
        buttons={sidebarButtons}
        onComponentChange={handleComponentChange}
      />
      <MainContainer pageTitle={currentPageTitle}>
        <h2>Super User View</h2>
        {currentComponent}
      </MainContainer>
    </Layout>
  );
};

export default SuperUser;
