import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { UserProvider, UserContext } from "../context/UserContext";
import ProtectedRoute from "./ProtectedRoute";
import NotFound from "../pages/NotFound/NotFound";
import LoginForm from "../pages/LoginForm/LoginForm";
import SuperUser from "../pages/SuperUser/SuperUser";
import Locator from "../pages/Locator/Locator";
import LeadsManager from "../pages/LeadsManager/LeadsManager";
import Agent from "../pages/Agent/Agent";
import SalesManager from "../pages/SalesManager/SalesManager";
import PricingSen from "../pages/PricingTeam/PricingSen";
import PricingJun from "../pages/PricingTeam/PricingJun";
import EmailTeam from "../pages/EmailTeam/EmailTeam";
import EmailReqTeam from "../pages/EmailTeam/EmailReqTeam";
import AgentCloser from "../pages/AgentCloser/AgentCloser";
import HistoryLeads from "../components/HistoryLeads/HistoryLeads";
import AgentMemberHistory from "../components/AgentMemberHistory/AgentMemberHistory";
import SalesSupport from "../pages/SalesSupport/SalesSupport";
import { WebSocketProvider } from "../context/websocketContext";
import DuplicatedLeads from "../components/DuplicatedLeads/DuplicatedLeads";
import DuplicatedMatchedLeads from "../components/DuplicatedLeads/DuplicatedMatchedLeads";
import CustomerSupport from "../pages/CustomerSupport/CustomerSupport";
import House from "../pages/House/House";

const Router = () => {
  return (
    <BrowserRouter>
      <UserProvider>
        <WebSocketProvider>
          <Routes>
            <Route path="/" element={<LoginForm />} />
            <Route
              path="/super-user"
              element={
                <ProtectedRoute allowedRoles={["super user"]}>
                  <SuperUser />
                </ProtectedRoute>
              }
            />
            <Route
              path="/locator"
              element={
                <ProtectedRoute allowedRoles={["locator", "locator manager"]}>
                  <Locator />
                </ProtectedRoute>
              }
            />
            <Route
              path="/leads-of-raw-show/:show"
              element={
                <ProtectedRoute allowedRoles={["locator", "locator manager"]}>
                  <DuplicatedLeads />
                </ProtectedRoute>
              }
            />
            <Route
              path="/leads-of-show/:show"
              element={
                <ProtectedRoute allowedRoles={["locator", "locator manager"]}>
                  <DuplicatedMatchedLeads />
                </ProtectedRoute>
              }
            />
            <Route
              path="/leads-manager"
              element={
                <ProtectedRoute allowedRoles={["leads junior", "leads senior"]}>
                  <LeadsManager />
                </ProtectedRoute>
              }
            />
            <Route
              path="/email-team"
              element={
                <ProtectedRoute
                  allowedRoles={["email team", "email team manager"]}
                >
                  <EmailTeam />
                </ProtectedRoute>
              }
            />
            <Route
              path="/email-team/email-team-req"
              element={
                <ProtectedRoute
                  allowedRoles={["email team", "email team manager"]}
                >
                  <EmailReqTeam />
                </ProtectedRoute>
              }
            />
            <Route
              path="/agent"
              element={
                <ProtectedRoute allowedRoles={["agent", "agent junior"]}>
                  <Agent />
                </ProtectedRoute>
              }
            />
            <Route
              path="/agent-closer"
              element={
                <ProtectedRoute allowedRoles={["agent closer"]}>
                  <AgentCloser />
                </ProtectedRoute>
              }
            />
            <Route
              path="/sales-manager"
              element={
                <ProtectedRoute allowedRoles={["sales manager"]}>
                  <SalesManager />
                </ProtectedRoute>
              }
            />
            <Route
              path="/agent-member-history/:id"
              element={
                <ProtectedRoute allowedRoles={["sales manager"]}>
                  <AgentMemberHistory />
                </ProtectedRoute>
              }
            />

            <Route
              path="/Sales-Support"
              element={
                <ProtectedRoute
                  allowedRoles={["sales support", "sales support manager"]}
                >
                  <SalesSupport />
                </ProtectedRoute>
              }
            />
            <Route
              path="/pricing-team-senior"
              element={
                <ProtectedRoute allowedRoles={["pricing senior"]}>
                  <PricingSen />
                </ProtectedRoute>
              }
            />
            <Route
              path="/pricing-team-junior"
              element={
                <ProtectedRoute allowedRoles={["pricing junior"]}>
                  <PricingJun />
                </ProtectedRoute>
              }
            />

            <Route
              path="/customer-support"
              element={
                <ProtectedRoute allowedRoles={["customer support"]}>
                  <CustomerSupport />
                </ProtectedRoute>
              }
            />

            <Route
              path="/house"
              element={
                <ProtectedRoute allowedRoles={["house"]}>
                  <House />
                </ProtectedRoute>
              }
            />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </WebSocketProvider>
      </UserProvider>
    </BrowserRouter>
  );
};

export default Router;
