import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./LoginForm.css";
import { MdWavingHand } from "react-icons/md";
import { UserContext } from "../../context/UserContext";

const LoginForm = () => {
  const navigate = useNavigate();
  const { loginFun } = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const role = await loginFun(email, password);
      if (role) {
        switch (role) {
          case "super user":
            navigate("/super-user");
            break;
          case "sales manager":
            navigate("/sales-manager");
            break;
          case "agent":
            navigate("/agent");
            break;
          case "agent junior":
            navigate("/agent");
            break;
          case "agent closer":
            navigate("/agent-closer");
            break;
          case "team leader":
            navigate("/team-leader");
            break;
          case "leads junior":
          case "leads senior":
            navigate("/leads-manager");
            break;
          case "sales support manager":
            navigate("/Sales-Support");
            break;
          case "sales support":
            navigate("/Sales-Support");
            break;

          case "pricing junior":
            navigate("/pricing-team-junior");
            break;
          case "pricing senior":
            navigate("/pricing-team-senior");
            break;
          case "lead generator":
            navigate("/leads-manager");
            break;
          case "locator":
          case "locator manager":
            navigate("/locator");
            break;
          case "email team":
          case "email team manager":
            navigate("/email-team");
            break;
          case "customer support":
            navigate("/customer-support");
            break;
          case "house":
            navigate("/house");
            break;
          default:
            navigate("/");
        }
      } else {
        // Handle case where role is undefined
        navigate("/");
      }
    } catch (error) {
      console.error(error);
      setLoginError(error.response.data.message);
    }

    setEmail("");
    setPassword("");
  };

  return (
    <div className="login-form-container">
      <div className="login-form-contain">
        <h1 className="login-form-header">Welcome Back!</h1>
        <h3 className="login-form-text">
          Hey <MdWavingHand style={{ color: "#f7c67e" }} /> Enter Your details
          to get sign in to your account
        </h3>
        <form className="from-inputs-controls" onSubmit={handleSubmit}>
          <div className="login-input-group">
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
            />
          </div>
          <div className="login-input-group">
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
            />
          </div>
          <button
            type="submit"
            className="login-button"
            disabled={email === "" || password === ""}
          >
            <p className="button-text">Sign In</p>
          </button>
          {loginError && (
            <p
              className="error"
              style={{
                color: "rgb(235, 85, 85)",
                fontSize: "16px",
              }}
            >
              {loginError}
            </p>
          )}
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
