import React, { useState, useEffect, useContext, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Grid, Box, Typography } from "@mui/material";
import "./AgentMemberHistory.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { GoDash } from "react-icons/go";
import { UserContext } from "../../context/UserContext";
import axios from "axios";
import { baseUrl } from "../../services/BaseUrl";
import LoadingComponent from "../LoadingComponent/LoadingComponent";

const callStyle = {
  borderRight: "2px solid #e0e0e0",
  fontSize: "14px",
  fontWeight: 550,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  padding: "7px 10px",
};

// const statusColors = {
//   "New": "#BDE0FE",
//   "VM": "rgb(234, 231, 231)",
//   "Redial": "#FFC39D",
//   "Callback": "rgb(243, 224, 122)",
//   "GK Blocked": "#C56869",
//   "DM Rejected": "#A9A9A9",
//   "Do Not Call": "rgba(244, 51, 51, 0.7)",
//   "Email Only": "#925892",
//   "Email Follow-up": "#32CD32",
//   "Waiting Approval": "rgb(219, 212, 207)",
//   "Prospect": "#4682B4",
//   "Flagged": "#800080",
//   "Wrong Number": "#FF6347",
//   "Closed Won": "#FFF19C",
//   "Closed Lost": "#2F4F4F"
// };

const AgentMemberHistory = () => {
  const { id } = useParams();
  const location = useLocation(); // Access the passed state
  const [agentData, setAgentData] = useState(location.state?.member || null);
  const navigate = useNavigate();
  const { token } = useContext(UserContext);
  const [agentMemberLeads, setAgentMemberLeads] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  // States to store unique timezone and status values
  const [uniqueTimezones, setUniqueTimezones] = useState([]);
  const [uniqueStatuses, setUniqueStatuses] = useState([]);
  const [uniqueShowName, setUniqueShowName] = useState([]);
  const [monthOptions, setMonthOptions] = useState([]);

  // State for Company Name search filter
  const [selectedTimezone, setSelectedTimezone] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedShowname, setSelectedShowname] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");

  const [statusCounts, setStatusCounts] = useState({});

  const handleCloseAgentMemberPage = () => {
    navigate("/sales-manager");
  };

  useEffect(() => {
    const fetchAgentMemberLeads = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${baseUrl}/api/sales-manager/agent-member-leads/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setAgentMemberLeads(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.message || "Something went wrong");
        setLoading(false);
      }
    };

    if (id && token) {
      fetchAgentMemberLeads();
    }
  }, [id, token]);

  const filteredLeads = agentMemberLeads?.leads.filter((lead) => {
    const matchesTimezone = selectedTimezone
      ? lead.timezone === selectedTimezone
      : true;
    const matchesStatus = selectedStatus
      ? lead.status === selectedStatus
      : true;
    const matchesShowname = selectedShowname
      ? lead.show === selectedShowname
      : true;
    const matchesMonth = selectedMonth
      ? new Date(lead.updatedAt).toLocaleString("default", {
          month: "long",
        }) === selectedMonth
      : true;

    return matchesTimezone && matchesStatus && matchesShowname && matchesMonth;
  });

  useEffect(() => {
    if (agentMemberLeads) {
      const timezones = [
        ...new Set(agentMemberLeads.leads.map((lead) => lead.timezone)),
      ];
      const statuses = [
        ...new Set(agentMemberLeads.leads.map((lead) => lead.status)),
      ];
      const showNames = [
        ...new Set(agentMemberLeads.leads.map((lead) => lead.show)),
      ];

      const months = Array.from(
        new Set(
          agentMemberLeads.leads
            .map((lead) => {
              if (!lead.updatedAt) return null;
              const date = new Date(lead.updatedAt);
              if (isNaN(date)) return null; // Ensure valid date
              return date.toLocaleString("default", { month: "long" });
            })
            .filter(Boolean) // Remove null values
        )
      );

      // Count occurrences of each status
      const countObj = {};
      const leadsToCount =
        filteredLeads.length > 0 ? filteredLeads : agentMemberLeads.leads;

      leadsToCount.forEach((lead) => {
        countObj[lead.status] = (countObj[lead.status] || 0) + 1;
      });

      setStatusCounts(countObj);

      setUniqueTimezones(timezones);
      setUniqueStatuses(statuses);
      setUniqueShowName(showNames);
      setMonthOptions(months);
    }
  }, [agentMemberLeads, filteredLeads]);

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleTimezoneChange = (event) => {
    setSelectedTimezone(event.target.value);
  };

  const handleShownameChange = (event) => {
    setSelectedShowname(event.target.value);
  };

  if (loading) return <LoadingComponent />;
  if (error)
    return (
      <div className="agent-member-histoy-container">
        <div className="agent-member-histor-contain">
          <div className="agent-member-header-container">
            <FaArrowLeftLong
              onClick={handleCloseAgentMemberPage}
              style={{ cursor: "pointer", fontSize: 22, color: "#fff" }}
            />
          </div>
          {error}
        </div>
      </div>
    );

  return (
    <div className="agent-member-histoy-container">
      <div className="agent-member-histor-contain">
        <div className="agent-member-header-container">
          <FaArrowLeftLong
            onClick={handleCloseAgentMemberPage}
            style={{ cursor: "pointer", fontSize: 22, color: "#fff" }}
          />
        </div>
        <div className="agent-member-info-section">
          <h2 style={{ letterSpacing: "1.5px", textTransform: "capitalize" }}>
            Leads for Agent : <span>{agentData?.name}</span>{" "}
          </h2>
        </div>
        <div className="agent-member-filter-section">
          <div className="assigned-lead-filtetr">
            <label
              htmlFor="timezone-select"
              style={{
                color: "#e1b5d7",
                fontWeight: 600,
                fontSize: 18,
                letterSpacing: 1,
                fontFamily: '"Playfair Display", serif',
              }}
            >
              Status:
            </label>
            <select
              id="timezone-select"
              value={selectedStatus}
              onChange={handleStatusChange}
            >
              <option value="">All Statuses</option>
              {uniqueStatuses.map((status, index) => (
                <option key={index} value={status}>
                  {status}
                </option>
              ))}
            </select>
          </div>
          <div className="assigned-lead-filtetr">
            <label
              htmlFor="timezone-select"
              style={{
                color: "#e1b5d7",
                fontWeight: 600,
                fontSize: 18,
                letterSpacing: 1,
                fontFamily: '"Playfair Display", serif',
              }}
            >
              Timezone:
            </label>
            <select
              id="timezone-select"
              value={selectedTimezone}
              onChange={handleTimezoneChange}
            >
              <option value="">All Timezones</option>
              {uniqueTimezones.map((timezone, index) => (
                <option key={index} value={timezone}>
                  {timezone}
                </option>
              ))}
            </select>
          </div>
          <div className="assigned-lead-filtetr">
            <label
              htmlFor="timezone-select"
              style={{
                color: "#e1b5d7",
                fontWeight: 600,
                fontSize: 18,
                letterSpacing: 1,
                fontFamily: '"Playfair Display", serif',
              }}
            >
              Show Name:
            </label>
            <select
              id="timezone-select"
              value={selectedShowname}
              onChange={handleShownameChange}
            >
              <option value="">All Shows</option>
              {uniqueShowName.map((timezone, index) => (
                <option key={index} value={timezone}>
                  {timezone}
                </option>
              ))}
            </select>
          </div>
          <div className="assigned-lead-filtetr">
            <label
              htmlFor="timezone-select"
              style={{
                color: "#e1b5d7",
                fontWeight: 600,
                fontSize: 18,
                letterSpacing: 1,
                fontFamily: '"Playfair Display", serif',
              }}
            >
              Month:
            </label>
            <select
              id="timezone-select"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
            >
              <option value="">All Months</option>
              {monthOptions.map((month, index) => (
                <option key={index} value={month}>
                  {month}
                </option>
              ))}
            </select>
          </div>
        </div>
        <Grid container spacing={2} style={{ width: "99%", margin: "auto" }}>
          {uniqueStatuses.map((status, index) => (
            <Grid item xs={12} sm={4} md={3} lg={2} key={index}>
              <Box
                sx={{
                  padding: 1,
                  textAlign: "center",
                  border: "1px solid #e0e0e0",
                  borderRadius: 1,
                }}
              >
                <Typography variant="subtitle1">{status}</Typography>
                <Typography variant="h6">
                  {statusCounts[status] || 0}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        <div className="agent-member-table-section">
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: "70vh",
              overflowY: "auto",
            }}
          >
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    background:
                      "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
                  }}
                >
                  {[
                    "Company Name",
                    "Show Name",
                    "Phone",
                    "Email",
                    "Timezone",
                    "Closer Note",
                    "Closer Name",
                    "Closing Date",
                  ].map((header, index) => (
                    <TableCell
                      key={header}
                      sx={{
                        color: "#fff",
                        borderRight: "2px solid #fff",
                        fontSize: "16px",
                        fontFamily: '"Playfair Display", serif',
                        whiteSpace: "nowrap",
                        wordBreak: "keep-all",
                      }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredLeads.length === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={8}
                      sx={{
                        textAlign: "center",
                        color: "#888",
                        fontWeight: 550,
                        whiteSpace: "nowrap",
                        wordBreak: "keep-all",
                        padding: "14px",
                      }}
                    >
                      There are no leads available.
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredLeads.map((lead) => (
                    <TableRow key={lead.leadId}>
                      <TableCell sx={callStyle}>
                        {lead.lead || (
                          <>
                            <GoDash style={{ fontSize: "20px" }} />
                            <GoDash style={{ fontSize: "20px" }} />
                          </>
                        )}
                      </TableCell>
                      <TableCell sx={callStyle}>
                        {lead.show || (
                          <>
                            <GoDash style={{ fontSize: "20px" }} />
                            <GoDash style={{ fontSize: "20px" }} />
                          </>
                        )}
                      </TableCell>
                      <TableCell sx={callStyle}>
                        {lead.phone || (
                          <>
                            <GoDash style={{ fontSize: "20px" }} />
                            <GoDash style={{ fontSize: "20px" }} />
                          </>
                        )}
                      </TableCell>
                      <TableCell sx={callStyle}>
                        {lead.email || (
                          <>
                            <GoDash style={{ fontSize: "20px" }} />
                            <GoDash style={{ fontSize: "20px" }} />
                          </>
                        )}
                      </TableCell>
                      <TableCell sx={callStyle}>
                        {lead.timezone || (
                          <>
                            <GoDash style={{ fontSize: "20px" }} />
                            <GoDash style={{ fontSize: "20px" }} />
                          </>
                        )}
                      </TableCell>
                      <TableCell sx={callStyle}>
                        {lead.closerNote || (
                          <>
                            <GoDash style={{ fontSize: "20px" }} />
                            <GoDash style={{ fontSize: "20px" }} />
                          </>
                        )}
                      </TableCell>
                      <TableCell sx={callStyle}>
                        {lead.closerName || (
                          <>
                            <GoDash style={{ fontSize: "20px" }} />
                            <GoDash style={{ fontSize: "20px" }} />
                          </>
                        )}
                      </TableCell>
                      <TableCell sx={callStyle}>
                        {lead.closingDate || (
                          <>
                            <GoDash style={{ fontSize: "20px" }} />
                            <GoDash style={{ fontSize: "20px" }} />
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default AgentMemberHistory;
