import React, { useState, useContext } from "react";
import "./SideBar.css";
import { RiMenu2Line } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";
import { MdLogout } from "react-icons/md";
import { UserContext } from "../../context/UserContext";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Alert,
} from "@mui/material";
import axios from "axios";
import { baseUrl } from "../../services/BaseUrl";
import LoadingComponent from "../LoadingComponent/LoadingComponent";

const SideBar = ({ buttons, onComponentChange }) => {
  const { token } = useContext(UserContext);
  // const { logout } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openReportModal, setOpenReportModal] = useState(false);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [bug, setBug] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const toggleSidebar = () => {
    setSidebarIsOpen(!sidebarIsOpen);
    // if (hasNotification) {
    //   setHasNotification(false);
    // }
  };

  // const handleLogout = () => {
  //   logout();
  // };
  const handleChangePasswordClick = () => {
    setOpenDialog(true);
  };

  const handleBugReport = () => {
    setOpenReportModal(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseBugReport = () => {
    setOpenReportModal(false);
  };

  const handleSubmitPasswordChange = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    // Validate confirm password
    if (newPassword !== confirmPassword) {
      setErrorMessage("New password and confirm password must be match.");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        `${baseUrl}/api/users/change-password`,
        { oldPassword, newPassword },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSuccessMessage(
        response.data.message || "Password changed successfully!"
      );
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setTimeout(() => {
        handleCloseDialog();
      }, 3000);
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message || "An error occurred. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSendReport = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    try {
      const response = await axios.post(
        `${baseUrl}/api/users/submit-bug`,
        { report: bug },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSuccessMessage(
        response.data.message || "Report submitted successfully!"
      );
      setBug("");
      setSuccessMessage("");
      setTimeout(() => {
        handleCloseDialog();
      }, 3000);
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message || "An error occurred. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <aside className={`sidebar-container ${sidebarIsOpen ? "open" : ""}`}>
      {!sidebarIsOpen && (
        <div className="icon-container" onClick={toggleSidebar}>
          {/* {!sidebarIsOpen && hasNotification && (
          <div className="notification-dot"></div>
        )} */}
          <RiMenu2Line className="menu-icon" />
        </div>
      )}

      {sidebarIsOpen && (
        <div className="sidebar-content">
          {sidebarIsOpen && (
            <IoMdClose className="menu-icon" onClick={toggleSidebar} />
          )}
          <div className="buttons-container-main">
            {buttons.map((button, index) => (
              <button
                className="sidebar-buttons"
                key={index}
                onClick={() => {
                  onComponentChange(button.component, button.pageTitle);
                  toggleSidebar();
                }}
              >
                {button.label}
              </button>
            ))}
          </div>
        </div>
      )}
      {sidebarIsOpen && (
        <div className="logout-container">
          <div className="change-logout-contain">
            <button
              className="sidebar-buttons"
              style={{
                background:
                  "linear-gradient(45deg,rgb(206, 196, 196), #8b5a5a, #6c4c6e, #333)",
                padding: "8px 10px",
              }}
              onClick={handleBugReport}
            >
              Submit Bug
            </button>
            <button
              className="sidebar-buttons"
              style={{
                background:
                  "linear-gradient(45deg,rgb(206, 196, 196), #8b5a5a, #6c4c6e, #333)",
                padding: "8px 10px",
              }}
              onClick={handleChangePasswordClick}
            >
              Change password
            </button>

            {/* <MdLogout className="menu-icon" onClick={handleLogout} /> */}
          </div>
        </div>
      )}

      {/* Change Password Dialog */}
      <Dialog open={openDialog}>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <TextField
            label="Old Password"
            type="password"
            fullWidth
            margin="normal"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
          <TextField
            label="New Password"
            type="password"
            fullWidth
            margin="normal"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            label="Confirm New Password"
            type="password"
            fullWidth
            margin="normal"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmitPasswordChange} color="primary">
            Save
          </Button>
        </DialogActions>
        {successMessage && (
          <Alert severity="success" sx={{ mt: 3 }}>
            {successMessage}
          </Alert>
        )}
        {errorMessage && (
          <Alert severity="error" sx={{ mt: 3 }}>
            {errorMessage}
          </Alert>
        )}
      </Dialog>

      <Dialog
        open={openReportModal}
        onClose={handleCloseBugReport}
        maxWidth="md" // Increased width
        fullWidth
        sx={{
          "& .MuiDialog-paper": { minHeight: "300px", maxHeight: "600px" },
        }} // Increased height
      >
        <DialogTitle>Report Bug</DialogTitle>
        <DialogContent>
          <TextField
            label="Report Bug"
            type="text"
            fullWidth
            margin="normal"
            value={bug}
            onChange={(e) => setBug(e.target.value)}
            multiline
            rows={6} // Turns the TextField into a textarea
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseBugReport} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSendReport} color="primary">
            Send
          </Button>
        </DialogActions>
        {successMessage && (
          <Alert severity="success" sx={{ mt: 3 }}>
            {successMessage}
          </Alert>
        )}
        {errorMessage && (
          <Alert severity="error" sx={{ mt: 3 }}>
            {errorMessage}
          </Alert>
        )}
      </Dialog>
    </aside>
  );
};

export default SideBar;
