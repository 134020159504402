import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/material";
import "./LiveRequestsLeads.css";
import { UserContext } from "../../context/UserContext";
import { baseUrl } from "../../services/BaseUrl";
import axios from "axios";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import { GiStaryu } from "react-icons/gi";
import { GoDash } from "react-icons/go";
import { useWebSocket } from "../../context/websocketContext";

const callStyle = {
  padding: 8.5,
  fontSize: 16,
  fontWeight: 700,
  textAlign: "center",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  borderRight: "2px solid #e0e0e0",
};
const renderSelectedInfo = (select, field) => {
  return (
    select?.[field] || (
      <>
        <GoDash style={{ fontSize: "20px" }} />
        <GoDash style={{ fontSize: "20px" }} />
      </>
    )
  );
};

const LiveRequestsLeads = () => {
  const { messages } = useWebSocket();
  const { token } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [liveRequests, setLiveRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [customDataFeedback, setCustomDataFeedback] = useState("");
  const [newHotel, setNewHotel] = useState({
    hotelName: "",
    address: "",
    distance: "",
    boardingType: "",
    nightRate: "",
    freeCancellation: "",
    roomType: "",
    sellingPrice: "",
    stars: "",
  });
  const [hotelsArray, setHotelsArray] = useState([]);
  const [openModel, setOpenModel] = useState(false);
  
  useEffect(() => {
    if (messages.length > 0) {
      console.log("New live request message:", messages[messages.length - 1]);
    }
  }, [messages]);

  useEffect(() => {
    const fetchLiveRequests = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${baseUrl}/api/support/live-requests`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const filteredRequests = response.data.filter(
          (request) =>
            request.Request === "Live Request" ||
            request.Request === "Custom Request"
        );
        setLiveRequests(filteredRequests);
      } catch (err) {
        setError("Error fetching live requests");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchLiveRequests();
  }, [token]);

  const handleOpenDialog = (request) => {
    setSelectedRequest(request);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedRequest(null);
    setCustomDataFeedback("");
    setHotelsArray([]);
  };

  // Handle feedback input change
  const handleFeedbackChange = (event) => {
    setCustomDataFeedback(event.target.value);
  };
  const handleModelCancel = () => {
    setOpenModel(false);
  };
  const handleModelOpen = () => {
    setOpenModel(true);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewHotel({ ...newHotel, [name]: value });
  };

  const handleAddHotel = () => {
    setHotelsArray([...hotelsArray, newHotel]);
    setNewHotel({
      hotelName: "",
      address: "",
      distance: "",
      boardingType: "",
      nightRate: "",
      freeCancellation: "",
      roomType: "",
      sellingPrice: "",
      stars: "",
    });
    setOpenModel(false);
  };

  const handleSendFeedback = async () => {
    const feedbackData =
      selectedRequest?.Request === "Custom Request"
        ? customDataFeedback
        : hotelsArray;

    try {
      const response = await axios.post(
        `${baseUrl}/api/support/update-company-feedback`,
        {
          showId: selectedRequest?.ShowId,
          companyId: selectedRequest?.CompanyId,
          feedback: feedbackData,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Feedback submitted successfully:", response.data);
     
      setLiveRequests((prevRequests) =>
        prevRequests.map((request) =>
          request === selectedRequest ? { ...request, highlight: true } : request
        )
      );
      
      setTimeout(() => {
        setLiveRequests((prevRequests) =>
          prevRequests.filter((request) => request !== selectedRequest)
        );
      }, 2000);

      setCustomDataFeedback("");
      setHotelsArray([]);
      handleCloseDialog();
      handleModelCancel();
    } catch (error) {
      console.error("Error submitting feedback:", error);
      
    }
  };

  // console.log("liveRequests", liveRequests);
  // console.log("selectedRequest", selectedRequest);

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const shouldEnableButton = customDataFeedback.trim() !== "" || hotelsArray.length > 0;

  if (loading) {
    return <LoadingComponent />;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="live-reuests-container">
      <div className="email-req-table-contain">
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: "70vh",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
            borderRadius: "16px 16px 0 0",
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="email requests table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#f6f6f6" }}>
                {[
                  "Company Name",
                  "Show Name",
                  "Agent Name",
                  "State",
                  "Request",
                  // "Request Date",
                  "Details",
                ].map((header, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      fontSize: 18,
                      fontFamily: '"Playfair Display", serif',
                      letterSpacing: "1.2px",
                      fontWeight: 800,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      wordBreak: "keep-all",
                      borderRight: "2px solid #e0e0e0",
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {liveRequests?.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    sx={{
                      borderRight: "2px solid #e0e0e0",
                      fontSize: "14px",
                      fontWeight: 550,
                      whiteSpace: "nowrap",
                      wordBreak: "keep-all",
                      padding: "14px",
                      textAlign: "center",
                    }}
                  >
                    No Live Requests
                  </TableCell>
                </TableRow>
              ) : (
                liveRequests
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((request, index) => (
                    <TableRow key={index} style={{backgroundColor: request?.highlight ? "lightgreen" : "white"}}>
                      <TableCell style={callStyle}>
                        {renderSelectedInfo(request?.companyData, "Lead")}
                      </TableCell>
                      <TableCell style={callStyle}>
                        {renderSelectedInfo(request?.showData, "Show")}
                      </TableCell>
                      <TableCell style={callStyle}>
                        {renderSelectedInfo(request, "agentName")}
                      </TableCell>
                      <TableCell style={callStyle}>
                        {renderSelectedInfo(request, "State")}
                      </TableCell>
                      <TableCell style={callStyle}>
                        {renderSelectedInfo(request, "Request")}
                      </TableCell>
                      <TableCell style={callStyle}>
                        <button
                          className="table-email-button"
                          onClick={() => handleOpenDialog(request)}
                        >
                          <p className="table-email-button-text">
                            View Details
                          </p>
                        </button>
                      </TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{
            backgroundColor: "#fff",
            borderTop: "1px solid #e0e0e0",
          }}
          rowsPerPageOptions={[8, 20]}
          component="div"
          count={liveRequests.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      {/* Popup Dialog for showing company and show ID */}
      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            Width: "100%",
            height: "100vh",
            margin: 0,
            overflowX: "hidden",
            overflowY: "auto",
            boxSizing: "border-box",
            padding: "15px 30px",
          },
        }}
      >
        <div className="Dialog-header-contain">
          <button className="close-dialog-button" onClick={handleCloseDialog}>
            Close
          </button>
        </div>
        <div className="Dialog-card-container">
          <div className="Dialog-card-header">
            <GiStaryu style={{ fontSize: 24 }} />
            <h3 style={{ letterSpacing: 1.5 }}>Request Details:</h3>
          </div>
          <div className="Dialog-card-contain">
            {selectedRequest && (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="Dialog-card-Details">
                  <p>
                    <span>Request:</span>
                    {selectedRequest?.Request}
                  </p>
                  <p>
                    <span>Request Date:</span>
                    {new Date(selectedRequest?.submittedTime).toLocaleString()}
                  </p>
                  <p>
                    <span>Agent Name:</span>
                    {selectedRequest.agentName}
                  </p>
                  <p>
                    <span>Show Name:</span>
                    {selectedRequest.showData.Show}
                  </p>
                  <p>
                    <span>Company Name:</span>
                    {selectedRequest.companyData.Lead}
                  </p>
                  <p>
                    <span>Requested Data:</span>
                    {selectedRequest.RequestedData}
                  </p>
                  <p>
                    <span>State:</span>
                    {selectedRequest?.State}
                  </p>
                </div>
                {selectedRequest?.Request === "Live Request" && (
                  <div className="Dialog-feedback-buttons">
                    <button
                      style={{
                        backgroundImage:
                          "linear-gradient(45deg,rgb(206, 196, 196), #8b5a5a, #6c4c6e, #333)",
                        border: "none",
                        padding: "10px 20px",
                      }}
                      onClick={handleModelOpen}
                    >
                      <p
                        style={{
                          fontSize: 14,
                          textTransform: "capitalize",
                          whiteSpace: "nowrap",
                          wordBreak: "keep-all",
                        }}
                      >
                        Create Hotel
                      </p>
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {selectedRequest?.Request === "Custom Request" && (
          <Box className="dialog-hotels-table-container">
            <TextField
              label="Custom Feedback"
              fullWidth
              variant="outlined"
              sx={{ margin: "0 12px 12px" }}
              value={customDataFeedback}
              onChange={handleFeedbackChange}
            />
          </Box>
        )}
        {selectedRequest?.Request === "Live Request" && (
          <div className="dialog-hotels-table-container">
            <h3 style={{ marginLeft: "12px" }}>Hotel Table :</h3>
            {selectedRequest && (
              <TableContainer
                component={Paper}
                sx={{
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
                  borderRadius: "16px",
                  margin: "15px",
                  overflow: "auto",
                  maxHeight: "40vh",
                }}
              >
                <Table sx={{ minWidth: 650 }} aria-label="email requests table">
                  <TableHead>
                    <TableRow
                      sx={{
                        background:
                          "linear-gradient(to right, #fed766, #e8b6ea)",
                      }}
                    >
                      {[
                        "Hotel Name",
                        "Stars",
                        "Address",
                        "Distance",
                        "Night Rate",
                        "Boarding Type",
                        "Room Type",
                        "Selling price",
                        "Free Cancellation Until",
                      ].map((header) => (
                        <TableCell
                          key={header}
                          sx={{
                            fontSize: 15,
                            fontFamily: '"Playfair Display", serif',
                            letterSpacing: "1.2px",
                            fontWeight: 800,
                            textAlign: "center",
                            whiteSpace: "nowrap",
                            wordBreak: "keep-all",
                            borderRight: "2px solid #fff",
                          }}
                        >
                          {header}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {hotelsArray?.length === 0 ? (
                      <TableRow>
                        <TableCell
                          colSpan={10}
                          sx={{
                            borderRight: "2px solid #e0e0e0",
                            fontSize: "14px",
                            fontWeight: 550,
                            whiteSpace: "nowrap",
                            wordBreak: "keep-all",
                            padding: "14px",
                            textAlign: "center",
                          }}
                        >
                          No Hotels Found
                        </TableCell>
                      </TableRow>
                    ) : (
                      hotelsArray?.map((hotel, index) => (
                        <TableRow key={index}>
                          <TableCell style={callStyle}>
                            {renderSelectedInfo(hotel, "hotelName")}
                          </TableCell>
                          <TableCell style={callStyle}>
                            {renderSelectedInfo(hotel, "stars")}
                          </TableCell>
                          <TableCell style={callStyle}>
                            {renderSelectedInfo(hotel, "address")}
                          </TableCell>
                          <TableCell style={callStyle}>
                            {renderSelectedInfo(hotel, "distance")}
                          </TableCell>
                          <TableCell style={callStyle}>
                            {renderSelectedInfo(hotel, "nightRate")}
                          </TableCell>
                          <TableCell style={callStyle}>
                            {renderSelectedInfo(hotel, "boardingType")}
                          </TableCell>
                          <TableCell style={callStyle}>
                            {renderSelectedInfo(hotel, "roomType")}
                          </TableCell>
                          <TableCell style={callStyle}>
                            {renderSelectedInfo(hotel, "sellingPrice")}
                          </TableCell>
                          <TableCell style={callStyle}>
                            {renderSelectedInfo(hotel, "freeCancellation")}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        )}
        {/* Modal */}
        <Dialog open={openModel}>
          <DialogTitle>Create Hotel</DialogTitle>
          <DialogContent>
            {Object.keys(newHotel).map((key) => (
              <TextField
                key={key}
                label={key.charAt(0).toUpperCase() + key.slice(1)}
                name={key}
                value={newHotel[key]}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
            ))}
          </DialogContent>
          <DialogActions>
            <button
              onClick={handleModelCancel}
              style={{
                backgroundImage: "linear-gradient(90deg, #FF6347, #B22222)",
                padding: "10px 20px",
                color: "#fff",
                borderRadius: "6px",
              }}
            >
              Cancel
            </button>
            <button
              onClick={handleAddHotel}
              style={{
                backgroundImage:
                  "linear-gradient(45deg,rgb(206, 196, 196), #8b5a5a, #6c4c6e, #333)",
                padding: "10px 20px",
                color: "#fff",
                borderRadius: "6px",
              }}
            >
              Add Hotel
            </button>
          </DialogActions>
        </Dialog>

        <div className="Dialog-feedback-buttons">
          <button
            style={{
              backgroundImage: "linear-gradient(90deg, #32CD32, #228B22)",
              border: "none",
              cursor: shouldEnableButton ? "pointer" : "not-allowed",
              opacity: shouldEnableButton ? 1 : 0.5, // Reduce opacity when disabled
            }}
            onClick={shouldEnableButton ? handleSendFeedback : null} 
            disabled={!shouldEnableButton}
          >
            <p
              style={{
                fontSize: 14,
                textTransform: "capitalize",
                whiteSpace: "nowrap",
                wordBreak: "keep-all",
              }}
            >
              Send Feedback
            </p>
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default LiveRequestsLeads;
