import React, { useEffect, useState, useContext } from "react";
import "./App.css";
import Router from "./router/Router";
import { UserContext, UserProvider } from "./context/UserContext";
import LoadingComponent from "./components/LoadingComponent/LoadingComponent";
import HealthCheck from "./components/HealthCheck/HealthCheck";
import { baseUrl } from "./services/BaseUrl";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";

function App() {
  const [serverStatus, setServerStatus] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const checkHealth = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/health`);
        if (response.ok) {
          setServerStatus("Server is online");
        } else {
          setServerStatus("Server is down");
        }
      } catch (error) {
        setServerStatus("Server is down");
      }
    };

    checkHealth();

    // const beforeUnloadHandler = (e) => {
    //   e.preventDefault();
    //   e.returnValue = "";
    //   setOpenDialog(true);
    // };

    // window.addEventListener("beforeunload", beforeUnloadHandler);

    // return () => {
    //   window.removeEventListener("beforeunload", beforeUnloadHandler);
    // };
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    setOpenDialog(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  if (serverStatus === null) {
    return <LoadingComponent />;
  }

  if (serverStatus === "Server is down") {
    return (
      <div>
        <HealthCheck />
      </div>
    );
  }

  return (
    <div className="App">
      <Router />

      {/* <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Are you sure you want to logout?</DialogTitle>
        <DialogContent>
          <p>You are about to log out. Do you want to continue?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogout} color="secondary">
            Logout
          </Button>
        </DialogActions>
      </Dialog> */}
    </div>
  );
}

export default App;

// <Dialog open={openDialog} onClose={handleDialogClose}>
//   <DialogTitle>Are you sure you want to logout?</DialogTitle>
//   <DialogContent>
//     <p>You are about to log out. Do you want to continue?</p>
//   </DialogContent>
//   <DialogActions>
//     <Button onClick={handleDialogClose} color="primary">
//       Cancel
//     </Button>
//     <Button onClick={handleLogout} color="secondary">
//       Logout
//     </Button>
//   </DialogActions>
// </Dialog>

// useEffect(() => {
//   const checkHealth = async () => {
//     try {
//       const response = await fetch(`${baseUrl}/api/health`);
//       if (response.ok) {
//         setServerStatus("Server is online");
//       } else {
//         setServerStatus("Server is down");
//       }
//     } catch (error) {
//       setServerStatus("Server is down");
//     }
//   };

//   checkHealth();
// }, []);
