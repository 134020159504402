import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../services/BaseUrl";

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("token") || null);
  const [name, setName] = useState(localStorage.getItem("name") || null);
  const [role, setRole] = useState(localStorage.getItem("role") || null);
  const [timezone, setTimezone] = useState(
    localStorage.getItem("timezone") || null
  );
  const [loginTime, setLoginTime] = useState(null);
  const [formattedTime, setFormattedTime] = useState("00:00:00");

  const navigate = useNavigate();

  const idleBreakTime = 1 * 60 * 1000; // 5 minutes
  const idleLogoutTime = 30 * 60 * 1000; // 30 minutes
  let breakTimer = null;
  let logoutTimer = null;

  // Function to start idle detection timers
  const startIdleTimer = () => {
    clearTimeout(breakTimer);
    clearTimeout(logoutTimer);

    logoutTimer = setTimeout(() => {
      console.log("User is idle for 30 minutes - Logging out...");
      endBreak();
      logout();
    }, idleLogoutTime);
  };

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, [token, navigate]);

  useEffect(() => {
    window.addEventListener("mousemove", startIdleTimer);
    window.addEventListener("keydown", startIdleTimer);
    startIdleTimer(); // Start on mount

    return () => {
      clearTimeout(breakTimer);
      clearTimeout(logoutTimer);
      window.removeEventListener("mousemove", startIdleTimer);
      window.removeEventListener("keydown", startIdleTimer);
    };
  }, [token]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      setTimeout(() => logout(), 3000);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [token]);

  // Function to update localStorage and state for user data
  const updateUserData = (userData) => {
    setUser(userData);
    setToken(userData.token);
    setRole(userData.role);
    setTimezone(userData.timezone);

    // Save start time in localStorage when login happens
    const startTime = Date.now(); // Save the current timestamp as start time
    localStorage.setItem("startTime", startTime);
    localStorage.setItem("token", userData.token);
    localStorage.setItem("name", userData.name);
    localStorage.setItem("role", userData.role);
    localStorage.setItem("timezone", userData.timezone);
  };

  // Format time to hh:mm:ss
  const formatTime = (elapsedTimeInSeconds) => {
    const hours = Math.floor(elapsedTimeInSeconds / 3600);
    const minutes = Math.floor((elapsedTimeInSeconds % 3600) / 60);
    const seconds = elapsedTimeInSeconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  };

  const endBreak = async () => {
    try {
      // Call the API or perform the necessary actions to end the break
      await axios.post(
        `${baseUrl}/api/users/end-break`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );

      console.log("Break ended.");
    } catch (error) {
      console.error("Error ending break:", error);
    }
  };

  // Update timer every second after login
  useEffect(() => {
    if (loginTime === null) return; // Wait for login to set loginTime

    const interval = setInterval(() => {
      const elapsedTimeInSeconds = Math.floor((Date.now() - loginTime) / 1000);
      setFormattedTime(formatTime(elapsedTimeInSeconds));
    }, 1000);

    return () => clearInterval(interval); // Clean up interval on unmount or when loginTime changes
  }, [loginTime]);

  // =========== login ===========>
  const loginFun = async (email, password) => {
    try {
      const response = await axios.post(`${baseUrl}/api/users/login`, {
        email,
        password,
      });
      updateUserData(response.data);
      return response.data.role;
    } catch (error) {
      console.error("Login error:", error);
      throw error;
    }
  };

  // =========== signup ===========>
  const signup = async (formData) => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/users/register`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
    } catch (error) {
      console.error("Signup error:", error);
      throw error;
    }
  };

  // =========== logout ===========>
  const logout = async () => {
    try {
      if (localStorage.getItem("breakStartTime")) {
        await endBreak();
        localStorage.removeItem("breakStartTime"); // Remove it after ending break
      }

      await axios.post(
        `${baseUrl}/api/users/logout`, // Make sure this URL matches your backend logout endpoint
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Clear user data from state and localStorage
      setUser(null);
      setToken(null);
      setName(null);
      setRole(null);
      setTimezone(null);
      setLoginTime(null); // Reset login time
      localStorage.removeItem("token");
      localStorage.removeItem("name");
      localStorage.removeItem("role");
      localStorage.removeItem("timezone");
      localStorage.removeItem("filteredLeads");
      localStorage.removeItem("filters");
      localStorage.removeItem("isDataEdited");
      localStorage.removeItem("breakTimer");
      localStorage.clear();

      // Optionally, redirect user to the home page or login page
      navigate("/");
    } catch (error) {
      console.error("Logout error:", error);
      // Handle logout error (optional)
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        token,
        name,
        role,
        timezone,
        formattedTime,
        loginFun,
        signup,
        logout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
