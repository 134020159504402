import React from 'react'
import "./layout.css"

const layout = ({children }) => {
  return (
    <div className='layout-container'>
        {children }
    </div>
  )
}

export default layout