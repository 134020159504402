import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useContext,
} from "react";
import "./CallbackLeads.css";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Paper,
} from "@mui/material";
import AnswerSection from "../AnswerSection/AnswerSection";
import { baseUrl } from "../../services/BaseUrl";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import { GoDash } from "react-icons/go";

const CallbackLeads = ({ leadsCallFromHistory }) => {
  const { token } = useContext(UserContext);
  const [currentDate, setCurrentDate] = useState("");
  const [leadsCallback, setLeadsCallback] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [changedLeads, setChangedLeads] = useState(new Set());
  ////////////////////////////////////////////////////////////////////////////////////////////////
  const [error, setError] = useState(null);
  const [answerPageActive, setAnswerPageActive] = useState(false);
  const [selectedLead, setSelectedLead] = useState({
    showId: "",
    leadId: "",
    showName: "",
    companyName: "",
    timeZone: "",
    Status: "",
    "Next Contact": "",
    address: "",
    "End Date": "",
    "Next Dates": "",
    "Check-in": "",
    "Check-out": "",
    "Lead Date": "",
    "Rejection Reason": "",
    Notes: "",
    "DM Name": "",
    "DM Rating": "",
    Email: "",
    "Email Type": "",
    emailRequestNotes: "",
    "Days to Call": "",
    "Last Contact": "",
    "Closing Date": "",
    Hotel: "",
    Budget: "",
    " Distance": "",
    Rooms: "",
    "/ Room Stay": "",
    "Total Stay": "",
    Activities: [],
    roomDetails: [],
  });
  const [selectedShowDataToRenderH, setSelectedShowDataRenderH] = useState({});
  const [optionsOfSelect, setOptionsOfSelect] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    const date = new Date();
    const options = { day: "numeric", month: "long", year: "numeric" };
    setCurrentDate(date.toLocaleDateString("en-US", options));
  }, []);

  const filteredLeadsHistory = useMemo(() => {
    return leadsCallFromHistory
      .flatMap((show) =>
        show.Leads.filter((lead) => lead.Status === "Callback").map((lead) => ({
          CompanyName: lead.Lead,
          showName: show.Show,
          city: lead.cityState,
          phone: lead.Phone,
          timezone: lead.Timezone,
          Status: lead.Status,
          "Next Contact": lead["Next Contact"],
          leadId: lead._id,
          address: show.Venue,
          showId: show._id,
          updatedAt: lead.updatedAt,
          nextContact: lead.NextContact,
          emailFeedback: lead.emailFeedback,
          "End Date": show["End Date"],
          "Next Dates": show["Next Dates"],
          "Check-in": lead["Check-in"],
          "Check-out": lead["Check-out"],
          "Lead Date": lead["Lead Date"],
          "Rejection Reason": lead["Rejection Reason"],
          Notes: lead.Notes,
          "DM Name": lead["DM Name"],
          "DM Rating": lead["DM Rating"],
          Email: lead.Email,
          "Email Type": lead["Email Type"],
          emailRequestNotes: lead.emailRequestNotes,
          "Days to Call": lead["Days to Call"],
          "Last Contact": lead["Last Contact"],
          "Closing Date": lead["Closing Date"],
          Hotel: lead.Hotel,
          Budget: lead.Budget,
          " Distance": lead.Max[" Distance"],
          Rooms: lead.Rooms,
          "/ Room Stay": lead["/ Room Stay"],
          "Total Stay": lead["Total Stay"],
          Activities: lead.Activities,
          roomDetails: lead.roomDetails,
          Pricings: lead?.Pricings,
        }))
      )
      .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
  }, [leadsCallFromHistory]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Get today's date in YYYY-MM-DD format for comparison
  const today = new Date();
  const todayString = today.toISOString().split("T")[0];

  // Handle Select Change
  const handleSelectChange = (event) => {
    const selectedDate = event.target.value;
    setSelectedDate(selectedDate);
    setCurrentDate(selectedDate);
  };

  useEffect(() => {
    const nextContacts = [
      ...new Set(filteredLeadsHistory.map((lead) => lead["Next Contact"])),
    ];

    // Sorting the nextContacts array in ascending order
    const sortedContacts = nextContacts.sort(
      (a, b) => new Date(b) - new Date(a)
    );

    setOptionsOfSelect(sortedContacts);
  }, [filteredLeadsHistory]);

  const filteredLeads = useMemo(() => {
    return filteredLeadsHistory.filter((lead) => {
      // Ensure selectedDate is formatted correctly
      const formattedSelectedDate = selectedDate
        ? new Date(selectedDate).toISOString().split("T")[0]
        : null;

      if (formattedSelectedDate) {
        console.log("Formatted Selected Date:", formattedSelectedDate);

        // Validate Next Contact date before processing
        const nextContactValue = lead["Next Contact"];
        if (!nextContactValue) {
          console.warn("Skipping lead with missing Next Contact:", lead);
          return false;
        }

        const nextContactDate = new Date(nextContactValue);
        if (isNaN(nextContactDate.getTime())) {
          console.error("Invalid Next Contact Date:", nextContactValue);
          return false;
        }

        const formattedNextContactDate = nextContactDate
          .toISOString()
          .split("T")[0];
        console.log("Formatted Next Contact Date:", formattedNextContactDate);

        return formattedNextContactDate === formattedSelectedDate;
      }

      // If no date is selected, show leads for today
      const todayDate = new Date().toISOString().split("T")[0];
      return todayDate === todayString;
    });
  }, [filteredLeadsHistory, selectedDate, todayString]);

  // const filteredLeads = useMemo(() => {
  //   return filteredLeadsHistory.filter((lead) => {
  //     const nextContactDate = new Date(lead["Next Contact"]);
  //     return nextContactDate.toISOString().split("T")[0] === todayString;
  //   });
  // }, [filteredLeadsHistory, todayString]);

  useEffect(() => {
    setLeadsCallback(filteredLeads);
  }, [filteredLeads]);

  const handleAnswerClick = useCallback((lead) => {
    const nextDate = new Date(lead?.["Next Dates"]);
    nextDate.setDate(nextDate.getDate() - 1);

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-based
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    setSelectedLead({
      showId: lead?.showId,
      leadId: lead?.leadId,
      showName: lead?.showName,
      companyName: lead?.CompanyName,
      timeZone: lead?.timezone,
      Status: lead?.Status,
      "Next Contact": lead?.["Next Contact"],
      address: lead?.address,
      "End Date": lead?.["End Date"],
      "Next Dates": lead?.["Next Dates"],
      "Check-in": lead?.["Check-in"],
      "Check-out": lead?.["Check-out"],
      "Lead Date": lead?.["Lead Date"],
      "Rejection Reason": lead?.["Rejection Reason"],
      Notes: lead?.Notes,
      "DM Name": lead?.["DM Name"],
      "DM Rating": lead?.["DM Rating"],
      Email: lead?.Email,
      "Email Type": lead?.["Email Type"],
      emailRequestNotes: lead?.emailRequestNotes,
      "Days to Call": lead?.["Days to Call"],
      "Last Contact": lead?.["Last Contact"],
      "Closing Date": lead?.["Closing Date"],
      Hotel: lead?.Hotel,
      Budget: lead?.Budget,
      " Distance": lead?.[" Distance"],
      Rooms: lead?.Rooms,
      "/ Room Stay": lead?.["/ Room Stay"],
      "Total Stay": lead?.["Total Stay"],
      Activities: lead.Activities,
      roomDetails: lead.roomDetails,
    });
    setAnswerPageActive(true);
  }, []);

  const handleCloseAnswerPage = useCallback(() => {
    setAnswerPageActive(false);
  }, []);

  const handleUpdateLead = async () => {
    try {
      const response = await axios.put(
        `${baseUrl}/api/agents/shows/${selectedLead.showId}/companies/${selectedLead.leadId}`,
        selectedLead,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Lead updated successfully", response.data);
      }
    } catch (error) {
      setError("Error updating lead");
      console.error("Error updating lead:", error);
    }
  };

  return (
    <>
      {!answerPageActive ? (
        <div className="callback-leads-agent-container">
          <div className="callback-agent-header-container">
            <h2 className="callback-agent-header">
              Callback Leads for <span>{currentDate || "Select a Date"}</span>
            </h2>
            <div>
              <select id="timezone-select" onChange={handleSelectChange}>
                <option value="">Select Date</option>
                {optionsOfSelect.map((contact, index) => (
                  <option key={index} value={contact}>
                    {contact}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="callback-agent-table">
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      background:
                        "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
                    }}
                  >
                    {[
                      "Company Name",
                      "Show Name",
                      "City, Country",
                      "Phone",
                      "Timezone",
                      "Status",
                      "Callback Date",
                      "Answer",
                    ].map((header, index) => (
                      <TableCell
                        key={header}
                        sx={{
                          color: "#fff",
                          borderRight: "2px solid #fff",
                          fontSize: "16px",
                          fontFamily: '"Playfair Display", serif',
                          textAlign: index === 6 ? "center" : "left",
                          whiteSpace: "nowrap",
                          wordBreak: "keep-all",
                        }}
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leadsCallback.length > 0 ? (
                    leadsCallback
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((lead, index) => (
                        <TableRow
                          key={lead.leadId}
                          sx={{
                            backgroundColor: changedLeads.has(lead.leadId)
                              ? "#33333344"
                              : "transparent",
                          }}
                        >
                          <TableCell
                            sx={{
                              borderRight: "2px solid #e0e0e0",
                              fontSize: "14px",
                              fontWeight: 550,
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                              padding: "7px 10px",
                            }}
                          >
                            {lead.CompanyName}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderRight: "2px solid #e0e0e0",
                              fontSize: "14px",
                              fontWeight: 550,
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                              padding: "7px 10px",
                            }}
                          >
                            {lead.showName}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderRight: "2px solid #e0e0e0",
                              fontSize: "14px",
                              fontWeight: 550,
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                              padding: "7px 10px",
                            }}
                          >
                            {lead.city || (
                              <>
                                <GoDash style={{ fontSize: "20px" }} />
                                <GoDash style={{ fontSize: "20px" }} />
                              </>
                            )}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderRight: "2px solid #e0e0e0",
                              fontSize: "14px",
                              fontWeight: 550,
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                              padding: "7px 10px",
                            }}
                          >
                            {lead.phone && lead.phone.length > 0 ? (
                              <a
                                href={`tel:${lead.phone}`}
                                style={{
                                  textDecoration: "none",
                                  color: "inherit",
                                }}
                              >
                                {lead.phone}
                              </a>
                            ) : (
                              ""
                            )}
                          </TableCell>

                          <TableCell
                            sx={{
                              borderRight: "2px solid #e0e0e0",
                              fontSize: "14px",
                              fontWeight: 550,
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                              padding: "7px 10px",
                            }}
                          >
                            {lead.timezone}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderRight: "2px solid #e0e0e0",
                              fontSize: "14px",
                              fontWeight: 550,
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                              padding: "7px 10px",
                            }}
                          >
                            {lead.Status}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderRight: "2px solid #e0e0e0",
                              fontSize: "14px",
                              fontWeight: 550,
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                              padding: "7px 10px",
                              textAlign: "center",
                            }}
                          >
                            {lead?.["Next Contact"]}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderRight: "2px solid #e0e0e0",
                              fontSize: "14px",
                              fontWeight: 550,
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                              padding: "7px 10px",
                              textAlign: "center",
                            }}
                          >
                            <button
                              style={{
                                padding: "10px 20px",
                                backgroundColor: "#4f637d",
                                color: "#fff",
                                border: "none",
                                borderRadius: "5px",
                                cursor: "pointer",
                                fontSize: "14px",
                              }}
                              onClick={() => handleAnswerClick(lead)}
                            >
                              Answer
                            </button>
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        No callback leads available.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              sx={{
                backgroundColor: "#fff",
                borderTop: "1px solid #e0e0e0",
              }}
              rowsPerPageOptions={[8, 10]}
              component="div"
              count={leadsCallback.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      ) : (
        selectedLead && (
          <AnswerSection
            handleCloseAnswerPage={handleCloseAnswerPage}
            handleUpdateLead={handleUpdateLead}
            selectedLead={selectedLead}
            setSelectedLead={setSelectedLead}
            selectedShowDataToRenderH={selectedShowDataToRenderH}
          />
        )
      )}
    </>
  );
};

export default CallbackLeads;
