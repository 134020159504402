import React, { useContext, useEffect, useState } from "react";
import "./CustomerSupport.css";
import Layout from "../../components/layout/layout";
import SideBar from "../../components/SideBar/SideBar";
import MainContainer from "../../mainContainer/MainContainer";
import OldLeadsTable from "../../components/CustomerSupport/OldLeadsTable";
import UploadCustomerSupportSection from "../../components/CustomerSupportUploads/UploadCustomerSupportSection";


const CustomerSupport = () => {
  //sidebar logic ------------>
  const [currentComponent, setCurrentComponent] = useState(<OldLeadsTable />);
  const [currentPageTitle, setCurrentPageTitle] = useState("Old Leads");

  // Get role from local storage
  const userRole = localStorage.getItem("role");

  //sidebar logic ------------>
  const sidebarButtons = [
    {
      label: "Old Leads",
      component: <OldLeadsTable />,
      pageTitle: "Old Leads",
    },
    {
      label: "Upload Old Leads",
      component: <UploadCustomerSupportSection />,
      pageTitle: "Upload Old Leads",
    },
  ];

  //sidebar logic ------------>
  const handleComponentChange = (component, pageTitle) => {
    setCurrentComponent(component);
    setCurrentPageTitle(pageTitle);
  };
  return (
    <Layout>
      <SideBar
        buttons={sidebarButtons}
        onComponentChange={handleComponentChange}
      />
      <MainContainer pageTitle={currentPageTitle}>
        <h2>{userRole === "customer support"}</h2>
        {currentComponent}
      </MainContainer>
    </Layout>
  );
};

export default CustomerSupport;
