import React, { useEffect, useState, useContext } from "react";
import "./UsersReports.css";
import { FaArrowLeftLong } from "react-icons/fa6";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material"; 
import { GoDash } from "react-icons/go";
import { baseUrl } from "../../services/BaseUrl";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import LoadingComponent from "../LoadingComponent/LoadingComponent";

const callStyle = {
  padding: 8.5,
  fontSize: 16,
  fontWeight: 700,
  textAlign: "center",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  borderRight: "2px solid #e0e0e0",
};
const renderSelectedInfo = (select, field) => {
  return (
    select?.[field] || (
      <>
        <GoDash style={{ fontSize: "20px" }} />
        <GoDash style={{ fontSize: "20px" }} />
      </>
    )
  );
};

const OperationUserReport = ({ selectReport, handleCloseViewReport }) => {
  const { token } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userReport, setUserReport] = useState([]);

  useEffect(() => {
    const fetchUserReport = async () => {
      const id = selectReport._id
      try {
        setLoading(true);
        const response = await axios.get(
          `${baseUrl}/api/super/user-report/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUserReport(response.data.result)
        setLoading(false);
      } catch (err) {
        setError("Failed to load user data.");
        setLoading(false);
      }
    };

    fetchUserReport();
  }, [token]);

  const formatTime = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  if (loading) {
    return <LoadingComponent />;
  }

  if (error) {
    return (
      <div className="operation-user-report-container">
        <div className="answer-header-container">
          <FaArrowLeftLong
            onClick={handleCloseViewReport}
            style={{ cursor: "pointer", fontSize: 22 }}
          />
        </div>
        <div className="operation-user-report-table">
          <h2 className="header-message-error">{error}</h2>
        </div>
      </div>
    );
  }

  return (
    <div className="operation-user-report-container">
      <div className="answer-header-container">
        <FaArrowLeftLong
          onClick={handleCloseViewReport}
          style={{ cursor: "pointer", fontSize: 22 }}
        />
      </div>
      <div className="operation-user-report-info">
        <h3 style={{ textTransform: "capitalize" }}><span style={{ color: "#e8b6ea", letterSpacing: "1px" }}>User Name :</span> {selectReport.name}</h3>
        <h3 style={{ textTransform: "capitalize" }}><span style={{ color: "#e8b6ea", letterSpacing: "1px" }}>Role :</span> {selectReport.role}</h3>
      </div>
      <div className="operation-user-report-table">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  background: "linear-gradient(to left, #fed766, #e8b6ea)",
                }}
              >
                {[
                  "Date",
                  "Login Time",
                  "Logout Time",
                  "Total Hours",
                  "Break Time",
                ].map((header, index) => (
                  <TableCell
                    key={header}
                    sx={{
                      fontSize: 16,
                      fontWeight: 600,
                      borderRight: "2px solid #fff",
                      whiteSpace: "nowrap",
                      wordBreak: "keep-all",
                      padding: "10px 14px",
                      color: "#fff",
                      textTransform: "capitalize",
                      letterSpacing: "1.2px",
                      textAlign: "center",
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {userReport.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={5}
                    sx={{
                      borderRight: "2px solid #e0e0e0",
                      fontSize: "14px",
                      fontWeight: 550,
                      whiteSpace: "nowrap",
                      wordBreak: "keep-all",
                      padding: "14px",
                      textAlign: "center",
                    }}
                  >
                    No Users Report
                  </TableCell>
                </TableRow>
              ) : (
                userReport.map((report, index) => (
                  <TableRow key={index}>
                    <TableCell style={callStyle}>
                      {renderSelectedInfo(report, "date")}
                    </TableCell>
                    <TableCell style={callStyle}>
                      {formatTime(report.loggedinTime) || (
                        <>
                          <GoDash style={{ fontSize: "20px" }} />
                          <GoDash style={{ fontSize: "20px" }} />
                        </>
                      )}
                    </TableCell>
                    <TableCell style={callStyle}>
                      {formatTime(report.loggedoutTime) || (
                        <>
                          <GoDash style={{ fontSize: "20px" }} />
                          <GoDash style={{ fontSize: "20px" }} />
                        </>
                      )}
                    </TableCell>
                    <TableCell style={callStyle}>
                      {report?.totalHours || "00:00"}
                    </TableCell>
                    <TableCell style={callStyle}>
                      {report?.breakTime || "00:00"}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

export default OperationUserReport