import React, { useState, useCallback, useEffect } from "react";
import Layout from "../../components/layout/layout";
import SideBar from "../../components/SideBar/SideBar";
import MainContainer from "../../mainContainer/MainContainer";
import PricingTableData from "../../components/PricingTableData/PricingTableData";
import audio from "../../assets/audio/email-request-priceing.wav";
import Alert from "@mui/material/Alert";
import UploadBulkPrice from "../../components/UploadBulkPrice/UploadBulkPrice";

const PricingSen = () => {
  const [currentComponent, setCurrentComponent] = useState(
    <PricingTableData />
  );
  const [currentPageTitle, setCurrentPageTitle] = useState("Pricing Table");

  const sidebarButtons = [
    {
      label: "Pricing Table",
      component: <PricingTableData />,
      pageTitle: "Pricing Table",
    }
  ];

  const handleComponentChange = useCallback((component, pageTitle) => {
    setCurrentComponent(component);
    setCurrentPageTitle(pageTitle);
  }, []);


  return (
    <Layout>
      <SideBar
        buttons={sidebarButtons}
        onComponentChange={handleComponentChange}
      />
      <MainContainer pageTitle={currentPageTitle}>
        <h2> Pricing Team (Senior) View </h2>
        {currentComponent}
      </MainContainer>
    </Layout>
  );
};

export default PricingSen;
