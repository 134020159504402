import React, { useEffect, useState, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { GoDash } from "react-icons/go";
import { GiStaryu } from "react-icons/gi";

const callStyle = {
  padding: 8.5,
  fontSize: 16,
  fontWeight: 700,
  textAlign: "center",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  borderRight: "2px solid #e0e0e0",
};
const renderSelectedInfo = (select, field) => {
  return (
    select?.[field] || (
      <>
        <GoDash style={{ fontSize: "20px" }} />
        <GoDash style={{ fontSize: "20px" }} />
      </>
    )
  );
};

const CallbackRequests = ({ callbackRequests }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);

  const handleOpenDialog = (request) => {
    setSelectedRequest(request);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedRequest(null);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="callback-sales-contain">
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
          borderRadius: "10px 10px 0 0",
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="email requests table">
          <TableHead>
            <TableRow
              sx={{
                background:
                  "linear-gradient(45deg,rgb(206, 196, 196), #8b5a5a, #6c4c6e)",
              }}
            >
              {[
                "Company Name",
                "Show Name",
                "Agent Name",
                "State",
                "Request",
                "Details",
              ].map((header, index) => (
                <TableCell
                  key={index}
                  sx={{
                    fontSize: 18,
                    fontFamily: '"Playfair Display", serif',
                    letterSpacing: "1.2px",
                    fontWeight: 800,
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    wordBreak: "keep-all",
                    borderRight: "2px solid #e0e0e0",
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {callbackRequests?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={6}
                  sx={{
                    borderRight: "2px solid #e0e0e0",
                    fontSize: "14px",
                    fontWeight: 550,
                    whiteSpace: "nowrap",
                    wordBreak: "keep-all",
                    padding: "14px",
                    textAlign: "center",
                  }}
                >
                  No Live Requests
                </TableCell>
              </TableRow>
            ) : (
              callbackRequests
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((request, index) => (
                  <TableRow
                    key={index}
                    style={{
                      backgroundColor: request?.highlight
                        ? "lightgreen"
                        : "white",
                    }}
                  >
                    <TableCell style={callStyle}>
                      {renderSelectedInfo(request?.companyData, "Lead")}
                    </TableCell>
                    <TableCell style={callStyle}>
                      {renderSelectedInfo(request?.showData, "Show")}
                    </TableCell>
                    <TableCell style={callStyle}>
                      {renderSelectedInfo(request, "agentName")}
                    </TableCell>
                    <TableCell style={callStyle}>
                      {renderSelectedInfo(request, "State")}
                    </TableCell>
                    <TableCell style={callStyle}>
                      {renderSelectedInfo(request, "Request")}
                    </TableCell>
                    <TableCell style={callStyle}>
                      <button
                        className="table-email-button"
                        onClick={() => handleOpenDialog(request)}
                      >
                        <p className="table-email-button-text">View Details</p>
                      </button>
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{
          backgroundColor: "#fff",
          borderTop: "1px solid #e0e0e0",
        }}
        rowsPerPageOptions={[5, 20]}
        component="div"
        count={callbackRequests.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {/* Popup Dialog for showing company and show ID */}
      <Dialog
        fullScreen
        open={openDialog}
        PaperProps={{
          style: {
            Width: "100%",
            height: "100vh",
            margin: 0,
            overflowX: "hidden",
            overflowY: "auto",
            boxSizing: "border-box",
            padding: "15px 30px",
          },
        }}
      >
        <div className="Dialog-header-contain">
          <button className="close-dialog-button" onClick={handleCloseDialog}>
            Close
          </button>
        </div>
        <div className="Dialog-card-container">
          <div className="Dialog-card-header">
            <GiStaryu style={{ fontSize: 24 }} />
            <h3 style={{ letterSpacing: 1.5 }}>Request Details:</h3>
          </div>
          <div className="Dialog-card-contain">
            {selectedRequest && (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="Dialog-card-Details">
                  <p>
                    <span>Request:</span>
                    {selectedRequest?.Request}
                  </p>
                  <p>
                    <span>Request Date:</span>
                    {new Date(selectedRequest?.submittedTime).toLocaleString()}
                  </p>
                  <p>
                    <span>Agent Name:</span>
                    {selectedRequest.agentName}
                  </p>
                  <p>
                    <span>Show Name:</span>
                    {selectedRequest.showData.Show}
                  </p>
                  <p>
                    <span>Company Name:</span>
                    {selectedRequest.companyData.Lead}
                  </p>
                  <p>
                    <span>Requested Data:</span>
                    {selectedRequest.RequestedData}
                  </p>
                  <p>
                    <span>State:</span>
                    {selectedRequest?.State}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default CallbackRequests;
