import React from "react";
import "./EmailTeam.css";
import { useNavigate } from "react-router-dom";


const EmailTeam = () => {
  const navigate = useNavigate();

  const handleButtonClick = async (selectedshift) => {
    localStorage.setItem('selectedShift', selectedshift);
    navigate("/email-team/email-team-req");
};

  return (
    <div>
      <div className="Email-team-section">
        <div className="Email-button-contain">
          <button
            className="Email-button-select"
            onClick={() => handleButtonClick("UK")}
          >
            UK
          </button>
          <button
            className="Email-button-select"
            onClick={() => handleButtonClick("US")}
          >
            US
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailTeam;
