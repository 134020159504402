import { useContext, useEffect, useState, useMemo, useCallback } from "react";
import "./HistoryLeads.css";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Modal,
} from "@mui/material";
import { FcExpand } from "react-icons/fc";
import AnswerSection from "../AnswerSection/AnswerSection";
import { baseUrl } from "../../services/BaseUrl";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import { FaCheckSquare, FaExclamationTriangle } from "react-icons/fa";
import { GoDash } from "react-icons/go";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const HistoryLeads = ({ leadsHistory, setLeadsHistory }) => {
  const { token } = useContext(UserContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTimezone, setSelectedTimezone] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedShowDate, setSelectedShowDate] = useState("");
  const [error, setError] = useState(null);
  const [answerPageActive, setAnswerPageActive] = useState(false);
  const [selectedLead, setSelectedLead] = useState({
    showId: "",
    leadId: "",
    showName: "",
    companyName: "",
    timeZone: "",
    Status: "",
    "Next Contact": "",
    address: "",
    "End Date": "",
    "Next Dates": "",
    "Check-in": "",
    "Check-out": "",
    "Lead Date": "",
    "Rejection Reason": "",
    Notes: "",
    "DM Name": "",
    "DM Rating": "",
    Email: "",
    "Email Type": "",
    emailRequestNotes: "",
    "Days to Call": "",
    "Last Contact": "",
    "Closing Date": "",
    Hotel: "",
    Budget: "",
    " Distance": "",
    Rooms: "",
    "/ Room Stay": "",
    "Total Stay": "",
    roomDetails: [],
    Activities: [],
    roomDetails: [],
    "Request Feedback": [],
    updatedAt: "",
  });
  const [selectedShowDataToRenderH, setSelectedShowDataRenderH] = useState({});
  const [statusCounts, setStatusCounts] = useState({});
  const [OpenFeedbackViewActive, setOpenFeedbackViewActive] = useState(false);
  const [feedbackReq, setfeedbackReq] = useState(null);

  // Get unique timezones and statuses
  const uniqueTimezones = useMemo(() => {
    const timezones = new Set();
    leadsHistory.forEach((show) =>
      show.Leads.forEach((lead) => timezones.add(lead.Timezone))
    );
    return [...timezones];
  }, [leadsHistory]);

  const uniqueStatuses = useMemo(() => {
    const statuses = new Set();
    leadsHistory.forEach((show) =>
      show.Leads.forEach((lead) => statuses.add(lead.Status))
    );
    return [...statuses];
  }, [leadsHistory]);

  const uniqueMonths = useMemo(() => {
    const months = new Set();

    leadsHistory.forEach((show) => {
      if (show.showDate) {
        const date = new Date(show.showDate);
        const monthYear = date.toLocaleString("default", {
          month: "long",
          year: "numeric",
        });
        months.add(monthYear);
      }
    });

    return [...months].sort((a, b) => new Date(a) - new Date(b));
  }, [leadsHistory]);

  console.log(uniqueMonths);

  // Filtered leads based on search term, timezone, and status
  const filteredLeadsHistory = useMemo(() => {
    return leadsHistory
      .flatMap((show) =>
        show.Leads.map((lead) => {
          // Extract year and month manually
          if (!show.showDate || typeof show.showDate !== "string") {
            console.error("Invalid showDate:", show.showDate);
            return null; // Handle the case properly
          }

          const [year, month] = show.showDate.split("-"); // Extract year and month
          if (!year || !month) {
            console.error("Invalid date format:", show.showDate);
            return null;
          }

          const showMonthYear = new Date(year, month - 1).toLocaleString(
            "default",
            {
              month: "long",
              year: "numeric",
            }
          );

          return {
            CompanyName: lead.Lead,
            showName: show.Show,
            city: lead.cityState,
            phone: lead.Phone,
            timezone: lead.Timezone,
            Status: lead.Status,
            "Next Contact": lead["Next Contact"],
            leadId: lead._id,
            address: show.Venue,
            showId: show._id,
            updatedAt: lead.updatedAt,
            nextContact: lead.NextContact,
            emailFeedback: lead.emailFeedback,
            "End Date": show["End Date"],
            "Next Dates": show["Next Dates"],
            "Check-in": lead["Check-in"],
            "Check-out": lead["Check-out"],
            "Lead Date": lead["Lead Date"],
            "Rejection Reason": lead["Rejection Reason"],
            Notes: lead.Notes,
            "DM Name": lead["DM Name"],
            "DM Rating": lead["DM Rating"],
            Email: lead.Email,
            "Email Type": lead["Email Type"],
            emailRequestNotes: lead.emailRequestNotes,
            "Days to Call": lead["Days to Call"],
            "Last Contact": lead["Last Contact"],
            "Closing Date": lead["Closing Date"],
            Hotel: lead.Hotel,
            Budget: lead.Budget,
            " Distance": lead.Max?.[" Distance"],
            Rooms: lead.Rooms,
            "/ Room Stay": lead["/ Room Stay"],
            "Total Stay": lead["Total Stay"],
            Activities: lead?.Activities,
            roomDetails: lead.roomDetails,
            "Request Feedback": lead?.["Request Feedback"],
            showMonthYear, // Add formatted month-year for filtering
          };
        })
      )
      .filter((lead) => {
        const matchesSearchTerm = lead.CompanyName.toLowerCase().includes(
          searchTerm.toLowerCase()
        );
        const matchesTimezone = selectedTimezone
          ? lead.timezone === selectedTimezone
          : true;
        const matchesStatus = selectedStatus
          ? lead.Status === selectedStatus
          : true;
        const matchesMonth = selectedShowDate
          ? lead.showMonthYear === selectedShowDate
          : true;

        return (
          matchesSearchTerm && matchesTimezone && matchesStatus && matchesMonth
        );
      })
      .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
  }, [
    leadsHistory,
    searchTerm,
    selectedTimezone,
    selectedStatus,
    selectedShowDate,
  ]);

  console.log(selectedShowDate);

  const groupedLeadsByDate = useMemo(() => {
    const validLeads = {};
    const invalidLeads = [];

    filteredLeadsHistory.forEach((lead) => {
      const updatedAt = lead?.updatedAt;

      if (!updatedAt || isNaN(new Date(updatedAt))) {
        invalidLeads.push(lead);
        return;
      }

      const date = new Date(updatedAt).toISOString().split("T")[0];

      if (!validLeads[date]) {
        validLeads[date] = [];
      }
      validLeads[date].push(lead);
    });

    // Sort valid dates
    const sortedValidLeads = Object.entries(validLeads)
      .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB))
      .reduce((acc, [date, leads]) => {
        acc[date] = leads;
        return acc;
      }, {});

    return {
      validLeads: sortedValidLeads,
      invalidLeads: invalidLeads,
    };
  }, [filteredLeadsHistory]);

  // const dates = Object.keys(groupedLeadsByDate);
  const dates = Object.keys(groupedLeadsByDate.validLeads).sort(
    (a, b) => new Date(b) - new Date(a) // Sort in descending order
  );

  // Get today's date in the same format as your lead dates (YYYY-MM-DD)
  const todayDate = new Date().toISOString().split("T")[0];
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const selectedShow = leadsHistory.find((lead) =>
      lead.Leads.some((leadData) => leadData.showId === selectedLead.showId)
    );

    if (selectedShow) {
      setSelectedShowDataRenderH(selectedShow);
    }
  }, [selectedLead, leadsHistory]);

  const handleAnswerClick = useCallback((lead) => {
    const nextDate = new Date(lead?.["Next Dates"]);
    nextDate.setDate(nextDate.getDate() - 1);

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-based
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    setSelectedLead({
      showId: lead?.showId,
      leadId: lead?.leadId,
      showName: lead?.showName,
      companyName: lead?.CompanyName,
      timeZone: lead?.timezone,
      Status: lead?.Status,
      "Next Contact": lead?.["Next Contact"],
      address: lead?.address,
      "End Date": lead?.["End Date"],
      "Next Dates": lead?.["Next Dates"],
      "Check-in": lead?.["Check-in"],
      "Check-out": lead?.["Check-out"],
      "Lead Date": lead?.["Lead Date"],
      "Rejection Reason": lead?.["Rejection Reason"],
      Notes: lead?.Notes,
      "DM Name": lead?.["DM Name"],
      "DM Rating": lead?.["DM Rating"],
      Email: lead?.Email,
      "Email Type": lead?.["Email Type"],
      emailRequestNotes: lead?.emailRequestNotes,
      "Days to Call": lead?.["Days to Call"],
      "Last Contact": lead?.["Last Contact"],
      "Closing Date": lead?.["Closing Date"],
      Hotel: lead?.Hotel,
      Budget: lead?.Budget,
      " Distance": lead?.[" Distance"],
      Rooms: lead?.Rooms,
      "/ Room Stay": lead?.["/ Room Stay"],
      "Total Stay": lead?.["Total Stay"],
      Activities: lead?.Activities,
      roomDetails: lead.roomDetails,
      "Request Feedback": lead?.["Request Feedback"],
    });
    setAnswerPageActive(true);
  }, []);

  const handleCloseAnswerPage = useCallback(() => {
    setAnswerPageActive(false);
  }, []);

  const handleUpdateLead = async () => {
    try {
      const response = await axios.put(
        `${baseUrl}/api/agents/shows/${selectedLead.showId}/companies/${selectedLead.leadId}`,
        selectedLead,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Lead updated successfully", response.data);
      }
    } catch (error) {
      setError("Error updating lead");
      console.error("Error updating lead:", error);
    }
  };
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    // Initialize the count object
    const countObj = {};

    // Loop through each show in the data
    leadsHistory.forEach((show) => {
      // Loop through each lead in the show's leads
      show.Leads.forEach((lead) => {
        // Increment the count of the current status
        countObj[lead.Status] = (countObj[lead.Status] || 0) + 1;
      });
    });

    // Update the status counts state
    setStatusCounts(countObj);
  }, [leadsHistory]); // Dependency array, re-run when 'data' changes

  // Get the unique statuses
  const uniqueStatusesbox = Object.keys(statusCounts);

  const handleOpenFeedbackView = (lead) => {
    setOpenFeedbackViewActive(true);
    setSelectedLead(lead);
    if (lead["Request Feedback"].length > 0) {
      setfeedbackReq(lead["Request Feedback"]);
    }
  };
  const handleCloseFeedbackView = () => {
    setOpenFeedbackViewActive(false);
  };

  return (
    <>
      {!answerPageActive ? (
        <div className="history-leads-agent-container">
          <div
            id="feedback"
            className="feedback-div"
            style={{
              background: "#37373f",
              margin: "10px",
              padding: "10px 20px",
              borderRadius: "10px",
              display: "flex",
              gap: "15px",
              alignItems: "center",
            }}
          >
            <p
              id="feedback-message"
              style={{
                fontSize: "12px",
                display: "flex",
                gap: "6px",
                alignItems: "center",
                fontWeight: "800",
                textTransform: "capitalize",
              }}
            >
              <span>
                <AiOutlineLoading3Quarters style={{ color: "orange" }} />
              </span>
              waiting email feedback.
            </p>
            <p
              id="feedback-message"
              style={{
                fontSize: "12px",
                display: "flex",
                gap: "6px",
                alignItems: "center",
                fontWeight: "800",
                textTransform: "capitalize",
              }}
            >
              <span>
                <FaCheckSquare style={{ color: "green" }} />
              </span>
              the email feedback is correct, and the email was sent
              successfully.
            </p>
            <p
              id="feedback-message"
              style={{
                fontSize: "12px",
                display: "flex",
                gap: "6px",
                alignItems: "center",
                fontWeight: "800",
                textTransform: "capitalize",
              }}
            >
              <span>
                <FaExclamationTriangle style={{ color: "red" }} />
              </span>
              the email is incorrect and there was an error sending the email.
            </p>
          </div>
          <div className="history-agent-filter">
            <div className="fliters-onAccordition">
              <TextField
                variant="outlined"
                label="Search by Company Name"
                onChange={(e) => setSearchTerm(e.target.value)}
                fullWidth
                sx={{ color: "#fff" }}
              />
            </div>
            <div className="fliters-onAccordition">
              <FormControl fullWidth>
                <InputLabel>Filter by Timezone</InputLabel>
                <Select
                  value={selectedTimezone}
                  onChange={(e) => setSelectedTimezone(e.target.value)}
                  label="Filter by Timezone"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {uniqueTimezones.map((timezone) => (
                    <MenuItem key={timezone} value={timezone}>
                      {timezone}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="fliters-onAccordition">
              <FormControl fullWidth>
                <InputLabel>Filter by Status</InputLabel>
                <Select
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                  label="Filter by Status"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {uniqueStatuses.map((Status) => (
                    <MenuItem key={Status} value={Status}>
                      {Status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="fliters-onAccordition">
              <FormControl fullWidth>
                <InputLabel>Filter by Show date</InputLabel>
                <Select
                  value={selectedShowDate}
                  onChange={(e) => setSelectedShowDate(e.target.value)}
                  label="Filter by Show date"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {uniqueMonths.map((month) => (
                    <MenuItem key={month} value={month}>
                      {month}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <Grid container spacing={2} style={{ width: "99%", margin: "auto" }}>
            {uniqueStatusesbox?.map((status, index) => (
              <Grid item xs={12} sm={4} md={3} lg={2} key={index}>
                <Box
                  sx={{
                    padding: 1,
                    textAlign: "center",
                    border: "1px solid #e0e0e0",
                    borderRadius: 1,
                  }}
                >
                  <Typography variant="subtitle1">{status}</Typography>
                  <Typography variant="h6">
                    {statusCounts[status] || 0}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
          <div
            className="history-agent-drop-container"
            style={{ margin: "10px 0 0 0" }}
          >
            {dates?.map((date, index) => {
              const leadsForDate = groupedLeadsByDate.validLeads[date] || [];
              const isToday = date === todayDate;
              return (
                <Accordion key={index} defaultExpanded={isToday}>
                  <AccordionSummary
                    expandIcon={<FcExpand />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography component="div">
                      <h2>
                        Leads For
                        <span className="span-liner-date"> {date} </span>
                      </h2>
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <TableContainer
                      component={Paper}
                      className="history-agent-table-container"
                    >
                      <Table
                        sx={{
                          maxHeight: "550px",
                          overflowY: "auto",
                          display: "block",
                        }}
                      >
                        <TableHead>
                          <TableRow
                            sx={{
                              background:
                                "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
                              position: "sticky",
                              top: 0,
                              zIndex: 1,
                            }}
                          >
                            {[
                              "Company Name",
                              "Show Name",
                              "City, Country",
                              "Phone",
                              "Timezone",
                              "Status",
                              "DM Name",
                              "Notes",
                              "Answer",
                              "FeedBack",
                              "",
                            ].map((header) => (
                              <TableCell
                                key={header}
                                sx={{
                                  width: "100%",
                                  color: "#fff",
                                  borderRight: "2px solid #fff",
                                  fontSize: "16px",
                                  fontFamily: "Playfair Display, serif",
                                  whiteSpace: "nowrap",
                                  wordBreak: "keep-all",
                                }}
                              >
                                {header}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {leadsForDate?.map((lead, idx) => {
                            const rowBackground =
                              lead?.emailFeedback[0] === "Sent"
                                ? "#e0f7e0"
                                : lead?.emailFeedback[0] === "Error Sent"
                                ? "#f8d7da"
                                : lead?.["Email Type"]
                                ? "rgb(248, 236, 215)"
                                : "transparent";
                            return (
                              <TableRow
                                key={lead.leadId}
                                sx={{
                                  background: rowBackground,
                                }}
                              >
                                <TableCell sx={cellStyle}>
                                  {lead?.CompanyName}
                                </TableCell>
                                <TableCell sx={cellStyle}>
                                  {lead?.showName}
                                </TableCell>
                                <TableCell sx={cellStyle}>
                                  {lead?.city || (
                                    <>
                                      <GoDash style={{ fontSize: "20px" }} />
                                      <GoDash style={{ fontSize: "20px" }} />
                                    </>
                                  )}
                                </TableCell>
                                <TableCell sx={cellStyle}>
                                  {lead?.phone}
                                </TableCell>
                                <TableCell sx={cellStyle}>
                                  {lead?.timezone}
                                </TableCell>
                                <TableCell sx={cellStyle}>
                                  {lead?.Status}
                                </TableCell>
                                <TableCell sx={cellStyle}>
                                  {lead?.["DM Name"] || (
                                    <>
                                      <GoDash style={{ fontSize: "20px" }} />
                                      <GoDash style={{ fontSize: "20px" }} />
                                    </>
                                  )}
                                </TableCell>
                                <TableCell sx={cellStyle}>
                                  {lead?.Notes || (
                                    <>
                                      <GoDash style={{ fontSize: "20px" }} />
                                      <GoDash style={{ fontSize: "20px" }} />
                                    </>
                                  )}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderRight: "2px solid #e0e0e0",
                                    fontSize: "14px",
                                    fontWeight: 550,
                                    whiteSpace: "nowrap",
                                    wordBreak: "keep-all",
                                    padding: "7px 10px",
                                    textAlign: "center",
                                  }}
                                >
                                  <button
                                    style={{
                                      padding: "10px 20px",
                                      backgroundColor: "#4f637d",
                                      color: "#fff",
                                      border: "none",
                                      borderRadius: "5px",
                                      cursor: "pointer",
                                      fontSize: "14px",
                                    }}
                                    onClick={() => handleAnswerClick(lead)}
                                  >
                                    Answer
                                  </button>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderRight: "2px solid #e0e0e0",
                                    fontSize: "14px",
                                    fontWeight: 550,
                                    whiteSpace: "nowrap",
                                    wordBreak: "keep-all",
                                    padding: "7px 10px",
                                    textAlign: "center",
                                  }}
                                >
                                  <button
                                    style={{
                                      padding: "10px 20px",
                                      backgroundColor: "#4f637d",
                                      color: "#fff",
                                      border: "none",
                                      borderRadius: "5px",
                                      cursor:
                                        lead?.["Request Feedback"]?.length > 0
                                          ? "pointer"
                                          : "not-allowed",
                                      fontSize: "14px",
                                    }}
                                    disabled={
                                      !(lead?.["Request Feedback"]?.length > 0)
                                    }
                                    onClick={() => handleOpenFeedbackView(lead)}
                                  >
                                    Feedback
                                  </button>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderRight: "2px solid #e0e0e0",
                                    fontSize: "14px",
                                    fontWeight: 550,
                                    whiteSpace: "nowrap",
                                    wordBreak: "keep-all",
                                    padding: "7px 10px",
                                    textAlign: "center",
                                  }}
                                >
                                  {lead?.emailFeedback &&
                                  lead?.emailFeedback.length > 0 ? (
                                    <>
                                      {lead?.emailFeedback[0] === "Sent" && (
                                        <FaCheckSquare
                                          style={{
                                            color: "green",
                                            fontSize: "20px",
                                            fontWeight: "800",
                                          }}
                                        />
                                      )}
                                      {lead?.emailFeedback[0] ===
                                        "Error Sent" && (
                                        <FaExclamationTriangle
                                          style={{
                                            color: "red",
                                            fontSize: "20px",
                                            fontWeight: "800",
                                          }}
                                        />
                                      )}
                                    </>
                                  ) : (
                                    lead?.["Email Type"] && (
                                      <AiOutlineLoading3Quarters
                                        style={{
                                          color: "orange",
                                          fontSize: "20px",
                                          fontWeight: "800",
                                        }}
                                      />
                                    )
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
              );
            })}
            {/* Invalid Date Section */}
            {groupedLeadsByDate.invalidLeads.length > 0 && (
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<FcExpand />}
                  aria-controls="panel1-content"
                  id="invalid-header"
                >
                  <Typography component="div">
                    <h2>
                      {" "}
                      Leads For{" "}
                      <span className="span-liner-date">
                        Invalid Date{" "}
                      </span>{" "}
                    </h2>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer
                    component={Paper}
                    className="history-agent-table-container"
                  >
                    <Table
                      sx={{
                        maxHeight: "550px",
                        overflowY: "auto",
                        display: "block",
                      }}
                    >
                      <TableHead>
                        <TableRow
                          sx={{
                            background:
                              "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          {[
                            "Company Name",
                            "Show Name",
                            "City, Country",
                            "Phone",
                            "Timezone",
                            "Status",
                            "DM Name",
                            "Notes",
                            "Answer",
                            "FeedBack",
                            "",
                          ].map((header) => (
                            <TableCell
                              key={header}
                              sx={{
                                width: "100%",
                                color: "#fff",
                                borderRight: "2px solid #fff",
                                fontSize: "16px",
                                fontFamily: "Playfair Display, serif",
                                whiteSpace: "nowrap",
                                wordBreak: "keep-all",
                              }}
                            >
                              {header}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {groupedLeadsByDate.invalidLeads.map((lead, idx) => {
                          const rowBackground =
                            lead?.emailFeedback[0] === "Sent"
                              ? "#e0f7e0"
                              : lead?.emailFeedback[0] === "Error Sent"
                              ? "#f8d7da"
                              : lead?.["Email Type"]
                              ? "rgb(248, 236, 215)"
                              : "transparent";
                          return (
                            <TableRow
                              key={lead.leadId}
                              sx={{
                                background: rowBackground,
                              }}
                            >
                              <TableCell sx={cellStyle}>
                                {lead?.CompanyName}
                              </TableCell>
                              <TableCell sx={cellStyle}>
                                {lead?.showName}
                              </TableCell>
                              <TableCell sx={cellStyle}>
                                {lead?.city || (
                                  <>
                                    <GoDash style={{ fontSize: "20px" }} />
                                    <GoDash style={{ fontSize: "20px" }} />
                                  </>
                                )}
                              </TableCell>
                              <TableCell sx={cellStyle}>
                                {lead?.phone}
                              </TableCell>
                              <TableCell sx={cellStyle}>
                                {lead?.timezone}
                              </TableCell>
                              <TableCell sx={cellStyle}>
                                {lead?.Status}
                              </TableCell>
                              <TableCell sx={cellStyle}>
                                {lead?.["DM Name"] || (
                                  <>
                                    <GoDash style={{ fontSize: "20px" }} />
                                    <GoDash style={{ fontSize: "20px" }} />
                                  </>
                                )}
                              </TableCell>
                              <TableCell sx={cellStyle}>
                                {lead?.Notes || (
                                  <>
                                    <GoDash style={{ fontSize: "20px" }} />
                                    <GoDash style={{ fontSize: "20px" }} />
                                  </>
                                )}
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderRight: "2px solid #e0e0e0",
                                  fontSize: "14px",
                                  fontWeight: 550,
                                  whiteSpace: "nowrap",
                                  wordBreak: "keep-all",
                                  padding: "7px 10px",
                                  textAlign: "center",
                                }}
                              >
                                <button
                                  style={{
                                    padding: "10px 20px",
                                    backgroundColor: "#4f637d",
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                    fontSize: "14px",
                                  }}
                                  onClick={() => handleAnswerClick(lead)}
                                >
                                  Answer
                                </button>
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderRight: "2px solid #e0e0e0",
                                  fontSize: "14px",
                                  fontWeight: 550,
                                  whiteSpace: "nowrap",
                                  wordBreak: "keep-all",
                                  padding: "7px 10px",
                                  textAlign: "center",
                                }}
                              >
                                <button
                                  style={{
                                    padding: "10px 20px",
                                    backgroundColor: "#4f637d",
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "5px",
                                    cursor:
                                      lead?.["Request Feedback"]?.length > 0
                                        ? "pointer"
                                        : "not-allowed",
                                    fontSize: "14px",
                                  }}
                                  disabled={
                                    !(lead?.["Request Feedback"]?.length > 0)
                                  }
                                  onClick={() => handleOpenFeedbackView(lead)}
                                >
                                  Feedback
                                </button>
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderRight: "2px solid #e0e0e0",
                                  fontSize: "14px",
                                  fontWeight: 550,
                                  whiteSpace: "nowrap",
                                  wordBreak: "keep-all",
                                  padding: "7px 10px",
                                  textAlign: "center",
                                }}
                              >
                                {lead?.emailFeedback &&
                                lead?.emailFeedback.length > 0 ? (
                                  <>
                                    {lead?.emailFeedback[0] === "Sent" && (
                                      <FaCheckSquare
                                        style={{
                                          color: "green",
                                          fontSize: "20px",
                                          fontWeight: "800",
                                        }}
                                      />
                                    )}
                                    {lead?.emailFeedback[0] ===
                                      "Error Sent" && (
                                      <FaExclamationTriangle
                                        style={{
                                          color: "red",
                                          fontSize: "20px",
                                          fontWeight: "800",
                                        }}
                                      />
                                    )}
                                  </>
                                ) : (
                                  lead?.["Email Type"] && (
                                    <AiOutlineLoading3Quarters
                                      style={{
                                        color: "orange",
                                        fontSize: "20px",
                                        fontWeight: "800",
                                      }}
                                    />
                                  )
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            )}
          </div>
          <Modal
            open={OpenFeedbackViewActive}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxWidth: 1000,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: "8px",
              }}
            >
              <Typography id="modal-title" variant="h6" component="h2">
                This is the feedback modal content
              </Typography>
              <Typography id="modal-description" sx={{ mt: 2 }}></Typography>
              <button
                style={{
                  marginTop: "20px",
                  padding: "10px 20px",
                  backgroundColor: "#4f637d",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  fontSize: "14px",
                }}
                onClick={handleCloseFeedbackView}
              >
                Close
              </button>
            </Box>
          </Modal>
        </div>
      ) : (
        selectedLead && (
          <AnswerSection
            handleCloseAnswerPage={handleCloseAnswerPage}
            handleUpdateLead={handleUpdateLead}
            selectedLead={selectedLead}
            setSelectedLead={setSelectedLead}
            selectedShowDataToRenderH={selectedShowDataToRenderH}
          />
        )
      )}
    </>
  );
};

const cellStyle = {
  borderRight: "2px solid #e0e0e0",
  fontSize: "14px",
  fontWeight: 550,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  padding: "7px 10px",
};

export default HistoryLeads;
