import React, { useState, useEffect, useContext } from "react";
import "./CreateRawLead.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { UserContext } from "../../context/UserContext";
import { baseUrl } from "../../services/BaseUrl";
import axios from "axios";
import RawShowCreatePage from "./RawShowCreatePage";

const callStyle = {
  borderRight: "2px solid #e0e0e0",
  fontSize: "14px",
  fontWeight: 550,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  padding: "8px 10px",
};

const CreateRawLead = () => {
  const { token } = useContext(UserContext);
  const [rawShowsData, setRawShowsData] = useState([]);
  const [rawShowDataPageActive, setRawShowDataPageActive] = useState(false);
  const [selectedRawData, setSelectedRawData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/companies/manually-raw-shows`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setRawShowsData(response.data);
      } catch (err) {
        console.error(err.message);
      }
    };

    fetchData();
  }, [token]);

  const handleButtonClick = (rawData,id) => {
    setSelectedRawData(rawData);
    setRawShowDataPageActive(true)
  };
  const handleButtonClose = () => {
    setRawShowDataPageActive(false)
    setSelectedRawData(null);
  };

  return (
    <>
    {!rawShowDataPageActive ? (
          <div className="create-raw-Leads-contanier">
          <div className="create-raw-table-container">
            <TableContainer
              component={Paper}
              sx={{ borderRadius: "8px", overflowX: "auto", maxHeight: "82vh" }}
              className="custom-scrollbar"
            >
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      background:
                        "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
                    }}
                  >
                    {["Show Name", "Controllers"].map((header, index) => (
                      <TableCell
                        key={header}
                        sx={{
                          color: "#fff",
                          borderRight: "2px solid #fff",
                          fontSize: "16px",
                          fontFamily: '"Playfair Display", serif',
                          whiteSpace: "nowrap",
                          wordBreak: "keep-all",
                        }}
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rawShowsData.map((rawData, index) => (
                    <TableRow key={rawData._id}>
                      <TableCell sx={callStyle}>{rawData.showName}</TableCell>
                      <TableCell sx={callStyle}>
                        <button
                          style={{
                            padding: "10px 15px",
                            backgroundColor: "#4f637d",
                            color: "#fff",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                            fontSize: "14px",
                          }}
                          onClick={() => handleButtonClick(rawData,rawData._id)}
                        >
                          Toggle Lead
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
    ) :(<RawShowCreatePage handleButtonClose={handleButtonClose}  rawData={selectedRawData}/>)}
    </>
  );
};

export default CreateRawLead;
