const cityState = [
  "Jersey City, NJ",
  "Washington, DC",
  "New Haven, CT",
  "Seattle, WA",
  "Portland, ME",
  "Boise, ID",
  "Stockton, CA",
  "New York City, NY",
  "Los Angeles, CA",
  "Philadelphia, PA",
  "Cleveland, OH",
  "Duluth, MN",
  "Gary, IN",
  "Chicago Suburbs, IL",
  "Gulfport, MS",
  "Albany, GA",
  "Western MI",
  "Akron, OH",
  "Naples, FL",
  "Detroit, MI",
  "Eastern NC",
  "Tacoma, WA",
  "Huntsville, AL",
  "Western KY",
  "Southwest, VA",
  "Maryland suburbs of DC, MD",
  "Delaware, DE",
  "West Virginia",
  "Miami, FL",
  "Wyoming, WY",
  "Nebraska, NE",
  "Southern, CA",
  "Chicago, IL",
  "St. Louis, MO",
  "Syracuse, NY",
  "Wichita, KS",
  "Indianapolis, IN",
  "Cedar Rapids, IA",
  "Central, MN",
  "Central, FL",
  "Northern, IL",
  "Greensboro, NC",
  "Lafayette, LA",
  "New York, NY",
  "Concord, MA",
  "Gainesville, FL",
  "Olympia, WA",
  "Ogden, UT",
  "Daytona Beach, FL",
  "Rhode Island, RI",
  "Atlanta, GA",
  "Montana MT",
  "Orlando, FL",
  "San Jose, CA",
  "Baltimore, MD",
  "Pittsburgh, PA",
  "Springfield, MA",
  "Milwaukee, WI",
  "San Francisco, CA",
  "Springfield, MO",
  "Chattanooga, TN",
  "Santa Monica, CA",
  "Seattle Suburbs, WA",
  "Lynchburg, VA",
  "Utah",
  "Bridgeport, CT",
  "Macon, GA",
  "Ft. Smith, AR",
  "Portland, OR",
  "New Mexico, NM",
  "Rochester, MN",
  "Worcester, MA",
  "Spokane, WA",
  "Oakland, CA",
  "Cincinnati, OH",
  "Long Island, NY",
  "Lansing, MI",
  "Albany, NY",
  "Tucson, AZ",
  "Redding, CA",
  "Roanoke, VA",
  "Eugene, OR",
  "Fresno, CA",
  "West Palm Beach, FL",
  "Long Beach, CA",
  "Toledo, OH",
  "Scranton, PA",
  "Arlington, VA",
  "Columbia, MO",
  "South Bend, IN",
  "Lawton, OK",
  "Rochester, NY",
  "Warren, MI",
  "Phoenix, AZ",
  "Manchester, NH",
  "Rapid City, SD",
  "Ashland, KY",
  "Binghamton, NY",
  "Madison, WI",
  "Trenton, NJ",
  "Allentown, PA",
  "Nashville, TN",
  "Grand Rapids, MI",
  "Belleville, IL",
  "Glendale, AZ",
  "Pasadena, CA",
  "Naperville, IL",
  "Huntington, NY",
  "St. Charles, MO",
  "Mason City, IA",
  "San Mateo, CA",
  "Sedalia, MO",
  "Bakersfield, CA",
  "Tupelo, MS",
  "Charleston, WV",
  "Fargo, ND",
  "Las Vegas, NV",
  "Alexandria, VA",
  "Augusta, GA",
  "North Coast, CA",
  "Cicero, IL",
  "Sioux City, IA",
  "Orange County, CA",
  "Central, WI",
  "Buffalo, NY",
  "Harrisburg, PA",
  "Colorado Springs, CO",
  "Tampa Bay, FL",
  "Central NJ",
  "Southeastern MI",
  "Southeastern OH",
  "Hampton Roads, VA",
  "Athens, GA",
  "Central, IN",
  "Hattiesburg, MS",
  "Treasure Coast, FL",
  "Brockton, MA",
  "Reno, NV",
  "Joliet, IL",
  "Topeka, KS",
  "Salt Lake City, UT",
  "Vermont, VT",
  "Columbia, SC",
  "Richmond, VA",
  "Central Coast, CA",
  "Hawaii",
  "Flint, MI",
  "Terre Haute, IN",
  "Tampa, FL",
  "Erie, PA",

  "San Fernando Valley, CA",
  "Asheville, NC",
  "Del Rio, TX",
  "Monterey, CA",
  "Hudson Valley, NY",
  "Northern IL",
  "New Jersey, NJ",
  "Pensacola, FL",
  "Southern, NJ",
  "San Diego, CA",
  "Central, CT",
  "Tyler, TX",
  "Jacksonville, FL",
  "Southern Ontario, Canada",
  "Central, NJ",
  "Southeastern, NC",
  "Savannah, GA",
  "Kansas City, KS",
  "Westchester, NY",
  "El Paso, TX",
  "Sacramento, CA",
  "Tulsa, OK",
  "Raleigh, NC",
  "Northern, AZ",
  "Huntsville, TX",
  "Dayton, OH",
  "North TX",
  "Sarasota, FL",
  "Fort Lauderdale, FL",
  "Ft. Collins, CO",
  "Northern, NJ",
  "Charlotte, NC",
  "Southeastern, LA",
  "Northern, MI",
  "Ohio",
  "Denver, CO",
  "Western, PA",
  "Eastern, TN",
  "East Bay, CA",
  "British Columbia, Canada",
  "Eastern Ontario, Canada",
  "Nevada, NV",
  "Toronto, Ontario, Canada",
  "Anaheim, CA",
  "Hamilton, ON",
  "Greenville, SC",
  "Charleston, SC",
  "Lowell, MA",
  "Gatineau, QC",
  "Laval, QC",
  "San Bernardino, CA",
  "Malden, MA",
  "Montreal, QC",
  "Unspecified",
  "London, ON",
  "Green Bay, WI",
  "Riverside, CA",
  "Lexington, KY",
  "New Brunswick, CA",
  "Nova Scotia, CA",
  "Anguilla",
  "Antigua and Barbuda",
  "US Virgin Islands",
  "Dominica",
  "Dominican Republic",

  "British Virgin Islands",
  "Bermuda",
  "Barbados",
  "Bahamas",
  "Grenada",
  "Montserrat",
  "Turks and Caicos Islands",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Sint Maarten",
  "Manitoba, CA",
  "Saskatchewan, CA",
  "Birmingham, AL",
  "San Antonio, TX",
  "Springfield, IL",
  "Baton Rouge, LA",
  "Biloxi, MS",
  "Mobile, AL",
  "Killeen, TX",
  "Waukesha, WI",
  "Grand Island, NE",
  "Peoria, IL",
  "Shreveport, LA",
  "St. Cloud, MN",
  "Abilene, TX",
  "Aurora, IL",
  "Montgomery, AL",
  "Corpus Christi, TX",
  "Omaha, NE",
  "Oklahoma City, OK",
  "Beaumont, TX",

  "Little Rock, AR",
  "Dallas, TX",
  "Springdale, AR",
  "New Orleans, LA",
  "Austin, TX",
  "Des Moines, IA",
  "Davenport, IA",
  "Enid, OK",
  "Jackson, MS",
  "Minneapolis, MN",
  "Carbondale, IL",
  "Hutchinson, KS",
  "Ames, IA",
  "St. Paul, MN",
  "Kirksville, MO",
  "Cape Girardeau, MO",
  "Bismarck, ND",
  "Houston, TX",
  "Eau Claire, WI",
  "Jackson, TN",

  "Lubbock, TX",
  "Rockford, IL",
  "Kansas City, MO",
  "Fort Worth, TX",
  "Memphis, TN",
  "Cookeville, TN",
  "McAllen, TX",
  "Boston, MA",
  "Georgetown, DE",
  "Louisville, KY",
  "Albuquerque, NM",
  "Honolulu, HI",
  "Kailua-Kona, HI",
  "Buenos Aires, AR",
  "Rio de Janeiro, BR",
  "Sao Paulo, BR",
  "Montevideo, UY",
  "Asuncion, PY",
  "Lima, PE",
  "Santiago, CL",
  "Brasilia, BR",
  "Cordoba, AR",
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Armenia",
  "Aruba",
  "Ascension Island",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahrain",
  "Bangladesh",
  "Belarus",
  "Belgium",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Cape Verde",
  "Central African Republic",
  "Chad",
  "China",
  "Comoros",
  "Congo",
  "Congo, Democratic Republic of",
  "Croatia",
  "Curacao",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Diego Garcia",
  "Djibouti",
  "East Timor",
  "Egypt",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Guadeloupe",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "International",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "North Korea",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saudi Arabia",
  "Senegal",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "England",
  "Scotland",
  "Wales",
  "Northern Ireland",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Virgin Islands",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export default cityState;
