import React, { useState, useCallback } from "react";
import Layout from "../../components/layout/layout";
import SideBar from "../../components/SideBar/SideBar";
import MainContainer from "../../mainContainer/MainContainer";
import UploadBulkPrice from "../../components/UploadBulkPrice/UploadBulkPrice";
import CreateRawPricing from "../../components/CreateRawPricing/CreateRawPricing";
import CreatePricing from "../../components/CreatePricing/CreatePricing";

const PricingJun = () => {
 //sidebar logic ------------>
  const [currentComponent, setCurrentComponent] = useState(<CreateRawPricing />);
  const [currentPageTitle, setCurrentPageTitle] = useState("Create Raw Pricing");

   //sidebar logic ------------>
   const sidebarButtons = [
    {
      label: "Create Raw Pricing",
      component: <CreateRawPricing />,
      pageTitle: "Create Raw Pricing",
    },
    {
      label: "Create Pricing",
      component: <CreatePricing />,
      pageTitle: "Create Pricing",
    },
  ];
  
  //sidebar logic ------------>
  const handleComponentChange = useCallback((component, pageTitle) => {
    setCurrentComponent(component);
    setCurrentPageTitle(pageTitle);
  }, []);


  return (
    <Layout>
      <SideBar
        buttons={sidebarButtons}
        onComponentChange={handleComponentChange}
      />
      <MainContainer pageTitle={currentPageTitle}>
        <h2> Pricing Team (Junior) View </h2>
        {currentComponent}
      </MainContainer>
    </Layout>
  )
}

export default PricingJun