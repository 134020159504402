import React, { useContext, useEffect, useState } from "react";
import "./House.css";
import Layout from "../../components/layout/layout";
import SideBar from "../../components/SideBar/SideBar";
import MainContainer from "../../mainContainer/MainContainer";
import HouseLeadsTable from "../../components/House/HouseLeadsTable";
import { UserContext } from "../../context/UserContext";
import axios from "axios";
import { baseUrl } from "../../services/BaseUrl";

const House = () => {
  //sidebar logic ------------>
  const [currentComponent, setCurrentComponent] = useState(<HouseLeadsTable />);
  const [currentPageTitle, setCurrentPageTitle] = useState("Old Leads");

  // Get role from local storage
  const userRole = localStorage.getItem("role");
  const { token } = useContext(UserContext);

  

  //sidebar logic ------------>
  const sidebarButtons = [
    {
      label: "House Leads",
      component: <HouseLeadsTable />,
      pageTitle: "House Leads",
    },
  ];

  //sidebar logic ------------>
  const handleComponentChange = (component, pageTitle) => {
    setCurrentComponent(component);
    setCurrentPageTitle(pageTitle);
  };
  return (
    <Layout>
      <SideBar
        buttons={sidebarButtons}
        onComponentChange={handleComponentChange}
      />
      <MainContainer pageTitle={currentPageTitle}>
        <h2>{userRole === "customer support"}</h2>
        {currentComponent}
      </MainContainer>
    </Layout>
  );
};

export default House;
