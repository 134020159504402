import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import "./OldLeadsTable.css";
import axios from "axios";
import { baseUrl } from "../../services/BaseUrl";
import { UserContext } from "../../context/UserContext";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import { GoDash } from "react-icons/go";

const callStyle = {
  borderRight: "2px solid #e0e0e0",
  fontSize: "14px",
  fontWeight: 550,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  padding: "8px 10px",
};

const OldLeadsTable = () => {
  const { token } = useContext(UserContext);
  const [agentClosers, setAgentClosers] = useState([]);
  const [existingLeads, setExistingLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [selectedShift, setSelectedShift] = useState("");

  const handleShiftChange = (event) => {
    setSelectedShift(event.target.value);
  };

  const fetchOldLeads = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/customer-support/get-old-leads`,
        { shift: selectedShift },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setExistingLeads(response.data.leads);
    } catch (error) {
      console.error("Error fetching old leads:", error);
    }
  };

  useEffect(() => {
    if (selectedShift) {
      fetchOldLeads();
    }
  }, [selectedShift]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const currentLeads = existingLeads.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  //   if (loading) {
  //     return <LoadingComponent />;
  //   }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="existing-leads-container">
      <FormControl
        sx={{ marginBottom: 2, width: "400px", marginTop: 4 }}
        className="shift-select"
      >
        <InputLabel>Select Shift</InputLabel>
        <Select value={selectedShift} onChange={handleShiftChange}>
          <MenuItem value="us">US Shift</MenuItem>
          <MenuItem value="uk">UK Shift</MenuItem>
        </Select>
      </FormControl>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                background: "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
              }}
            >
              {[
                "Lead Name",
                "Show Name",
                "Timezone",
                "Phone",
                "Email",
                "Status",
                "DM Name",
                "Agent Name",
              ].map((header, index) => (
                <TableCell
                  key={header}
                  sx={{
                    color: "#fff",
                    borderRight: "2px solid #fff",
                    fontSize: "16px",
                    fontFamily: '"Playfair Display", serif',
                    whiteSpace: "nowrap",
                    wordBreak: "keep-all",
                    textAlign: index === 5 || index === 6 ? "center" : "left",
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentLeads.map((lead, index) => (
              <TableRow key={lead._id}>
                <TableCell sx={callStyle}>
                  {lead.Lead || (
                    <>
                      <GoDash style={{ fontSize: "20px" }} />
                      <GoDash style={{ fontSize: "20px" }} />
                    </>
                  )}
                </TableCell>
                <TableCell sx={callStyle}>
                  {lead.Show || (
                    <>
                      <GoDash style={{ fontSize: "20px" }} />
                      <GoDash style={{ fontSize: "20px" }} />
                    </>
                  )}
                </TableCell>
                <TableCell sx={callStyle}>
                  {lead.Timezone || (
                    <>
                      <GoDash style={{ fontSize: "20px" }} />
                      <GoDash style={{ fontSize: "20px" }} />
                    </>
                  )}
                </TableCell>
                <TableCell sx={callStyle}>
                  {lead.Phone || (
                    <>
                      <GoDash style={{ fontSize: "20px" }} />
                      <GoDash style={{ fontSize: "20px" }} />
                    </>
                  )}
                </TableCell>
                <TableCell sx={callStyle}>
                  {lead.Email || (
                    <>
                      <GoDash style={{ fontSize: "20px" }} />
                      <GoDash style={{ fontSize: "20px" }} />
                    </>
                  )}
                </TableCell>
                <TableCell sx={callStyle}>
                  {lead.Status || (
                    <>
                      <GoDash style={{ fontSize: "20px" }} />
                      <GoDash style={{ fontSize: "20px" }} />
                    </>
                  )}
                </TableCell>
                <TableCell sx={callStyle}>
                  {lead["DM Name"] || (
                    <>
                      <GoDash style={{ fontSize: "20px" }} />
                      <GoDash style={{ fontSize: "20px" }} />
                    </>
                  )}
                </TableCell>
                <TableCell sx={callStyle}>
                  {lead["agentName"] || (
                    <>
                      <GoDash style={{ fontSize: "20px" }} />
                      <GoDash style={{ fontSize: "20px" }} />
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{
          backgroundColor: "#fff",
          borderTop: "1px solid #e0e0e0",
        }}
        rowsPerPageOptions={[8, 10]}
        component="div"
        count={existingLeads.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default OldLeadsTable;
