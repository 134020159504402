import React, { useState, useEffect, useContext, useCallback } from "react";
import "./LocatedShows.css";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import { baseUrl } from "../../services/BaseUrl";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import { GoDash } from "react-icons/go";
import { FaArrowLeftLong } from "react-icons/fa6";

const LocatedShows = () => {
  const { token } = useContext(UserContext);
  const [locatedShows, setLocatedShows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchLocatedShows = useCallback(async () => {
    if (!token) return;

    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(
        `${baseUrl}/api/locator/located-shows-data`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLocatedShows(response.data.data);
    } catch (err) {
      setError("Error fetching Located Shows");
      console.error("Error fetching Located Shows", err);
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchLocatedShows();
  }, [fetchLocatedShows]);

  if (loading) return <LoadingComponent />;

  if (error) return <div>{error}</div>;

  return (
    <div className="Located-shows-container">
      <div className="Located-shows-contain">
        {locatedShows?.length === 0 ? (
          <div className="Located-show-box">
            <p>No shows are currently located. Please try again later.</p>
          </div>
        ) : (
          locatedShows?.map((show, index) => (
            <div className="Located-show-box" key={index}>
              <div
                style={{
                  background: " #34495e",
                  color: "white",
                  padding: "15px",
                  fontWeight: "bold",
                  borderRadius: "8px 8px 0 0",
                }}
              >
                {show?.showName}
              </div>
              <div className="located-show-card-info">
                <p
                  style={{ borderBottom: "1px solid #37373F", padding: "10px" }}
                >
                  <strong>City:</strong> {show?.city}
                </p>
                <p
                  style={{ borderBottom: "1px solid #37373F", padding: "10px" }}
                >
                  <strong>Start Date:</strong> {show?.startDate}
                </p>
                <p
                  style={{ borderBottom: "1px solid #37373F", padding: "10px" }}
                >
                  <strong>End Date:</strong> {show?.endDate}
                </p>
                <p
                  style={{ borderBottom: "1px solid #37373F", padding: "10px" }}
                >
                  <strong>Venue:</strong> {show?.venue}
                </p>
              </div>
              <div>
                <p
                  style={{
                    padding: "12px",
                    background: "rgb(226, 229, 231)",
                    borderBottom: "1px solid #37373F",
                  }}
                >
                  <strong>Similar Shows:</strong>
                </p>
                {show?.similarShows?.map((similarShow, index) => (
                  <ul key={index}>
                    <li
                      style={{
                        borderBottom: "1px solid #37373F",
                        padding: "10px 20px",
                      }}
                    >
                      <p>
                        - {similarShow?.showName}, {similarShow?.location}
                      </p>
                    </li>
                  </ul>
                ))}
              </div>
              <div>
                <p
                  style={{
                    padding: "12px",
                    background: "rgb(226, 229, 231)",
                    borderBottom: "1px solid #37373F",
                  }}
                >
                  <strong>Best Hotels:</strong>
                </p>
                {Array.isArray(show?.bestHotels) &&
                show.bestHotels.length > 0 ? (
                  show.bestHotels.map((bestHotel, index) => (
                    <ul key={index}>
                      <li
                        style={{
                          borderBottom: "1px solid #37373F",
                          padding: "10px 20px",
                        }}
                      >
                        <p>
                          - {bestHotel?.name} {" - Stars: "} {bestHotel?.stars}
                        </p>
                      </li>
                    </ul>
                  ))
                ) : (
                  <p style={{ padding: "10px 20px", color: "gray" }}>
                    No best hotels available
                  </p>
                )}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default LocatedShows;
