import React, { useEffect, useState, useMemo, useContext } from "react";
import { FormControl, Select, MenuItem } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Modal,
  TextField,
  Button,
} from "@mui/material";
import "./UsersPanel.css";
import { GoDotFill } from "react-icons/go";
import { UserContext } from "../../context/UserContext";
import { GoDash } from "react-icons/go";
import { FaEdit } from "react-icons/fa";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";
import axios from "axios";
import { baseUrl } from "../../services/BaseUrl";
import SetUserPasswordModal from "./SetUserPasswordModal";

const selectTimeZoneOptions = [
  { label: "UK", value: "uk" },
  { label: "US", value: "us" },
];
const selectStateOptions = [
  { label: "Online", value: true },
  { label: "Offline", value: false },
];
const selectRoleOptions = [
  { label: "super user", value: "super user" },
  { label: "agent", value: "agent" },
  { label: "agent closer", value: "agent closer" },
  { label: "team leader", value: "team leader" },
  { label: "sales manager", value: "sales manager" },
  { label: "sales support", value: "sales support" },
  { label: "sales support manager", value: "sales support manager" },
  { label: "email team manager", value: "email team manager" },
  { label: "customer support", value: "customer support" },
  { label: "locator", value: "locator" },
  { label: "leads junior", value: "leads junior" },
  { label: "leads senior", value: "leads senior" },
  { label: "email team", value: "email team" },
  { label: "leads manager", value: "leads manager" },
  { label: "pricing manager", value: "pricing manager" },
  { label: "pricing junior", value: "pricing junior" },
  { label: "pricing senior", value: "pricing senior" },
  { label: "lead generator", value: "lead generator" },
  { label: "locator manager", value: "locator manager" },
  { label: "operations manager", value: "operations manager" },
];

// Modal styling
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "white",
  borderRadius: 8,
  padding: 4,
  boxShadow: 24,
};

const UsersPanel = () => {
  const [UserData, setUserData] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const { token } = useContext(UserContext);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [openEditModel, setOpenEditModel] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    role: "",
    timezone: "",
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/super/get-all-users`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }

        const data = await response.json();
        setUserData(data.users);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [token]);

  useEffect(() => {
    const onlineUsersList = UserData.filter((user) => user.loggedIn === true);

    const roleCount = onlineUsersList.reduce((acc, user) => {
      acc[user.role] = (acc[user.role] || 0) + 1;
      return acc;
    }, {});

    setOnlineUsers(roleCount);
  }, [UserData]);

  useEffect(() => {
    const filtered = UserData.filter((user) => {
      const matchesTimeZone = selectedTimeZone
        ? user.timezone === selectedTimeZone
        : true;
      const matchesState = selectedState
        ? user.loggedIn === selectedState
        : true;

      const matchesRole = selectedRole ? user.role === selectedRole : true;

      return matchesTimeZone && matchesState && matchesRole;
    });
    setFilteredUsers(filtered);
  }, [selectedTimeZone, selectedState, selectedRole, UserData]);

  const handleRoleChange = (event) => setSelectedRole(event.target.value);
  const handleTimeZoneChange = (event) =>
    setSelectedTimeZone(event.target.value);
  const handleStateChange = (event) => setSelectedState(event.target.value);

  const roleCountArray = useMemo(
    () =>
      Object.entries(onlineUsers).map(([role, count]) => ({
        role,
        count,
      })),
    [onlineUsers]
  );

  const handleAccessClick = (userId) => {
    setSelectedUser(userId);
    setOpenPopup(true); // Open popup when the icon is clicked
  };

  const handleGrantAccess = async () => {
    try {
      await axios.put(
        `${baseUrl}/api/super/grant-access/${selectedUser}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`, // Send the token in the Authorization header
          },
        }
      );
      setFilteredUsers((prevData) =>
        prevData.map((user) =>
          user._id === selectedUser ? { ...user, accessAllowed: true } : user
        )
      );
      // After successful request, close the popup and refresh or update state
      setOpenPopup(false);
      alert("Access granted");
      // Optionally, refresh or update the state to reflect changes in the table
    } catch (error) {
      console.error("Error granting access", error);
    }
  };

  const handleRevokeAccess = async () => {
    try {
      await axios.put(
        `${baseUrl}/api/super/revoke-access/${selectedUser}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`, // Send the token in the Authorization header
          },
        }
      );
      // After successful request, close the popup and refresh or update state
      setFilteredUsers((prevData) =>
        prevData.map((user) =>
          user._id === selectedUser ? { ...user, accessAllowed: false } : user
        )
      );
      setOpenPopup(false);
      alert("Access revoked");
      // Optionally, refresh or update the state to reflect changes in the table
    } catch (error) {
      console.error("Error revoking access", error);
    }
  };

  const handleSetPasswordOpenModal = (userId, email) => {
    setSelectedUser(userId);
    setSelectedEmail(email);
    setPasswordModalOpen(true);
  };

  const handleCloseModal = () => {
    setPasswordModalOpen(false);
  };

  const handleEditView = (user) => {
    setSelectedUser(user);
    setFormData({
      name: user.name,
      email: user.email,
      role: user.role,
      timezone: user.timezone,
    });
    setOpenEditModel(true);
  };
  const handleEditViewClose = () => {
    setOpenEditModel(false);
  };
  // Handle form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedUser) {
      console.error("No selected user for update.");
      return;
    }
    const apiUrl = `${baseUrl}/api/super/update-user-data/${selectedUser._id}`;
    try {
      const response = await axios.put(apiUrl, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("User updated successfully");
      handleEditViewClose();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="user-panel-contanier">
      <h2 className="user-panel-header">Online Users Count</h2>
      <Grid container spacing={1} className="user-panel-counter">
        {roleCountArray.map((user, index) => (
          <Grid item xs={12} sm={4} md={3} lg={2} key={index}>
            <Box
              sx={{
                padding: 0.5,
                textAlign: "center",
                border: "1px solid #e0e0e0",
                borderRadius: 1,
              }}
            >
              <Typography variant="subtitle1" sx={{ lineHeight: 1.4 }}>
                {user.role}
              </Typography>
              <Typography variant="h6" sx={{ lineHeight: 1.4 }}>
                {user.count}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      <div className="user-panel-filters">
        <div className="select-dropdown-control">
          <FormControl fullWidth>
            <Select
              id="timezone-selected"
              value={selectedTimeZone}
              onChange={handleTimeZoneChange}
              displayEmpty
              sx={{
                "& .MuiSelect-select": {
                  padding: "12px 20px",
                  border: "1px solid #a093b8",
                  borderRadius: "8px",
                  backgroundColor: "#fff",
                  color: selectedState === "" ? "#757575" : "black",
                  letterSpacing: "1px",
                  fontSize: "16px",
                  fontWeight: selectedState === "" ? 550 : 500,
                },
                "&:focus .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#3b364c",
                },
                "& .MuiSelect-icon": {
                  color: "#a093b8",
                },
              }}
            >
              <MenuItem value="">
                <p style={{ fontFamily: "Playfair Display, serif" }}>
                  Select TimeZone
                </p>
              </MenuItem>
              {selectTimeZoneOptions.map((optionItem) => (
                <MenuItem
                  key={optionItem.value}
                  value={optionItem.value}
                  sx={{
                    "&:hover": {
                      color: "#ffffff",
                      backgroundColor: "#3b364c !important",
                    },
                    "&.Mui-selected": {
                      color: "#ffffff",
                      backgroundColor: "#3b364c !important",
                    },
                  }}
                >
                  <p style={{ fontFamily: "Playfair Display, serif" }}>
                    {optionItem.label}
                  </p>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="select-dropdown-control">
          <FormControl fullWidth>
            <Select
              id="state-select"
              value={selectedState}
              onChange={handleStateChange}
              displayEmpty
              sx={{
                "& .MuiSelect-select": {
                  padding: "12px 20px",
                  border: "1px solid #a093b8",
                  borderRadius: "8px",
                  backgroundColor: "#fff",
                  color: selectedState === "" ? "#757575" : "black",
                  letterSpacing: "1px",
                  fontSize: "16px",
                  fontWeight: selectedState === "" ? 550 : 500,
                },
                "&:focus .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#3b364c",
                },
                "& .MuiSelect-icon": {
                  color: "#a093b8",
                },
              }}
            >
              <MenuItem value="">
                <p style={{ fontFamily: "Playfair Display, serif" }}>
                  Select State
                </p>
              </MenuItem>
              {selectStateOptions.map((optionItem) => (
                <MenuItem
                  key={optionItem.value}
                  value={optionItem.value}
                  sx={{
                    "&:hover": {
                      color: "#ffffff",
                      backgroundColor: "#3b364c !important",
                    },
                    "&.Mui-selected": {
                      color: "#ffffff",
                      backgroundColor: "#3b364c !important",
                    },
                  }}
                >
                  <p style={{ fontFamily: "Playfair Display, serif" }}>
                    {optionItem.label}
                  </p>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="select-dropdown-control">
          <FormControl fullWidth>
            <Select
              id="role-select"
              value={selectedRole}
              onChange={handleRoleChange}
              displayEmpty
              sx={{
                "& .MuiSelect-select": {
                  padding: "12px 20px",
                  border: "1px solid #a093b8",
                  borderRadius: "8px",
                  backgroundColor: "#fff",
                  color: selectedRole === "" ? "#757575" : "black",
                  letterSpacing: "1px",
                  fontSize: "16px",
                  fontWeight: selectedRole === "" ? 550 : 500,
                },
                "&:focus .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#3b364c",
                },
                "& .MuiSelect-icon": {
                  color: "#a093b8",
                },
              }}
            >
              <MenuItem value="">
                <p style={{ fontFamily: "Playfair Display, serif" }}>
                  Select Role
                </p>
              </MenuItem>
              {selectRoleOptions.map((optionItem) => (
                <MenuItem
                  key={optionItem.value}
                  value={optionItem.value}
                  sx={{
                    "&:hover": {
                      color: "#ffffff",
                      backgroundColor: "#3b364c !important",
                    },
                    "&.Mui-selected": {
                      color: "#ffffff",
                      backgroundColor: "#3b364c !important",
                    },
                  }}
                >
                  <p style={{ fontFamily: "Playfair Display, serif" }}>
                    {optionItem.label}
                  </p>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <div
        className="user-panel-table-contanier"
        style={{ maxHeight: "59vh", overflowY: "auto", borderRadius: "8px" }}
      >
        <TableContainer
          component={Paper}
          sx={{ borderRadius: "8px" }}
          className="custom-scrollbar"
        >
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  background:
                    "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
                  color: "#fff",
                }}
              >
                <TableCell
                  sx={{
                    color: "#fff",
                    borderRight: "2px solid #fff",
                    fontSize: "16px",
                    fontFamily: '"Playfair Display", serif',
                  }}
                >
                  Username
                </TableCell>
                <TableCell
                  sx={{
                    color: "#fff",
                    borderRight: "2px solid #fff",
                    fontSize: "16px",
                    fontFamily: '"Playfair Display", serif',
                  }}
                >
                  Email
                </TableCell>
                <TableCell
                  sx={{
                    color: "#fff",
                    borderRight: "2px solid #fff",
                    fontSize: "16px",
                    fontFamily: '"Playfair Display", serif',
                    textAlign: "center",
                  }}
                >
                  Role
                </TableCell>
                <TableCell
                  sx={{
                    color: "#fff",
                    borderRight: "2px solid #fff",
                    fontSize: "16px",
                    fontFamily: '"Playfair Display", serif',
                    textAlign: "center",
                  }}
                >
                  Logged In
                </TableCell>
                <TableCell
                  sx={{
                    color: "#fff",
                    borderRight: "2px solid #fff",
                    fontSize: "16px",
                    fontFamily: '"Playfair Display", serif',
                    textAlign: "center",
                  }}
                >
                  Timezone
                </TableCell>
                <TableCell
                  sx={{
                    color: "#fff",
                    borderRight: "2px solid #fff",
                    fontSize: "16px",
                    fontFamily: '"Playfair Display", serif',
                    textAlign: "center",
                  }}
                >
                  Last Seen
                </TableCell>
                <TableCell
                  sx={{
                    color: "#fff",
                    borderRight: "2px solid #fff",
                    fontSize: "16px",
                    fontFamily: '"Playfair Display", serif',
                  }}
                >
                  Team
                </TableCell>

                <TableCell
                  sx={{
                    color: "#fff",
                    borderRight: "2px solid #fff",
                    fontSize: "16px",
                    fontFamily: '"Playfair Display", serif',
                  }}
                >
                  Edit
                </TableCell>
                <TableCell
                  sx={{
                    color: "#fff",
                    borderRight: "2px solid #fff",
                    fontSize: "16px",
                    fontFamily: '"Playfair Display", serif',
                  }}
                >
                  Access
                </TableCell>
                <TableCell
                  sx={{
                    color: "#fff",
                    borderRight: "2px solid #fff",
                    fontSize: "16px",
                    fontFamily: '"Playfair Display", serif',
                  }}
                >
                  Set Password
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      borderRight: "2px solid #e0e0e0",
                      fontSize: "14px",
                      fontWeight: 550,
                    }}
                  >
                    {row.name}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "2px solid #e0e0e0",
                      fontSize: "14px",
                      fontWeight: 550,
                    }}
                  >
                    {row.email}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "2px solid #e0e0e0",
                      fontSize: "14px",
                      fontWeight: 550,
                      textAlign: "center",
                    }}
                  >
                    {row.role}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "2px solid #e0e0e0",
                      fontSize: "14px",
                      fontWeight: 550,
                      textAlign: "center",
                    }}
                  >
                    {row.loggedIn === true ? (
                      <GoDotFill style={{ color: "green", fontSize: "22px" }} />
                    ) : (
                      <GoDotFill style={{ color: "red", fontSize: "22px" }} />
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "2px solid #e0e0e0",
                      fontSize: "14px",
                      fontWeight: 550,
                      textAlign: "center",
                    }}
                  >
                    {row.timezone || <GoDash style={{ fontSize: "20px" }} />}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "2px solid #e0e0e0",
                      fontSize: "14px",
                      fontWeight: 550,
                      textAlign: "center",
                    }}
                  >
                    {row.lastSeen || (
                      <>
                        <GoDash style={{ fontSize: "20px" }} />
                        <GoDash style={{ fontSize: "20px" }} />
                      </>
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "2px solid #e0e0e0",
                      fontSize: "14px",
                      fontWeight: 550,
                      textAlign: "center",
                    }}
                  >
                    <button
                      // onClick={() => console.log(row.id)}
                      style={{
                        padding: "10px 20px",
                        backgroundColor: "#4f637d",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        fontSize: "14px",
                      }}
                    >
                      <p> View Team</p>
                    </button>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "2px solid #e0e0e0",
                      fontSize: "18px",
                      fontWeight: 550,
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => handleEditView(row)}
                  >
                    <FaEdit />
                  </TableCell>
                  <TableCell sx={{ color: "wheat", textAlign: "center" }}>
                    {row.accessAllowed ? (
                      <CheckCircleIcon
                        sx={{ color: "green" }}
                        onClick={() => handleAccessClick(row._id)}
                      />
                    ) : (
                      <CancelIcon
                        sx={{ color: "red" }}
                        onClick={() => handleAccessClick(row._id)}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <button
                      style={{
                        padding: "10px 20px",
                        backgroundColor: "#4f637d",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        fontSize: "14px",
                      }}
                      onClick={() =>
                        handleSetPasswordOpenModal(row._id, row.email)
                      }
                    >
                      Set Password
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {passwordModalOpen && (
          <SetUserPasswordModal
            open={passwordModalOpen}
            handleClose={handleCloseModal}
            userId={selectedUser}
            userEmail={selectedEmail}
          />
        )}
        <Dialog open={openPopup} onClose={() => setOpenPopup(false)}>
          <DialogTitle>Change Access</DialogTitle>
          <DialogContent>
            <p>Do you want to allow or revoke access for this user?</p>
          </DialogContent>
          <DialogActions>
            <button
              onClick={handleGrantAccess}
              style={{
                padding: "10px 20px",
                backgroundColor: "#4f637d",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                fontSize: "14px",
              }}
            >
              Allow Access
            </button>
            <button
              onClick={handleRevokeAccess}
              style={{
                padding: "10px 20px",
                backgroundColor: "#4f637d",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                fontSize: "14px",
              }}
            >
              Revoke Access
            </button>
          </DialogActions>
        </Dialog>
      </div>
      {/* Modal for Editing */}
      <Modal open={openEditModel}>
        <Box sx={style}>
          <Typography variant="h6" component="h2" sx={{ marginBottom: 2 }}>
            Edit User Information
          </Typography>

          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {/* Name Input */}
              <Grid item xs={12}>
                <TextField
                  label="Name"
                  variant="outlined"
                  fullWidth
                  value={formData.name}
                  onChange={handleInputChange}
                  name="name"
                  required
                />
              </Grid>

              {/* Email Input */}
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  name="email"
                  required
                />
              </Grid>

              {/* Role Input */}
              <Grid item xs={12}>
                <TextField
                  label="Role"
                  variant="outlined"
                  fullWidth
                  value={formData.role}
                  onChange={handleInputChange}
                  name="role"
                  required
                />
              </Grid>

              {/* Timezone Input */}
              <Grid item xs={12}>
                <TextField
                  label="Timezone"
                  variant="outlined"
                  fullWidth
                  value={formData.timezone}
                  onChange={handleInputChange}
                  name="timezone"
                  required
                />
              </Grid>

              {/* Submit Button */}
              <Grid item xs={6}>
                <Button type="submit" variant="contained" fullWidth>
                  Save
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="button"
                  variant="contained"
                  fullWidth
                  onClick={handleEditViewClose}
                  sx={{
                    backgroundColor: "red", // Set the button background to red
                    "&:hover": {
                      backgroundColor: "#d32f2f", // Set the hover color to a lighter red (you can adjust this as needed)
                    },
                  }}
                >
                  cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default React.memo(UsersPanel);
