import React, { useContext, useEffect, useState } from "react";
import "./LoginTimer.css";
import { UserContext } from "../../context/UserContext";
import { baseUrl } from "../../services/BaseUrl";
import axios from "axios";
import { Backdrop, Box, Button, Modal, Typography } from "@mui/material";

const LoginTimer = () => {
    const { token } = useContext(UserContext);
    const [timer, setTimer] = useState(0);
    const [isTimerRunning, setIsTimerRunning] = useState(false);
    const [breakTimer, setBreakTimer] = useState(0);
    const [isBreakTimerRunning, setIsBreakTimerRunning] = useState(false);
    const [isPopupVisible, setIsPopupVisible] = useState(false);


    useEffect(() => {
        // Initialize login timer
        const storedStartTime = localStorage.getItem("startTime");
        if (storedStartTime) {
            const elapsedTime = Date.now() - storedStartTime;
            setTimer(elapsedTime);
            setIsTimerRunning(true);
        } else {
            setIsTimerRunning(true);
            localStorage.setItem("startTime", Date.now());
        }

        // Initialize break timer
        const storedBreakStartTime = localStorage.getItem("breakStartTime");
        const storedBreakTimer = localStorage.getItem("breakTimer");
        if (storedBreakStartTime) {
            const elapsedBreakTime = Date.now() - storedBreakStartTime;
            setBreakTimer(
                (storedBreakTimer ? +storedBreakTimer : 0) + elapsedBreakTime
            );
            setIsBreakTimerRunning(true);
            setIsPopupVisible(true);
        } else if (storedBreakTimer) {
            setBreakTimer(+storedBreakTimer);
        }

        const interval = setInterval(() => {
            if (isTimerRunning) {
                setTimer((prevTime) => prevTime + 1000);
            }
            if (isBreakTimerRunning) {
                setBreakTimer((prevBreakTime) => prevBreakTime + 1000);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [isTimerRunning, isBreakTimerRunning]);

    const formatTime = (time) => {
        const hours = Math.floor(time / 3600000);
        const minutes = Math.floor((time % 3600000) / 60000);
        const seconds = Math.floor((time % 60000) / 1000);
        return `${hours}:${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds
            }`;
    };

    const startBreak = () => {
        if (!isBreakTimerRunning) {
            localStorage.setItem("breakStartTime", Date.now());
            setIsBreakTimerRunning(true);
            setIsPopupVisible(true);
            axios
                .post(
                    `${baseUrl}/api/users/start-break`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then((response) => {
                    console.log("Break started:", response.data);
                })
                .catch((error) => {
                    console.error("Error starting break:", error);
                });
        }
    };

    const endBreak = () => {
        if (isBreakTimerRunning) {
            const storedBreakStartTime = localStorage.getItem("breakStartTime");
            if (storedBreakStartTime) {
                const elapsedBreakTime = Date.now() - storedBreakStartTime;
                const totalBreakTime = breakTimer;
                setBreakTimer(totalBreakTime);
                localStorage.setItem("breakTimer", totalBreakTime);
            }
            localStorage.removeItem("breakStartTime");
            axios
                .post(
                    `${baseUrl}/api/users/end-break`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${token}`, // Send the token in the headers
                        },
                    }
                )
                .then((response) => {
                    console.log("Break ended:", response.data); // Handle success
                })
                .catch((error) => {
                    console.error("Error ending break:", error); // Handle error
                });
            setIsBreakTimerRunning(false);
            setIsPopupVisible(false); // Hide popup after break ends
        }
    };

    useEffect(() => {
        let idleTimeout;

        const resetIdleTimer = () => {
            clearTimeout(idleTimeout);
            idleTimeout = setTimeout(() => {
                // Trigger break if idle for 10 minute
                startBreak();
            }, 600000); // 10 minute of inactivity
        };

        // Attach event listeners for user actions
        const events = ["mousemove", "keydown", "click"];
        events.forEach((event) => window.addEventListener(event, resetIdleTimer));

        // Start the idle timer on first render
        resetIdleTimer();

        // Cleanup event listeners on component unmount
        return () => {
            clearTimeout(idleTimeout);
            events.forEach((event) =>
                window.removeEventListener(event, resetIdleTimer)
            );
        };
    }, []);

    return (
        <div className="login-time-contanier">
            {/* <p className="lettel-bar-text" style={{ fontWeight: "800px" }}>Login Time <span className="span-lettel-bar">00:00:00</span></p>
            <button style={{ background: "linear-gradient(45deg,rgb(206, 196, 196), #8b5a5a, #6c4c6e, #333)", color: "#fff", padding: "8px 16px", borderRadius: "6px" }}>
                Start Breake
            </button> */}

            <p className="lettel-bar-text" style={{ fontWeight: "800px" }}>
                Login Time <span className="span-lettel-bar">{formatTime(timer)}</span>
            </p>
            <button
                style={{
                    background:
                        "linear-gradient(45deg,rgb(206, 196, 196), #8b5a5a, #6c4c6e, #333)",
                    color: "#fff",
                    padding: "7px 18px",
                    borderRadius: "5px",
                }}
                onClick={startBreak}
                disabled={isBreakTimerRunning}
            >
                Start Break
            </button>
            <Modal
                open={isPopupVisible}
                onClose={() => { }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                    onClick: (e) => e.stopPropagation(), // Disable backdrop click
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        p: 4,
                        textAlign: "center",
                    }}
                >
                    <Typography variant="h6">Break Time</Typography>
                    <p className="timer">{formatTime(breakTimer)}</p>
                    <button
                        onClick={endBreak}
                        style={{
                            marginTop: "20px",
                            background:
                                "linear-gradient(45deg,rgb(206, 196, 196), #8b5a5a, #6c4c6e, #333)",
                            color: "#fff",
                            padding: "16px 22px",
                            borderRadius: "6px",
                        }}
                    >
                        End Break
                    </button>
                </Box>
            </Modal>
        </div>
    )
}

export default LoginTimer