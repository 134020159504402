import React, { useState, useEffect } from "react";
import axios from "axios";
import "./UserReport.css";
import { FaArrowLeftLong } from "react-icons/fa6";
import { baseUrl } from "../../services/BaseUrl";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const callStyle = {
  borderRight: "2px solid #e0e0e0",
  fontSize: "13px",
  fontWeight: 550,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  padding: "6px 8px",
  textAlign: "center",
};

const UserReport = ({
  handleCloseReportPage,
  member,
  token,
  LoadingComponent,
}) => {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserReport = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/sales-manager/get-agent-reports/${member._id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const sortedData = response.data.result.sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        });

        setUserData(sortedData);
        setLoading(false);
      } catch (err) {
        setError("Failed to load user data.");
        setLoading(false);
      }
    };

    if (member && member._id) {
      fetchUserReport();
    }
  }, [member, token]);

  if (loading) {
    return <LoadingComponent />;
  }

  if (error) {
    return (
      <div className="user-report-section">
        <div className="answer-header-container">
          <FaArrowLeftLong
            onClick={handleCloseReportPage}
            style={{ cursor: "pointer", fontSize: 22 }}
          />
          <h3 style={{ letterSpacing: "1.2px", textTransform: "capitalize" }}>
            Report Page
          </h3>
        </div>
        <h2>{error}</h2>
      </div>
    );
  }

  return (
    <div className="user-report-section">
      <div className="answer-header-container">
        <FaArrowLeftLong
          onClick={handleCloseReportPage}
          style={{ cursor: "pointer", fontSize: 22 }}
        />
        <h3 style={{letterSpacing:"1.2px", textTransform:"capitalize"}}>Report Page</h3>
      </div>
      <div className="user-report-info">
        <p
          style={{
            textTransform: "capitalize",
            fontSize: "18px",
            color: "#e2b6ea",
            fontWeight: "600px",
          }}
        >
          Name :
          <span
            style={{
              textTransform: "capitalize",
              letterSpacing: "1.5px",
              color: "#fff",
              marginLeft: "6px",
            }}
          >
            {member.name}
          </span>
        </p>
        <p
          style={{
            textTransform: "capitalize",
            fontSize: "18px",
            color: "#e2b6ea",
            fontWeight: "600px",
          }}
        >
          Role :
          <span
            style={{
              textTransform: "capitalize",
              letterSpacing: "1.5px",
              color: "#fff",
              marginLeft: "6px",
            }}
          >
            {member.role}
          </span>
        </p>
      </div>
      <div className="user-report-table-container">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  background:
                    "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
                }}
              >
                {[
                  "Date",
                  "User Name",
                  "Login Time",
                  "Logout Time",
                  "Break Time",
                  "Total Hours",
                  "Total Leads",
                  "New",
                  "VM",
                  "Redial",
                  "Callback",
                  "GK Blocked",
                  "DM Rejected",
                  "Do Not Call",
                  "Email Only",
                  "Email Follow-up",
                  "Waiting Approval",
                  "Prospect",
                  "Flagged",
                  "Wrong Number",
                  "Closed Won",
                  "Closed Lost",
                ].map((header, index) => (
                  <TableCell
                    key={header}
                    sx={{
                      color: "#fff",
                      borderRight: "2px solid #fff",
                      fontSize: "15px",
                      fontFamily: '"Playfair Display", serif',
                      whiteSpace: "nowrap",
                      wordBreak: "keep-all",
                      textAlign: "center",
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {userData.map((user,index) => {
                const leadStatusCounts = user.leadStatusCounts || {};
                console.log("user",user);
                
                return (
                  <TableRow key={index}>
                    <TableCell sx={callStyle}>{user.date}</TableCell>
                    <TableCell sx={callStyle}>{user.userName}</TableCell>
                    <TableCell sx={callStyle}>
                      {new Date(user.loggedinTime).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {new Date(user.loggedoutTime).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })}
                    </TableCell>
                    <TableCell
                      sx={callStyle}
                    >
                      {user.breakTime}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderRight: "2px solid #e0e0e0",
                        fontSize: "13px",
                        fontWeight: 800,
                        whiteSpace: "nowrap",
                        wordBreak: "keep-all",
                        padding: "6px 8px",
                        textAlign: "center",
                        background: "#565e79",
                        color: "#fff",
                      }}
                    >
                      {user.totalHours}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderRight: "2px solid #e0e0e0",
                        fontSize: "13px",
                        fontWeight: 800,
                        whiteSpace: "nowrap",
                        wordBreak: "keep-all",
                        padding: "6px 8px",
                        textAlign: "center",
                        background: "#4c657e",
                        color: "#fff",
                      }}
                    >
                      {leadStatusCounts.totalLeads || 0}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {leadStatusCounts.New || 0}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {leadStatusCounts.VM || 0}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {leadStatusCounts.Redial || 0}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {leadStatusCounts.Callback || 0}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {leadStatusCounts["GK Blocked"] || 0}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {leadStatusCounts["DM Rejected"] || 0}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {leadStatusCounts["Do Not Call"] || 0}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {leadStatusCounts["Email Only"] || 0}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {leadStatusCounts["Email Follow-up"] || 0}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {leadStatusCounts["Waiting Approval"] || 0}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {leadStatusCounts.Prospect || 0}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {leadStatusCounts.Flagged || 0}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {leadStatusCounts["Wrong Number"] || 0}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {leadStatusCounts["Closed Won"] || 0}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {leadStatusCounts["Closed Lost"] || 0}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default UserReport;
