import React, { useState, useEffect, useContext, useMemo } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Snackbar,
    Alert,
} from "@mui/material";
import "./DuplicatedLeads.css";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import axios from "axios";
import { baseUrl } from "../../services/BaseUrl";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import LoadingSection from "../LoadingComponent/LoadingSection";

const callStyle = {
    padding: 6,
    fontSize: 15,
    textAlign: "center",
    whiteSpace: "nowrap",
    wordBreak: "keep-all",
    borderRight: "2px solid #e0e0e0",
    fontWeight: "450",
};

const DuplicatedMatchedLeads = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { token } = useContext(UserContext);
    const [showLeads, setShowLeads] = useState([]);
    const [showName, setShowName] = useState("");
    const [locatorId, setLocatorId] = useState("");
    const [type, setType] = useState("");
    const [alert, setAlert] = useState({
        open: false,
        message: "",
        severity: "",
    });

    const handleCloseAgentMemberPage = () => {
        navigate("/locator");
    };

    useEffect(() => {
        if (location.state) {
            setShowLeads(location.state.showLeads);
            setShowName(location.state.show);
            setLocatorId(location.state.showId);
            setType(location.state.type);
        }
    }, [location.state]);

    const handleDeleteLead = async (leadId) => {
        try {
            // Make the DELETE request to remove the lead
            const response = await axios.delete(
                `${baseUrl}/api/locator/delete/matchedShow/${locatorId}/lead/${leadId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                setShowLeads((prevLeads) =>
                    prevLeads.filter((lead) => lead._id !== leadId)
                );
                setAlert({
                    open: true,
                    message: "Lead deleted successfully.",
                    severity: "success",
                });
            }
        } catch (error) {
            console.error("Error deleting lead:", error);
            setAlert({
                open: true,
                message: "Failed to delete lead.",
                severity: "error",
            });
        }
    };

    const handleCloseAlert = () => {
        setAlert({ ...alert, open: false });
    };

    return (
        <div className="dublicated-leads-container">
            <div className="agent-member-header-container">
                <FaArrowLeftLong
                    onClick={handleCloseAgentMemberPage}
                    style={{ cursor: "pointer", fontSize: 22, color: "#fff" }}
                />
            </div>
            <div className="agent-member-info-section">
                <h2 style={{ letterSpacing: "1.5px", textTransform: "capitalize" }}>
                    Show Name : <span>{showName}</span>
                </h2>
            </div>
            <div className="agent-member-table-section">
                <TableContainer
                    component={Paper}
                    sx={{
                        maxHeight: "70vh",
                        overflowY: "auto",
                        position: "relative",
                    }}
                >
                    <Table>
                        <TableHead>
                            <TableRow
                                sx={{
                                    background: "#8b5a5a",
                                    color: "#fff",
                                    position: "sticky",
                                    top: 0,
                                    zIndex: 1,
                                }}
                            >
                                {[
                                    "Delete",
                                    "Lead",
                                    "Phone",
                                    "Email",
                                    "Time Zone",
                                    "Status",
                                    "Closer Note",
                                    "Closing Date",
                                    "Closer Name",
                                    "Hidden",
                                ].map((header, index) => (
                                    <TableCell
                                        key={header}
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: 650,
                                            borderRight: "2px solid #fff",
                                            whiteSpace: "nowrap",
                                            wordBreak: "keep-all",
                                            padding: "8px 10px",
                                            color: "#fff",
                                            textTransform: "capitalize",
                                            letterSpacing: "1.2px",
                                            textAlign: "center",
                                        }}
                                    >
                                        {header}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {showLeads?.length > 0 ? (
                                showLeads?.map((lead, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={callStyle}>
                                            <button
                                                className="matching-linear-button"
                                                style={{ background: "red" }}
                                                onClick={() => handleDeleteLead(lead._id)}
                                            >
                                                Delete
                                            </button>
                                        </TableCell>
                                        <TableCell style={callStyle}>{lead.Lead}</TableCell>
                                        <TableCell style={callStyle}>{lead.Phone}</TableCell>
                                        <TableCell style={callStyle}>{lead.Email}</TableCell>
                                        <TableCell style={callStyle}>{lead.Timezone}</TableCell>
                                        <TableCell style={callStyle}>{lead.Status}</TableCell>
                                        <TableCell style={callStyle}></TableCell>
                                        <TableCell style={callStyle}></TableCell>
                                        <TableCell style={callStyle}></TableCell>
                                        <TableCell style={callStyle}>
                                            {lead.Hidden ? "Yes" : "No"}
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell
                                        colSpan={9}
                                        sx={{
                                            textAlign: "center",
                                            padding: "16px",
                                            fontSize: "14px",
                                            fontWeight: 550,
                                        }}
                                    >
                                        <h3>No duplicated leads found.</h3>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            {/* Snackbar for Alerts */}
            <Snackbar
                open={alert.open}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
            >
                <Alert onClose={handleCloseAlert} severity={alert.severity}>
                    {alert.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default DuplicatedMatchedLeads;
