import React from 'react'
import "./HealthCheck.css"
import helthImg from "../../assets/images/helthCheck.png"

const HealthCheck = () => {
    return (
        <div className='helth-check-contanier'>
            <h3 style={{
                color: "#333", fontSize: "24px", fontWeight: "700px",
                fontFamily: '"Playfair Display", serif',
                whiteSpace: "nowrap",
                wordBreak: "keep-all",textTransform:"capitalize"
            }}>Site Under Maintenance</h3>
            <div className="img-helth-check-contain">
                <img src={helthImg} alt="helthImg" />
            </div>
            <p style={{
                fontSize: "16px", fontWeight: "800px",
                whiteSpace: "nowrap",
                wordBreak: "keep-all",textTransform:"capitalize"
            }} > we are currently down for maintenance. but don't warry, we will be back soon.</p>
        </div>
    )
}

export default HealthCheck