import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Modal,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import "./HouseLeadsTable.css";
import axios from "axios";
import { baseUrl } from "../../services/BaseUrl";
import { UserContext } from "../../context/UserContext";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import { GoDash } from "react-icons/go";
import * as XLSX from "xlsx";

const callStyle = {
  borderRight: "2px solid #e0e0e0",
  fontSize: "14px",
  fontWeight: 550,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  padding: "8px 10px",
};

const HouseLeadsTable = () => {
  const { token } = useContext(UserContext);
  const [activeShows, setActiveShows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [selectedShowId, setSelectedShowId] = useState("");
  const [selectedShowName, setSelectedShowName] = useState("");
  const [houseLeads, setHouseLeads] = useState([]);
  const [showPricing, setShowPricing] = useState([]);
  const [previewData, setPreviewData] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchActiveShows = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/house/get-active-shows`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setActiveShows(response.data);
      } catch (error) {
        console.error("Error fetching active shows:", error);
      }
    };

    fetchActiveShows();
  }, []);

  const fetchHouseLeads = async (showId) => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/house/get-house-leads/${showId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setHouseLeads(response.data); // Save fetched leads to state
    } catch (error) {
      console.error("Error fetching house leads:", error);
    }
  };

  const fetchShowPricing = async (showId) => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/house/get-show-pricings/${showId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowPricing(response.data);
    } catch (error) {
      console.error("Error fetching house leads:", error);
    }
  };

  const updateLeadStatus = async (leadId, status) => {
    try {
      await axios.put(
        `${baseUrl}/api/house/update-status/${leadId}`,
        { status },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Update the lead's status in the table without refetching
      setHouseLeads((prevLeads) =>
        prevLeads.map((lead) =>
          lead._id === leadId ? { ...lead, Status: status } : lead
        )
      );
    } catch (error) {
      console.error(`Error updating status to ${status}:`, error);
    }
  };

  const handleChange = (event) => {
    const selectedId = event.target.value;

    const selectedShow = activeShows.find((show) => show._id === selectedId);

    if (selectedShow) {
      setSelectedShowName(selectedShow.Show);
    }

    setSelectedShowId(selectedId);
    fetchHouseLeads(selectedId);
    fetchShowPricing(selectedId);
  };
  console.log(houseLeads);

  const handleSendAll = async () => {
    try {
      const payload = {
        to: houseLeads.map((lead) => ({
          id: lead._id, // Include lead._id
          email: lead.Email, // Include lead.Email
        })),
        subject: "Hotel Offers",
        text: "Check out the latest hotel offers.",
        show: selectedShowName,
        templateId: "67053ae620f29a5175ad2270",
        hotelData: showPricing.Hotels.map((hotel) => {
          const amenitiesObject = hotel.Amenities[0] || {};
          const amenities = Object.keys(amenitiesObject)
            .filter((key) => amenitiesObject[key] === true)
            .map((key) => formatAmenityKey(key));

          return {
            name: hotel["Hotel Name"],
            stars: hotel.Stars,
            amenities,
            originalPrice: hotel["Online Price"],
            discountPrice: hotel["Average Nightly Rate with Markup"],
            image: hotel["Image URL"] || "",
            link: hotel.link || "",
            id: hotel._id,
          };
        }),
      };

      console.log("Payload:", payload);

      // Send the request with Axios
      const response = await axios.post(
        `${baseUrl}/api/house/send-email`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // Handle the response
      console.log("Response:", response.data);
      alert("Emails sent successfully!");
    } catch (error) {
      console.error(
        "Error sending emails:",
        error.response?.data || error.message
      );
      alert("Failed to send emails. Please try again.");
    }
  };

  // Helper function to format amenity keys (e.g., capitalize the first letter)
  const formatAmenityKey = (key) => {
    return key.charAt(0).toUpperCase() + key.slice(1);
  };

  const handleOpenPreview = async () => {
    setLoading(true);
    setError("");
    const payload = {
      to: houseLeads.map((lead) => ({
        id: lead._id, // Include lead._id
        email: lead.Email, // Include lead.Email
      })),
      subject: "Hotel Offers",
      text: "Check out the latest hotel offers.",
      show: selectedShowName,
      templateId: "67053ae620f29a5175ad2270",
      hotelData: showPricing.Hotels.map((hotel) => {
        const amenitiesObject = hotel.Amenities[0] || {};
        const amenities = Object.keys(amenitiesObject)
          .filter((key) => amenitiesObject[key] === true)
          .map((key) => formatAmenityKey(key));

        return {
          name: hotel["Hotel Name"],
          stars: hotel.Stars,
          amenities,
          originalPrice: hotel["Online Price"],
          discountPrice: hotel["Average Nightly Rate with Markup"],
          image: hotel["Image URL"] || "",
          link: hotel.link || "",
          id: hotel._id,
        };
      }),
    };

    try {
      const response = await axios.post(
        `${baseUrl}/api/house/get-email-preview`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setPreviewData(response.data.htmlContent);
    } catch (err) {
      setError("Failed to load preview. Please try again.");
    }

    setLoading(false);
    setOpen(true);
  };

  const handleDownloadExcel = () => {
    if (!houseLeads.length) {
      alert("No leads available to download.");
      return;
    }

    // Format data for the sheet
    const data = houseLeads.map((lead) => ({
      Lead: lead.Lead, // or lead.Name if available
      Email: lead.Email,
    }));

    // Create a worksheet
    const ws = XLSX.utils.json_to_sheet(data);

    // Create a workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Leads");

    // Generate and trigger the download
    XLSX.writeFile(wb, "HouseLeads.xlsx");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const currentLeads = houseLeads.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  //   if (loading) {
  //     return <LoadingComponent />;
  //   }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="existing-leads-container">
      <div className="header-controllers">
        <FormControl
          sx={{ marginBottom: 2, width: "400px", marginTop: 4 }}
          className="shift-select"
        >
          <InputLabel>Select Show</InputLabel>
          <Select
            value={selectedShowId}
            onChange={handleChange}
            label="Select Show"
          >
            {activeShows?.map((show) => (
              <MenuItem key={show._id} value={show._id}>
                {show.Show}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          disabled={houseLeads.length === 0 || showPricing.length === 0}
          onClick={handleSendAll}
          sx={{
            bgcolor: "primary.main",
            "&.Mui-disabled": {
              bgcolor: "rgba(0, 0, 0, 0.5)", // Black overlay
              color: "#fff", // White text for visibility
            },
          }}
        >
          Send All
        </Button>
        <Button variant="contained" color="primary" onClick={handleOpenPreview}>
          Preview
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleDownloadExcel}
          disabled={houseLeads.length === 0}
          sx={{
            bgcolor: "primary.main",
            "&.Mui-disabled": {
              bgcolor: "rgba(0, 0, 0, 0.5)", // Disabled styling
              color: "#fff",
            },
          }}
        >
          Download Excel
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                background: "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
              }}
            >
              {[
                "Lead Name",
                "DM Name",
                "Title",
                "Email",
                "Status",
                "Actions",
              ].map((header, index) => (
                <TableCell
                  key={header}
                  sx={{
                    color: "#fff",
                    borderRight: "2px solid #fff",
                    fontSize: "16px",
                    fontFamily: '"Playfair Display", serif',
                    whiteSpace: "nowrap",
                    wordBreak: "keep-all",
                    textAlign: index === 5 || index === 6 ? "center" : "left",
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentLeads.map((lead, index) => (
              <TableRow key={lead._id}>
                <TableCell sx={callStyle}>
                  {lead.Lead || (
                    <>
                      <GoDash style={{ fontSize: "20px" }} />
                      <GoDash style={{ fontSize: "20px" }} />
                    </>
                  )}
                </TableCell>
                <TableCell sx={callStyle}>
                  {lead["DM Name"] || "N/A" || (
                    <>
                      <GoDash style={{ fontSize: "20px" }} />
                      <GoDash style={{ fontSize: "20px" }} />
                    </>
                  )}
                </TableCell>
                <TableCell sx={callStyle}>
                  {lead.Title || "N/A" || (
                    <>
                      <GoDash style={{ fontSize: "20px" }} />
                      <GoDash style={{ fontSize: "20px" }} />
                    </>
                  )}
                </TableCell>
                <TableCell sx={callStyle}>
                  {lead.Email || (
                    <>
                      <GoDash style={{ fontSize: "20px" }} />
                      <GoDash style={{ fontSize: "20px" }} />
                    </>
                  )}
                </TableCell>
                <TableCell sx={callStyle}>
                  {lead.Status || (
                    <>
                      <GoDash style={{ fontSize: "20px" }} />
                      <GoDash style={{ fontSize: "20px" }} />
                    </>
                  )}
                </TableCell>

                <TableCell sx={callStyle}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => updateLeadStatus(lead._id, "Sent")}
                    style={{ marginRight: "0.5rem" }}
                  >
                    Sent
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={() => updateLeadStatus(lead._id, "Error")}
                  >
                    Error
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{
          backgroundColor: "#fff",
          borderTop: "1px solid #e0e0e0",
        }}
        rowsPerPageOptions={[8, 10]}
        component="div"
        count={houseLeads.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "45%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <Typography variant="h6">Email Preview</Typography>

          {loading ? (
            <CircularProgress sx={{ display: "block", margin: "20px auto" }} />
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : previewData ? (
            <Box sx={{ mt: 2, border: "1px solid #ccc", p: 2 }}>
              <div dangerouslySetInnerHTML={{ __html: previewData }} />
            </Box>
          ) : (
            <Typography>No preview available</Typography>
          )}

          <Button
            variant="contained"
            sx={{ mt: 2 }}
            fullWidth
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default HouseLeadsTable;
