import React, { useState, useEffect, useContext } from "react";
import "./AnswerSection.css";
import { FaArrowLeftLong } from "react-icons/fa6";
import Tabs from "../Tabs/Tabs";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Alert,
  Snackbar,
} from "@mui/material";
import { baseUrl } from "../../services/BaseUrl";
import axios from "axios";
import { UserContext } from "../../context/UserContext";

const statusOptions = [
  { label: "New", value: "New" },
  { label: "VM", value: "VM" },
  { label: "Redial", value: "Redial" },
  { label: "Callback", value: "Callback" },
  { label: "GK Blocked", value: "GK Blocked" },
  { label: "DM Rejected", value: "DM Rejected" },
  { label: "Do Not Call", value: "Do Not Call" },
  { label: "Email Only", value: "Email Only" },
  { label: "Email Follow-up", value: "Email Follow-up" },
  { label: "Waiting Approval", value: "Waiting Approval" },
  { label: "Prospect", value: "Prospect" },
  { label: "Flagged", value: "Flagged" },
  { label: "Wrong Number", value: "Wrong Number" },
  { label: "Closed Won", value: "Closed Won" },
  { label: "Closed Lost", value: "Closed Lost" },
];

const optionsReason = [
  { label: "Competition Rate", value: "Competition Rate" },
  { label: "Official Housing Only", value: "Official Housing Only" },
  { label: "Recently Booked", value: "Recently Booked" },
  { label: "Early Booked", value: "Early Booked" },
  { label: "Not Interested", value: "Not Interested" },
  { label: "All Set", value: "All Set" },
  { label: "Airbnb", value: "Airbnb" },
  { label: "Local", value: "Local" },
  { label: "Homeowner", value: "Homeowner" },
  { label: "Free Rooms", value: "Free Rooms" },
  { label: "Next Show", value: "Next Show" },
  { label: "Bad Experience 3rd Party", value: "Bad Experience 3rd Party" },
  { label: "Escalate", value: "Escalate" },
  { label: "Try Again", value: "Try Again" },
];

const AnswerSection = ({
  selectedLead,
  setSelectedLead,
  handleCloseAnswerPage,
  handleUpdateLead,
  selectedShowDataToRender,
  selectedShowDataToRenderH,
}) => {
  const { token } = useContext(UserContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [userRole, setUserRole] = useState(null);
  // Snackbar alert state
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");

  useEffect(() => {
    const role = localStorage.getItem("role");
    setUserRole(role);
  }, []);

  const filteredStatusOptions =
    userRole === "agent"
      ? statusOptions.filter(
          (status) =>
            status.value !== "Closed Won" &&
            status.value !== "Closed Lost" &&
            status.value !== "Waiting Approval"
        )
      : statusOptions;

  const handleSaveClick = () => {
    setOpenDialog(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedLead((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRejectionInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedLead((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleConfirmSave = () => {
    handleUpdateLead();
    handleCloseAnswerPage();
    setOpenDialog(false);
  };

  const sendEmailRequest = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/agents/send-request`,
        {
          showId: selectedLead.showId,
          companyId: selectedLead.leadId,
          request: "Email Request",
          requestedData: selectedLead?.["Email Type"],
          notes: selectedLead?.["emailRequestNotes"],
          Email: selectedLead?.Email,
          ContactName: selectedLead?.["DM Name"],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAlertMessage("Email request sent successfully!");
      setAlertSeverity("success");
      setAlertOpen(true);
      console.log("response", response);
    } catch (error) {
      console.error("Error sending request:", error);
      setAlertMessage("Error sending email request.");
      setAlertSeverity("error");
      setAlertOpen(true);
    }
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <div className="answer-section-container">
      <div className="answer-header-container">
        <FaArrowLeftLong
          onClick={handleCloseAnswerPage}
          style={{ cursor: "pointer", fontSize: 22 }}
        />
        <button className="save-answer-button" onClick={handleSaveClick}>
          <p>Save</p>
        </button>
      </div>
      <div className="answer-details-container">
        <div className="header-answer-details">
          <p style={{ fontSize: 18, fontWeight: 600 }}>
            <strong
              style={{ color: "#e7b7e9", letterSpacing: 1, marginRight: "5px" }}
            >
              Show Name:
            </strong>
            {selectedLead?.showName}
          </p>
          <p style={{ fontSize: 18, fontWeight: 600 }}>
            <strong
              style={{ color: "#e7b7e9", letterSpacing: 1, marginRight: "5px" }}
            >
              Company Name:
            </strong>
            {selectedLead?.companyName}
          </p>
          <p style={{ fontSize: 18, fontWeight: 600 }}>
            <strong
              style={{ color: "#e7b7e9", letterSpacing: 1, marginRight: "5px" }}
            >
              TimeZone:
            </strong>
            {selectedLead?.timeZone}
          </p>
        </div>
        <div className="tabs-answer-details">
          <Tabs
            selectedLead={selectedLead}
            setSelectedLead={setSelectedLead}
            sendEmailRequest={sendEmailRequest}
            userRole={userRole}
            selectedShowDataToRender={selectedShowDataToRender}
            selectedShowDataToRenderH={selectedShowDataToRenderH}
          />
        </div>
      </div>
      {/* Confirmation Dialog */}
      <Dialog open={openDialog}>
        <DialogContent>
          <label
            htmlFor="status-select"
            style={{ fontSize: "18px", fontWeight: "700", marginRight: "10px" }}
          >
            {userRole === "sales manager" ? "Closer Status:" : "Status:"}
          </label>
          <select
            id="status-select"
            name={userRole === "sales manager" ? "closerStatus" : "Status"}
            value={
              userRole === "sales manager"
                ? selectedLead.closerStatus || ""
                : selectedLead.Status || ""
            }
            onChange={(e) => {
              const { name, value } = e.target;
              setSelectedLead((prevLead) => ({
                ...prevLead,
                [name]: value,
              }));
            }}
            style={{ padding: "10px", width: "100%" }}
          >
            {filteredStatusOptions.map((status) => (
              <option key={status.value} value={status.value}>
                {status.label}
              </option>
            ))}
          </select>

          {((userRole === "sales manager"
            ? selectedLead.closerStatus
            : selectedLead.Status) === "Callback" ||
            (userRole === "sales manager"
              ? selectedLead.closerStatus
              : selectedLead.Status) === "VM" ||
            (userRole === "sales manager"
              ? selectedLead.closerStatus
              : selectedLead.Status) === "Email Follow-up" ||
            (userRole === "sales manager"
              ? selectedLead.closerStatus
              : selectedLead.Status) === "Waiting Approval" ||
            (userRole === "sales manager"
              ? selectedLead.closerStatus
              : selectedLead.Status) === "Prospect") && (
            <div>
              <label
                htmlFor="next-contact"
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                  marginRight: "10px",
                }}
              >
                Next Contact Date:
              </label>
              <input
                type="date"
                name="Next Contact"
                id="next-contact"
                value={selectedLead["Next Contact"] || ""}
                onChange={handleInputChange}
                style={{ padding: "10px", width: "100%" }}
              />
            </div>
          )}

          {selectedLead.Status === "DM Rejected" && (
            <div>
              <label
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                  marginRight: "10px",
                }}
              >
                Rejection Reason:
              </label>
              <select
                id="rejection-reason"
                name="Rejection Reason"
                value={selectedLead?.["Rejection Reason"]}
                onChange={handleRejectionInputChange}
                style={{ padding: "10px", width: "100%" }}
              >
                {optionsReason.map((reason) => (
                  <option key={reason.value} value={reason.value}>
                    {reason.label}
                  </option>
                ))}
              </select>
            </div>
          )}
          {userRole === "sales manager" && (
            <div>
              <label
                htmlFor="next-contact"
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                  marginRight: "10px",
                }}
              >
                Closer Note:
              </label>
              <input
                type="text"
                name="Closer Note"
                id="next-contact"
                value={selectedLead["Closer Note"]}
                onChange={handleInputChange}
                style={{ padding: "10px", width: "100%" }}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleConfirmSave}
            sx={{ background: "#333", color: "#cebfbf" }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AnswerSection;
