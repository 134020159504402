import React, { useState } from "react";
import axios from "axios";
import "./HotelScraper.css";
import { baseUrl } from "../../services/BaseUrl";

function HotelScraper() {
  const [showName, setShowName] = useState("");
  const [venue, setVenue] = useState("");
  const [city, setCity] = useState("");
  const [stateOrCountry, setStateOrCountry] = useState("");
  const [arrival, setArrival] = useState("");
  const [departure, setDeparture] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleScrape = async () => {
    setError("");
    setResults([]);
    setLoading(true);

    try {
      const formatDate = (date) => {
        const d = new Date(date);
        const month = (d.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero if necessary
        const day = d.getDate().toString().padStart(2, "0");
        const year = d.getFullYear();
        return `${month}/${day}/${year}`;
      };

      const formattedArrival = formatDate(arrival);
      const formattedDeparture = formatDate(departure);
      // Call your Node/Express endpoint that triggers the Playwright script
      // This endpoint might be at "/api/scrape" or whichever route you set up.
      // Pass in all relevant parameters in the request body.
      const { data } = await axios.post(`${baseUrl}/api/scrape/scrape`, {
        showName,
        venue,
        city,
        stateOrCountry,
        arrival: formattedArrival,
        departure: formattedDeparture,
      });

      setResults(data.hotels || []);
    } catch (err) {
      setError(err.response?.data?.message || "An error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="scraper-container">
      <h1 className="title">Hotelbeds Rate Scraper</h1>

      <div className="form-group">
        <label>Show Name</label>
        <input
          type="text"
          value={showName}
          onChange={(e) => setShowName(e.target.value)}
          placeholder="e.g. My Great Convention"
        />
      </div>

      <div className="form-group">
        <label>Venue</label>
        <input
          type="text"
          value={venue}
          onChange={(e) => setVenue(e.target.value)}
          placeholder="e.g. Orlando Convention Center"
        />
      </div>

      <div className="form-group">
        <label>City</label>
        <input
          type="text"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          placeholder="e.g. Orlando"
        />
      </div>

      <div className="form-group">
        <label>State / Country</label>
        <input
          type="text"
          value={stateOrCountry}
          onChange={(e) => setStateOrCountry(e.target.value)}
          placeholder="e.g. FL or USA"
        />
      </div>

      <div className="form-group">
        <label>Arrival Date</label>
        <input
          type="date"
          value={arrival}
          onChange={(e) => setArrival(e.target.value)}
        />
      </div>

      <div className="form-group">
        <label>Departure Date</label>
        <input
          type="date"
          value={departure}
          onChange={(e) => setDeparture(e.target.value)}
        />
      </div>

      <button
        className="scrape-button"
        onClick={handleScrape}
        disabled={loading}
      >
        {loading ? "Scraping..." : "Scrape Rates"}
      </button>

      {error && <div className="error-msg">{error}</div>}

      {results.length > 0 && (
        <div className="results-container">
          <h2 className="results-title">Scraped Hotel Rates</h2>
          <table className="results-table">
            <thead>
              <tr>
                <th>Hotel Name</th>
                <th>Stars</th>
                <th>Nightly Rate</th>
                <th>Free Cancellation Until</th>
                <th>Distance</th>
              </tr>
            </thead>
            <tbody>
              {results.map((hotel, i) => (
                <tr key={i}>
                  <td>{hotel["Hotel Name"]}</td>
                  <td>{hotel["Stars"]}</td>
                  <td>{hotel["Average Nightly Rate with Markup"]}</td>
                  <td>{hotel["Free Cancellation Until"]}</td>
                  <td>{hotel.Distance}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default HotelScraper;
