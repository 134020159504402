import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import "./AgentOldLeadsTable.css";
import axios from "axios";
import { baseUrl } from "../../services/BaseUrl";
import { UserContext } from "../../context/UserContext";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import { GoDash } from "react-icons/go";
import AnswerSection from "../AnswerSection/AnswerSection";

const callStyle = {
  borderRight: "2px solid #e0e0e0",
  fontSize: "14px",
  fontWeight: 550,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  padding: "8px 10px",
};

const AgentOldLeadsTable = () => {
  const { token } = useContext(UserContext);
  const [agentClosers, setAgentClosers] = useState([]);
  const [oldLeads, setOldLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [answerPageActive, setAnswerPageActive] = useState(false);
  const [selectedShowDataToRenderH, setSelectedShowDataRenderH] = useState({});

  const [selectedLead, setSelectedLead] = useState({
    showId: "",
    leadId: "",
    showName: "",
    companyName: "",
    timeZone: "",
    Status: "",
    "Next Contact": "",
    address: "",
    "End Date": "",
    "Next Dates": "",
    "Check-in": "",
    "Check-out": "",
    "Lead Date": "",
    "Rejection Reason": "",
    Notes: "",
    "DM Name": "",
    "DM Rating": "",
    Email: "",
    "Email Type": "",
    emailRequestNotes: "",
    "Days to Call": "",
    "Last Contact": "",
    "Closing Date": "",
    Hotel: "",
    Budget: "",
    " Distance": "",
    Rooms: "",
    "/ Room Stay": "",
    "Total Stay": "",
    roomDetails: [],
    Activities: [],
    roomDetails: [],
    "Request Feedback": [],
    updatedAt: "",
  });

  const fetchOldLeads = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/agents/get-old-leads`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setOldLeads(response.data.leads);
    } catch (error) {
      console.error("Error fetching old leads:", error);
    }
  };

  useEffect(() => {
    fetchOldLeads();
  }, []);

  const handleAnswerClick = useCallback((lead) => {
    console.log(lead);

    const nextDate = new Date(lead?.["Next Dates"]);
    nextDate.setDate(nextDate.getDate() - 1);

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-based
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    setSelectedLead({
      leadId: lead?._id,
      showName: lead?.Show,
      companyName: lead?.Lead,
      timeZone: lead?.Timezone,
      Status: lead?.Status,
      "Next Contact": lead?.["Next Contact"],
      address: lead?.address,
      "End Date": lead?.["End Date"],
      "Next Dates": lead?.["Next Dates"],
      "Check-in": lead?.["Check-in"],
      "Check-out": lead?.["Check-out"],
      "Lead Date": lead?.["Lead Date"],
      "Rejection Reason": lead?.["Rejection Reason"],
      Notes: lead?.Notes,
      "DM Name": lead?.["DM Name"],
      "DM Rating": lead?.["DM Rating"],
      Email: lead?.Email,
      "Email Type": lead?.["Email Type"],
      emailRequestNotes: lead?.emailRequestNotes,
      "Days to Call": lead?.["Days to Call"],
      "Last Contact": lead?.["Last Contact"],
      "Closing Date": lead?.["Closing Date"],
      Hotel: lead?.Hotel,
      Budget: lead?.Budget,
      " Distance": lead?.[" Distance"],
      Rooms: lead?.Rooms,
      "/ Room Stay": lead?.["/ Room Stay"],
      "Total Stay": lead?.["Total Stay"],
      Activities: lead?.Activities,
      roomDetails: lead.roomDetails,
      "Request Feedback": lead?.["Request Feedback"],
    });
    setAnswerPageActive(true);
  }, []);

  const handleUpdateOldLead = async () => {
    try {
      const response = await axios.put(
        `${baseUrl}/api/agents/update-old-lead/${selectedLead.leadId}`,
        selectedLead,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Lead updated successfully", response.data);
      }
    } catch (error) {
      setError("Error updating lead");
      console.error("Error updating lead:", error);
    }
  };

  const handleCloseAnswerPage = useCallback(() => {
    setAnswerPageActive(false);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const currentLeads = oldLeads.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  //   if (loading) {
  //     return <LoadingComponent />;
  //   }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="existing-leads-container">
      {!answerPageActive ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  background:
                    "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
                }}
              >
                {[
                  "Lead Name",
                  "Show Name",
                  "Timezone",
                  "Phone",
                  "Email",
                  "Status",
                  "DM Name",
                  "Answer",
                ].map((header, index) => (
                  <TableCell
                    key={header}
                    sx={{
                      color: "#fff",
                      borderRight: "2px solid #fff",
                      fontSize: "16px",
                      fontFamily: '"Playfair Display", serif',
                      whiteSpace: "nowrap",
                      wordBreak: "keep-all",
                      textAlign: index === 5 || index === 6 ? "center" : "left",
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {currentLeads.map((lead, index) => (
                <TableRow key={lead._id}>
                  <TableCell sx={callStyle}>
                    {lead.Lead || (
                      <>
                        <GoDash style={{ fontSize: "20px" }} />
                        <GoDash style={{ fontSize: "20px" }} />
                      </>
                    )}
                  </TableCell>
                  <TableCell sx={callStyle}>
                    {lead.Show || (
                      <>
                        <GoDash style={{ fontSize: "20px" }} />
                        <GoDash style={{ fontSize: "20px" }} />
                      </>
                    )}
                  </TableCell>
                  <TableCell sx={callStyle}>
                    {lead.Timezone || (
                      <>
                        <GoDash style={{ fontSize: "20px" }} />
                        <GoDash style={{ fontSize: "20px" }} />
                      </>
                    )}
                  </TableCell>
                  <TableCell sx={callStyle}>
                    {lead.Phone || (
                      <>
                        <GoDash style={{ fontSize: "20px" }} />
                        <GoDash style={{ fontSize: "20px" }} />
                      </>
                    )}
                  </TableCell>
                  <TableCell sx={callStyle}>
                    {lead.Email || (
                      <>
                        <GoDash style={{ fontSize: "20px" }} />
                        <GoDash style={{ fontSize: "20px" }} />
                      </>
                    )}
                  </TableCell>
                  <TableCell sx={callStyle}>
                    {lead.Status || (
                      <>
                        <GoDash style={{ fontSize: "20px" }} />
                        <GoDash style={{ fontSize: "20px" }} />
                      </>
                    )}
                  </TableCell>
                  <TableCell sx={callStyle}>
                    {lead["DM Name"] || (
                      <>
                        <GoDash style={{ fontSize: "20px" }} />
                        <GoDash style={{ fontSize: "20px" }} />
                      </>
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "2px solid #e0e0e0",
                      fontSize: "14px",
                      fontWeight: 550,
                      whiteSpace: "nowrap",
                      wordBreak: "keep-all",
                      padding: "7px 10px",
                      textAlign: "center",
                    }}
                  >
                    <button
                      style={{
                        padding: "10px 20px",
                        backgroundColor: "#4f637d",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        fontSize: "14px",
                      }}
                      onClick={() => handleAnswerClick(lead)}
                    >
                      Answer
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        selectedLead && (
          <AnswerSection
            handleCloseAnswerPage={handleCloseAnswerPage}
            handleUpdateLead={handleUpdateOldLead}
            selectedLead={selectedLead}
            setSelectedLead={setSelectedLead}
            selectedShowDataToRenderH={selectedShowDataToRenderH}
          />
        )
      )}
      <TablePagination
        sx={{
          backgroundColor: "#fff",
          borderTop: "1px solid #e0e0e0",
        }}
        rowsPerPageOptions={[8, 10]}
        component="div"
        count={oldLeads.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default AgentOldLeadsTable;
