import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Dialog,
  Alert,
  Snackbar,
  TextField, Button, Modal, Box, Grid, FormControlLabel, Checkbox,
} from "@mui/material";
import "./CreateRawPricing.css"
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import { baseUrl } from "../../services/BaseUrl";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import { GoDash } from "react-icons/go";
import { FaArrowLeftLong } from "react-icons/fa6";

const amenitiesList = [
  "parking",
  "airConditioned",
  "spa",
  "restaurant",
  "gym",
  "reception24Hour",
  "internet",
  "swimmingPool",
];

const callStyle = {
  borderRight: "2px solid #e0e0e0",
  fontSize: "14px",
  fontWeight: 550,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  padding: "8px 10px",
  textAlign: "left"
};

const renderSelectInfo = (renderData, field) => {
  return renderData?.[field] || (
    <>
      <GoDash style={{ fontSize: "20px" }} />
      <GoDash style={{ fontSize: "20px" }} />
    </>
  );
};

const CreateRawPricing = () => {
  const { token } = useContext(UserContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [loading, setLoading] = useState(false);
  const [rawPricing, setRawPricing] = useState([]);
  const [isCreatePricingModal, setIsCreatePricingModal] = useState(false);
  const [isCreateHotelModal, setIsCreateHotelModal] = useState(false);
  const [isUpdateHotelModal, setIsUpdateHotelModal] = useState(false);
  const [showName, setShowName] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedShow, setSelectedShow] = useState(null);
  const [hotelData, setHotelData] = useState({
    "Hotel Name": "",
    Stars: "",
    "Image URL": "",
    "Average Nightly Rate with Markup": "",
    "Free Cancellation Until": "",
    "Boarding Type": "",
    Distance: "",
    Address: "",
    "Room Type": "",
    Amenities: amenitiesList.reduce((acc, amenity) => {
      acc[amenity] = false;
      return acc;
    }, {}),
  });


  const fetchRawPricing = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/pricing/manually-generated-pricings`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const sortedData = response.data.data.sort((a, b) => {
        return new Date(b.lastModified) - new Date(a.lastModified);
      });

      setRawPricing(sortedData);
    } catch (error) {
      console.error("Error fetching junior staging data:", error);
    }
  };

  useEffect(() => {
    fetchRawPricing()
  }, [])

  const handleChange = (event) => {
    setShowName(event.target.value);
  };

  const handleCreatePricingClick = () => {
    setIsCreatePricingModal(true);
  };

  const handleClose = () => {
    setIsCreatePricingModal(false);
    setIsCreateHotelModal(false)
    setIsUpdateHotelModal(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${baseUrl}/api/pricing/create-raw-pricing`,
        { showName },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await fetchRawPricing();
      handleClose();
    } catch (error) {
      console.error("Error creating pricing:", error);
    }
  };

  const handleShowHotels = (show) => {
    setSelectedShow(show);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedShow(null);
  };

  const handleCreateHotelClick = () => {
    setHotelData({
      "Hotel Name": "",
      Stars: "",
      "Image URL": "",
      "Average Nightly Rate with Markup": "",
      "Free Cancellation Until": "",
      "Boarding Type": "",
      Distance: "",
      Address: "",
      "Room Type": "",
      Amenities: amenitiesList.reduce((acc, amenity) => {
        acc[amenity] = false;
        return acc;
      }, {}),
    });
    setIsCreateHotelModal(true);
  };

  const handleUpdateHotelClick = (selectedHotel) => {
    setHotelData({
      "Hotel Name": selectedHotel["Hotel Name"],
      Stars: selectedHotel.Stars,
      "Image URL": selectedHotel["Image URL"],
      "Average Nightly Rate with Markup": selectedHotel["Average Nightly Rate with Markup"],
      "Free Cancellation Until": selectedHotel["Free Cancellation Until"],
      "Boarding Type": selectedHotel["Boarding Type"],
      Distance: selectedHotel.Distance,
      Address: selectedHotel.Address,
      "Room Type": selectedHotel["Room Type"],
      Amenities: amenitiesList.reduce((acc, amenity) => {
        acc[amenity] = selectedHotel.Amenities[0]?.[amenity] || false;
        return acc;
      }, {}),
    });

    setIsUpdateHotelModal(true);
  };

  const handleCreateHotelChange = (key, event) => {
    if (key === "Amenities") {
      const { name, checked } = event.target;
      setHotelData((prevData) => ({
        ...prevData,
        Amenities: {
          ...prevData.Amenities,
          [name]: checked,
        },
      }));
    } else {
      setHotelData((prevData) => ({
        ...prevData,
        [key]: event.target.value,
      }));
    }
  };

  const handleCreateHotelSubmit = async (selectedShow) => {
    const pricingId = selectedShow._id
    try {
      const response = await axios.post(
        `${baseUrl}/api/pricing/add-hotel-to-raw-pricing/${pricingId}`,
        {
          hotel: {
            ...hotelData,
            Amenities: hotelData.Amenities,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSelectedShow(prevState => ({
        ...prevState,
        Hotels: [...prevState.Hotels, response.data.hotel],
      }));

      setHotelData({
        "Hotel Name": "",
        Stars: "",
        "Image URL": "",
        "Average Nightly Rate with Markup": "",
        "Free Cancellation Until": "",
        "Boarding Type": "",
        Distance: "",
        Address: "",
        "Room Type": "",
        Amenities: amenitiesList.reduce((acc, amenity) => {
          acc[amenity] = false;
          return acc;
        }, {}),
      });

      await fetchRawPricing();
      handleClose();
    } catch (error) {
      console.error("Error creating hotel:", error);
    }
  };

  const handleUpdateHotelSubmit = async (selectedShow) => {
    const pricingId = selectedShow._id;

    try {
      const updatedHotelData = {
        hotel: {
          ...hotelData,
          Amenities: { ...hotelData.Amenities },
        },
      };
      const response = await axios.put(
        `${baseUrl}/api/pricing/update-raw-hotel/${pricingId}`,
        updatedHotelData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSelectedShow((prevState) => ({
        ...prevState,
        Hotels: prevState.Hotels.map((hotel) =>
          hotel._id === pricingId ? { ...hotel, ...response.data.hotel } : hotel
        ),
      }));

      setHotelData({
        "Hotel Name": "",
        Stars: "",
        "Image URL": "",
        "Average Nightly Rate with Markup": "",
        "Free Cancellation Until": "",
        "Boarding Type": "",
        Distance: "",
        Address: "",
        "Room Type": "",
        Amenities: amenitiesList.reduce((acc, amenity) => {
          acc[amenity] = false;
          return acc;
        }, {}),
      });

      await fetchRawPricing();
      handleClose();
    } catch (error) {
      console.error("Error updating hotel:", error.response || error.message);
    }
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <div className="create-raw-pricing-container">
      <div className="create-raw-pricing-btn">
        <button style={{
          letterSpacing: 1,
          padding: "10px 25px",
          cursor: "pointer",
          borderRadius: "4px",
          fontSize: "16px",
          fontWeight: "700",
          background: " rgb(79, 99, 125)",
          color: "#fff"
        }}
          onClick={() => handleCreatePricingClick()}
        >
          Create Pricing
        </button>
      </div>
      <div className="email-request-sales-table-container">
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "8px 8px 0 0 ",
            overflowX: "auto",
            maxHeight: "82vh",
          }}
          className="custom-scrollbar"
        >
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  background:
                    "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
                }}
              >
                {[
                  "Controllers",
                  "Show Name",
                  "Expired",
                  "Date Modified"
                ].map((header, index) => (
                  <TableCell
                    key={header}
                    sx={{
                      color: "#fff",
                      borderRight: "2px solid #fff",
                      fontSize: "16px",
                      fontFamily: '"Playfair Display", serif',
                      whiteSpace: "nowrap",
                      wordBreak: "keep-all",
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rawPricing.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((pricing, index) => (
                <TableRow key={pricing._id}>
                  <TableCell sx={callStyle}>
                    <button
                      style={{
                        padding: "10px 20px",
                        backgroundColor: "#4f637d",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        fontSize: "14px",
                      }}
                      onClick={() => handleShowHotels(pricing)}
                    >
                      Toggle Hotels
                    </button>
                  </TableCell>
                  <TableCell sx={callStyle}>
                    {renderSelectInfo(pricing, "showName")}
                  </TableCell>
                  <TableCell sx={callStyle}>
                    {pricing?.["isExpired"] ? "Yes" : "No"}
                  </TableCell>
                  <TableCell sx={callStyle}>
                    {new Date(pricing.lastModified).toLocaleString()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{
            backgroundColor: "#fff",
            borderTop: "1px solid #e0e0e0",
          }}
          rowsPerPageOptions={[8, 15]}
          component="div"
          count={rawPricing.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      <Modal
        open={isCreatePricingModal}
        aria-labelledby="create-raw-pricing-modal"
        aria-describedby="modal-to-create-raw-pricing"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <h2 id="create-raw-pricing-modal">Create Raw Pricing</h2>
          <TextField
            fullWidth
            id="show-name"
            label="Show Name"
            variant="outlined"
            value={showName}
            onChange={handleChange}
            margin="normal"
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
              mt: 3,
            }}
          >
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Save
            </Button>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      {/* Full Screen Dialog */}
      <Dialog open={openDialog} fullScreen>
        <div className="show-table-dialog-container">
          <div className="dialog-header-close">
            <FaArrowLeftLong
              onClick={handleCloseDialog}
              style={{ cursor: "pointer", fontSize: 22, color: "#333" }}
            />
          </div>
          <div className="info-show-dialog">
            <h3 style={{ letterSpacing: 1 }}>
              <span style={{ color: "#86575d", fontSize: "20px" }}>
                Show Details:
              </span>
              {selectedShow?.showName}
            </h3>
          </div>
          <div className="create-raw-pricing-btn">
            <button style={{
              letterSpacing: 1,
              padding: "10px 30px",
              cursor: "pointer",
              borderRadius: "4px",
              fontSize: "16px",
              fontWeight: "700",
              background: " rgb(79, 99, 125)",
              color: "#fff"
            }}
              onClick={() => handleCreateHotelClick()}
            >
              Create Hotel
            </button>
          </div>
          <div className="email-request-sales-table-container">
            {selectedShow && (
              <TableContainer
                component={Paper}
                sx={{
                  borderRadius: "8px 8px 0 0 ",
                  overflowX: "auto",
                  maxHeight: "70vh",
                }}
                className="custom-scrollbar"
              >
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        background:
                          "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
                      }}
                    >
                      {[
                        "Controllers",
                        "Hotel Name",
                        "Stars",
                        "Image URL",
                        "Nightly Rate",
                        "Hotel Address",
                        "Distance",
                        "Room Type",
                        "Free Cancellation Until",
                        "Amenities",
                      ].map((header, index) => (
                        <TableCell
                          key={header}
                          sx={{
                            color: "#fff",
                            borderRight: "2px solid #fff",
                            fontSize: "16px",
                            fontFamily: '"Playfair Display", serif',
                            whiteSpace: "nowrap",
                            wordBreak: "keep-all",
                          }}
                        >
                          {header}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedShow.Hotels.length === 0 ? (
                      <TableRow >
                        <TableCell
                          colSpan={10}
                          sx={{
                            fontSize: 16,
                            fontWeight: 700,
                            textAlign: "center",
                            borderRight: "2px solid #e0e0e0",
                            padding: "10px",
                          }}
                        >
                          No Hotels Here
                        </TableCell>
                      </TableRow>
                    ) : (selectedShow.Hotels.map((hotel, index) => (
                      <TableRow key={index}>
                        <TableCell sx={callStyle}>
                          <button
                            style={{
                              padding: "10px 20px",
                              backgroundColor: "#4f637d",
                              color: "#fff",
                              border: "none",
                              borderRadius: "5px",
                              cursor: "pointer",
                              fontSize: "14px",
                            }}
                            onClick={() => handleUpdateHotelClick(hotel)}
                          >
                            Edit
                          </button>
                        </TableCell>
                        <TableCell sx={callStyle}>
                          {renderSelectInfo(hotel, "Hotel Name")}
                        </TableCell>
                        <TableCell sx={callStyle}>
                          {renderSelectInfo(hotel, "Stars")}
                        </TableCell>
                        <TableCell sx={callStyle}>
                          <img src={hotel?.["Image URL"]} alt={hotel?.["Hotel Name"]}
                            style={{
                              width: '100%',
                              height: 'auto',
                              objectFit: 'cover',
                              maxHeight: '250px',
                            }}
                          />
                        </TableCell>
                        <TableCell sx={callStyle}>
                          {renderSelectInfo(hotel, "Average Nightly Rate with Markup")}
                        </TableCell>
                        <TableCell sx={callStyle}>
                          {renderSelectInfo(hotel, "Address")}
                        </TableCell>
                        <TableCell sx={callStyle}>
                          {renderSelectInfo(hotel, "Distance")}
                        </TableCell>
                        <TableCell sx={callStyle}>
                          {renderSelectInfo(hotel, "Room Type")}
                        </TableCell>
                        <TableCell sx={callStyle}>
                          {renderSelectInfo(hotel, "Free Cancellation Until")}
                        </TableCell>
                        <TableCell sx={callStyle}>
                          <TableContainer
                            component={Paper}
                            sx={{
                              borderRadius: "8px 8px 0 0 ",
                            }}
                          >
                            <Table>
                              <TableHead>
                                <TableRow
                                  sx={{
                                    background: "linear-gradient(to right, #fed766, #e8b6ea)",
                                  }}
                                >
                                  {[
                                    "Air Conditioned",
                                    "Gym",
                                    "Internet",
                                    "Parking",
                                    "24-Hour Reception",
                                    "Restaurant",
                                    "Spa",
                                    "Swimming Pool",
                                  ].map((header, index) => (
                                    <TableCell
                                      key={header}
                                      sx={{
                                        color: "#fff",
                                        borderRight: "2px solid #fff",
                                        fontSize: "16px",
                                        fontFamily: '"Playfair Display", serif',
                                        whiteSpace: "nowrap",
                                        wordBreak: "keep-all",
                                      }}
                                    >
                                      {header}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {hotel.Amenities.length === 0 ? (
                                  <TableRow >
                                    <TableCell
                                      colSpan={8}
                                      sx={{
                                        fontSize: 16,
                                        fontWeight: 700,
                                        textAlign: "center",
                                        borderRight: "2px solid #e0e0e0",
                                        padding: "10px",
                                      }}
                                    >
                                      No Amenities Available.
                                    </TableCell>
                                  </TableRow>
                                ) : (
                                  <TableRow>
                                    <TableCell sx={callStyle}>
                                      {hotel.Amenities[0].airConditioned ? "Yes" : "No"}
                                    </TableCell>
                                    <TableCell sx={callStyle}>
                                      {hotel.Amenities[0].gym ? "Yes" : "No"}
                                    </TableCell>
                                    <TableCell sx={callStyle}>
                                      {hotel.Amenities[0].internet ? "Yes" : "No"}
                                    </TableCell>
                                    <TableCell sx={callStyle}>
                                      {hotel.Amenities[0].parking ? "Yes" : "No"}
                                    </TableCell>
                                    <TableCell sx={callStyle}>
                                      {hotel.Amenities[0].reception24Hour ? "Yes" : "No"}
                                    </TableCell>
                                    <TableCell sx={callStyle}>
                                      {hotel.Amenities[0].restaurant ? "Yes" : "No"}
                                    </TableCell>
                                    <TableCell sx={callStyle}>
                                      {hotel.Amenities[0].spa ? "Yes" : "No"}
                                    </TableCell>
                                    <TableCell sx={callStyle}>
                                      {hotel.Amenities[0].swimmingPool ? "Yes" : "No"}
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </TableCell>
                      </TableRow>
                    )))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </div>
      </Dialog>
      {/*modal*/}
      <Modal
        open={isCreateHotelModal || isUpdateHotelModal}
        aria-labelledby="create-raw-pricing-modal"
        aria-describedby="modal-to-create-raw-pricing"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1000,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          {isCreateHotelModal && <h2 id="create-raw-pricing-modal">Create Hotel</h2>}
          {isUpdateHotelModal && <h2 id="create-raw-pricing-modal">Edit Hotel</h2>}
          {/* Form fields */}
          <Grid container spacing={2}>
            {Object.keys(hotelData)
              .filter((key) => key !== "Amenities")
              .map((key) => (
                <Grid item xs={12} sm={6} md={4} key={key}>
                  {key === "Free Cancellation Until" ? (
                    <TextField
                      fullWidth
                      label={key}
                      variant="outlined"
                      value={hotelData[key]}
                      onChange={(e) => handleCreateHotelChange(key, e)}
                      margin="normal"
                      type="date" // Set the input type to date
                      InputLabelProps={{
                        shrink: true, // Ensures the label stays above the input field for the date
                      }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      label={key}
                      variant="outlined"
                      value={hotelData[key]}
                      onChange={(e) => handleCreateHotelChange(key, e)}
                      margin="normal"
                    />
                  )}
                </Grid>
              ))}
          </Grid>
          {/* Amenities checkboxes */}
          <Grid item xs={12}>
            <Box>
              <Box sx={{ mb: 2 }}>
                <strong>Amenities</strong>
              </Box>
              {amenitiesList.map((amenity) => (
                <FormControlLabel
                  key={amenity}
                  control={
                    <Checkbox
                      checked={hotelData.Amenities[amenity] === true}
                      onChange={(e) =>
                        setHotelData((prevData) => ({
                          ...prevData,
                          Amenities: {
                            ...prevData.Amenities,
                            [amenity]: e.target.checked,
                          },
                        }))
                      }
                      name={amenity}
                    />
                  }
                  label={amenity.charAt(0).toUpperCase() + amenity.slice(1)}
                />
              ))}
            </Box>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
              mt: 3,
            }}
          >
            {isCreateHotelModal &&
              <Button variant="contained" color="primary"
                onClick={() => handleCreateHotelSubmit(selectedShow)}
              >
                Create Hotel
              </Button>}

            {isUpdateHotelModal &&
              <Button variant="contained" color="primary"
                onClick={() => handleUpdateHotelSubmit(selectedShow)}

              >
                Update Hotel
              </Button>
            }

            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}

export default CreateRawPricing