import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
} from "@mui/material";
import "./CreatePricing.css";
import CreateRawPricingModal from "../CreateRawPricingModal/CreateRawPricingModal";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import { baseUrl } from "../../services/BaseUrl";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import { GoDash } from "react-icons/go";

const callStyle = {
  borderRight: "2px solid #e0e0e0",
  fontSize: "14px",
  fontWeight: 550,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  padding: "8px 10px",
  textAlign: "left",
};

const renderSelectInfo = (renderData, field) => {
  return (
    renderData?.[field] || (
      <>
        <GoDash style={{ fontSize: "20px" }} />
        <GoDash style={{ fontSize: "20px" }} />
      </>
    )
  );
};

const CreatePricing = () => {
  const { token } = useContext(UserContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [loading, setLoading] = useState(false);
  const [pricingPageActive, setPricingPageActive] = useState(false);
  const [allShows, setAllShows] = useState([]);
  const [selectedShow, setSelectedShow] = useState(null);

  const fetchAllShows = async () => {
    // setLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/api/pricing/all-shows`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAllShows(response.data.data);
    } catch (error) {
      console.error("Error fetching junior staging data:", error);
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllShows();
  }, []);

  const handelViewPricingPage = (show) => {
    setSelectedShow(show);
    setPricingPageActive(true);
  };

  const handleCloseDialog = () => {
    setPricingPageActive(false);
    setSelectedShow(null);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <>
      {!pricingPageActive ? (
        <div className="create-raw-pricing-container">
          <div className="email-request-sales-table-container">
            <TableContainer
              component={Paper}
              sx={{
                borderRadius: "8px 8px 0 0 ",
                overflowX: "auto",
                maxHeight: "82vh",
              }}
              className="custom-scrollbar"
            >
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      background:
                        "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
                    }}
                  >
                    {[
                      "Controllers",
                      "Show Name",
                      "Start Date",
                      "End Date",
                      "Convention",
                      "Venue",
                      "City, Country",
                      "Active",
                      "Pricing Expired",
                      "Show Modifying Date ",
                    ].map((header, index) => (
                      <TableCell
                        key={header}
                        sx={{
                          color: "#fff",
                          borderRight: "2px solid #fff",
                          fontSize: "16px",
                          fontFamily: '"Playfair Display", serif',
                          whiteSpace: "nowrap",
                          wordBreak: "keep-all",
                        }}
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allShows.length === 0 ? (
                    <TableRow>
                      <TableCell
                        colSpan={9}
                        sx={{
                          fontSize: 16,
                          fontWeight: 700,
                          textAlign: "center",
                          borderRight: "2px solid #e0e0e0",
                          padding: "10px",
                        }}
                      >
                        No Shows Here
                      </TableCell>
                    </TableRow>
                  ) : (
                    allShows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((show, index) => (
                        <TableRow key={show._id}>
                          <TableCell sx={callStyle}>
                            <button
                              style={{
                                padding: "10px 20px",
                                backgroundColor: "#4f637d",
                                color: "#fff",
                                border: "none",
                                borderRadius: "5px",
                                cursor: "pointer",
                                fontSize: "14px",
                              }}
                              onClick={() => handelViewPricingPage(show)}
                            >
                              View Pricing
                            </button>
                          </TableCell>
                          <TableCell sx={callStyle}>
                            {renderSelectInfo(show, "Show")}
                          </TableCell>
                          <TableCell sx={callStyle}>
                            {renderSelectInfo(show, "Next Dates")}
                          </TableCell>
                          <TableCell sx={callStyle}>
                            {renderSelectInfo(show, "End Date")}
                          </TableCell>
                          <TableCell sx={callStyle}>
                            {renderSelectInfo(
                              show,
                              "Convention Center / Host Hotel"
                            )}
                          </TableCell>
                          <TableCell sx={callStyle}>
                            {renderSelectInfo(show, "Venue")}
                          </TableCell>
                          <TableCell sx={callStyle}>
                            {renderSelectInfo(show, "City, Country")}
                          </TableCell>
                          <TableCell sx={callStyle}>
                            {show?.["isActive"] ? "Yes" : "No"}
                          </TableCell>
                          <TableCell sx={callStyle}>
                            {show?.pricing[0]?.isExpired ? "Yes" : "No"}
                          </TableCell>
                          <TableCell sx={callStyle}>
                            {new Date(show.updatedAt).toLocaleString()}
                          </TableCell>
                        </TableRow>
                      ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              sx={{
                backgroundColor: "#fff",
                borderTop: "1px solid #e0e0e0",
              }}
              rowsPerPageOptions={[8, 15]}
              component="div"
              count={allShows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      ) : (
        selectedShow && (
          <CreateRawPricingModal
            handleCloseDialog={handleCloseDialog}
            selectedShow={selectedShow}
            setSelectedShow={setSelectedShow}
            fetchAllShows={fetchAllShows}
          />
        )
      )}
    </>
  );
};

export default CreatePricing;
