import React, { useContext, useEffect, useState } from "react";
import "./HistoryOfMatching.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TablePagination,
} from "@mui/material";
import { format } from "date-fns";

import axios from "axios";
import { baseUrl } from "../../services/BaseUrl";
import { UserContext } from "../../context/UserContext";
import { GoDash } from "react-icons/go";

const callStyle = {
  borderRight: "2px solid #e0e0e0",
  fontSize: "14px",
  fontWeight: 550,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  padding: "8px 10px",
};

const HistoryOfMatching = () => {
  const [showsPage, setShowsPage] = useState(0);
  const [pricingsPage, setPricingsPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [matchedRawLeads, setMatchedRawLeads] = useState(null);
  const [matchedRawPricings, setMatchedRawPricings] = useState(null);
  const { token } = useContext(UserContext);

  const fetchMatchedRawLeads = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/locator/get-matched-raw-leads`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMatchedRawLeads(response.data);
      console.log("Raw Leads", response.data);
    } catch (error) {
      console.log("Error fetching raw leads", error);
    }
  };

  const fetchMatchedRawPricings = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/locator/get-matched-raw-pricings`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMatchedRawPricings(response.data);
      console.log("Raw Pricings", response.data);
    } catch (error) {
      console.log("Error fetching raw pricings", error);
    }
  };

  useEffect(() => {
    const fetchMatchHistoryTableData = async () => {
      await fetchMatchedRawLeads();
      await fetchMatchedRawPricings();
    };
    fetchMatchHistoryTableData();
  }, []);

  const formatDate = (dateString) => {
    if (dateString) {
      return format(new Date(dateString), "MMM dd, yyyy h:mm a");
    }
  };
  const handleChangeShowsPage = (event, newPage) => {
    setShowsPage(newPage);
  };

  const handleChangeShowsRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setShowsPage(0);
  };

  console.log(matchedRawLeads);

  const currentShows = matchedRawLeads?.slice(
    showsPage * rowsPerPage,
    showsPage * rowsPerPage + rowsPerPage
  );

  const handleChangePricingsPage = (event, newPage) => {
    setPricingsPage(newPage);
  };

  const handleChangePricingsRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPricingsPage(0);
  };

  const currentPricings = matchedRawPricings?.slice(
    pricingsPage * rowsPerPage,
    pricingsPage * rowsPerPage + rowsPerPage
  );

  return (
    <div className="locator-report-container">
      <div className="locator-leads-report-contain">
        <span>View Matched Raw Leads</span>
        {/* <div className="locator-leads-report-tables-contain">
          <div className="table-match-search-contain">
            <input
              type="text"
              placeholder="Search Leads..."
              className="table-search-input"
              // value={searchRowLeads}
              // onChange={handleSearchChange}
            />
          </div>
        </div> */}
        <div className="leads-locator-timezone-container">
          <TableContainer component={Paper} style={{ height: "400px" }}>
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    background:
                      "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
                  }}
                >
                  {[
                    "Raw Show Name",
                    "Companies Count",
                    "Matched At",
                    "Matched By",
                    "Matching details",
                  ].map((header, index) => (
                    <TableCell
                      key={header}
                      sx={{
                        color: "#fff",
                        borderRight: "2px solid #fff",
                        fontSize: "16px",
                        fontFamily: '"Playfair Display", serif',
                        whiteSpace: "nowrap",
                        wordBreak: "keep-all",
                        textAlign:
                          index === 5 || index === 6 ? "center" : "left",
                      }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {currentShows?.map((show, index) => (
                  <TableRow key={show._id}>
                    <TableCell sx={callStyle}>
                      {show.showName || (
                        <>
                          <GoDash style={{ fontSize: "20px" }} />
                          <GoDash style={{ fontSize: "20px" }} />
                        </>
                      )}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {show.companyCount || "N/A" || (
                        <>
                          <GoDash style={{ fontSize: "20px" }} />
                          <GoDash style={{ fontSize: "20px" }} />
                        </>
                      )}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {formatDate(show.matchingDetails[0].createdAt) ||
                        "N/A" || (
                          <>
                            <GoDash style={{ fontSize: "20px" }} />
                            <GoDash style={{ fontSize: "20px" }} />
                          </>
                        )}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {show.matchingDetails[0].MadeBy || "N/A" || (
                        <>
                          <GoDash style={{ fontSize: "20px" }} />
                          <GoDash style={{ fontSize: "20px" }} />
                        </>
                      )}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {show.matchingDetails?.length > 0 ? (
                        <ul
                          style={{
                            paddingLeft: "20px",
                            margin: 0,
                            listStyleType: "circle",
                          }}
                        >
                          {show.matchingDetails.map((detail, detailIndex) => (
                            <li
                              key={detailIndex}
                              style={{
                                marginBottom: "10px",
                                padding: "10px",
                                backgroundColor: "#f9f9f9",
                                border: "1px solid #e0e0e0",
                                borderRadius: "5px",
                                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                              }}
                            >
                              <div>
                                <strong style={{ color: "#333" }}>Show:</strong>{" "}
                                <span style={{ color: "#555" }}>
                                  {detail.show || "N/A"}
                                </span>
                              </div>
                              <div>
                                <strong style={{ color: "#333" }}>
                                  Pricing:
                                </strong>{" "}
                                <span style={{ color: "#555" }}>
                                  {detail.pricing || "N/A"}
                                </span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <span style={{ color: "#999", fontStyle: "italic" }}>
                          No Matching Details
                        </span>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            sx={{
              backgroundColor: "#fff",
              borderTop: "1px solid #e0e0e0",
            }}
            rowsPerPageOptions={[8, 10]}
            component="div"
            count={matchedRawLeads?.length}
            rowsPerPage={rowsPerPage}
            page={showsPage}
            onPageChange={handleChangeShowsPage}
            onRowsPerPageChange={handleChangeShowsRowsPerPage}
          />
        </div>
      </div>
      <div className="locator-leads-report-contain">
        <span>View Matched Raw Pricing</span>
        {/* <div className="locator-leads-report-tables-contain">
          <div className="table-match-search-contain">
            <input
              type="text"
              placeholder="Search Leads..."
              className="table-search-input"
              // value={searchRowLeads}
              // onChange={handleSearchChange}
            />
          </div>
        </div> */}
        <div className="leads-locator-timezone-container">
          <TableContainer component={Paper} style={{ height: "400px" }}>
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    background:
                      "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
                  }}
                >
                  {[
                    "Raw Name",
                    "Raw Show Name",
                    "Pricing Status",
                    "Updated At",
                    "Matching details",
                  ].map((header, index) => (
                    <TableCell
                      key={header}
                      sx={{
                        color: "#fff",
                        borderRight: "2px solid #fff",
                        fontSize: "16px",
                        fontFamily: '"Playfair Display", serif',
                        whiteSpace: "nowrap",
                        wordBreak: "keep-all",
                        textAlign:
                          index === 5 || index === 6 ? "center" : "left",
                      }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {currentPricings?.map((show, index) => (
                  <TableRow key={show._id}>
                    <TableCell sx={callStyle}>
                      {show.rawName || (
                        <>
                          <GoDash style={{ fontSize: "20px" }} />
                          <GoDash style={{ fontSize: "20px" }} />
                        </>
                      )}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {show.showName || (
                        <>
                          <GoDash style={{ fontSize: "20px" }} />
                          <GoDash style={{ fontSize: "20px" }} />
                        </>
                      )}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {show["Pricing Status"] || "N/A" || (
                        <>
                          <GoDash style={{ fontSize: "20px" }} />
                          <GoDash style={{ fontSize: "20px" }} />
                        </>
                      )}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {formatDate(show.lastModified) || "N/A" || (
                        <>
                          <GoDash style={{ fontSize: "20px" }} />
                          <GoDash style={{ fontSize: "20px" }} />
                        </>
                      )}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {show.matchingDetails?.length > 0 ? (
                        <ul
                          style={{
                            paddingLeft: "20px",
                            margin: 0,
                            listStyleType: "circle",
                          }}
                        >
                          {show.matchingDetails.map((detail, detailIndex) => (
                            <li
                              key={detailIndex}
                              style={{
                                marginBottom: "10px",
                                padding: "10px",
                                backgroundColor: "#f9f9f9",
                                border: "1px solid #e0e0e0",
                                borderRadius: "5px",
                                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                              }}
                            >
                              <div>
                                <strong style={{ color: "#333" }}>Show:</strong>{" "}
                                <span style={{ color: "#555" }}>
                                  {detail.show || "N/A"}
                                </span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <span style={{ color: "#999", fontStyle: "italic" }}>
                          No Matching Details
                        </span>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            sx={{
              backgroundColor: "#fff",
              borderTop: "1px solid #e0e0e0",
            }}
            rowsPerPageOptions={[8, 10]}
            component="div"
            count={matchedRawPricings?.length}
            rowsPerPage={rowsPerPage}
            page={pricingsPage}
            onPageChange={handleChangePricingsPage}
            onRowsPerPageChange={handleChangePricingsRowsPerPage}
          />
        </div>
      </div>
    </div>
  );
};

export default HistoryOfMatching;
