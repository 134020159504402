import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Paper,
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
  Input,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Alert,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import "./ShowDashboard.css";
import { baseUrl } from "../../services/BaseUrl";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import CompaniesTable from "./CompaniesTable";
import HotelsTable from "./HotelsTable";

const ShowDashboard = () => {
  const [totalShows, setTotalShows] = useState(0);
  const [totalCompanies, setTotalCompanies] = useState(0);
  const [totalEmails, setTotalEmails] = useState(0);
  const [expiredFilter, setExpiredFilter] = useState("all");
  const [matchingFilter, setMatchingFilter] = useState("all");
  const [selectedShow, setSelectedShow] = useState("");
  const [shows, setShows] = useState([]);
  const [pricings, setPricings] = useState([]);
  const [filteredShows, setFilteredShows] = useState([]);
  const [monthFilter, setMonthFilter] = useState("");
  const [availableMonths, setAvailableMonths] = useState([]);
  const [matchedShowsCount, setMatchedShowsCount] = useState(0);
  const [unmatchedShowsCount, setUnmatchedShowsCount] = useState(0);
  const [showDetails, setShowDetails] = useState({});
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [selectedHotels, setSelectedHotels] = useState([]);
  const [customRecipient, setCustomRecipient] = useState({
    companyName: "",
    dmName: "",
    email: "",
  });
  const [transporter, setTransporter] = useState("exclusiveskyline.com");
  const [templateType, setTemplateType] = useState("standard");
  const [htmlEmailPreview, setHtmlEmailPreview] = useState("");
  const [textEmailPreview, setTextEmailPreview] = useState("");
  const [selectedTemplateType, setSelectedTemplateType] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);
  const [textVariant, setTextVariant] = useState("standard");
  const [htmlVariant, setHtmlVariant] = useState("standard");
  const [selectedCompany, setSelectedCompany] = useState("No company selected");
  const [recipientEmail, setRecipientEmail] = useState("No email");
  const [attachments, setAttachments] = useState([]);
  const { token } = useContext(UserContext);

  useEffect(() => {
    // Fetch shows from backend
    const fetchShows = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/super/get-raw-shows`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const showData = response.data.formattedShows;
        const pricingData = response.data.pricings;

        setShows(showData);
        setPricings(pricingData);
        setMatchedShowsCount(showData.filter((show) => show.matched).length);
        setUnmatchedShowsCount(showData.filter((show) => !show.matched).length);
      } catch (error) {
        console.error("Error fetching shows:", error);
      }
    };

    const fetchShowStats = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/super/shows-statistics`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const { totalShows, totalCompanies, totalEmails } = response.data.data;

        // Set state with fetched data
        setTotalShows(totalShows);
        setTotalCompanies(totalCompanies);
        setTotalEmails(totalEmails);
      } catch (error) {
        console.error("Error fetching show stats:", error);
      }
    };

    fetchShows();
    fetchShowStats();
  }, []);

  useEffect(() => {
    // Extract unique months from pricings
    const uniqueMonths = [
      ...new Set(
        pricings.map((pricing) =>
          new Date(pricing.startDate).toLocaleString("default", {
            month: "long",
            year: "numeric",
          })
        )
      ),
    ];
    setAvailableMonths(uniqueMonths);
  }, [pricings]);

  // Filter shows based on selected filter
  useEffect(() => {
    let filteredPricingsData = pricings;
    let filteredShowsData = shows;

    // Filter pricings by selected month
    if (monthFilter) {
      filteredPricingsData = pricings.filter((pricing) => {
        const pricingMonth = new Date(pricing.startDate).toLocaleString(
          "default",
          { month: "long", year: "numeric" }
        );
        return pricingMonth === monthFilter;
      });

      // Get valid showNames from filtered pricings (rawName should match)
      const validShowNames = new Set(
        filteredPricingsData.map((p) => p.rawName)
      );

      // Filter shows to include only those matching valid showNames
      filteredShowsData = shows.filter((show) =>
        validShowNames.has(show.showName)
      );
    }

    // Apply matching filter
    if (matchingFilter === "true") {
      filteredShowsData = filteredShowsData.filter((show) => show.matched);
    } else if (matchingFilter === "false") {
      filteredShowsData = filteredShowsData.filter((show) => !show.matched);
    }

    setFilteredShows(filteredShowsData);
  }, [matchingFilter, monthFilter, shows, pricings]);

  const handleExpiredChange = (event) => setExpiredFilter(event.target.value);
  const handleMatchingChange = (event) => setMatchingFilter(event.target.value);

  const handleShowSelect = async (event) => {
    const selectedShowId = event.target.value;
    setSelectedShow(selectedShowId);

    if (!selectedShowId) return; // Avoid unnecessary API calls

    try {
      const response = await axios.post(
        `${baseUrl}/api/super/get-show-data`,
        { showId: selectedShowId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setShowDetails(response.data.data);
      setHtmlEmailPreview("");
      setTextEmailPreview("");
      setSelectedTemplateType(null);
      setSelectedEmails([]);
    } catch (error) {
      console.error("Error fetching show details:", error);
    }
  };

  const handleTransporterChange = (event) => setTransporter(event.target.value);
  const handleTemplateTypeChange = (event) =>
    setTemplateType(event.target.value);
  const handleTextVariantChange = (event) => setTextVariant(event.target.value);
  const handleHtmlVariantChange = (event) => setHtmlVariant(event.target.value);
  const handleFileChange = (e) => {
    setAttachments(Array.from(e.target.files));
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const fetchEmailContent = async () => {
    if (!selectedShow) {
      console.error("No show selected.");
      return;
    }

    try {
      const response = await axios.post(
        `${baseUrl}/api/super/email-preview`,
        {
          showId: selectedShow, // Send selectedShow as showId
          model: templateType, // Send templateType as model
          transporter,
          selectedHotels,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // console.log("Generated Email Content:", response.data);
      setHtmlEmailPreview(response.data.html);
      setTextEmailPreview(response.data.text);
      setOpenPreview(true); // Open modal if response is OK
    } catch (error) {
      console.error("Error fetching email content:", error);
    }
  };

  useEffect(() => {
    if (selectedShow) {
      fetchEmailContent(selectedShow);
    }
  }, [selectedShow]);

  const handleSendEmails = async () => {
    if (!selectedShow) {
      console.error("No show selected.");
      return;
    }
    try {
      const response = await axios.post(
        `${baseUrl}/api/super/send-emails`,
        {
          showId: selectedShow,
          transporterKey: transporter,
          model: templateType,
          selectedEmails,
          selectedHotels,
          emailTemplateType: selectedTemplateType,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      console.log("Emails sent successfully:", response.data);

      // Update local state for showDetails.companies
      setShowDetails((prevDetails) => {
        if (!prevDetails || !prevDetails.companies) return prevDetails;

        const updatedCompanies = prevDetails.companies.map((company) => {
          const matchedRecipient = selectedEmails.find(
            (emailObj) => emailObj.email === company.email
          );

          if (matchedRecipient) {
            return {
              ...company,
              mailStatus: "sent",
              ...(templateType === "followup" && { mailFollowup: "sent" }),
            };
          }

          return company;
        });

        return { ...prevDetails, companies: updatedCompanies };
      });
    } catch (error) {
      console.error("Error sending emails:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomRecipient((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddRecipient = () => {
    // Optional: Validate email is not empty and is valid, etc.
    if (!customRecipient.email || customRecipient.email.trim() === "") {
      alert("Please provide a valid email.");
      return;
    }
    // Add recipient to selectedEmails state
    setSelectedEmails([
      ...selectedEmails,
      {
        company: customRecipient.companyName,
        dmName: customRecipient.dmName,
        email: customRecipient.email,
      },
    ]);
    // Clear the fields
    setCustomRecipient({ companyName: "", dmName: "", email: "" });
  };

  console.log(selectedHotels);

  return (
    <Box
      className="show-dashboard"
      p={3}
      boxShadow={2}
      borderRadius={2}
      bgcolor="white"
    >
      <Typography variant="h5" gutterBottom>
        Show Dashboard
      </Typography>

      {/* Filters and Selection */}
      <Grid container spacing={2} className="controls-container">
        {/* <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              value={expiredFilter}
              onChange={handleExpiredChange}
              label="Status"
            >
              <MenuItem value="all">All Shows</MenuItem>
              <MenuItem value="false">Active Shows (0)</MenuItem>
              <MenuItem value="true">Expired Shows (0)</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Matching</InputLabel>
            <Select
              value={matchingFilter}
              onChange={handleMatchingChange}
              label="Matching"
            >
              <MenuItem value="all">All Shows</MenuItem>
              <MenuItem value="true">
                Matched Shows ({matchedShowsCount})
              </MenuItem>
              <MenuItem value="false">
                Unmatched Shows ({unmatchedShowsCount})
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Select Month</InputLabel>
            <Select
              value={monthFilter}
              onChange={(e) => setMonthFilter(e.target.value)}
              label="Select Month"
            >
              <MenuItem value="">All Months</MenuItem>
              {availableMonths.map((month) => (
                <MenuItem key={month} value={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Select Show</InputLabel>
            <Select
              value={selectedShow}
              onChange={handleShowSelect}
              label="Select Show"
            >
              {filteredShows.length > 0 ? (
                filteredShows.map((show) => (
                  <MenuItem key={show._id} value={show._id}>
                    {show.showName}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">No Shows Available</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Show Details */}
      <Paper className="section-card">
        <Typography variant="h6" className="section-title">
          Show Details
        </Typography>
        <Grid container spacing={2} id="showDetails">
          {showDetails ? (
            <>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <strong>Name:</strong> {showDetails.showName}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <strong>Last Update:</strong>{" "}
                  {new Date(showDetails.updatedAt).toLocaleDateString()}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <strong>Location:</strong> {showDetails.city || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <strong>Venue:</strong> {showDetails.venue || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <strong>Start Date:</strong> {showDetails.startDate || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <strong>End Date:</strong> {showDetails.endDate || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <strong>Hotels Number:</strong>{" "}
                  {showDetails.hotelsCount || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <strong>Status:</strong>{" "}
                  {showDetails.matched ? "Matched" : "Unmatched"}
                </Typography>
              </Grid>
            </>
          ) : (
            <Typography variant="body1">
              Select a show to see details
            </Typography>
          )}
        </Grid>
      </Paper>

      {/* Statistics */}
      <Paper className="section-card">
        <Typography variant="h6" className="section-title">
          Show Statistics
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6} className="stat-box blue">
            <Typography variant="body2">Companies</Typography>
            <Typography variant="h5" id="companyCount">
              {showDetails.companiesCount}
            </Typography>
          </Grid>
          <Grid item xs={6} className="stat-box green">
            <Typography variant="body2">Emails</Typography>
            <Typography variant="h5" id="emailCount">
              {showDetails.emailCount}
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      {/* Global Stats */}
      <Paper className="section-card">
        <Typography variant="h6" className="section-title">
          Global Stats
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6} className="stat-box blue">
            <Typography variant="body2">Total Shows With Pricing</Typography>
            <Typography variant="h5" id="totalShows">
              {totalShows}
            </Typography>
          </Grid>
          <Grid item xs={6} className="stat-box green">
            <Typography variant="body2">Total Companies</Typography>
            <Typography variant="h5" id="totalCompanies">
              {totalCompanies}
            </Typography>
            <Typography variant="body2">Total Emails</Typography>
            <Typography variant="h5" id="totalEmails">
              {totalEmails}
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <HotelsTable
        showDetails={showDetails}
        setShowDetails={setShowDetails}
        selectedHotels={selectedHotels}
        setSelectedHotels={setSelectedHotels}
      />
      <CompaniesTable
        showDetails={showDetails}
        setShowDetails={setShowDetails}
        selectedEmails={selectedEmails}
        setSelectedEmails={setSelectedEmails}
      />

      {/* Enhanced Email Preview Section */}
      <Paper className="section-card">
        <Typography variant="h6" className="section-title">
          Email Preview
        </Typography>
        <Grid container spacing={2} className="email-config">
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel>Transporter</InputLabel>
              <Select value={transporter} onChange={handleTransporterChange}>
                <MenuItem value="exclusiveskyline.com">
                  Exclusive Skyline
                </MenuItem>
                <MenuItem value="blubeds.com">BluBeds</MenuItem>
                <MenuItem value="skylinehousing.net">Skyline Housing</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel>Template Type</InputLabel>
              <Select value={templateType} onChange={handleTemplateTypeChange}>
                <MenuItem value="standard">Standard Welcome</MenuItem>
                <MenuItem value="followup">Follow-up</MenuItem>
                <MenuItem value="enhanced">Enhanced</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box mt={2} textAlign="right">
              <Button
                variant="contained"
                color="primary"
                onClick={fetchEmailContent}
              >
                Preview Email
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Typography variant="subtitle1">Custom Recipient</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="Company Name"
              name="companyName"
              variant="outlined"
              value={customRecipient.companyName}
              onChange={handleInputChange}
            />{" "}
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="DM Name"
              name="dmName"
              variant="outlined"
              value={customRecipient.dmName}
              onChange={handleInputChange}
            />{" "}
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              variant="outlined"
              value={customRecipient.email}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>

        <Box mt={2} textAlign="right">
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddRecipient}
          >
            Add Recipient
          </Button>
        </Box>
      </Paper>

      {/* Email Previews */}
      <Box className="email-previews" my={4}>
        <Grid container spacing={4}>
          {/* Text Email Preview */}
          <Grid item xs={12} md={6}>
            <Paper
              className="email-preview-section"
              elevation={2}
              sx={{
                p: 2,
                border:
                  selectedTemplateType === "text"
                    ? "3px solid #1976d2"
                    : "1px solid #ccc",
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h6" className="section-title">
                    Text Email Preview
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    className="preview-btn"
                    size="small"
                    variant="outlined"
                    id="selectTextBtn"
                    onClick={() => setSelectedTemplateType("text")}
                  >
                    Select
                  </Button>
                </Grid>
              </Grid>
              <Box id="emailPreviewHtml" className="email-preview-html" mt={1}>
                <Box
                  p={2}
                  dangerouslySetInnerHTML={{
                    __html:
                      textEmailPreview || "Text email preview will appear here",
                  }}
                />
              </Box>
            </Paper>
          </Grid>

          {/* HTML Email Preview */}
          <Grid item xs={12} md={6}>
            <Paper
              className="email-preview-section"
              elevation={2}
              sx={{
                p: 2,
                border:
                  selectedTemplateType === "html"
                    ? "3px solid #1976d2"
                    : "1px solid #ccc",
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h6" className="section-title">
                    HTML Email Preview
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    className="preview-btn"
                    size="small"
                    variant="outlined"
                    id="selectHtmlBtn"
                    onClick={() => setSelectedTemplateType("html")}
                  >
                    Select
                  </Button>
                </Grid>
              </Grid>
              <Box id="emailPreviewHtml" className="email-preview-html" mt={1}>
                <Box
                  p={2}
                  dangerouslySetInnerHTML={{
                    __html:
                      htmlEmailPreview || "HTML email preview will appear here",
                  }}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      <Box mb={4}>
        <Paper className="preview-box" elevation={2} sx={{ p: 2 }}>
          <Box sx={{ maxHeight: "250px", overflowY: "auto" }}>
            {selectedEmails && selectedEmails.length > 0 ? (
              selectedEmails.map((item, index) => (
                <Grid
                  container
                  key={index}
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                      size="small"
                      onClick={() =>
                        setSelectedEmails(
                          selectedEmails.filter((_, i) => i !== index)
                        )
                      }
                      aria-label="delete"
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                    <Typography
                      variant="body1"
                      fontWeight="medium"
                      sx={{ ml: 1 }}
                    >
                      Company:{" "}
                      <span id={`previewCompanyName-${index}`}>
                        {item.company}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body1"
                      fontWeight="medium"
                      color="primary"
                      id={`previewRecipientEmail-${index}`}
                    >
                      {item.email}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography
                      variant="body1"
                      fontWeight="medium"
                      color="primary"
                      id={`previewRecipientEmail-${index}`}
                    >
                      {item["Dm Name"]}
                    </Typography>
                  </Grid>
                </Grid>
              ))
            ) : (
              <Typography variant="body1" fontWeight="medium">
                No companies selected
              </Typography>
            )}
          </Box>
        </Paper>

        <Box mt={2} textAlign="right">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSendEmails}
          >
            Send Emails
          </Button>
        </Box>
      </Box>

      {/* Email Action Buttons
      <Box
        className="email-actions"
        mt={4}
        display="flex"
        justifyContent="space-between"
      >
        <Button id="sendCurrentEmailBtn" variant="contained" color="success">
          Send to Current Recipient
        </Button>
        <Button id="sendBulkEmailsBtn" variant="contained" color="secondary">
          Send to All Recipients in Show
        </Button>
      </Box> */}

      {/* Selected Template Notice */}
      <Paper
        id="selectedTemplateNotice"
        className="selected-template-notice"
        elevation={2}
        sx={{ mt: 4, display: "none" }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="body1">
              <span className="font-medium">Selected for sending: </span>
              <span id="selectedTemplateType">None</span>
            </Typography>
          </Grid>
          <Grid item>
            <Button
              id="clearSelectedTemplateBtn"
              variant="outlined"
              size="small"
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {/* Shows Without Emails Section */}
      <Paper
        className="shows-without-emails"
        elevation={2}
        sx={{ mt: 4, p: 3 }}
      >
        <Typography variant="h6" className="section-title" gutterBottom>
          Shows Without Emails
        </Typography>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Grid item>
            <Select
              id="pricingFilter"
              size="small"
              defaultValue="all"
              onChange={() => {
                /* Add handler */
              }}
            >
              <MenuItem value="all">All Shows (0)</MenuItem>
              <MenuItem value="hasPricing_noEmails">
                Has Pricing & No Emails (0)
              </MenuItem>
              <MenuItem value="noPricing_noEmails">
                No Pricing & No Emails (0)
              </MenuItem>
              <MenuItem value="hasPricing_hasEmails">
                Has Pricing & Has Emails (0)
              </MenuItem>
              <MenuItem value="noPricing_hasEmails">
                No Pricing & Has Emails (0)
              </MenuItem>
            </Select>
          </Grid>
          <Grid item>
            <Typography variant="body2" className="text-sm">
              <span id="showsWithoutEmailsCount">0</span> shows found
            </Typography>
          </Grid>
        </Grid>
        <Box className="table-container">
          <Table>
            <TableHead className="table-head">
              <TableRow>
                <TableCell
                  onClick={() => {
                    /* sort handler */
                  }}
                >
                  Show Name
                </TableCell>
                <TableCell
                  onClick={() => {
                    /* sort handler */
                  }}
                >
                  Companies
                </TableCell>
                <TableCell
                  onClick={() => {
                    /* sort handler */
                  }}
                >
                  Emails
                </TableCell>
                <TableCell
                  onClick={() => {
                    /* sort handler */
                  }}
                >
                  Has Pricing
                </TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody id="showsWithoutEmailsBody">
              {/* Rows dynamically inserted */}
            </TableBody>
          </Table>
        </Box>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <Grid item>
            <Typography
              variant="caption"
              color="textSecondary"
              id="paginationInfo"
            >
              0-0 of 0
            </Typography>
          </Grid>
          <Grid item>{/* Pagination buttons could be inserted here */}</Grid>
        </Grid>
      </Paper>

      {/* Shows Sent Section */}
      <Paper className="shows-sent" elevation={2} sx={{ mt: 4, p: 3 }}>
        <Typography variant="h6" className="section-title" gutterBottom>
          Shows Sent
        </Typography>
        <Typography variant="body2" color="textSecondary" mb={2}>
          Shows with at least one email sent to a company
        </Typography>
        <Box className="table-container">
          <Table>
            <TableHead className="table-head">
              <TableRow>
                <TableCell
                  onClick={() => {
                    /* sort handler */
                  }}
                >
                  Show Name
                </TableCell>
                <TableCell
                  onClick={() => {
                    /* sort handler */
                  }}
                >
                  Sent
                </TableCell>
                <TableCell
                  onClick={() => {
                    /* sort handler */
                  }}
                >
                  Opened
                </TableCell>
                <TableCell>Clicked</TableCell>
                <TableCell>Bounced</TableCell>
                <TableCell>Pending</TableCell>
              </TableRow>
            </TableHead>
            <TableBody id="showsSentBody">
              {/* Rows dynamically inserted */}
            </TableBody>
          </Table>
        </Box>
        <Box mt={2} textAlign="center">
          <Typography
            variant="caption"
            color="textSecondary"
            id="showsSentCount"
          >
            0 shows with sent emails
          </Typography>
        </Box>
      </Paper>

      {/* Error Message */}
      <Alert
        id="errorMessage"
        severity="error"
        sx={{
          display: "none",
          position: "fixed",
          top: 16,
          right: 16,
          zIndex: 1300,
        }}
      >
        {/* Error message content goes here */}
      </Alert>
    </Box>
  );
};

export default ShowDashboard;
