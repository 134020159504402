import React, { useState } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import styles from "./UploadCustomerSupportSection.module.css";
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Button,
} from "@mui/material";
import UploadOldLeads from "./UploadOldLeads";
import { baseUrl } from "../../services/BaseUrl";

const UploadCustomerSupportSection = () => {
  const [selectedLeadsFile, setSelectedLeadsFile] = useState(null);
  const [selectedShift, setSelectedShift] = useState("");
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");

  const handleShowsFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedLeadsFile(file);
  };

  const handleShiftChange = (event) => {
    setSelectedShift(event.target.value);
  };

  const handleShowsUpload = async () => {
    if (!selectedShift) {
      console.error("No shift selected");
      return;
    }

    if (selectedLeadsFile) {
      const formData = new FormData();
      formData.append("excelFile", selectedLeadsFile);
      formData.append("shift", selectedShift);
      setLoading(true);
      setProgress(0);
      try {
        const response = await axios.post(
          `${baseUrl}/api/customer-support/upload-old-leads`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (event) => {
              const percentCompleted = Math.round(
                (event.loaded * 100) / event.total
              );
              setProgress(percentCompleted);
            },
          }
        );
        console.log(response.data);
        setSelectedLeadsFile(null);
        setProgress(0);
      } catch (error) {
        console.error("Error uploading the file:", error);
      } finally {
        setLoading(false);
      }
    } else {
      console.error("No file selected for Leads");
    }
  };

  const handleDownloadTemplate = () => {
    const worksheetData = [
      [
        "Show",
        "Company",
        "Timezone",
        "Email",
        "Phone",
        "Status",
        "Dm Name",
        "Notes",
        "Agent Name",
      ],
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Template");

    // Create Excel file and trigger download
    XLSX.writeFile(workbook, "CustomerSupportLeadsTemplate.xlsx");
  };

  return (
    <div className={styles.container}>
      <p>Only Excel files can be uploaded</p>

      <FormControl
        sx={{
          marginBottom: 2,
          width: "400px",
          marginTop: 4,
          backgroundColor: "white",
        }}
      >
        <InputLabel>Select Shift</InputLabel>
        <Select value={selectedShift} onChange={handleShiftChange}>
          <MenuItem value="us">US Shift</MenuItem>
          <MenuItem value="uk">UK Shift</MenuItem>
        </Select>
      </FormControl>

      <div className={styles.controllersContainer}>
        <UploadOldLeads
          onFileChange={handleShowsFileChange}
          onUpload={handleShowsUpload}
          isLoading={loading}
          progress={progress}
          setProgress={setProgress}
        />
      </div>

      <Button
        variant="contained"
        color="primary"
        sx={{ marginTop: 2 }}
        onClick={handleDownloadTemplate}
      >
        Download Excel Template
      </Button>
    </div>
  );
};

export default UploadCustomerSupportSection;
