import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Paper,
  Select,
  MenuItem,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  TablePagination,
  Modal,
  TextField,
} from "@mui/material";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import { baseUrl } from "../../services/BaseUrl";

const CompaniesTable = ({
  showDetails,
  setShowDetails,
  selectedEmails,
  setSelectedEmails,
}) => {
  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [statusFilter, setStatusFilter] = useState("all");
  const [followupFilter, setFollowupFilter] = useState("all");
  const [openModal, setOpenModal] = useState(false);
  const [editCompany, setEditCompany] = useState(null);
  const { token } = useContext(UserContext);
  const emailRef = useRef(null);
  const dmNameRef = useRef(null);
  const mailStatusRef = useRef(null);
  const mailFollowupRef = useRef(null);

  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    setStatusFilter("all");
    setFollowupFilter("all");
    setSelectAll(false);
    setSelectedEmails([]);
    setPage(0);
  }, [showDetails]);

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
    setPage(0);
  };

  const handleFollowupFilterChange = (e) => {
    setFollowupFilter(e.target.value);
    setPage(0);
  };
  // Compute companies to display for current page
  const companies = showDetails.companies || [];

  // Filter companies based on selected status
  const filteredCompanies = companies.filter((company) => {
    // Filter by mailStatus
    if (statusFilter !== "all" && company.mailStatus !== statusFilter) {
      return false;
    }

    // Filter by mailFollowup
    if (followupFilter !== "all" && company.mailFollowup !== followupFilter) {
      return false;
    }

    return true; // "all" selected for both filters
  });

  const companiesToDisplay = filteredCompanies.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Helper: determine if company has a valid email
  const hasValidEmail = (company) =>
    company.email &&
    company.email.trim() !== "" &&
    company.email.trim().toUpperCase() !== "N/A";

  // Handle checkbox change for a company.
  const handleCheckboxChange = (company, checked) => {
    if (!hasValidEmail(company)) return;
    if (checked) {
      // Add the object if it doesn't already exist in selectedEmails
      if (!selectedEmails.some((obj) => obj.email === company.email)) {
        setSelectedEmails([
          ...selectedEmails,
          {
            company: company.companyName,
            email: company.email,
            "Dm Name": company["Dm Name"],
          },
        ]);
      }
    } else {
      // Remove the object
      setSelectedEmails(
        selectedEmails.filter((obj) => obj.email !== company.email)
      );
    }
  };

  // Handle Select All checkbox change
  const handleSelectAllChange = (e) => {
    const checked = e.target.checked;
    setSelectAll(checked);

    if (checked) {
      const allSelectedEmails = companies
        .filter((company) => hasValidEmail(company))
        .map((company) => ({
          company: company.companyName,
          email: company.email,
          "Dm Name": company["Dm Name"],
        }));
      setSelectedEmails(allSelectedEmails);
    } else {
      setSelectedEmails([]);
    }
  };

  const handleEditClick = (company) => {
    setEditCompany(company);
    setOpenModal(true);
  };

  const handleUpdateCompany = async () => {
    // Read values from refs only when Update is clicked
    const newEmail = emailRef.current.value;
    const newDmName = dmNameRef.current.value;
    const newMailStatus = mailStatusRef.current.value;
    const newMailFollowup = mailFollowupRef.current.value;

    try {
      await axios.put(
        `${baseUrl}/api/super/update-company-details`,
        {
          showId: showDetails._id,
          companyId: editCompany._id,
          email: newEmail,
          dmName: newDmName,
          mailStatus: newMailStatus,
          mailFollowup: newMailFollowup,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // Update the local state for this company in showDetails.companies
      setShowDetails((prev) => {
        if (!prev || !prev.companies) return prev;
        const updatedCompanies = prev.companies.map((company) => {
          if (company._id === editCompany._id) {
            return {
              ...company,
              email: newEmail,
              "Dm Name": newDmName,
              mailStatus: newMailStatus,
              mailFollowup: newMailFollowup,
            };
          }
          return company;
        });
        return { ...prev, companies: updatedCompanies };
      });
      setOpenModal(false);
    } catch (error) {
      console.error("Failed to update company", error);
    }
  };

  return (
    <Paper className="companies-table" elevation={2} sx={{ mt: 4, p: 3 }}>
      <Typography variant="h6" className="section-title" gutterBottom>
        Companies in Selected Show
      </Typography>
      <Grid container justifyContent="space-between" alignItems="center" mb={2}>
        <Grid item>
          <Typography variant="body2">
            <span className="font-medium">Total Companies:</span>{" "}
            <span id="companiesTableCount">{companies.length}</span>
          </Typography>
          <Typography variant="body2">
            <span className="font-medium">With Email:</span>{" "}
            <span id="companiesWithEmailCount">
              {companies.filter((c) => hasValidEmail(c)).length}
            </span>
          </Typography>
        </Grid>
        <Grid item>
          <Select
            id="companyStatusFilter"
            size="small"
            value={statusFilter}
            onChange={handleStatusFilterChange}
          >
            <MenuItem value="all">All Statuses</MenuItem>
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="Sent">Sent</MenuItem>
            <MenuItem value="Failed">Failed</MenuItem>
            <MenuItem value="Delivered">Delivered</MenuItem>
            <MenuItem value="Read">Read</MenuItem>
          </Select>

          <Select
            id="companyFollowupFilter"
            size="small"
            value={followupFilter}
            onChange={handleFollowupFilterChange}
          >
            <MenuItem value="all">All Follow-ups</MenuItem>
            <MenuItem value="Not Needed">Not Needed</MenuItem>
            <MenuItem value="Scheduled">Scheduled</MenuItem>
            <MenuItem value="Follow-up Pending">Follow-up Pending</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
            <MenuItem value="No Response">No Response</MenuItem>
          </Select>

          <Select
            id="companiesPerPage"
            size="small"
            value={rowsPerPage}
            onChange={handleChangeRowsPerPage}
            sx={{ ml: 1 }}
          >
            <MenuItem value={10}>10 per page</MenuItem>
            <MenuItem value={25}>25 per page</MenuItem>
            <MenuItem value={50}>50 per page</MenuItem>
            <MenuItem value={100}>100 per page</MenuItem>
          </Select>
        </Grid>
      </Grid>
      {/* Scrollable table container */}
      <Box
        className="table-container"
        sx={{ maxHeight: 300, overflowY: "auto" }}
      >
        <Table stickyHeader>
          <TableHead className="table-head">
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                />
              </TableCell>
              <TableCell>Company</TableCell>
              <TableCell>DM Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Follow-up</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody id="companiesTableBody">
            {companiesToDisplay.length > 0 ? (
              companiesToDisplay.map((company, index) => (
                <TableRow key={company._id || index}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      disabled={!hasValidEmail(company)}
                      checked={selectedEmails.some(
                        (obj) => obj.email === company.email
                      )}
                      onChange={(e) =>
                        handleCheckboxChange(company, e.target.checked)
                      }
                    />
                  </TableCell>
                  <TableCell>{company.companyName}</TableCell>
                  <TableCell>{company["Dm Name"]}</TableCell>
                  <TableCell>{company.email}</TableCell>
                  <TableCell>{company.phone}</TableCell>
                  <TableCell>{company.mailStatus}</TableCell>
                  <TableCell>{company.mailFollowup}</TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => handleEditClick(company)}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center" className="no-data">
                  No companies available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
      <Grid container justifyContent="space-between" alignItems="center" mt={2}>
        <Grid item>
          <Typography
            variant="caption"
            color="textSecondary"
            id="companyTableInfo"
          >
            {companies.length > 0
              ? `Showing ${page * rowsPerPage + 1} to ${
                  page * rowsPerPage + companiesToDisplay.length
                } of ${companies.length} companies`
              : "Showing 0 of 0 companies"}
          </Typography>
        </Grid>
        {/* <Grid item>
          <Button id="exportCompaniesCSV" variant="contained" color="primary" size="small">
            Export CSV
          </Button>
          <Button
            id="refreshCompaniesTable"
            variant="outlined"
            size="small"
            sx={{ ml: 1 }}
          >
            Refresh
          </Button>
        </Grid> */}
      </Grid>
      {/* Table Pagination */}
      <TablePagination
        component="div"
        count={companies.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[10, 25, 50, 100]}
      />

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={{
            p: 4,
            backgroundColor: "white",
            borderRadius: 2,
            width: 400,
            mx: "auto",
            mt: 10,
          }}
        >
          <Typography variant="h6" mb={2}>
            {editCompany ? editCompany.companyName : "Edit Company"}
          </Typography>
          <TextField
            fullWidth
            label="Email"
            defaultValue={editCompany ? editCompany.email : ""}
            inputRef={emailRef}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="DM Name"
            defaultValue={editCompany ? editCompany.dmName : ""}
            inputRef={dmNameRef}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Mail Status"
            select
            defaultValue={editCompany ? editCompany.mailStatus : ""}
            inputRef={mailStatusRef}
            sx={{ mb: 2 }}
          >
            {/* Add options here */}
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="Sent">Sent</MenuItem>
            <MenuItem value="Failed">Failed</MenuItem>
            <MenuItem value="Delivered">Delivered</MenuItem>
            <MenuItem value="Read">Read</MenuItem>
          </TextField>

          <TextField
            fullWidth
            label="Mail Follow-up"
            select
            defaultValue={editCompany ? editCompany.mailFollowup : ""}
            inputRef={mailFollowupRef}
            sx={{ mb: 2 }}
          >
            {/* Add options here */}
            <MenuItem value="Not Needed">Not Needed</MenuItem>
            <MenuItem value="Scheduled">Scheduled</MenuItem>
            <MenuItem value="Follow-up Pending">Follow-up Pending</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
            <MenuItem value="No Response">No Response</MenuItem>
          </TextField>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdateCompany}
          >
            Update
          </Button>
        </Box>
      </Modal>
    </Paper>
  );
};

export default CompaniesTable;
