import React, { useState, useCallback } from "react";
import "./AgentCloser.css"
import Layout from "../../components/layout/layout";
import SideBar from "../../components/SideBar/SideBar";
import MainContainer from "../../mainContainer/MainContainer";
import NewCloserLeads from "../../components/NewCloserLeads/NewCloserLeads";


const AgentCloser = () => {
  //sidebar logic ------------>
  const [currentComponent, setCurrentComponent] = useState(<NewCloserLeads />);
  const [currentPageTitle, setCurrentPageTitle] = useState("New Assigned Leads");

   //sidebar logic ------------>
   const sidebarButtons = [
    {
      label: "New Leads",
      component: <NewCloserLeads />,
      pageTitle: "New Assigned Leads",
    }
  ];

    //sidebar logic ------------>
    const handleComponentChange = useCallback((component, pageTitle) => {
        setCurrentComponent(component);
        setCurrentPageTitle(pageTitle);
    }, []);

  return (
    <Layout>
    <SideBar
      buttons={sidebarButtons}
      onComponentChange={handleComponentChange}
    />
    <MainContainer pageTitle={currentPageTitle}>
      <h2> Agent Closer View </h2>
      {currentComponent}
    </MainContainer>
  </Layout>
  )
}

export default AgentCloser