import React, { useState , useContext} from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { UserContext } from "../../context/UserContext";
import { baseUrl } from "../../services/BaseUrl";
import axios from "axios";

const callStyle = {
  borderRight: "2px solid #e0e0e0",
  fontSize: "14px",
  fontWeight: 550,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  padding: "14px",
};

const RawShowCreatePage = ({ handleButtonClose, rawData }) => {
const { token } = useContext(UserContext);
  const [companiesData, setCompaniesData] = useState(rawData.companies);
  const [openDialog, setOpenDialog] = useState(false);
  const [newLead, setNewLead] = useState({
    companyName: "",
    phone: "",
    email: "",
    link: "",
  });
  const [error, setError] = useState(null);


  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setError(null)
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewLead((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
     // Basic validation
     if (!newLead.companyName || !newLead.phone || !newLead.email || !newLead.link) {
        setError("Please fill all fields.");
        return;
      }
    try {
      const response = await axios.post(
        `${baseUrl}/api/companies/create-raw-lead/${rawData._id}`,
        {
          companyName: newLead.companyName,
          phone: newLead.phone,
          email: newLead.email,
          link: newLead.link,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCompaniesData(response.data.show.companies)

      // Clear input fields after successful creation
      setNewLead({
        companyName: "",
        phone: "",
        email: "",
        link: "",
      });

      // Close the dialog
      handleCloseDialog();
      setError(null)
    } catch (error) {
      console.error("Error creating lead:", error);
    }
  };


  return (
    <div className="raw-Show-Create-container">
      <div className="raw-Show-Create-header-container">
        <FaArrowLeftLong
          onClick={handleButtonClose}
          style={{ cursor: "pointer", fontSize: 22 }}
        />
        <button
          style={{
            padding: "10px 15px",
            backgroundColor: "#4f637d",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "14px",
          }}
          onClick={handleClickOpenDialog}
        >
          Create New Lead
        </button>
      </div>
      <div className="raw-Show-details-container">
        <div style={{ marginBottom: "10px" }}>
          <h2 style={{ textTransform: "capitalize" }}>
            <strong>Show Name : </strong>
            {rawData.showName}
          </h2>
        </div>
        <TableContainer
          component={Paper}
          sx={{ borderRadius: "8px", overflowX: "auto", maxHeight: "70vh" }}
          className="custom-scrollbar"
        >
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  background: "linear-gradient(to right, #fed766, #e8b6ea)",
                }}
              >
                {["Company Name", "Phone", "Email", "Timezone", "Link"].map(
                  (header, index) => (
                    <TableCell
                      key={header}
                      sx={{
                        color: "#fff",
                        borderRight: "2px solid #fff",
                        fontSize: "16px",
                        fontFamily: '"Playfair Display", serif',
                        whiteSpace: "nowrap",
                        wordBreak: "keep-all",
                      }}
                    >
                      {header}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {companiesData.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={5}
                    sx={{
                      borderRight: "2px solid #e0e0e0",
                      fontSize: "14px",
                      fontWeight: 550,
                      whiteSpace: "nowrap",
                      wordBreak: "keep-all",
                      padding: "14px",
                      textAlign: "center",
                    }}
                  >
                    No Data Available
                  </TableCell>
                </TableRow>
              ) : (
                companiesData.map((company, index) => (
                  <TableRow key={index}>
                    <TableCell sx={callStyle}>{company.companyName}</TableCell>
                    <TableCell sx={callStyle}>{company.phone}</TableCell>
                    <TableCell sx={callStyle}>{company.email}</TableCell>
                    <TableCell sx={callStyle}>{company.timezone}</TableCell>
                    <TableCell sx={callStyle}>{company.link}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Dialog open={openDialog}>
        <DialogTitle>Create New Lead</DialogTitle>
        <DialogContent onSubmit={handleSubmit}>
        <TextField
            label="Company Name"
            name="companyName"
            value={newLead.companyName}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Phone"
            name="phone"
            value={newLead.phone}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Email"
            name="email"
            value={newLead.email}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Link"
            name="link"
            value={newLead.link}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          {error && (<p className="message-error">{error}</p>)}
        </DialogContent>
        <DialogActions>
        <button
          style={{
            padding: "10px 15px",
            backgroundColor: "#4f637d",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "14px",
          }}
          onClick={handleCloseDialog} 
        >
          Cancel
        </button>
        <button
          style={{
            padding: "10px 15px",
            backgroundColor: "#4f637d",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "14px",
          }}
          onClick={handleSubmit} 
        >
          Create Lead
        </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RawShowCreatePage;
