import React, { useState, useCallback} from "react";
import Layout from "../../components/layout/layout";
import SideBar from "../../components/SideBar/SideBar";
import MainContainer from "../../mainContainer/MainContainer";
import EmailRequestNew from "../../components/EmailRequestNew/EmailRequestNew";
import HistoryEmailReq from "../../components/HistoryEmailReq/HistoryEmailReq";

const EmailReqTeam = () => {

  // Sidebar logic ------------------>
  const [currentComponent, setCurrentComponent] = useState(
    <EmailRequestNew />
  );
  const [currentPageTitle, setCurrentPageTitle] = useState("Email Request");

  const handleComponentChange = useCallback((component, pageTitle) => {
    setCurrentComponent(component);
    setCurrentPageTitle(pageTitle);
  }, []);

  const sidebarButtons = [
    {
      label: "Email Request",
      component: <EmailRequestNew  />,
      pageTitle: "Email Request",
    },
    {
      label: "History Email Request",
      component: <HistoryEmailReq />,
      pageTitle: "History Email Request",
    },
  ];

  return (
    <Layout>
      <SideBar
        buttons={sidebarButtons}
        onComponentChange={handleComponentChange}
      />
      <MainContainer pageTitle={currentPageTitle}>
        <h2>Email Team View</h2>
        {currentComponent}
      </MainContainer>
    </Layout>
  );
};

export default EmailReqTeam;
