import React, { useContext, useEffect, useState } from "react";
import "./MatchPricingShow.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Tooltip,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import axios from "axios";
import { baseUrl } from "../../services/BaseUrl";
import MatchingPopup from "../LocatorPopups/MatchingPopup";
import CreateRawShowPopup from "../LocatorPopups/CreateRawShowPopup";
import cityState from "../../utils/Timezones";
import UploadRawLeadsSection from "../LocatorPopups/Uploads/UploadRawLeadsSection";
import { FaArrowLeftLong } from "react-icons/fa6";
import { GoDash } from "react-icons/go";

const callStyle = {
  padding: 6,
  fontSize: 14,
  textAlign: "center",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  borderRight: "2px solid #e0e0e0",
  fontWeight: "450",
};

const MatchPricingShow = () => {
  const navigate = useNavigate();
  const [searchRowLeads, setSearchRowLeads] = useState("");
  const [searchRowPricings, setSearchRowPricings] = useState("");
  const { token, role } = useContext(UserContext);
  const [rawLeads, setRawLeads] = useState(null);
  const [finalRawLeads, setFinalRawLeads] = useState(null);
  const [rawPricings, setRawPricings] = useState(null);
  const [finalRawPricings, setFinalRawPricings] = useState(null);
  const [preMatchData, setPreMatchData] = useState([]);
  const [preShowsData, setPreShowsData] = useState([]);
  const [prePricingData, setPrePricingData] = useState([]);
  const [tableType, setTableType] = useState("");
  const [isHotelPopupActive, setIsHotelPopupActive] = useState(false);
  const [selectedPricing, setSelectedPricing] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [activeRowIdInLead, setActiveRowIdInLead] = useState(null);
  const [rawShowType, setRawShowType] = useState("");
  const [activeRowIdInPricing, setActiveRowIdInPricing] = useState(null);
  const [rawPricingType, setRawPricingType] = useState("");
  const [activeShowName, setActiveShowName] = useState(null);
  const [requestQueue, setRequestQueue] = useState([]);
  const [isProcessingQueue, setIsProcessingQueue] = useState(false);
  const [processStatuses, setProcessStatuses] = useState([]);
  const [openCreateRawShowModal, setOpenCreateRawShowModal] = useState(false);
  const [viewUploadSection, setViewUploadSection] = useState(false);
  const [locatedShows, setLocatedShows] = useState(null);
  const [viewPricingHotelsActive, setViewPricingHotelsActive] = useState(false);
  const [viewHotelsActive, setViewHotelsActive] = useState(false);
  const [formDataLocator, setFormDataLocator] = useState({
    edition: "",
    Show: "",
    "Show Url": "",
    "Next Dates": "",
    "End Date": "",
    Duration: "",
    "Convention Center / Host Hotel": "",
    Venue: "",
    "City, Country": "",
  });
  const [pricingStatus, setPricingStatus] = useState("");

  const handleSearchChange = (event) => {
    setSearchRowLeads(event.target.value);
  };

  const reorderData = (rawLeads, rawPricings, locatedShows) => {
    // Find matching leads and pricings
    const matchedLeads = rawLeads?.filter((lead) =>
      rawPricings?.some((pricing) =>
        locatedShows.some(
          (show) =>
            lead.showName === pricing.rawName &&
            pricing.rawName === show.rawName
        )
      )
    );

    const matchedPricings = rawPricings?.filter((pricing) =>
      rawLeads?.some((lead) =>
        locatedShows.some(
          (show) =>
            lead.showName === pricing.rawName &&
            pricing.rawName === show.rawName
        )
      )
    );

    // Separate unmatched leads and pricings
    const unmatchedLeads = rawLeads?.filter(
      (lead) => !matchedLeads?.includes(lead)
    );
    const unmatchedPricings = rawPricings?.filter(
      (pricing) => !matchedPricings?.includes(pricing)
    );
    let reorderedLeads;
    let reorderedPricings;
    if (matchedLeads && matchedPricings) {
      // Combine matched entries at the top
      reorderedLeads = [...matchedLeads, ...unmatchedLeads];
      reorderedPricings = [...matchedPricings, ...unmatchedPricings];
    }
    return { reorderedLeads, reorderedPricings };
  };

  // const formatDate = (dateString) => {
  //   if (dateString) {
  //     return format(new Date(dateString), "MMM dd, yyyy h:mm a");
  //   }
  // };

  useEffect(() => {
    fetchRawLeads();
    fetchRawPricings();
    fetchLocatedShows();
    fetchPreMatchData();
  }, []);

  useEffect(() => {
    if (preMatchData && rawLeads && rawPricings) {
      filterRawData();
    }
  }, [preMatchData, rawLeads, rawPricings]);

  const fetchLocatedShows = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/locator/located-shows-data`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLocatedShows(response.data.data);
    } catch (error) {
      console.error("Error fetching raw pricings", error);
    }
  };

  const fetchRawLeads = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/locator/get-raw-leads`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setRawLeads(response.data);
    } catch (error) {
      console.error("Error fetching raw leads", error);
    }
  };

  const fetchRawPricings = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/locator/get-raw-pricings`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRawPricings(response.data);
    } catch (error) {
      console.error("Error fetching raw pricings", error);
    }
  };

  const fetchPreMatchData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/locator/get-pre-match`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPreMatchData(response.data);
      setPreShowsData(response.data.showData);
      setPrePricingData(response.data.pricingData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };

  const filterRawData = () => {
    if (!preMatchData || !rawLeads || !rawPricings) return;

    const matchedShowNames = preMatchData.showData?.map(
      (show) => show.showName
    );
    const matchedRawNames = preMatchData.pricingData?.map(
      (pricing) => pricing.rawName
    );

    const filteredRawShows = rawLeads.filter(
      (show) => !matchedShowNames?.includes(show.showName)
    );
    const filteredRawPricings = rawPricings.filter(
      (pricing) => !matchedRawNames?.includes(pricing.rawName)
    );

    // **Only update state if data has changed**
    if (
      JSON.stringify(filteredRawShows) !== JSON.stringify(rawLeads) ||
      JSON.stringify(filteredRawPricings) !== JSON.stringify(rawPricings)
    ) {
      setRawLeads(filteredRawShows);
      setRawPricings(filteredRawPricings);
    }
  };

  const { reorderedLeads, reorderedPricings } = reorderData(
    rawLeads,
    rawPricings,
    locatedShows
  );

  const filteredRowData = reorderedLeads?.filter((row) =>
    row.showName?.toLowerCase().includes(searchRowLeads.toLowerCase())
  );

  const handleSearchPricings = (event) => {
    setSearchRowPricings(event.target.value);
  };

  const filteredRowPricings = reorderedPricings?.filter((row) =>
    row.showName?.toLowerCase().includes(searchRowPricings.toLowerCase())
  );

  const handleViewPricingData = (pricing) => {
    console.log(pricing);

    setRawPricingType(pricing.type);
    setSelectedPricing(pricing);
    setIsHotelPopupActive(true);
    setViewPricingHotelsActive(true);
    setPricingStatus(pricing?.["Pricing Status"]);
  };

  const handlecloseViewPricingData = () => {
    setSelectedPricing(null);
    setViewPricingHotelsActive(false);
    setViewHotelsActive(false);
  };

  const handleClosePricingPopup = () => {
    setIsHotelPopupActive(false);
  };

  const handleCloseMatchingPopup = () => {
    setFormDataLocator({
      edition: "",
      Show: "",
      "Show Url": "",
      "Next Dates": "",
      "End Date": "",
      Duration: "",
      "Convention Center / Host Hotel": "",
      Venue: "",
      "City, Country": "",
      description: "",
      similarShows: [],
    });

    setIsPopupVisible(false);
  };

  const handleLocatedShowClick = (locatedData) => {
    const duration = calculateDuration(
      locatedData.startDate,
      locatedData.endDate
    );

    const edition = extractYear(locatedData.startDate);
    const formattedNextDates = formatDateToISO(locatedData.startDate);
    const formattedEndDate = formatDateToISO(locatedData.endDate);

    const locatedCityName = locatedData.city.split(",")[0].trim().toLowerCase();

    // Find the matching city from cityState by comparing only the city part before the comma
    const matchedCity = cityState.find((city) => {
      const cityName = city.split(",")[0].trim().toLowerCase();
      return cityName === locatedCityName;
    });

    // If a match is found, use it, otherwise, set to "N/A" or leave empty
    const cityCountry = matchedCity ? matchedCity : locatedData.city;

    setFormDataLocator({
      ...formDataLocator,
      edition: edition,
      Show: locatedData.showName,
      "Next Dates": formattedNextDates,
      "End Date": formattedEndDate,
      Duration: duration,
      Venue: locatedData.venue,
      "City, Country": cityCountry,
    });
  };

  const extractYear = (dateStr) => {
    const [day, month, year] = dateStr.split("/").map(Number);
    return year.toString();
  };

  const formatDateToISO = (dateStr) => {
    const [day, month, year] = dateStr.split("/").map(Number);
    return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(
      2,
      "0"
    )}`;
  };

  const convertToDDMMYYYY = (dateStr) => {
    // Split the date string into day, month, and year
    const [day, month, year] = dateStr.split("/").map(Number);

    // Return the date in MM/DD/YYYY format
    return `${day}/${month}/${year}`;
  };

  const calculateDuration = (startDateStr, endDateStr) => {
    // Convert date strings from dd/mm/yyyy to mm/dd/yyyy
    const startDate = new Date(convertToDDMMYYYY(startDateStr));
    const endDate = new Date(convertToDDMMYYYY(endDateStr));

    // Calculate the duration in days
    const durationInDays = Math.ceil(
      (endDate - startDate) / (1000 * 60 * 60 * 24)
    );

    return durationInDays;
  };

  const parseShowName = (showName) => {
    // Split the string by commas
    const parts = showName.split(",").map((part) => part.trim());

    // Extract the relevant parts
    const show = parts[0] || "";
    const nextDates = parts[1] || "";
    const endDate = parts[2] || "";

    return [show, nextDates, endDate];
  };

  const handleMatchButtonClick = () => {
    if (activeRowIdInLead !== null && activeRowIdInPricing !== null) {
      let selectedPricing;
      if (tableType === "pre") {
        selectedPricing = prePricingData.find(
          (pricing) => pricing._id === activeRowIdInPricing
        );
      } else {
        selectedPricing = rawPricings.find(
          (pricing) => pricing._id === activeRowIdInPricing
        );
      }

      const showName = selectedPricing.showName;

      const [show, nextDates, endDate] = parseShowName(showName);

      const duration = calculateDuration(nextDates, endDate);
      const formattedNextDates = formatDateToISO(nextDates);
      const formattedEndDate = formatDateToISO(endDate);
      setIsPopupVisible(true);

      setFormDataLocator({
        ...formDataLocator,
        Show: showName,
        "Next Dates": formattedNextDates || "",
        "End Date": formattedEndDate || "",
        Duration: duration,
      });
    }
  };

  const handleLeadRowClick = (id, showName, showType, table) => {
    console.log(showType);
    setActiveRowIdInLead(id);
    setActiveShowName(showName);
    setRawShowType(showType);
    if (table === "pre") {
      setTableType("pre");
    }
  };

  const handlePricingRowClick = (id, pricingType, table) => {
    setActiveRowIdInPricing(id);
    setRawPricingType(pricingType);
    if (table === "pre") {
      setTableType("pre");
    }
  };

  const handleButtonClick = async (e) => {
    handleCloseMatchingPopup();

    // Add request to queue and handle it
    try {
      await addRequestToQueue(
        formDataLocator,
        activeRowIdInLead,
        activeRowIdInPricing
      );
      // Reset form data after processing
      setFormDataLocator({
        edition: "",
        Show: "",
        "Show Url": "",
        "Next Dates": "",
        "End Date": "",
        Duration: "",
        "Convention Center / Host Hotel": "",
        Venue: "",
        "City, Country": "",
      });
    } catch (error) {
      console.error("Error processing request:", error);
    }
  };

  const addRequestToQueue = (
    formDataLocator,
    activeRowIdInLead,
    activeRowIdInPricing
  ) => {
    return new Promise((resolve, reject) => {
      const newRequest = {
        id: Date.now(), // Unique ID for each request
        formDataLocator,
        activeRowIdInLead,
        activeRowIdInPricing,
        resolve,
        reject,
        status: "Waiting",
      };

      // Add the request to the queue and initialize status as "Waiting"
      setRequestQueue((prevQueue) => [...prevQueue, newRequest]);
      setProcessStatuses((prevStatuses) => [
        ...prevStatuses,
        { id: newRequest.id, show: formDataLocator.Show, status: "Waiting" },
      ]);
    });
  };

  useEffect(() => {
    const processQueueAsync = async () => {
      if (requestQueue.length > 0 && !isProcessingQueue) {
        setIsProcessingQueue(true);
        await processQueue();
      }
    };
    processQueueAsync();
  }, [requestQueue]);

  const processQueue = async () => {
    setIsProcessingQueue(true);

    if (requestQueue.length === 0) {
      setIsProcessingQueue(false);
      return;
    }

    // Set the status of the first request in the queue to "Pending"
    const currentRequest = requestQueue[0];
    setProcessStatuses((prevStatuses) =>
      prevStatuses.map((req) =>
        req.id === currentRequest.id ? { ...req, status: "Pending" } : req
      )
    );

    try {
      const {
        formDataLocator,
        activeRowIdInLead,
        activeRowIdInPricing,
        resolve,
      } = currentRequest;

      const payload = {
        ...formDataLocator, // Spread existing data
        showType: rawShowType, // Add showType as a string
        pricingType: rawPricingType, // Add pricingType as a string
      };
      await axios.post(
        `${baseUrl}/api/locator/match-pricing-show/${activeRowIdInLead}/${activeRowIdInPricing}`,
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Update the status to "Completed" after successful request
      const updatedStatus = { show: formDataLocator.Show, status: "Completed" };
      setProcessStatuses((prev) =>
        prev.map((req) => (req.id === currentRequest.id ? updatedStatus : req))
      );

      // Resolve the promise for this request
      resolve(updatedStatus);
    } catch (error) {
      console.error("Request error:", error);
      setProcessStatuses((prev) =>
        prev.map((req) =>
          req.id === currentRequest.id ? { ...req, status: "Failed" } : req
        )
      );
      // Handle rejection in case of error
      currentRequest.reject(error);
    }

    // Remove the completed request from the queue and process the next one
    setRequestQueue((prevQueue) => prevQueue.slice(1));
    setIsProcessingQueue(false);
  };

  const handleChangedata = (e) => {
    const { id, name, value } = e.target;
    setFormDataLocator((prevData) => ({
      ...prevData,
      [id]: value,
      [name]: value,
    }));
  };

  const handleCreateRawShowClick = () => {
    setOpenCreateRawShowModal(true);
  };

  const handleCloseCreateRawShowModal = () => {
    setOpenCreateRawShowModal(false); // Close the modal
  };

  const handleViewUploadSection = (showId) => {
    setActiveRowIdInLead(showId);
    setViewUploadSection(true);
  };

  const handleCloseUploadSection = () => {
    setViewUploadSection(false);
  };

  const handleDeletePricing = async (pricingId) => {
    if (window.confirm("Are you sure you want to delete this pricing?")) {
      try {
        const response = await axios.delete(
          `${baseUrl}/api/locator/delete-raw-pricing/${pricingId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          alert("Pricing deleted successfully.");
          fetchRawPricings();
        } else {
          alert("Failed to delete pricing. Please try again.");
        }
      } catch (error) {
        console.error("Error deleting pricing:", error);
        alert("An error occurred while deleting the pricing.");
      }
    }
  };

  const handlePricingChange = async (event) => {
    const selectedPricingStatus = event.target.value;
    setPricingStatus(selectedPricingStatus);

    const payload = {
      "Pricing Status": selectedPricingStatus,
      pricingType: rawPricingType,
    };
    const pricingId = selectedPricing._id;

    try {
      await axios.put(
        `${baseUrl}/api/locator/update-pricing/${pricingId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Pricing status updated successfully");
    } catch (error) {
      console.error("Error updating pricing status:", error);
    }
  };

  const handleViewDublicaedLeads = (show, showId) => {
    const fetchDublicatedLeadsOfTheShow = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/locator/duplicated-raw-leads/${showId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data);

        // Only navigate after the leads have been fetched

        const encodedShow = encodeURIComponent(show);
        navigate(`/leads-of-raw-show/${encodedShow}`, {
          state: { showLeads: response.data, show, showId, type: "dublicated" },
        });
      } catch (error) {
        console.error(`Error fetching Leads of ${show}:`, error);
      }
    };

    fetchDublicatedLeadsOfTheShow();
  };

  console.log(role);
  console.log(selectedPricing);

  return (
    <>
      {!viewPricingHotelsActive ? (
        <div className="matching-Pricing-container">
          {processStatuses.length > 0 && (
            <div className="request-status-container">
              {processStatuses.map((req) => (
                <div key={req.id} className={`request-status ${req.status}`}>
                  <p>Show: {req.show}</p>
                  <p>Status: {req.status}</p>
                </div>
              ))}
            </div>
          )}
          <div className="tables-matching-Pricing-section">
            <div className="matching-button-container">
              <button
                className="matching-linear-button"
                onClick={handleMatchButtonClick}
              >
                <p>Match Data</p>
              </button>
            </div>
            <div className="tables-matcing-container">
              <div className="table-match-pricing-contain">
                <div className="table-matching-table-button">
                  <h2
                    style={{
                      letterSpacing: 1,
                      color: "#6c4c6e",
                      marginBottom: "10px",
                    }}
                  >
                    Pre Matching Raw Shows
                  </h2>
                </div>
                <div className="table-matchPrice-contain">
                  <TableContainer
                    component={Paper}
                    sx={{
                      maxHeight: "70vh",
                      overflowY: "auto",
                      position: "relative",
                    }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow
                          sx={{
                            background: "#8b5a5a",
                            color: "#fff",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          {[
                            "No",
                            "Show Name",
                            "Companies Count",
                            "Active Companies count",
                            "Type",
                            "Updated At",
                            "Duplicates",
                            "Upload",
                          ].map((header, index) => (
                            <TableCell
                              key={header}
                              sx={{
                                fontSize: 15,
                                fontWeight: 550,
                                borderRight: "2px solid #fff",
                                whiteSpace: "nowrap",
                                wordBreak: "keep-all",
                                padding: "6px 10px",
                                color: "#fff",
                                textTransform: "capitalize",
                                letterSpacing: "1px",
                                textAlign: "center",
                              }}
                            >
                              {header}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {preShowsData?.length > 0 ? (
                          preShowsData?.map((row, index) => (
                            <TableRow
                              key={row._id}
                              onClick={() =>
                                handleLeadRowClick(
                                  row._id,
                                  row.showName,
                                  row.type || "default",
                                  "pre"
                                )
                              }
                              style={{
                                backgroundImage:
                                  row._id === activeRowIdInLead
                                    ? "linear-gradient(to right, #ded5e6, #ddd9d9, #fff)"
                                    : "none",
                                cursor: "pointer",
                                border: "1px solid #ddd",
                              }}
                            >
                              <TableCell style={callStyle}>
                                {index + 1}
                              </TableCell>
                              <TableCell style={callStyle}>
                                <Tooltip
                                  title={
                                    row.timezoneCounts &&
                                    Object.keys(row.timezoneCounts).length >
                                      0 ? (
                                      <ul
                                        style={{
                                          margin: 0,
                                          padding: "5px",
                                          listStyle: "none",
                                        }}
                                      >
                                        {Object.entries(row.timezoneCounts).map(
                                          ([timezone, count]) => (
                                            <li key={timezone}>
                                              <strong>{timezone}:</strong>{" "}
                                              {count}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    ) : (
                                      "No timezone data available"
                                    )
                                  }
                                  arrow
                                >
                                  <span>{row.showName}</span>
                                </Tooltip>
                              </TableCell>
                              <TableCell style={callStyle}>
                                {row.companyCount}
                              </TableCell>
                              <TableCell style={callStyle}>
                                {row.activeLeadsCount}
                              </TableCell>
                              <TableCell style={callStyle}>
                                {row.type || "Auto Generated"}
                              </TableCell>
                              <TableCell style={callStyle}>
                                {new Date(row.updatedAt).toLocaleString(
                                  "en-US",
                                  {
                                    month: "short", // Short form of the month (e.g., "Aug")
                                    day: "numeric", // Numeric day of the month (e.g., "27")
                                    year: "numeric", // Year in numeric format (e.g., "2024")
                                    hour: "numeric", // Hour in 12-hour format (e.g., "12")
                                    minute: "numeric", // Minute (e.g., "47")
                                    second: "numeric", // Second (e.g., "52")
                                    hour12: true, // Use AM/PM format
                                  }
                                )}
                              </TableCell>

                              <TableCell style={callStyle}>
                                <button
                                  className="matching-linear-button"
                                  onClick={() =>
                                    handleViewDublicaedLeads(
                                      row.showName,
                                      row._id
                                    )
                                  }
                                >
                                  Duplicates
                                </button>
                              </TableCell>
                              {row.type?.toLowerCase() ===
                                "manually generated" && (
                                <TableCell style={callStyle}>
                                  <button
                                    className="matching-linear-button"
                                    onClick={() =>
                                      handleViewUploadSection(row._id)
                                    }
                                  >
                                    Upload
                                  </button>
                                </TableCell>
                              )}
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={4}
                              sx={{
                                textAlign: "center",
                                padding: "16px",
                                fontSize: "14px",
                                fontWeight: 550,
                              }}
                            >
                              <h3>There is no data here</h3>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
              <div className="table-match-pricing-contain">
                <div className="table-matching-table-button">
                  <h2
                    style={{
                      letterSpacing: 1,
                      color: "#6c4c6e",
                      marginBottom: "10px",
                    }}
                  >
                    Pre Matching Raw Pricings
                  </h2>
                </div>
                <div className="table-matchPrice-contain">
                  <TableContainer
                    component={Paper}
                    sx={{
                      maxHeight: "70vh",
                      overflowY: "auto",
                      position: "relative",
                    }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow
                          sx={{
                            background: "#8b5a5a",
                            color: "#fff",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          {[
                            "No",
                            "Raw Name",
                            "Show Name",
                            "Type",
                            "Is Expired",
                            "last modified",
                            "View",
                            "Delete",
                          ].map((header, index) => (
                            <TableCell
                              key={header}
                              sx={{
                                fontSize: 15,
                                fontWeight: 550,
                                borderRight: "2px solid #fff",
                                whiteSpace: "nowrap",
                                wordBreak: "keep-all",
                                padding: "6px 10px",
                                color: "#fff",
                                textTransform: "capitalize",
                                letterSpacing: "1px",
                                textAlign: "center",
                              }}
                            >
                              {header}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {prePricingData?.length > 0 ? (
                          prePricingData?.map((row, index) => (
                            <TableRow
                              key={row._id}
                              onClick={() =>
                                handlePricingRowClick(
                                  row._id,
                                  row.type || "default",
                                  "pre"
                                )
                              }
                              style={{
                                backgroundImage:
                                  row._id === activeRowIdInPricing
                                    ? "linear-gradient(to right, #ded5e6, #ddd9d9, #fff)"
                                    : "none",
                                cursor: "pointer",
                                border: "1px solid #ddd",
                              }}
                            >
                              <TableCell style={callStyle}>
                                {index + 1}
                              </TableCell>
                              <TableCell
                                style={{
                                  padding: 8,
                                  fontSize: 14,
                                  textAlign: "center",
                                  borderRight: "2px solid #e0e0e0",
                                  fontWeight: "450",
                                }}
                              >
                                {row.rawName || "N/A"}
                              </TableCell>
                              <TableCell
                                style={{
                                  padding: 8,
                                  fontSize: 14,
                                  textAlign: "center",
                                  borderRight: "2px solid #e0e0e0",
                                  fontWeight: "450",
                                }}
                              >
                                {row.showName}
                              </TableCell>
                              <TableCell style={callStyle}>
                                {row.type || "Auto Generated"}
                              </TableCell>
                              <TableCell style={callStyle}>
                                {row.isExpired ? "Yes" : "No"}
                              </TableCell>
                              <TableCell style={callStyle}>
                                {new Date(row.lastModified).toLocaleString(
                                  "en-US",
                                  {
                                    month: "short", // Short form of the month (e.g., "Aug")
                                    day: "numeric", // Numeric day of the month (e.g., "27")
                                    year: "numeric", // Year in numeric format (e.g., "2024")
                                    hour: "numeric", // Hour in 12-hour format (e.g., "12")
                                    minute: "numeric", // Minute (e.g., "47")
                                    second: "numeric", // Second (e.g., "52")
                                    hour12: true, // Use AM/PM format
                                  }
                                )}
                              </TableCell>
                              <TableCell style={callStyle}>
                                <button
                                  className="matching-linear-button"
                                  onClick={() => handleViewPricingData(row)}
                                >
                                  View
                                </button>
                              </TableCell>
                              <TableCell style={callStyle}>
                                <button
                                  className="matching-linear-button"
                                  style={{ background: "red" }}
                                  onClick={() => handleDeletePricing(row._id)}
                                >
                                  Delete
                                </button>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={6}
                              sx={{
                                textAlign: "center",
                                padding: "16px",
                                fontSize: "14px",
                                fontWeight: 550,
                              }}
                            >
                              <h3>There is no data here</h3>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
            <div className="tables-matcing-container">
              <div className="table-match-pricing-contain">
                <div className="table-matching-table-button">
                  <h2 style={{ letterSpacing: 1, color: "#6c4c6e" }}>
                    Raw Leads
                  </h2>
                  <button
                    className="matching-linear-button"
                    onClick={handleCreateRawShowClick}
                    style={{ marginBottom: "0.5rem" }}
                  >
                    Create Raw Show
                  </button>
                </div>
                <div className="table-match-search-contain">
                  <input
                    type="text"
                    placeholder="Search Leads..."
                    className="table-search-input"
                    value={searchRowLeads}
                    onChange={handleSearchChange}
                  />
                </div>

                <div className="table-matchPrice-contain">
                  <TableContainer
                    component={Paper}
                    sx={{
                      maxHeight: "70vh",
                      overflowY: "auto",
                      position: "relative",
                    }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow
                          sx={{
                            background: "#8b5a5a",
                            color: "#fff",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          {[
                            "No",
                            "Show Name",
                            "Companies Count",
                            "Active Companies count",
                            "Type",
                            "updated At",
                            "Duplicates",
                            "Upload",
                          ].map((header, index) => (
                            <TableCell
                              key={header}
                              sx={{
                                fontSize: 15,
                                fontWeight: 550,
                                borderRight: "2px solid #fff",
                                whiteSpace: "nowrap",
                                wordBreak: "keep-all",
                                padding: "6px 10px",
                                color: "#fff",
                                textTransform: "capitalize",
                                letterSpacing: "1px",
                                textAlign: "center",
                              }}
                            >
                              {header}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredRowData?.length > 0 ? (
                          filteredRowData?.map((row, index) => (
                            <TableRow
                              key={row._id}
                              onClick={() =>
                                handleLeadRowClick(
                                  row._id,
                                  row.showName,
                                  row.type || "default"
                                )
                              }
                              style={{
                                backgroundImage:
                                  row._id === activeRowIdInLead
                                    ? "linear-gradient(to right, #ded5e6, #ddd9d9, #fff)"
                                    : "none",
                                cursor: "pointer",
                                border: "1px solid #ddd",
                              }}
                            >
                              <TableCell style={callStyle}>
                                {index + 1}
                              </TableCell>
                              <TableCell style={callStyle}>
                                <Tooltip
                                  title={
                                    row.timezoneCounts &&
                                    Object.keys(row.timezoneCounts).length >
                                      0 ? (
                                      <ul
                                        style={{
                                          margin: 0,
                                          padding: "5px",
                                          listStyle: "none",
                                        }}
                                      >
                                        {Object.entries(row.timezoneCounts).map(
                                          ([timezone, count]) => (
                                            <li key={timezone}>
                                              <strong>{timezone}:</strong>{" "}
                                              {count}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    ) : (
                                      "No timezone data available"
                                    )
                                  }
                                  arrow
                                >
                                  <span>{row.showName}</span>
                                </Tooltip>
                              </TableCell>
                              <TableCell style={callStyle}>
                                {row.companyCount}
                              </TableCell>
                              <TableCell style={callStyle}>
                                {row.activeLeadsCount}
                              </TableCell>
                              <TableCell style={callStyle}>
                                {row.type || "Auto Generated"}
                              </TableCell>
                              <TableCell style={callStyle}>
                                {new Date(row.updatedAt).toLocaleString(
                                  "en-US",
                                  {
                                    month: "short", // Short form of the month (e.g., "Aug")
                                    day: "numeric", // Numeric day of the month (e.g., "27")
                                    year: "numeric", // Year in numeric format (e.g., "2024")
                                    hour: "numeric", // Hour in 12-hour format (e.g., "12")
                                    minute: "numeric", // Minute (e.g., "47")
                                    second: "numeric", // Second (e.g., "52")
                                    hour12: true, // Use AM/PM format
                                  }
                                )}
                              </TableCell>

                              <TableCell style={callStyle}>
                                <button
                                  className="matching-linear-button"
                                  onClick={() =>
                                    handleViewDublicaedLeads(
                                      row.showName,
                                      row._id
                                    )
                                  }
                                >
                                  Duplicates
                                </button>
                              </TableCell>
                              {row.type.toLowerCase() ===
                                "manually generated" && (
                                <TableCell style={callStyle}>
                                  <button
                                    className="matching-linear-button"
                                    onClick={() =>
                                      handleViewUploadSection(row._id)
                                    }
                                  >
                                    Upload
                                  </button>
                                </TableCell>
                              )}
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={4}
                              sx={{
                                textAlign: "center",
                                padding: "16px",
                                fontSize: "14px",
                                fontWeight: 550,
                              }}
                            >
                              <h3>There is no data here</h3>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
              <div className="table-match-pricing-contain">
                <div className="table-matching-table-button">
                  <h2
                    style={{
                      letterSpacing: 1,
                      color: "#6c4c6e",
                      marginBottom: "10px",
                    }}
                  >
                    Raw Pricings
                  </h2>
                </div>
                <div className="table-match-search-contain">
                  <input
                    type="text"
                    placeholder="Search Pricings..."
                    className="table-search-input"
                    value={searchRowPricings}
                    onChange={handleSearchPricings}
                  />
                </div>
                <div className="table-matchPrice-contain">
                  <TableContainer
                    component={Paper}
                    sx={{
                      maxHeight: "70vh",
                      overflowY: "auto",
                      position: "relative",
                    }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow
                          sx={{
                            background: "#8b5a5a",
                            color: "#fff",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          {[
                            "No",
                            "Raw Name",
                            "Show Name",
                            "Type",
                            "Is Expired",
                            "last modified",
                            "View",
                            "Delete",
                          ].map((header, index) => (
                            <TableCell
                              key={header}
                              sx={{
                                fontSize: 15,
                                fontWeight: 550,
                                borderRight: "2px solid #fff",
                                whiteSpace: "nowrap",
                                wordBreak: "keep-all",
                                padding: "6px 10px",
                                color: "#fff",
                                textTransform: "capitalize",
                                letterSpacing: "1px",
                                textAlign: "center",
                              }}
                            >
                              {header}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredRowPricings?.length > 0 ? (
                          filteredRowPricings?.map((row, index) => (
                            <TableRow
                              key={row._id}
                              onClick={() =>
                                handlePricingRowClick(
                                  row._id,
                                  row.type || "default"
                                )
                              }
                              style={{
                                backgroundImage:
                                  row._id === activeRowIdInPricing
                                    ? "linear-gradient(to right, #ded5e6, #ddd9d9, #fff)"
                                    : "none",
                                cursor: "pointer",
                                border: "1px solid #ddd",
                              }}
                            >
                              <TableCell style={callStyle}>
                                {index + 1}
                              </TableCell>
                              <TableCell
                                style={{
                                  padding: 8,
                                  fontSize: 14,
                                  textAlign: "center",
                                  borderRight: "2px solid #e0e0e0",
                                  fontWeight: "450",
                                }}
                              >
                                {row.rawName || "N/A"}
                              </TableCell>
                              <TableCell
                                style={{
                                  padding: 8,
                                  fontSize: 14,
                                  textAlign: "center",
                                  borderRight: "2px solid #e0e0e0",
                                  fontWeight: "450",
                                }}
                              >
                                {row.showName}
                              </TableCell>
                              <TableCell style={callStyle}>
                                {row.type || "Auto Generated"}
                              </TableCell>
                              <TableCell style={callStyle}>
                                {row.isExpired ? "Yes" : "No"}
                              </TableCell>
                              <TableCell style={callStyle}>
                                {new Date(row.lastModified).toLocaleString(
                                  "en-US",
                                  {
                                    month: "short", // Short form of the month (e.g., "Aug")
                                    day: "numeric", // Numeric day of the month (e.g., "27")
                                    year: "numeric", // Year in numeric format (e.g., "2024")
                                    hour: "numeric", // Hour in 12-hour format (e.g., "12")
                                    minute: "numeric", // Minute (e.g., "47")
                                    second: "numeric", // Second (e.g., "52")
                                    hour12: true, // Use AM/PM format
                                  }
                                )}
                              </TableCell>
                              <TableCell style={callStyle}>
                                <button
                                  className="matching-linear-button"
                                  onClick={() => handleViewPricingData(row)}
                                >
                                  View
                                </button>
                              </TableCell>
                              <TableCell style={callStyle}>
                                <button
                                  className="matching-linear-button"
                                  style={{ background: "red" }}
                                  onClick={() => handleDeletePricing(row._id)}
                                >
                                  Delete
                                </button>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={6}
                              sx={{
                                textAlign: "center",
                                padding: "16px",
                                fontSize: "14px",
                                fontWeight: 550,
                              }}
                            >
                              <h3>There is no data here</h3>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
            {isPopupVisible && (
              <MatchingPopup
                handleClosePopup={handleCloseMatchingPopup}
                activeShowName={activeShowName}
                formDataLocator={formDataLocator}
                handleChangedata={handleChangedata}
                handleButtonClick={handleButtonClick}
                locatedShows={locatedShows}
                handleLocatedShowClick={handleLocatedShowClick}
              />
            )}
            {openCreateRawShowModal && (
              <CreateRawShowPopup
                open={openCreateRawShowModal} // Control visibility
                onClose={handleCloseCreateRawShowModal} // Close function
                token={token} // Pass the token
              />
            )}
            {viewUploadSection && (
              <div className="modal-backdrop">
                <div className="upload-modal-content">
                  <UploadRawLeadsSection showId={activeRowIdInLead} />

                  <button
                    onClick={handleCloseUploadSection}
                    className="matching-linear-button"
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        selectedPricing && (
          <div className="matching-Pricing-container">
            <div className="answer-header-container">
              <FaArrowLeftLong
                onClick={handlecloseViewPricingData}
                style={{ cursor: "pointer", fontSize: 22 }}
              />
            </div>
            <div className="operation-user-report-info">
              <h3 style={{ textTransform: "capitalize", letterSpacing: "1px" }}>
                <span style={{ color: "#e8b6ea" }}>Show Name :</span>{" "}
                {selectedPricing.showName}
              </h3>
              <h3 style={{ textTransform: "capitalize", letterSpacing: "1px" }}>
                <span style={{ color: "#e8b6ea" }}>Date Modified :</span>{" "}
                {new Date(selectedPricing.lastModified).toLocaleString(
                  "en-US",
                  {
                    weekday: "short", // Short form of weekday (e.g., "Mon")
                    month: "short", // Short form of the month (e.g., "Aug")
                    day: "numeric", // Numeric day of the month (e.g., "27")
                    year: "numeric", // Year in numeric format (e.g., "2024")
                    hour: "numeric", // Hour in 12-hour format (e.g., "12")
                    minute: "numeric", // Minute (e.g., "47")
                    second: "numeric", // Second (e.g., "52")
                    hour12: true, // Use AM/PM format
                  }
                )}
              </h3>
              <h3 style={{ textTransform: "capitalize", letterSpacing: "1px" }}>
                <span style={{ color: "#e8b6ea" }}>Expired :</span>{" "}
                {selectedPricing.isExpired ? "Yes" : "No"}
              </h3>
              <h3 style={{ textTransform: "capitalize", letterSpacing: "1px" }}>
                <span style={{ color: "#e8b6ea" }}>Pricing Status:</span>{" "}
                {pricingStatus}
              </h3>
              <FormControl
                fullWidth
                style={{
                  marginTop: "10px",
                  backgroundColor: "white",
                  borderRadius: "6px",
                }}
              >
                <Select
                  labelId="pricing-status-label"
                  value={pricingStatus}
                  onChange={handlePricingChange}
                >
                  <MenuItem value="Low">Low</MenuItem>
                  <MenuItem value="High">High</MenuItem>
                </Select>
              </FormControl>
              <button
                style={{
                  backgroundColor: "#F0C2B9",
                  color: "#333",
                  padding: "8px 16px",
                  fontSize: "14px",
                  fontWeight: "750",
                  letterSpacing: "1px",
                  borderRadius: "5px",
                  margin: "10px 0",
                  cursor:
                    role === "locator manager" ||
                    selectedPricing?.type === "manually generated"
                      ? "pointer"
                      : "not-allowed",
                  opacity:
                    role === "locator manager" ||
                    selectedPricing?.type === "manually generated"
                      ? 1
                      : 0.6,
                }}
                onClick={() => setViewHotelsActive(!viewHotelsActive)}
                disabled={
                  !(
                    role === "locator manager" ||
                    selectedPricing?.type === "manually generated"
                  )
                }
              >
                {viewHotelsActive ? "Hide Hotels" : "Show Hotels"}
              </button>
            </div>
            {viewHotelsActive && (
              <div className="operation-user-report-table">
                <TableContainer
                  component={Paper}
                  sx={{
                    borderRadius: "8px 8px 0 0 ",
                    overflowX: "auto",
                    maxHeight: "60vh",
                  }}
                >
                  <Table>
                    <TableHead>
                      <TableRow
                        sx={{
                          background:
                            "linear-gradient(to left, #fed766, #e8b6ea)",
                        }}
                      >
                        {[
                          "Hotel Name",
                          "Stars",
                          "Image URL",
                          "Nightly Rate",
                          "Address",
                          "Distance",
                          "Room Type",
                          "Free Cancellation Until",
                          "Amenities",
                        ].map((header, index) => (
                          <TableCell
                            key={header}
                            sx={{
                              fontSize: 16,
                              fontWeight: 550,
                              borderRight: "2px solid #fff",
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                              padding: "10px 14px",
                              color: "#fff",
                              textTransform: "capitalize",
                              letterSpacing: "1.2px",
                              textAlign: "center",
                            }}
                          >
                            {header}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedPricing.Hotels.length === 0 ? (
                        <TableRow>
                          <TableCell
                            colSpan={11}
                            sx={{
                              borderRight: "2px solid #e0e0e0",
                              fontSize: "14px",
                              fontWeight: 550,
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                              padding: "14px",
                              textAlign: "center",
                            }}
                          >
                            No Hotels Found
                          </TableCell>
                        </TableRow>
                      ) : (
                        selectedPricing.Hotels.map((hotel, index) => (
                          <TableRow key={index}>
                            <TableCell style={callStyle}>
                              {hotel?.["Hotel Name"] || (
                                <>
                                  <GoDash style={{ fontSize: "20px" }} />
                                  <GoDash style={{ fontSize: "20px" }} />
                                </>
                              )}
                            </TableCell>
                            <TableCell style={callStyle}>
                              {hotel?.["Stars"] || (
                                <>
                                  <GoDash style={{ fontSize: "20px" }} />
                                  <GoDash style={{ fontSize: "20px" }} />
                                </>
                              )}
                            </TableCell>
                            <TableCell style={callStyle}>
                              {/* {hotel?.["Image URL"] || (
                                <>
                                  <GoDash style={{ fontSize: "20px" }} />
                                  <GoDash style={{ fontSize: "20px" }} />
                                </>
                              )} */}

                              <img
                                src={hotel?.["Image URL"]}
                                alt={hotel?.["Hotel Name"]}
                                style={{
                                  width: "100%",
                                  objectFit: "cover",
                                  maxHeight: "100px",
                                }}
                              />
                            </TableCell>
                            <TableCell style={callStyle}>
                              {hotel?.["Average Nightly Rate with Markup"] ||
                                hotel?.["Starting from"] || (
                                  <>
                                    <GoDash style={{ fontSize: "20px" }} />
                                    <GoDash style={{ fontSize: "20px" }} />
                                  </>
                                )}
                            </TableCell>

                            <TableCell style={callStyle}>
                              {hotel?.["Address"] || (
                                <>
                                  <GoDash style={{ fontSize: "20px" }} />
                                  <GoDash style={{ fontSize: "20px" }} />
                                </>
                              )}
                            </TableCell>
                            <TableCell style={callStyle}>
                              {hotel?.["Distance"] || (
                                <>
                                  <GoDash style={{ fontSize: "20px" }} />
                                  <GoDash style={{ fontSize: "20px" }} />
                                </>
                              )}
                            </TableCell>
                            <TableCell style={callStyle}>
                              {hotel?.["Room Type"] || (
                                <>
                                  <GoDash style={{ fontSize: "20px" }} />
                                  <GoDash style={{ fontSize: "20px" }} />
                                </>
                              )}
                            </TableCell>
                            <TableCell style={callStyle}>
                              {hotel?.["Free Cancellation Until"] || (
                                <>
                                  <GoDash style={{ fontSize: "20px" }} />
                                  <GoDash style={{ fontSize: "20px" }} />
                                </>
                              )}
                            </TableCell>
                            <TableCell style={callStyle}>
                              <TableContainer
                                component={Paper}
                                sx={{
                                  borderRadius: "8px 8px 0 0 ",
                                }}
                              >
                                <Table>
                                  <TableHead>
                                    <TableRow
                                      sx={{
                                        background: "#F0C2B9",
                                      }}
                                    >
                                      {[
                                        "Air Conditioned",
                                        "Gym",
                                        "Internet",
                                        "Parking",
                                        "24-Hour Reception",
                                        "Restaurant",
                                        "Spa",
                                        "Swimming Pool",
                                      ].map((header, index) => (
                                        <TableCell
                                          key={header}
                                          sx={{
                                            color: "#fff",
                                            borderRight: "2px solid #fff",
                                            fontSize: "16px",
                                            fontFamily:
                                              '"Playfair Display", serif',
                                            whiteSpace: "nowrap",
                                            wordBreak: "keep-all",
                                          }}
                                        >
                                          {header}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {hotel.Amenities.length === 0 ? (
                                      <TableRow>
                                        <TableCell
                                          colSpan={8}
                                          sx={{
                                            fontSize: 16,
                                            fontWeight: 700,
                                            textAlign: "center",
                                            borderRight: "2px solid #e0e0e0",
                                            padding: "10px",
                                          }}
                                        >
                                          No Amenities Available.
                                        </TableCell>
                                      </TableRow>
                                    ) : (
                                      <TableRow>
                                        <TableCell style={callStyle}>
                                          {hotel.Amenities[0].airConditioned
                                            ? "Yes"
                                            : "No"}
                                        </TableCell>
                                        <TableCell style={callStyle}>
                                          {hotel.Amenities[0].gym
                                            ? "Yes"
                                            : "No"}
                                        </TableCell>
                                        <TableCell style={callStyle}>
                                          {hotel.Amenities[0].internet
                                            ? "Yes"
                                            : "No"}
                                        </TableCell>
                                        <TableCell style={callStyle}>
                                          {hotel.Amenities[0].parking
                                            ? "Yes"
                                            : "No"}
                                        </TableCell>
                                        <TableCell style={callStyle}>
                                          {hotel.Amenities[0].reception24Hour
                                            ? "Yes"
                                            : "No"}
                                        </TableCell>
                                        <TableCell style={callStyle}>
                                          {hotel.Amenities[0].restaurant
                                            ? "Yes"
                                            : "No"}
                                        </TableCell>
                                        <TableCell style={callStyle}>
                                          {hotel.Amenities[0].spa
                                            ? "Yes"
                                            : "No"}
                                        </TableCell>
                                        <TableCell style={callStyle}>
                                          {hotel.Amenities[0].swimmingPool
                                            ? "Yes"
                                            : "No"}
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
          </div>
        )
      )}
    </>
  );
};

export default MatchPricingShow;
