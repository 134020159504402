import React, { useState, useCallback } from "react";
import "./SalesManager.css";
import Layout from "../../components/layout/layout";
import SideBar from "../../components/SideBar/SideBar";
import MainContainer from "../../mainContainer/MainContainer";
import AssignedLeads from "../../components/AssignedLeads/AssignedLeads";
import TeamMembers from "../../components/TeamMembers/TeamMembers";
import AllCallbacks from "../../components/AllCallbacks/AllCallbacks";
import ExistingLeads from "../../components/ExistingLeads/ExistingLeads";
import OldLeadsTable from "../../components/ExistingLeads/OldLeadsTable";
import OldLeadsHistory from "../../components/ExistingLeads/OldLeadsHistory";

const SalesManager = () => {
  //sidebar logic ------------>
  const [currentComponent, setCurrentComponent] = useState(<TeamMembers />);
  const [currentPageTitle, setCurrentPageTitle] = useState("Team Members");
  //sidebar logic ------------>
  const sidebarButtons = [
    {
      label: "Team Members",
      component: <TeamMembers />,
      pageTitle: "Team Members",
    },
    {
      label: "Assigned Leads",
      component: <AssignedLeads />,
      pageTitle: "Assigned Leads",
    },
    {
      label: "Callback Leads",
      component: <AllCallbacks />,
      pageTitle: "Manage Callback Leads",
    },
    {
      label: "Old Leads",
      component: <OldLeadsTable />,
      pageTitle: "Old Leads",
    },
    {
      label: "Old Leads History",
      component: <OldLeadsHistory />,
      pageTitle: "Old Leads History",
    },
  ];

  //sidebar logic ------------>
  const handleComponentChange = useCallback((component, pageTitle) => {
    setCurrentComponent(component);
    setCurrentPageTitle(pageTitle);
  }, []);

  return (
    <Layout>
      <SideBar
        buttons={sidebarButtons}
        onComponentChange={handleComponentChange}
      />
      <MainContainer pageTitle={currentPageTitle}>
        <h2> Sales Manager View </h2>
        {currentComponent}
      </MainContainer>
    </Layout>
  );
};

export default SalesManager;
