import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Modal,
  Box,
  Typography,
  CircularProgress,
  Button,
  TextField,
} from "@mui/material";
import "./AssignedLeads.css";
import axios from "axios";
import { baseUrl } from "../../services/BaseUrl";
import { UserContext } from "../../context/UserContext";
import LoadingSection from "../LoadingComponent/LoadingSection";
import { GoDash } from "react-icons/go";
import AnswerSection from "../AnswerSection/AnswerSection";

const callStyle = {
  borderRight: "2px solid #e0e0e0",
  fontSize: "14px",
  fontWeight: 550,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  padding: "8px 10px",
};

const AssignedLeads = () => {
  const { token } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  // States to store unique timezone and status values
  const [uniqueTimezones, setUniqueTimezones] = useState([]);
  const [uniqueStatuses, setUniqueStatuses] = useState([]);
  // State for Company Name search filter
  const [searchCompany, setSearchCompany] = useState("");
  const [selectedTimezone, setSelectedTimezone] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [answerPageActive, setAnswerPageActive] = useState(false);
  const [selectedLead, setSelectedLead] = useState({
    showId: "",
    leadId: "",
    showName: "",
    companyName: "",
    timeZone: "",
    Status: "",
    "Next Contact": "",
    address: "",
    "End Date": "",
    "Next Dates": "",
    "Check-in": "",
    "Check-out": "",
    "Lead Date": "",
    "Rejection Reason": "",
    Notes: "",
    "DM Name": "",
    "DM Rating": "",
    Email: "",
    "Email Type": "",
    emailRequestNotes: "",
    "Days to Call": "",
    "Last Contact": "",
    "Closing Date": "",
    Hotel: "",
    Budget: "",
    " Distance": "",
    Rooms: "",
    "/ Room Stay": "",
    "Total Stay": "",
    Activities: [],
    roomDetails: [],
    "Closer Note": "",
    closerStatus: "",
  });
  const [selectedShowDataToRender, setSelectedShowDataRender] = useState({});
  //////////////////////////////////////////////////////////////////////////////////////////
  const today = new Date().toISOString().split("T")[0];
  const [selectedDate, setSelectedDate] = useState(today);
  const [leadsData, setLeadsData] = useState([]);
  const [selectedShowName, setSelectedShowName] = useState("");
  const [isEditing, setIsEditing] = useState(true);
  const [selectedPricing, setSelectedPricing] = useState([]);
  const [editedPricing, setEditedPricing] = useState([]);
  const [emailHelperData, setEmailHelperData] = useState({
    leadId: "",
    showName: "",
    email: "",
  });
  const [previewData, setPreviewData] = useState(null);
  const [open, setOpen] = useState(false);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  useEffect(() => {
    const fetchLeads = async () => {
      if (token) {
        setLoading(true);
        try {
          const response = await axios.post(
            `${baseUrl}/api/sales-manager/get-leads-to-close`,
            { date: selectedDate },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setLeadsData(response.data);

          const timezones = response.data.flatMap((item) =>
            item.leads.map((lead) => lead.Timezone)
          );
          const statuses = response.data.flatMap((item) =>
            item.leads.map((lead) => lead.Status)
          );

          const uniqueTimezonesSet = new Set(timezones);
          const uniqueStatusesSet = new Set(statuses);

          setUniqueTimezones([...uniqueTimezonesSet]);
          setUniqueStatuses([...uniqueStatusesSet]);
          setLoading(false);
        } catch (error) {
          setError("Failed to fetch leads. Please try again later.");
          console.error("Error fetching leads:", error);
          setLoading(false);
        }
        // finally {
        //   setLoading(false);
        // }
      }
    };

    fetchLeads();
  }, [token, selectedDate]);

  const allLeads = useMemo(
    () => leadsData.flatMap((item) => item.leads),
    [leadsData]
  );

  const handleTimezoneChange = (event) => {
    setSelectedTimezone(event.target.value);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const filteredLeads = allLeads.filter((lead) => {
    const matchesCompany =
      lead.Lead &&
      lead.Lead.toLowerCase().includes(searchCompany.toLowerCase());
    const matchesTimezone = selectedTimezone
      ? lead.Timezone === selectedTimezone
      : true;
    const matchesStatus = selectedStatus
      ? lead.Status === selectedStatus
      : true;

    return matchesCompany && matchesTimezone && matchesStatus;
  });

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const selectedShow = leadsData.find((lead) =>
      lead.leads.some((leadData) => leadData.showId === selectedLead.showId)
    );

    if (selectedShow) {
      setSelectedShowDataRender(selectedShow);
    }
  }, [selectedLead, leadsData]);

  const handleAnswerClick = useCallback((lead) => {
    const nextDate = new Date(lead?.["Next Dates"]);
    nextDate.setDate(nextDate.getDate() - 1);

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    setSelectedLead({
      showId: lead?.showId,
      leadId: lead?._id,
      showName: lead?.Show,
      companyName: lead?.Lead,
      timeZone: lead?.Timezone,
      Status: lead?.Status,
      "Next Contact": lead?.["Next Contact"],
      address: lead?.address,
      "End Date": lead?.["End Date"],
      "Next Dates": lead?.["Next Dates"],
      "Check-in": lead?.["Check-in"],
      "Check-out": lead?.["Check-out"],
      "Lead Date": lead?.["Lead Date"],
      "Rejection Reason": lead?.["Rejection Reason"],
      Notes: lead?.Notes,
      "DM Name": lead?.["DM Name"],
      "DM Rating": lead?.["DM Rating"],
      Email: lead?.Email,
      "Email Type": lead?.["Email Type"],
      emailRequestNotes: lead?.emailRequestNotes,
      "Days to Call": lead?.["Days to Call"],
      "Last Contact": lead?.["Last Contact"],
      "Closing Date": lead?.["Closing Date"],
      Hotel: lead?.Hotel,
      Budget: lead?.Budget,
      " Distance": lead?.Max?.[" Distance"],
      Rooms: lead?.Rooms,
      "/ Room Stay": lead?.["/ Room Stay"],
      "Total Stay": lead?.["Total Stay"],
      Activities: lead.Activities,
      roomDetails: lead.roomDetails,
      "Closer Note": lead?.["Closer Note"],
      closerStatus: lead?.closerStatus,
    });
    setAnswerPageActive(true);
  }, []);

  const handleCloseAnswerPage = useCallback(() => {
    setAnswerPageActive(false);
  }, []);

  const handleUpdateLead = async () => {
    try {
      const response = await axios.put(
        `${baseUrl}/api/leader/close-lead/${selectedLead.showId}/${selectedLead.leadId}`,
        selectedLead,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Lead updated successfully", response.data);
      }
    } catch (error) {
      // setError(error.message);
      console.error("Error updating lead:", error);
    }
  };

  const formatAmenityKey = (key) => {
    return key.charAt(0).toUpperCase() + key.slice(1);
  };

  const handleOpenPreview = (leadId, showId, show, email) => {
    setLoading(true);
    setError("");
    setEmailHelperData({
      leadId: leadId,
      showName: show,
      email: email,
    });

    const selectedShow = leadsData.find((lead) =>
      lead.leads.some((leadData) => leadData.showId === showId)
    );

    if (!selectedShow) {
      setError("Show not found.");
      setLoading(false);
      return;
    }

    const initialPricing = selectedShow.pricings[0]?.Hotels || [];
    console.log(selectedShow);

    setSelectedPricing(initialPricing);
    setEditedPricing(initialPricing); // Initialize editable pricing

    setOpen(true);
  };

  // Handle edits to pricing
  const handleEditPricing = (index, field, value) => {
    setEditedPricing((prev) =>
      prev.map((item, i) => (i === index ? { ...item, [field]: value } : item))
    );
  };

  // Submit the edited pricing and fetch the email preview
  const handleSubmitPricing = () => {
    setLoading(true);
    setError("");

    console.log(emailHelperData.showName);
    console.log(editedPricing);

    const payload = {
      to: emailHelperData.email,
      subject: "Hotel Offers",
      text: "Check out the latest hotel offers.",
      show: emailHelperData.showName || "",
      templateId: "67053ae620f29a5175ad2270",
      hotelData: editedPricing.map((hotel) => {
        const amenitiesObject = hotel.Amenities[0] || {};
        const amenities = Object.keys(amenitiesObject)
          .filter((key) => amenitiesObject[key] === true)
          .map((key) => formatAmenityKey(key));

        return {
          name: hotel["Hotel Name"],
          stars: hotel.Stars,
          amenities,
          originalPrice: hotel["Online Price"],
          discountPrice:
            hotel["Average Nightly Rate with Markup"] || hotel["Starting from"],
          image: hotel["Image URL"] || "",
          link: hotel.link || "",
          id: hotel._id,
        };
      }),
      leadId: emailHelperData.leadId,
    };

    axios
      .post(`${baseUrl}/api/sales-manager/get-email-preview`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setPreviewData(response.data.htmlContent);
      })
      .catch(() => {
        setError("Failed to load preview. Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSendEmail = async () => {
    console.log(editedPricing);

    try {
      const payload = {
        to: emailHelperData.email,
        subject: "Hotel Offers",
        text: "Check out the latest hotel offers.",
        show: emailHelperData.showName || "",
        templateId: "67053ae620f29a5175ad2270",
        hotelData: editedPricing.map((hotel) => {
          const amenitiesObject = hotel.Amenities[0] || {};
          const amenities = Object.keys(amenitiesObject)
            .filter((key) => amenitiesObject[key] === true)
            .map((key) => formatAmenityKey(key));

          return {
            name: hotel["Hotel Name"],
            stars: hotel.Stars,
            amenities,
            originalPrice: hotel["Online Price"],
            discountPrice:
              hotel["Average Nightly Rate with Markup"] ||
              hotel["Starting from"],
            image: hotel["Image URL"] || "",
            link: hotel.link || "",
            id: hotel._id,
          };
        }),
        leadId: emailHelperData.leadId,
      };

      const response = await axios.post(
        `${baseUrl}/api/sales-manager/send-email-SG`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      alert("Email sent successfully!");
    } catch (error) {
      console.error("Error sending email:", error);
      alert("Failed to send email.");
    }
  };

  // if (loading) {
  //   return <LoadingComponent />;
  // }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <>
      {!answerPageActive ? (
        <div className="sales-manager-section">
          <div className="assigned-leads-filters-container">
            <div className="assigned-lead-filtetr">
              <label
                htmlFor="dateInput"
                style={{
                  color: "#e1b5d7",
                  fontWeight: 600,
                  fontSize: 18,
                  letterSpacing: 1.2,
                  fontFamily: '"Playfair Display", serif',
                  marginRight: "10px",
                }}
              >
                Choose a Date:
              </label>
              <input
                type="date"
                id="dateInput"
                name="dateInput"
                value={selectedDate}
                onChange={handleDateChange}
                max={today}
                style={{
                  textTransform: "uppercase",
                  fontFamily: '"Playfair Display", serif',
                  padding: "6px 25px",
                  fontSize: "16px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  letterSpacing: 1.5,
                }}
              />
            </div>
          </div>
          {loading ? (
            <LoadingSection />
          ) : (
            <>
              <div className="assigned-leads-filters-container">
                <div className="assigned-lead-filtetr">
                  <label
                    htmlFor="timezone-select"
                    style={{
                      color: "#e1b5d7",
                      fontWeight: 600,
                      fontSize: 18,
                      letterSpacing: 1,
                      fontFamily: '"Playfair Display", serif',
                    }}
                  >
                    Status:
                  </label>
                  <select
                    id="timezone-select"
                    value={selectedStatus}
                    onChange={handleStatusChange}
                  >
                    <option value="">All Statuses</option>
                    {uniqueStatuses.map((status, index) => (
                      <option key={index} value={status}>
                        {status}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="assigned-lead-filtetr">
                  <label
                    htmlFor="timezone-select"
                    style={{
                      color: "#e1b5d7",
                      fontWeight: 600,
                      fontSize: 18,
                      letterSpacing: 1,
                      fontFamily: '"Playfair Display", serif',
                    }}
                  >
                    Timezone:
                  </label>
                  <select
                    id="timezone-select"
                    value={selectedTimezone}
                    onChange={handleTimezoneChange}
                  >
                    <option value="">All Timezones</option>
                    {uniqueTimezones.map((timezone, index) => (
                      <option key={index} value={timezone}>
                        {timezone}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="assigned-lead-filtetr">
                  <label
                    htmlFor="timezone-select"
                    style={{
                      color: "#e1b5d7",
                      fontWeight: 600,
                      fontSize: 18,
                      letterSpacing: 1,
                      fontFamily: '"Playfair Display", serif',
                    }}
                  >
                    Company Name:
                  </label>
                  <input
                    id="company-name-search"
                    type="text"
                    value={searchCompany}
                    onChange={(e) => setSearchCompany(e.target.value)}
                    placeholder="Search by company name..."
                    style={{
                      padding: "6px 15px",
                      fontSize: "16px",
                      marginLeft: "5px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                    }}
                  />
                </div>
              </div>
              <div className="assigned-leads-table-container">
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow
                        sx={{
                          background:
                            "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
                        }}
                      >
                        {[
                          "Lead",
                          "Show Name",
                          "Phone",
                          "Timezone",
                          "Agent Status",
                          "Closer Status",
                          "Agent Name",
                          "Answer",
                          "Email Options",
                          "Feedback",
                        ].map((header, index) => (
                          <TableCell
                            key={header}
                            sx={{
                              color: "#fff",
                              borderRight: "2px solid #fff",
                              fontSize: "16px",
                              fontFamily: '"Playfair Display", serif',
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                            }}
                          >
                            {header}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredLeads
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((lead, index) => (
                          <TableRow key={index}>
                            <TableCell sx={callStyle}>
                              {lead.Lead || (
                                <>
                                  <GoDash style={{ fontSize: "20px" }} />
                                  <GoDash style={{ fontSize: "20px" }} />
                                </>
                              )}
                            </TableCell>
                            <TableCell sx={callStyle}>
                              {lead.Show || (
                                <>
                                  <GoDash style={{ fontSize: "20px" }} />
                                  <GoDash style={{ fontSize: "20px" }} />
                                </>
                              )}
                            </TableCell>
                            <TableCell sx={callStyle}>
                              {lead.Phone || (
                                <>
                                  <GoDash style={{ fontSize: "20px" }} />
                                  <GoDash style={{ fontSize: "20px" }} />
                                </>
                              )}
                            </TableCell>
                            <TableCell sx={callStyle}>
                              {lead.Timezone || (
                                <>
                                  <GoDash style={{ fontSize: "20px" }} />
                                  <GoDash style={{ fontSize: "20px" }} />
                                </>
                              )}
                            </TableCell>
                            <TableCell sx={callStyle}>
                              {lead.Status || (
                                <>
                                  <GoDash style={{ fontSize: "20px" }} />
                                  <GoDash style={{ fontSize: "20px" }} />
                                </>
                              )}
                            </TableCell>
                            <TableCell sx={callStyle}>
                              {lead.closerStatus || (
                                <>
                                  <GoDash style={{ fontSize: "20px" }} />
                                  <GoDash style={{ fontSize: "20px" }} />
                                </>
                              )}
                            </TableCell>
                            <TableCell sx={callStyle}>
                              {lead.agentName || (
                                <>
                                  <GoDash style={{ fontSize: "20px" }} />
                                  <GoDash style={{ fontSize: "20px" }} />
                                </>
                              )}
                            </TableCell>
                            <TableCell
                              sx={{
                                borderRight: "2px solid #e0e0e0",
                                fontSize: "14px",
                                fontWeight: 550,
                                whiteSpace: "nowrap",
                                wordBreak: "keep-all",
                                padding: "7px 10px",
                                textAlign: "center",
                              }}
                            >
                              <button
                                style={{
                                  padding: "10px 20px",
                                  backgroundColor: "#4f637d",
                                  color: "#fff",
                                  border: "none",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                  fontSize: "14px",
                                }}
                                onClick={() => handleAnswerClick(lead)}
                              >
                                Answer
                              </button>
                            </TableCell>
                            <TableCell
                              sx={{
                                borderRight: "2px solid #e0e0e0",
                                fontSize: "14px",
                                fontWeight: 550,
                                whiteSpace: "nowrap",
                                wordBreak: "keep-all",
                                padding: "7px 10px",
                                textAlign: "center",
                              }}
                            >
                              <button
                                style={{
                                  padding: "10px 20px",
                                  backgroundColor: lead.clientEmailRequest
                                    ? "#4f637d"
                                    : "#ccc",
                                  color: "#fff",
                                  border: "none",
                                  borderRadius: "5px",
                                  cursor: lead.clientEmailRequest
                                    ? "pointer"
                                    : "not-allowed",
                                  fontSize: "14px",
                                }}
                                onClick={() =>
                                  handleOpenPreview(
                                    lead._id,
                                    lead.showId,
                                    lead.Show,
                                    lead.Email
                                  )
                                }
                                disabled={
                                  !lead.clientEmailRequest ||
                                  lead.clientEmailStatus === "Sent"
                                }
                              >
                                View Email
                              </button>
                            </TableCell>
                            <TableCell
                              sx={{
                                borderRight: "2px solid #e0e0e0",
                                fontSize: "14px",
                                fontWeight: 550,
                                whiteSpace: "nowrap",
                                wordBreak: "keep-all",
                                padding: "7px 10px",
                                textAlign: "center",
                              }}
                            >
                              <button
                                style={{
                                  padding: "10px 20px",
                                  backgroundColor: "#4f637d",
                                  color: "#fff",
                                  border: "none",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                  fontSize: "14px",
                                }}
                                // onClick={() => handleAnswerClick(lead)}
                              >
                                Feedback
                              </button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  sx={{
                    backgroundColor: "#fff",
                    borderTop: "1px solid #e0e0e0",
                  }}
                  rowsPerPageOptions={[8, 12]}
                  component="div"
                  count={filteredLeads.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </>
          )}
        </div>
      ) : (
        selectedLead && (
          <AnswerSection
            handleCloseAnswerPage={handleCloseAnswerPage}
            handleUpdateLead={handleUpdateLead}
            selectedLead={selectedLead}
            setSelectedLead={setSelectedLead}
            selectedShowDataToRender={selectedShowDataToRender}
          />
        )
      )}
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "45%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <Typography variant="h6">Edit Pricing & Preview Email</Typography>

          {error ? (
            <Typography color="error">{error}</Typography>
          ) : previewData && !isEditing ? ( // Show preview only if not in edit mode
            <Box sx={{ mt: 2, border: "1px solid #ccc", p: 2 }}>
              <div dangerouslySetInnerHTML={{ __html: previewData }} />
            </Box>
          ) : (
            <>
              {editedPricing.length > 0 ? (
                <TableContainer component={Paper} sx={{ mt: 2, mb: 2 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Hotel Name</TableCell>
                        <TableCell>Stars</TableCell>
                        <TableCell>Original Price</TableCell>
                        <TableCell>Discount Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {editedPricing.map((hotel, index) => (
                        <TableRow key={hotel._id}>
                          <TableCell>
                            <Typography>{hotel["Hotel Name"]}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{hotel.Stars}</Typography>
                          </TableCell>
                          <TableCell>
                            <TextField
                              type="number"
                              value={hotel["Online Price"]}
                              onChange={(e) =>
                                handleEditPricing(
                                  index,
                                  "Online Price",
                                  e.target.value
                                )
                              }
                              disabled={!isEditing} // Disable input if in preview mode
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              type="number"
                              value={
                                hotel["Average Nightly Rate with Markup"] ||
                                hotel["Starting from"]
                              }
                              onChange={(e) =>
                                handleEditPricing(
                                  index,
                                  "Average Nightly Rate with Markup",
                                  e.target.value
                                )
                              }
                              disabled={!isEditing} // Disable input if in preview mode
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography>No pricing available.</Typography>
              )}
            </>
          )}

          {/* Buttons */}
          <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
            {/* Submit Button (Only in Edit Mode) */}
            {isEditing && (
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSubmitPricing}
              >
                Submit
              </Button>
            )}

            {/* Toggle Button */}
            <Button
              variant="contained"
              color="primary"
              onClick={() => setIsEditing(!isEditing)}
            >
              {isEditing ? "Get Email Preview" : "Edit"}
            </Button>

            {/* Send Button (Only in Preview Mode) */}
            {!isEditing && previewData && (
              <Button
                variant="contained"
                color="success"
                onClick={handleSendEmail}
              >
                Send
              </Button>
            )}
          </Box>

          <Button
            variant="outlined"
            sx={{ mt: 2, width: "100%" }}
            onClick={() => {
              setOpen(false);
              setLoading(false);
            }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default AssignedLeads;
