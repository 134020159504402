import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Dialog,
  Alert,
  Snackbar,
} from "@mui/material";
import "./EmailRequestSales.css";
import { UserContext } from "../../context/UserContext";
import { baseUrl } from "../../services/BaseUrl";
import axios from "axios";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import { GoDash } from "react-icons/go";
import { FaWindowClose } from "react-icons/fa";
import { GiStaryu } from "react-icons/gi";

const callStyle = {
  borderRight: "2px solid #e0e0e0",
  fontSize: "14px",
  fontWeight: 550,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  padding: "8px 10px",
  textAlign:"left"
};

const renderSelectInfo = (renderData, field) => {
  return renderData?.[field] || (
    <>
      <GoDash style={{ fontSize: "20px" }} />
      <GoDash style={{ fontSize: "20px" }} />
    </>
  );
};

const EmailRequestSales = () => {
  const { token } = useContext(UserContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [emailRequests, setEmailRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [openDialogForm, setOpenDialogForm] = useState(false);
  const [hotelsArr, setHotelsArr] = useState([]);
  const [hotelInfo, setHotelInfo] = useState({
    "Hotel Name": "",
    Stars: "",
    SellingPrice: "",
    "Free Cancellation Until": "",
    "Boarding Type": "",
    Distance: "",
    Address: "",
    "Room Type": "",
  });
  

  const fetchEmailRequests = async (region) => {
    setLoading(true);
    setSelectedRegion(region);
    try {
      const url =
        region === "us"
          ? `${baseUrl}/api/support/email-requests-us`
          : `${baseUrl}/api/support/email-requests-uk`;

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const sortedRequests = response.data.sort((a, b) => new Date(a.submittedTime) - new Date(b.submittedTime));
      setEmailRequests(sortedRequests);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = (request) => {
    setSelectedRequest(request);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedRequest(null);
  };

  const openFormDialog = () => {
    setOpenDialogForm(true);
  };

  const addHotelInArray = (e) => {
    e.preventDefault();

    if (
      !hotelInfo["Hotel Name"] 
      // ||
      // !hotelInfo.Stars ||
      // !hotelInfo.SellingPrice ||
      // !hotelInfo["Free Cancellation Until"] ||
      // !hotelInfo["Boarding Type"] ||
      // !hotelInfo.Distance ||
      // !hotelInfo.Address ||
      // !hotelInfo["Room Type"]
    ) {
      // alert("All fields are required.");
      alert("Hotel Name is required.");
      return;
    }

    setHotelsArr((prevHotels) => [...prevHotels, hotelInfo]);

    // Reset hotel form fields
    setHotelInfo({
      "Hotel Name": "",
      Stars: "",
      SellingPrice: "",
      "Free Cancellation Until": "",
      "Boarding Type": "",
      Distance: "",
      Address: "",
      "Room Type": "",
    });

    // Close form dialog
    setOpenDialogForm(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setHotelInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCloseFormDialog = () => {
    setOpenDialogForm(false);
  };

  const sendEmailFeedback = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/support/update-dynamic-email-feedback`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      alert("Email feedback sent successfully!");
    } catch (error) {
      console.error("Error sending email feedback:", error);
      alert("An error occurred while sending the feedback. Please try again.");
    }
  };

  const handleSendEmailFeedback = async () => {
  if (hotelsArr.length === 0) {
    alert("Hotels list is empty. Please add hotels before sending feedback.");
    return;
  }
    const payload = {
      leadId: selectedRequest.CompanyId,
      showId: selectedRequest.ShowId,
      requestId: selectedRequest._id,
      shift:selectedRegion,
      emailPricingFeedback: hotelsArr,
    };

    // Send the feedback
  try {
    await sendEmailFeedback(payload);

    setEmailRequests((prevRequests) => 
      prevRequests.filter((request) => request._id !== selectedRequest._id)
    );

    // Close the dialog and reset data
    setOpenDialog(false);
    setHotelsArr([]);
  } catch (error) {
    console.error("Error sending email feedback:", error);
    alert("An error occurred while sending the feedback. Please try again.");
  }

    // sendEmailFeedback(payload);
    // setOpenDialog(false);
    // setHotelsArr([]);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getButtonStyle = (region) => {
    return {
      letterSpacing: 1,
      padding: "10px 25px",
      cursor: "pointer",
      borderRadius: "4px",
      fontSize: "16px",
      fontWeight: "700",
      background: selectedRegion === region ? " rgb(79, 99, 125)" : "#cebfbf",
      color: selectedRegion === region ? "#fff" : "#000",
    };
  }

  return (
    <div className="email-request-sales-container">
      <div className="email-request-sales-header">
        <button
          style={getButtonStyle("uk")}
          onClick={() => fetchEmailRequests("uk")}
        >
          Uk Email Request
        </button>
        <button
          style={getButtonStyle("us")}
          onClick={() => fetchEmailRequests("us")}
        >
          Us Email Request
        </button>
      </div>
      <div className="email-request-sales-table-container">
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "8px 8px 0 0 ",
            overflowX: "auto",
            maxHeight: "82vh",
          }}
          className="custom-scrollbar"
        >
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  background:
                    "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
                }}
              >
                {[
                  "Lead Name",
                  "Show Name",
                  "Request Data",
                  "Agent Name",
                  "State",
                  "Request Date",
                  "Details",
                ].map((header, index) => (
                  <TableCell
                    key={header}
                    sx={{
                      color: "#fff",
                      borderRight: "2px solid #fff",
                      fontSize: "16px",
                      fontFamily: '"Playfair Display", serif',
                      whiteSpace: "nowrap",
                      wordBreak: "keep-all",
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {emailRequests
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((request, index) => (
                  <TableRow key={request._id}>
                    <TableCell sx={callStyle}>
                      {renderSelectInfo(request?.companyData, "Lead")}
                    </TableCell>
                    <TableCell sx={callStyle}>
                    {renderSelectInfo(request?.showData, "Show")}
                    </TableCell>
                    <TableCell sx={callStyle}>
                    {renderSelectInfo(request, "RequestedData")}
                    </TableCell>
                    <TableCell sx={callStyle}>
                    {renderSelectInfo(request, "agentName")}
                    </TableCell>
                    <TableCell sx={callStyle}>
                    {renderSelectInfo(request, "State")}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {new Date(request.submittedTime).toLocaleString()}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      <button
                        style={{
                          padding: "10px 20px",
                          backgroundColor: "#4f637d",
                          color: "#fff",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                          fontSize: "14px",
                        }}
                        onClick={() => handleOpenDialog(request)}
                      >
                        Details
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{
            backgroundColor: "#fff",
            borderTop: "1px solid #e0e0e0",
          }}
          rowsPerPageOptions={[8, 15]}
          component="div"
          count={emailRequests.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      {/* Popup Dialog for showing company and show ID */}
      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            Width: "100%",
            height: "100vh",
            margin: 0,
            overflow: "auto",
            padding: "12px 30px",
          },
        }}
      >
        <div className="Dialog-header-contain">
          <button onClick={handleCloseDialog} style={{ background: "#fff" }}>
            <FaWindowClose style={{ fontSize: "26px" }} />
          </button>
        </div>
        <div className="Dialog-card-sales-container">
          <div className="Dialog-card-header-sales">
            <div className="dialog-header-text-sales">
              <GiStaryu style={{ fontSize: 24 }} />
              <h3 style={{ letterSpacing: 1.5 }}>Email Request Details</h3>
            </div>
            <div className="close-btn-dialog-sales">
              <button
                className="table-email-button"
                style={{ background: "#d44a00" }}
                onClick={openFormDialog}
              >
                <p className="table-email-button-text">
                  Add Hotel
                </p>
              </button>
            </div>
          </div>
          <div className="Dialog-card-contain-sales">
            {selectedRequest && (<>
              <div className="Dialog-card-Details">
                <div className="dialod-card-data-email">
                  <p>
                    <span>Request:</span>
                    {renderSelectInfo(selectedRequest, "Request")}
                  </p>
                  <p>
                    <span>Request Date:</span>
                    {new Date(selectedRequest?.submittedTime).toLocaleString()}
                  </p>
                </div>
                <div className="dialod-card-data-email">
                  <p>
                    <span>Requested Data:</span>
                    {renderSelectInfo(selectedRequest, "RequestedData")}
                  </p>
                  <p>
                    <span>Agent Name:</span>
                    {renderSelectInfo(selectedRequest, "agentName")}
                  </p>
                </div>
                <p>
                  <span>Show Name:</span>
                  {renderSelectInfo(selectedRequest?.showData, "Show")}
                </p>
                <p>
                  <span>Company Name:</span>
                  {renderSelectInfo(selectedRequest?.companyData, "Lead")}
                </p>
                <p>
                  <span>Email:</span>
                  {renderSelectInfo(selectedRequest?.companyData, "Email")}
                </p>
                <p>
                  <span>Email Notes:</span>
                  {renderSelectInfo(selectedRequest?.companyData, "RequestedData")}
                </p>
                <p>
                  <span>Contact Name:</span>
                  {renderSelectInfo(selectedRequest?.companyData, "DM Name")}
                </p>
                <div className="dialod-card-data-email">
                  {!(selectedRequest.RequestedData === 'Pricing (Same Day, Last Day)' ||
                    selectedRequest.RequestedData === 'Pricing (1 Day Before, Last Day)' ||
                    selectedRequest.RequestedData === 'Contact Card for Future Reference') && (
                      <>
                        <p>
                          <span>Check-In:</span>
                          {renderSelectInfo(selectedRequest?.companyData, "Check-in")}
                        </p>
                        <p>
                          <span>Check-Out:</span>
                          {renderSelectInfo(selectedRequest?.companyData, "Check-out")}
                        </p>
                      </>
                    )}
                  <p>
                    <span>No Of Rooms:</span>
                    {selectedRequest?.companyData?.Rooms || 0}
                  </p>
                </div>
              </div>
            </>)}
          </div>
          <div className="dialog-hotels-table-container-sales">
            <TableContainer
              component={Paper}
              sx={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
                borderRadius: "16px",
              }}
            >
              <Table sx={{ minWidth: 650 }} aria-label="email requests table">
                <TableHead>
                  <TableRow
                    sx={{
                      background: "linear-gradient(to right, #fed766, #e8b6ea)",
                    }}
                  >
                    {[
                      "Hotel Name",
                      "Address",
                      "Stars",
                      "Selling price",
                      "Boarding Type",
                      "Distance",
                      "Room Type",
                      "Free Cancellation Until",
                    ].map((header) => (
                      <TableCell
                        key={header}
                        sx={{
                          fontSize: 18,
                          fontFamily: '"Playfair Display", serif',
                          letterSpacing: "1.2px",
                          fontWeight: 700,
                          textAlign: "center",
                          whiteSpace: "nowrap",
                          wordBreak: "keep-all",
                          borderRight: "2px solid #fff",
                        }}
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {hotelsArr?.length === 0 ? (
                    <TableRow>
                      <TableCell
                        colSpan={8}
                        sx={{
                          fontSize: 16,
                          fontWeight: 700,
                          textAlign: "center",
                          borderRight: "2px solid #e0e0e0",
                          padding: "10px",
                        }}
                      >
                        No email requests found
                      </TableCell>
                    </TableRow>
                  ) : (
                    hotelsArr?.map((hotel, index) => {
                      console.log("Rendering hotel:", hotel); // Log each hotel
                      return (
                        <TableRow key={index}>
                          <TableCell style={{...callStyle, textAlign: "center"}}>
                          {renderSelectInfo(hotel, "Hotel Name")}
                          </TableCell>
                          <TableCell style={{...callStyle, textAlign: "center"}}> {renderSelectInfo(hotel, "Address")}</TableCell>
                          <TableCell style={{...callStyle, textAlign: "center"}}>
                          {renderSelectInfo(hotel, "Stars")}</TableCell>
                          <TableCell style={{...callStyle, textAlign: "center"}}>
                          {renderSelectInfo(hotel, "SellingPrice")}</TableCell>
                          <TableCell style={{...callStyle, textAlign: "center"}}>
                          {renderSelectInfo(hotel, "Room Type")}</TableCell>
                          <TableCell style={{...callStyle, textAlign: "center"}}>
                          {renderSelectInfo(hotel, "Distance")}</TableCell>
                          <TableCell style={{...callStyle, textAlign: "center"}}>
                          {renderSelectInfo(hotel, "Boarding Type")}</TableCell>
                          <TableCell style={{...callStyle, textAlign: "center"}}>
                          {renderSelectInfo(hotel, "Free Cancellation Until")}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <button
            onClick={handleSendEmailFeedback}
            style={{ margin: "10px 6px 0", borderRadius: "8px", background: "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)", padding: "10px 20px", color: "#fff" }}
          >
            <p>Send To Email Team</p>
          </button>
        </div>
      </Dialog>
      {/* Material-UI Dialog for Request Details */}
      <Dialog open={openDialogForm}>
        <div
          style={{
            padding: "10px",
            backgroundColor: "#ffffff",
            borderRadius: "8px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            Width: "100%",
            margin: "0 auto",
          }}
        >
          <form
            onSubmit={addHotelInArray}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div style={{ marginBottom: "10px" }}>
              <label
                style={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                  display: "block",
                }}
              >
                Hotel Name:
              </label>
              <input
                type="text"
                name="Hotel Name"
                value={hotelInfo["Hotel Name"]}
                onChange={handleChange}
                required
                style={{
                  padding: "7px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "1rem",
                  width: "100%",
                }}
              />
            </div>
            <div style={{ marginBottom: "10px" }}>
              <label
                style={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                  display: "block",
                }}
              >
                Stars:
              </label>
              <input
                type="number"
                name="Stars"
                value={hotelInfo.Stars}
                min="1"
                max="5"
                onChange={handleChange}
                required
                style={{
                  padding: "7px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "1rem",
                  width: "100%",
                }}
              />
            </div>
            <div style={{ marginBottom: "10px" }}>
              <label
                style={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                  display: "block",
                }}
              >
                Selling Price:
              </label>
              <input
                type="text"
                name="SellingPrice"
                value={hotelInfo.SellingPrice}
                onChange={handleChange}
                required
                style={{
                  padding: "7px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "1rem",
                  width: "100%",
                }}
              />
            </div>
            <div style={{ marginBottom: "10px" }}>
              <label
                style={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                  display: "block",
                }}
              >
                Free Cancellation Until:
              </label>
              <input
                type="date"
                name="Free Cancellation Until"
                value={hotelInfo["Free Cancellation Until"]}
                onChange={handleChange}
                required
                style={{
                  padding: "7px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "1rem",
                  width: "100%",
                }}
              />
            </div>
            <div style={{ marginBottom: "10px" }}>
              <label
                style={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                  display: "block",
                }}
              >
                Boarding Type:
              </label>
              <input
                type="text"
                name="Boarding Type"
                value={hotelInfo["Boarding Type"]}
                onChange={handleChange}
                required
                style={{
                  padding: "7px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "1rem",
                  width: "100%",
                }}
              />
            </div>
            <div style={{ marginBottom: "10px" }}>
              <label
                style={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                  display: "block",
                }}
              >
                Distance:
              </label>
              <input
                type="text"
                name="Distance"
                value={hotelInfo.Distance}
                onChange={handleChange}
                required
                style={{
                  padding: "7px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "1rem",
                  width: "100%",
                }}
              />
            </div>
            <div style={{ marginBottom: "10px" }}>
              <label
                style={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                  display: "block",
                }}
              >
                Address:
              </label>
              <input
                type="text"
                name="Address"
                value={hotelInfo.Address}
                onChange={handleChange}
                required
                style={{
                  padding: "7px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "1rem",
                  width: "100%",
                }}
              />
            </div>
            <div style={{ marginBottom: "10px" }}>
              <label
                style={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                  display: "block",
                }}
              >
                Room Type:
              </label>
              <input
                type="text"
                name="Room Type"
                value={hotelInfo["Room Type"]}
                onChange={handleChange}
                required
                style={{
                  padding: "7px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "1rem",
                  width: "100%",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <button
                type="submit"
                style={{
                  padding: "10px",
                  backgroundColor: "#007bff",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                  fontSize: "1rem",
                  flex: "1",
                  marginRight: "10px",
                }}
                onClick={addHotelInArray}
              >
                Add Hotel
              </button>
              <button
                type="button"
                onClick={handleCloseFormDialog}
                style={{
                  padding: "10px",
                  backgroundColor: "#dc3545",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                  fontSize: "1rem",
                  flex: "1",
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default EmailRequestSales;
       