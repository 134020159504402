import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Paper,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@mui/material";
import { UserContext } from "../../context/UserContext";

const HotelsTable = ({
  showDetails,
  setShowDetails,
  selectedHotels,
  setSelectedHotels,
}) => {
  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const { token } = useContext(UserContext);
  const [selectAll, setSelectAll] = useState(false); // Default to false, will be updated based on selectedHotels

  // Update selected hotels when showDetails change
  useEffect(() => {
    const allHotelIds = showDetails?.hotels?.map(hotel => hotel._id);
    setSelectedHotels(allHotelIds || []); // Set the selected hotel IDs
    setPage(0); // Reset to first page
  }, [showDetails, setSelectedHotels]);

  useEffect(() => {
    // Update selectAll checkbox state based on selectedHotels
    const allHotelIds = showDetails?.hotels?.map(hotel => hotel._id);
    setSelectAll(
      selectedHotels.length === allHotelIds?.length &&
      selectedHotels.every((hotelId) => allHotelIds.includes(hotelId))
    );
  }, [selectedHotels, showDetails]);

  const hotels = showDetails.hotels || [];

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const hotelsToDisplay = hotels.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Handle Select/Deselect all
  const handleSelectAll = (event) => {
    const selectedHotelIds = event.target.checked
      ? hotels.map(hotel => hotel._id)
      : [];
    setSelectedHotels(selectedHotelIds);
  };

  // Handle individual checkbox change
  const handleCheckboxChange = (hotelId, checked) => {
    let updatedSelectedHotels = [...selectedHotels];
    if (checked) {
      updatedSelectedHotels.push(hotelId); // Add the hotelId to the array
    } else {
      updatedSelectedHotels = updatedSelectedHotels.filter(
        (id) => id !== hotelId // Remove the hotelId from the array
      );
    }
    setSelectedHotels(updatedSelectedHotels);
  };

  return (
    <Paper className="companies-table" elevation={2} sx={{ mt: 4, p: 3 }}>
      <Typography variant="h6" className="section-title" gutterBottom>
        Hotels in Selected Show
      </Typography>
      <Grid container justifyContent="space-between" alignItems="center" mb={2}>
        <Grid item>
          <Typography variant="body2">
            <span className="font-medium">Total Hotels:</span>{" "}
            <span id="companiesTableCount">{hotels.length}</span>
          </Typography>
        </Grid>
      </Grid>
      {/* Scrollable table container */}
      <Box className="table-container" sx={{ maxHeight: 300, overflowY: "auto" }}>
        <Table stickyHeader>
          <TableHead className="table-head">
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>Hotel</TableCell>
              <TableCell>Stars</TableCell>
              <TableCell>Start From</TableCell>
              <TableCell>Boarding Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody id="companiesTableBody">
            {hotelsToDisplay.length > 0 ? (
              hotelsToDisplay.map((hotel, index) => (
                <TableRow key={hotel._id || index}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedHotels?.includes(hotel._id)}
                      onChange={(e) =>
                        handleCheckboxChange(hotel._id, e.target.checked)
                      }
                    />
                  </TableCell>
                  <TableCell>{hotel["Hotel Name"]}</TableCell>
                  <TableCell>{hotel.Stars}</TableCell>
                  <TableCell>{hotel["Starting from"]} $</TableCell>
                  <TableCell>{hotel["Boarding Type"]}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center" className="no-data">
                  No Hotels available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
      <Grid container justifyContent="space-between" alignItems="center" mt={2}>
        <Grid item>
          <Typography variant="caption" color="textSecondary" id="companyTableInfo">
            {hotels.length > 0
              ? `Showing ${page * rowsPerPage + 1} to ${
                  page * rowsPerPage + hotelsToDisplay.length
                } of ${hotels.length} Hotels`
              : "Showing 0 of 0 Hotels"}
          </Typography>
        </Grid>
      </Grid>
      {/* Table Pagination */}
      <TablePagination
        component="div"
        count={hotels.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[10, 25, 50, 100]}
      />
    </Paper>
  );
};

export default HotelsTable;
