import React, { useState, useEffect, useContext } from "react";
import "./PricingTableData.css";
import { UserContext } from "../../context/UserContext";
import { baseUrl } from "../../services/BaseUrl";
import axios from "axios";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import { GoDash } from "react-icons/go";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Dialog,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { TextField } from "@mui/material";

const callStyle = {
  borderRight: "2px solid #e0e0e0",
  fontSize: "14px",
  fontWeight: 550,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  padding: "6px 10px",
  textAlign: "left",
};

const renderSelectInfo = (renderData, field) => {
  return (
    renderData?.[field] || (
      <>
        <GoDash style={{ fontSize: "20px" }} />
        <GoDash style={{ fontSize: "20px" }} />
      </>
    )
  );
};

const PricingTableData = () => {
  const { token } = useContext(UserContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [pricingData, setPricingData] = useState([]);
  const [selectedPricingStatus, setSelectedPricingStatus] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedShow, setSelectedShow] = useState(null);
  

  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/pricing/all-pricings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPricingData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleApproveClick = async (pricingId) => {
    try {
      await axios.put(
        `${baseUrl}/api/pricing/approve-pricing/${pricingId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPricingData((prevData) =>
        prevData.map((pricing) =>
          pricing._id === pricingId ? { ...pricing, isExpired: false } : pricing
        )
      );
    } catch (error) {
      console.error("Error updating isExpired:", error);
    }
  };

  const canShowApproveButton = (pricing) => {    
    if (!pricing.isExpired) return false;

    return (
      pricing.Hotels.every(
        (hotel) => hotel["Average Nightly Rate with Markup"] != null 
      ) && 
      pricing["Pricing Status"]?.toLowerCase() === "high"
    );
  };

  const handlePricingChange = async (event,pricingId,pricing) =>{
  const payload = {
    "pricingStatus": event.target.value,
  };

  try {
    await axios.put(
      `${baseUrl}/api/pricing/update-senior-pricing/${pricingId}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setPricingData((prevData) =>
      prevData.map((item) =>
        item._id === pricingId ? { ...item, "Pricing Status": event.target.value } : item
      )
    );

  } catch (error) {
    console.error("Error updating pricing status:", error);
  }
}

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleShowHotels = (show) => {
    setSelectedShow(show);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedShow(null);
  };

  return (
    <div className="pricing-table-data-container">
      <div className="email-request-sales-table-container">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  background: "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
                }}
              >
                {[
                  "Action",
                  "Approve Pricing",
                  "Show Name",
                  "Pricing Status",
                  "Made By",
                  "Min Budget",
                  "Min Distance",
                  "Min ± Max",
                  "Top Rated",
                  "Expired",
                  "Date Modified",
                ].map((header, index) => (
                  <TableCell
                    key={header}
                    sx={{
                      color: "#fff",
                      borderRight: "2px solid #fff",
                      fontSize: "16px",
                      fontFamily: '"Playfair Display", serif',
                      whiteSpace: "nowrap",
                      wordBreak: "keep-all",
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {pricingData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow key={index}>
                    <TableCell sx={callStyle}>
                      <button
                        onClick={() => handleShowHotels(row)}
                        style={{
                          padding: "10px 18px",
                          backgroundColor: "#4f637d",
                          color: "#fff",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                          fontSize: "14px",
                        }}
                      >
                        Show Hotels
                      </button>
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {canShowApproveButton(row) ? (
                        <button
                          onClick={() => handleApproveClick(row._id)}
                          style={{
                            padding: "10px 18px",
                            backgroundColor: "#4f637d",
                            color: "#fff",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                            fontSize: "14px",
                          }}
                        >
                          Approve
                        </button>
                      ) : (
                        "Not Eligible"
                      )}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {renderSelectInfo(row, "Show")}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      <FormControl fullWidth>
                        <Select
                          value={row?.["Pricing Status"]}
                          onChange={(event) => handlePricingChange(event,row._id, row)}
                          sx={{
                            fontSize: "14px",
                            padding: "0px 8px",
                            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          <MenuItem value="High">High</MenuItem>
                          <MenuItem value="Low">Low</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {renderSelectInfo(row, "MadeBy")}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {renderSelectInfo(row, "Min Budget")}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {renderSelectInfo(row, "Min Distance")}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {renderSelectInfo(row, "Min +- Max")}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {row?.["Top Rated"] ? "Yes" : "No"}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {row?.["isExpired"] ? "Yes" : "No"}
                    </TableCell>
                    <TableCell sx={callStyle}>
                      {new Date(row.lastModified).toLocaleDateString("en-GB")}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{
            backgroundColor: "#fff",
            borderTop: "1px solid #e0e0e0",
          }}
          rowsPerPageOptions={[8, 15]}
          component="div"
          count={pricingData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      {/* Full Screen Dialog */}
      <Dialog open={openDialog} fullScreen>
        <div className="show-table-dialog-container">
          <div className="dialog-header-close">
            <button
              onClick={handleCloseDialog}
              style={{
                padding: "10px 20px",
                backgroundColor: "#4f637d",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                fontSize: "14px",
              }}
            >
              close
            </button>
          </div>
          <div className="info-show-dialog">
            <h3 style={{ letterSpacing: 1 }}>
              <span style={{ color: "#86575d", fontSize: "20px" }}>
                Show Details:
              </span>
              {selectedShow?.Show}
            </h3>
            <h3 style={{ letterSpacing: 1 }}>
              <span style={{ color: "#86575d", fontSize: "20px" }}>
                Pricing Status:
              </span>
              {selectedShow?.["Pricing Status"]}
            </h3>
          </div>
          <div className="hotel-table-dialog">
            <TableContainer component={Paper}
              sx={{
                borderRadius: "8px 8px 0 0 ",
                overflowX: "auto",
                maxHeight: "75vh",
              }}
              className="custom-scrollbar">
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      background:
                        "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
                    }}
                  >
                    {[
                      "Action",
                      "Hidden",
                      "Hotel Name",
                      "Stars",
                      "Selling Price",
                      "Online Price",
                      "Actual Price",
                      "Address",
                      "Distance",
                      "Room Type",
                      "Free Cancellation Until",
                    ].map((header, index) => (
                      <TableCell
                        key={header}
                        sx={{
                          color: "#fff",
                          borderRight: "2px solid #fff",
                          fontSize: "16px",
                          fontFamily: '"Playfair Display", serif',
                          whiteSpace: "nowrap",
                          wordBreak: "keep-all",
                        }}
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedShow?.Hotels?.map((hotel,index) => (
                    <TableRow key={index}>
                      <TableCell
                        sx={callStyle}
                      >
                        <button
                          style={{
                            padding: "10px 20px",
                            backgroundColor: "#4f637d",
                            color: "#fff",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                            fontSize: "14px",
                          }}
                        >
                          {hotel.hidden ? "Show" : "Hide"}
                        </button>

                      </TableCell>

                      <TableCell
                        sx={callStyle}
                      >
                        {hotel.hidden ? "Yes" : "No"}
                      </TableCell>
                      <TableCell
                        sx={callStyle}
                      >
                        {renderSelectInfo(hotel, "Hotel Name")}
                      </TableCell>
                      <TableCell
                        sx={callStyle}
                      >
                        {renderSelectInfo(hotel, "Stars")}
                      </TableCell>
                      <TableCell
                        sx={callStyle}
                      >
                        <TextField
                          value={`$ ${hotel["Average Nightly Rate with Markup"]}`}
                          sx={{
                            width: "90%",
                          }}
                        />
                      </TableCell>
                      <TableCell
                        sx={callStyle}
                      >
                        <TextField
                          value={`$ ${hotel["Online Price"]}`}
                          sx={{
                            width: "90%",
                          }}
                        />
                      </TableCell>
                      <TableCell
                        sx={callStyle}
                      >
                        <TextField
                          value={`$ ${hotel["Actual Price"]}`}
                          sx={{
                            width: "90%",
                          }}
                        />
                      </TableCell>
                      <TableCell
                        sx={callStyle}
                      >
                        {renderSelectInfo(hotel, "hotelAddress")}
                      </TableCell>
                      <TableCell

                        sx={callStyle}
                      >
                        <TextField
                          value={`${hotel.Distance}`}
                          // onChange={(e) => handleDistanceChange(e.target.value)} // Function to handle updates
                          sx={{
                            width: "90%",
                          }}
                        />
                      </TableCell>
                      <TableCell
                        sx={callStyle}
                      >
                        {hotel["Room Type"]}
                      </TableCell>
                      <TableCell
                        sx={callStyle}
                      >
                        {hotel["Free Cancellation Until"]}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default PricingTableData;
