import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Dialog,
  Alert,
  Snackbar,
  TextField, Button, Modal, Box, Grid, FormControlLabel, Checkbox,
} from "@mui/material";
import "./CreateRawPricingModal.css"
import { FaArrowLeftLong } from "react-icons/fa6";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import { baseUrl } from "../../services/BaseUrl";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import { GoDash } from "react-icons/go";

const amenitiesList = [
  "parking",
  "airConditioned",
  "spa",
  "restaurant",
  "gym",
  "reception24Hour",
  "internet",
  "swimmingPool",
];

const callStyle = {
  borderRight: "2px solid #e0e0e0",
  fontSize: "14px",
  fontWeight: 550,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  padding: "8px 10px",
  textAlign: "left"
};

const renderSelectInfo = (renderData, field) => {
  return renderData?.[field] || (
    <>
      <GoDash style={{ fontSize: "20px" }} />
      <GoDash style={{ fontSize: "20px" }} />
    </>
  );
};

const CreateRawPricingModal = ({ handleCloseDialog, selectedShow,setSelectedShow,fetchAllShows }) => {
  const { token } = useContext(UserContext);
  const [selectHotelNames, setSelectHotelNames] = useState([])
  const [selectedHotel, setSelectedHotel] = useState("")
  const [isCreatePricingModal, setIsCreatePricingModal] = useState(false);
  const [conventionCenter, setConventionCenter] = useState("");
  const [pricingStatus, setPricingStatus] = useState("");
  const [selectedPriceHotelsShow, setSelectedPriceHotelsShow] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [isCreateHotelModal, setIsCreateHotelModal] = useState(false);
  const [isUpdateHotelModal, setIsUpdateHotelModal] = useState(false);
  const [hotelData, setHotelData] = useState({
    "Hotel Name": "",
    Stars: "",
    "Image URL": "",
    "Average Nightly Rate with Markup": "",
    "Free Cancellation Until": "",
    "Boarding Type": "",
    Distance: "",
    Address: "",
    "Room Type": "",
    Amenities: amenitiesList.reduce((acc, amenity) => {
      acc[amenity] = false;
      return acc;
    }, {}),
  });

  const handelViewCreatePricing = () => {
    setIsCreatePricingModal(true)
  }

  const handleClose = () => {
    setIsCreatePricingModal(false)
    setIsCreateHotelModal(false)
    setIsUpdateHotelModal(false)
  };

  const handleSave = () => {
    // handleClose();
  };

  const handleShowHotels = (price) => {
    setSelectedPriceHotelsShow(price.Hotels);
    setOpenDialog(true);
  };

  const handleCloseNewDialog = () => {
    setOpenDialog(false);
    setSelectedPriceHotelsShow(null);
  };

  const handleCreateHotelClick = () => {
    setHotelData({
      "Hotel Name": "",
      Stars: "",
      "Image URL": "",
      "Average Nightly Rate with Markup": "",
      "Free Cancellation Until": "",
      "Boarding Type": "",
      Distance: "",
      Address: "",
      "Room Type": "",
      Amenities: amenitiesList.reduce((acc, amenity) => {
        acc[amenity] = false;
        return acc;
      }, {}),
    });
    setIsCreateHotelModal(true);
  };

  const handleUpdateHotelClick = (hotel) => {
    setHotelData({
      "Hotel Name": hotel["Hotel Name"],
      Stars: hotel.Stars,
      "Image URL": hotel["Image URL"],
      "Average Nightly Rate with Markup": hotel["Average Nightly Rate with Markup"],
      "Free Cancellation Until": hotel["Free Cancellation Until"],
      "Boarding Type": hotel["Boarding Type"],
      Distance: hotel.Distance,
      Address: hotel.Address,
      "Room Type": hotel["Room Type"],
      Amenities: amenitiesList.reduce((acc, amenity) => {
        acc[amenity] = hotel.Amenities[0]?.[amenity] || false;
        return acc;
      }, {}),
    });

    setIsUpdateHotelModal(true);
  };

  const handleCreateHotelChange = (key, event) => {
    if (key === "Amenities") {
      const { name, checked } = event.target;
      setHotelData((prevData) => ({
        ...prevData,
        Amenities: {
          ...prevData.Amenities,
          [name]: checked,
        },
      }));
    } else {
      setHotelData((prevData) => ({
        ...prevData,
        [key]: event.target.value,
      }));
    }
  };

  const handleCreateNewHotelSubmit = async (selectedShow) => {
    const pricingId = selectedShow.pricing[0]._id;
    try {
      const response = await axios.post(
        `${baseUrl}/api/pricing/add-hotel-to-pricing/${pricingId}`,
        {
          hotel: {
            ...hotelData,
            Amenities: hotelData.Amenities,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // setSelectedShow(prevState => ({
      //   ...prevState
      // }));

      setHotelData({
        "Hotel Name": "",
        Stars: "",
        "Image URL": "",
        "Average Nightly Rate with Markup": "",
        "Free Cancellation Until": "",
        "Boarding Type": "",
        Distance: "",
        Address: "",
        "Room Type": "",
        Amenities: amenitiesList.reduce((acc, amenity) => {
          acc[amenity] = false;
          return acc;
        }, {}),
      });

      // await fetchAllShows();
      handleClose();
    } catch (error) {
      console.error("Error creating hotel:", error);
    }
  };

   const handleUpdateHotelSubmit = async (selectedShow) => {
    const pricingId = selectedShow.pricing[0]._id;

    try {
      const response = await axios.put(
        `${baseUrl}/api/pricing/update-hotel/${pricingId}`,
        {
          hotel: {
            ...hotelData,
            Amenities: hotelData.Amenities,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setHotelData({
        "Hotel Name": "",
        Stars: "",
        "Image URL": "",
        "Average Nightly Rate with Markup": "",
        "Free Cancellation Until": "",
        "Boarding Type": "",
        Distance: "",
        Address: "",
        "Room Type": "",
        Amenities: amenitiesList.reduce((acc, amenity) => {
          acc[amenity] = false;
          return acc;
        }, {}),
      });

      handleClose();
    } catch (error) {
      console.error("Error updating hotel:", error.response || error.message);
    }
  };

  return (
    <div className="show-table-dialog-container">
      <div className="dialog-header-close">
        <FaArrowLeftLong
          onClick={handleCloseDialog}
          style={{ cursor: "pointer", fontSize: 22 }}
        />
      </div>
      <div className="create-raw-pricing-btn">
        <button style={{
          letterSpacing: 1,
          padding: "10px 30px",
          cursor: "pointer",
          borderRadius: "4px",
          fontSize: "16px",
          fontWeight: "700",
          background: " rgb(79, 99, 125)",
          color: "#fff"
        }}
          onClick={() => handelViewCreatePricing()}
        >
          Create Pricing
        </button>
      </div>
      {/* /////////////////////////////////////////////////////////// */}
      <div className="email-request-sales-table-container">
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "8px 8px 0 0 ",
            overflowX: "auto",
            maxHeight: "70vh",
          }}
          className="custom-scrollbar"
        >
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  background:
                    "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
                }}
              >
                {[
                  "Controllers",
                  "Show Name",
                  "Pricing Status",
                  "Made By",
                  "Min Budget",
                  "Min Distance",
                  "Min ± Max",
                  "Top Rated",
                  "Expired",
                  "Date Modified"
                ].map((header, index) => (
                  <TableCell
                    key={header}
                    sx={{
                      color: "#fff",
                      borderRight: "2px solid #fff",
                      fontSize: "16px",
                      fontFamily: '"Playfair Display", serif',
                      whiteSpace: "nowrap",
                      wordBreak: "keep-all",
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedShow.pricing.length === 0 ? (
                <TableRow >
                  <TableCell
                    colSpan={10}
                    sx={{
                      fontSize: 16,
                      fontWeight: 700,
                      textAlign: "center",
                      borderRight: "2px solid #e0e0e0",
                      padding: "10px",
                    }}
                  >
                    No Shows Here
                  </TableCell>
                </TableRow>
              ) : (selectedShow.pricing.map((price, index) => (
                <TableRow key={price._id}>
                  <TableCell sx={callStyle}>
                    <button
                      style={{
                        padding: "10px 20px",
                        backgroundColor: "#4f637d",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        fontSize: "14px",
                      }}
                      onClick={() => handleShowHotels(price)}

                    >
                      Toggle Hotels
                    </button>
                  </TableCell>
                  <TableCell sx={callStyle}>
                    {renderSelectInfo(selectedShow, "Show")}
                  </TableCell>
                  <TableCell sx={callStyle}>
                    {renderSelectInfo(price, "Pricing Status")}
                  </TableCell>
                  <TableCell sx={callStyle}>
                    {renderSelectInfo(price, "Made By")}
                  </TableCell>
                  <TableCell sx={callStyle}>
                    {renderSelectInfo(price, "Min Budget")}
                  </TableCell>
                  <TableCell sx={callStyle}>
                    {renderSelectInfo(price, "Min Distance")}
                  </TableCell>
                  <TableCell sx={callStyle}>
                    {renderSelectInfo(price, "Min +- Max")}
                  </TableCell>
                  <TableCell sx={callStyle}>
                    {/* //after finish integrate Select (change logic of this select), remove price?.["Top Rated"] */}
                    {price?.["Top Rated"] || <select
                      value={selectedHotel}
                      onChange={(e) => setSelectedHotel(e.target.value)}
                      style={{
                        padding: "5px",
                        fontSize: "14px",
                        borderRadius: "4px",
                        border: "1px solid #ccc",
                      }}
                    >
                      <option value="" disabled>
                        Select Hotel
                      </option>
                      {selectHotelNames.map((hotel, index) => (
                        <option key={index} value={hotel}>
                          {hotel}
                        </option>
                      ))}
                    </select>}
                  </TableCell>
                  <TableCell sx={callStyle}>
                    {price?.["isExpired"] ? "Yes" : "No"}
                  </TableCell>
                  <TableCell sx={callStyle}>
                    {new Date(price.lastModified).toLocaleString()}
                  </TableCell>
                </TableRow>
              )))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {/* /////////////////////////////////////////////////////////// */}
      <Modal
        open={isCreatePricingModal}
        aria-labelledby="create-raw-pricing-modal"
        aria-describedby="modal-to-create-raw-pricing"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <TextField
            fullWidth
            id="convention-center-host-hotel"
            label="Convention Center / Host Hotel"
            variant="outlined"
            value={conventionCenter}
            onChange={(e) => setConventionCenter(e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            select
            id="pricing-status"
            value={pricingStatus}
            onChange={(e) => setPricingStatus(e.target.value)}
            margin="normal"
            SelectProps={{
              native: true,
            }}
          >
            <option value="" disabled>
              Select Pricing Status
            </option>
            <option value="Low">Low</option>
            <option value="High">High</option>
          </TextField>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
              mt: 3,
            }}
          >
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save
            </Button>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      {/* Full Screen Dialog */}
      <Dialog open={openDialog} fullScreen>
        <div className="show-table-dialog-container">
          <div className="dialog-header-close">
            <FaArrowLeftLong
              onClick={handleCloseNewDialog}
              style={{ cursor: "pointer", fontSize: 22, color: "#333" }}
            />
          </div>
          <div className="info-show-dialog">
            <h3 style={{ letterSpacing: 1 }}>
              <span style={{ color: "#86575d", fontSize: "20px" }}>
                Show Details:
              </span>
              {selectedShow?.Show}
            </h3>
          </div>
          <div className="create-raw-pricing-btn">
            <button style={{
              letterSpacing: 1,
              padding: "10px 30px",
              cursor: "pointer",
              borderRadius: "4px",
              fontSize: "16px",
              fontWeight: "700",
              background: " rgb(79, 99, 125)",
              color: "#fff"
            }}
              onClick={() => handleCreateHotelClick()}
            >
              Create Hotel
            </button>
          </div>
          <div className="email-request-sales-table-container">
            {selectedShow && (
              <TableContainer
                component={Paper}
                sx={{
                  borderRadius: "8px 8px 0 0 ",
                  overflowX: "auto",
                  maxHeight: "70vh",
                }}
                className="custom-scrollbar"
              >
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        background:
                          "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
                      }}
                    >
                      {[
                        "Controllers",
                        "Hotel Name",
                        "Stars",
                        "Image URL",
                        "Nightly Rate",
                        "Hotel Address",
                        "Distance",
                        "Room Type",
                        "Free Cancellation Until",
                        "Amenities",
                      ].map((header, index) => (
                        <TableCell
                          key={header}
                          sx={{
                            color: "#fff",
                            borderRight: "2px solid #fff",
                            fontSize: "16px",
                            fontFamily: '"Playfair Display", serif',
                            whiteSpace: "nowrap",
                            wordBreak: "keep-all",
                          }}
                        >
                          {header}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedPriceHotelsShow?.length === 0 ? (
                      <TableRow >
                        <TableCell
                          colSpan={10}
                          sx={{
                            fontSize: 16,
                            fontWeight: 700,
                            textAlign: "center",
                            borderRight: "2px solid #e0e0e0",
                            padding: "10px",
                          }}
                        >
                          No Hotels Here
                        </TableCell>
                      </TableRow>
                    ) : (selectedPriceHotelsShow?.map((hotel, index) => (
                      <TableRow key={hotel._id}>
                        <TableCell sx={callStyle}>
                          <button
                            style={{
                              padding: "10px 20px",
                              backgroundColor: "#4f637d",
                              color: "#fff",
                              border: "none",
                              borderRadius: "5px",
                              cursor: "pointer",
                              fontSize: "14px",
                            }}
                            onClick={() => handleUpdateHotelClick(hotel)}
                          >
                            Edit
                          </button>
                        </TableCell>
                        <TableCell sx={callStyle}>
                          {renderSelectInfo(hotel, "Hotel Name")}
                        </TableCell>
                        <TableCell sx={callStyle}>
                          {renderSelectInfo(hotel, "Stars")}
                        </TableCell>
                        <TableCell sx={callStyle}>
                          <img src={hotel?.["Image URL"]} alt={hotel?.["Hotel Name"]}
                            style={{
                              width: '100%',
                              height: 'auto',
                              objectFit: 'cover',
                              maxHeight: '250px',
                            }}
                          />
                        </TableCell>
                        <TableCell sx={callStyle}>
                          {renderSelectInfo(hotel, "Average Nightly Rate with Markup")}
                        </TableCell>
                        <TableCell sx={callStyle}>
                          {renderSelectInfo(hotel, "Address")}
                        </TableCell>
                        <TableCell sx={callStyle}>
                          {renderSelectInfo(hotel, "Distance")}
                        </TableCell>
                        <TableCell sx={callStyle}>
                          {renderSelectInfo(hotel, "Room Type")}
                        </TableCell>
                        <TableCell sx={callStyle}>
                          {renderSelectInfo(hotel, "Free Cancellation Until")}
                        </TableCell>
                        <TableCell sx={callStyle}>
                          <TableContainer
                            component={Paper}
                            sx={{
                              borderRadius: "8px 8px 0 0 ",
                            }}
                          >
                            <Table>
                              <TableHead>
                                <TableRow
                                  sx={{
                                    background: "linear-gradient(to right, #fed766, #e8b6ea)",
                                  }}
                                >
                                  {[
                                    "Air Conditioned",
                                    "Gym",
                                    "Internet",
                                    "Parking",
                                    "24-Hour Reception",
                                    "Restaurant",
                                    "Spa",
                                    "Swimming Pool",
                                  ].map((header, index) => (
                                    <TableCell
                                      key={header}
                                      sx={{
                                        color: "#fff",
                                        borderRight: "2px solid #fff",
                                        fontSize: "16px",
                                        fontFamily: '"Playfair Display", serif',
                                        whiteSpace: "nowrap",
                                        wordBreak: "keep-all",
                                      }}
                                    >
                                      {header}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {hotel.Amenities.length === 0 ? (
                                  <TableRow >
                                    <TableCell
                                      colSpan={8}
                                      sx={{
                                        fontSize: 16,
                                        fontWeight: 700,
                                        textAlign: "center",
                                        borderRight: "2px solid #e0e0e0",
                                        padding: "10px",
                                      }}
                                    >
                                      No Amenities Available.
                                    </TableCell>
                                  </TableRow>
                                ) : (
                                  <TableRow>
                                    <TableCell sx={callStyle}>
                                      {hotel.Amenities[0].airConditioned ? "Yes" : "No"}
                                    </TableCell>
                                    <TableCell sx={callStyle}>
                                      {hotel.Amenities[0].gym ? "Yes" : "No"}
                                    </TableCell>
                                    <TableCell sx={callStyle}>
                                      {hotel.Amenities[0].internet ? "Yes" : "No"}
                                    </TableCell>
                                    <TableCell sx={callStyle}>
                                      {hotel.Amenities[0].parking ? "Yes" : "No"}
                                    </TableCell>
                                    <TableCell sx={callStyle}>
                                      {hotel.Amenities[0].reception24Hour ? "Yes" : "No"}
                                    </TableCell>
                                    <TableCell sx={callStyle}>
                                      {hotel.Amenities[0].restaurant ? "Yes" : "No"}
                                    </TableCell>
                                    <TableCell sx={callStyle}>
                                      {hotel.Amenities[0].spa ? "Yes" : "No"}
                                    </TableCell>
                                    <TableCell sx={callStyle}>
                                      {hotel.Amenities[0].swimmingPool ? "Yes" : "No"}
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </TableCell>
                      </TableRow>
                    )))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </div>
      </Dialog>

      {/*modal*/}
      <Modal
        open={isCreateHotelModal || isUpdateHotelModal}
        aria-labelledby="create-raw-pricing-modal"
        aria-describedby="modal-to-create-raw-pricing"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1000,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          {isCreateHotelModal && <h2 id="create-raw-pricing-modal">Create Hotel</h2>}
          {isUpdateHotelModal && <h2 id="create-raw-pricing-modal">Edit Hotel</h2>}
          {/* Form fields */}
          <Grid container spacing={2}>
            {Object.keys(hotelData)
              .filter((key) => key !== "Amenities")
              .map((key) => (
                <Grid item xs={12} sm={6} md={4} key={key}>
                  {key === "Free Cancellation Until" ? (
                    <TextField
                      fullWidth
                      label={key}
                      variant="outlined"
                      value={hotelData[key]}
                      onChange={(e) => handleCreateHotelChange(key, e)}
                      margin="normal"
                      type="date" // Set the input type to date
                      InputLabelProps={{
                        shrink: true, // Ensures the label stays above the input field for the date
                      }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      label={key}
                      variant="outlined"
                      value={hotelData[key]}
                      onChange={(e) => handleCreateHotelChange(key, e)}
                      margin="normal"
                    />
                  )}
                </Grid>
              ))}
          </Grid>
          {/* Amenities checkboxes */}
          <Grid item xs={12}>
            <Box>
              <Box sx={{ mb: 2 }}>
                <strong>Amenities</strong>
              </Box>
              {amenitiesList.map((amenity) => (
                <FormControlLabel
                  key={amenity}
                  control={
                    <Checkbox
                      checked={hotelData.Amenities[amenity] === true}
                      onChange={(e) =>
                        setHotelData((prevData) => ({
                          ...prevData,
                          Amenities: {
                            ...prevData.Amenities,
                            [amenity]: e.target.checked,
                          },
                        }))
                      }
                      name={amenity}
                    />
                  }
                  label={amenity.charAt(0).toUpperCase() + amenity.slice(1)}
                />
              ))}
            </Box>
          </Grid>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
              mt: 3,
            }}
          >
            {isCreateHotelModal &&
              <Button variant="contained" color="primary"
                onClick={() => handleCreateNewHotelSubmit(selectedShow)}
              >
                Create Hotel
              </Button>}

            {isUpdateHotelModal &&
              <Button variant="contained" color="primary"
                onClick={() => handleUpdateHotelSubmit(selectedShow)}

              >
                Update Hotel
              </Button>
            }

            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}

export default CreateRawPricingModal
