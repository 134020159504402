import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Dialog,
  TextField
} from "@mui/material";
import "./HistoryEmailReqSales.css"
import { UserContext } from "../../context/UserContext";
import { baseUrl } from "../../services/BaseUrl";
import axios from "axios";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import { GoDash } from "react-icons/go";
import { FaWindowClose } from "react-icons/fa";
import { GiStaryu } from "react-icons/gi";

const callStyle = {
  padding: 8.5,
  fontSize: 16,
  fontWeight: 700,
  textAlign: "center",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  borderRight: "2px solid #e0e0e0",
};

const renderSelectInfo = (renderData, field) => {
  return renderData?.[field] || (
    <>
      <GoDash style={{ fontSize: "20px" }} />
      <GoDash style={{ fontSize: "20px" }} />
    </>
  );
};

const HistoryEmailReqSales = () => {
  const { token } = useContext(UserContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [loading, setLoading] = useState(false);
  const [historyEmailRequests, setHistoryEmailRequests] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchHistoryEmailRequests = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${baseUrl}/api/support/pricing-emails-history`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setHistoryEmailRequests(response.data);
      } catch (error) {
        console.error("Error fetching email requests:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchHistoryEmailRequests();
  }, [token]);

  const handleOpenDialog = (request) => {
    setSelectedRequest(request);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedRequest(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <div className="history-email-request-sales-container">
      <h3 className="email-request-page-header">
        History Email Requests Table:
      </h3>
      <div className="email-req-table-filters">
        <div className="search-email-req">
          <TextField
            label="Search by show Name"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
            InputLabelProps={{ style: { color: "#e1b5d7", fontSize: "16px", letterSpacing: "1px" } }}
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "white",
                borderRadius: "8px",
                width: "350px"
              },
            }}
          />
        </div>
      </div>
      <div className="email-req-table-contain">
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
            borderRadius: "16px 16px 0 0",
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="email requests table">
            <TableHead>
              <TableRow
                sx={{
                  background: "linear-gradient(to right, #fed766, #e8b6ea)",
                }}
              >
                {[
                  "Company Name",
                  "Show Name",
                  "Agent Name",
                  "State",
                  "Request Data",
                  "Details"
                ].map((header) => (
                  <TableCell
                    key={header}
                    sx={{
                      fontSize: 18,
                      fontFamily: '"Playfair Display", serif',
                      letterSpacing: "1.2px",
                      fontWeight: 700,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      wordBreak: "keep-all",
                      borderRight: "2px solid #fff",
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {historyEmailRequests.length === 0 ? (<TableRow>
                <TableCell
                  colSpan={7}
                  sx={{
                    fontSize: 16,
                    fontWeight: 700,
                    textAlign: "center",
                    borderRight: "2px solid #e0e0e0",
                    padding: "10px",
                  }}
                >
                  No email requests found
                </TableCell>
              </TableRow>) : (historyEmailRequests
                .filter((request) =>
                  request?.showData?.Show?.toLowerCase().includes(searchQuery.toLowerCase())
                ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((request) => (
                  <TableRow
                    key={request._id}
                    sx={{
                      background:
                        request.State === "Sent"
                          ? "linear-gradient(to left, white, white, #285c4c)"
                          : request.State === "Error Sent"
                            ? "linear-gradient(to left, white, white, #940000)"
                            : "inherit",
                      borderBottom: "2px solid rgba(224, 224, 224, 1)",
                    }}
                  >
                    <TableCell
                      style={{
                        fontSize: 16,
                        fontWeight: 700,
                        textAlign: "center",
                        borderRight: "2px solid rgb(195, 189, 189)",
                        whiteSpace: "nowrap",
                        wordBreak: "keep-all",
                        padding: "10px",
                        color:
                          request.State === "Sent"
                            ? "white"
                            : request.State === "Error Sent"
                              ? "white"
                              : "inherit",
                      }}

                    >
                      {renderSelectInfo(request?.companyData, "Lead")}
                    </TableCell>
                    <TableCell
                      style={callStyle}
                    >
                      {renderSelectInfo(request?.showData, "Show")}
                    </TableCell>
                    <TableCell
                      style={callStyle}
                    >
                      {renderSelectInfo(request, "agentName")}
                    </TableCell>
                    <TableCell
                      style={callStyle}
                    >
                      {renderSelectInfo(request, "State")}
                    </TableCell>
                    <TableCell
                      style={callStyle}
                    >
                      {renderSelectInfo(request, "RequestedData")}
                    </TableCell>
                    <TableCell style={callStyle}>
                      <button
                        className="table-email-button"
                        onClick={() => handleOpenDialog(request)}
                      >
                        <p className="table-email-button-text">
                          View Details
                        </p>
                      </button>
                    </TableCell>
                  </TableRow>
                )))}

            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{
            backgroundColor: "#fff",
            borderTop: "1px solid #e0e0e0",
          }}
          rowsPerPageOptions={[8, 20]}
          component="div"
          count={historyEmailRequests.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      {/* Popup Dialog for showing company and show ID */}
      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            Width: "100%",
            height: "100vh",
            margin: 0,
            overflow: "auto",
            padding: "12px 30px",
          },
        }}
      >
        <div className="Dialog-header-contain">
          <button onClick={handleCloseDialog} style={{ background: "#fff" }}>
            <FaWindowClose style={{ fontSize: "26px" }} />
          </button>
        </div>
        <div className="Dialog-card-sales-container">
          <div className="Dialog-card-header-sales">
            <div className="dialog-header-text-sales">
              <GiStaryu style={{ fontSize: 24 }} />
              <h3 style={{ letterSpacing: 1.5 }}>Email Request Details</h3>
            </div>
          </div>
          <div className="Dialog-card-contain-sales">
            {selectedRequest && (<>
              <div className="Dialog-card-Details">
                <div className="dialod-card-data-email">
                  <p>
                    <span>Request:</span>
                    {renderSelectInfo(selectedRequest, "Request")}
                  </p>
                  <p>
                    <span>Request Date:</span>
                    {new Date(selectedRequest?.submittedTime).toLocaleString()}
                  </p>
                </div>
                <div className="dialod-card-data-email">
                  <p>
                    <span>Requested Data:</span>
                    {renderSelectInfo(selectedRequest, "RequestedData")}
                  </p>
                  <p>
                    <span>Agent Name:</span>
                    {renderSelectInfo(selectedRequest, "agentName")}
                  </p>
                </div>
                <p>
                  <span>Show Name:</span>
                  {renderSelectInfo(selectedRequest?.showData, "Show")}
                </p>
                <p>
                  <span>Company Name:</span>
                  {renderSelectInfo(selectedRequest?.companyData, "Lead")}
                </p>
                <p>
                  <span>Email:</span>
                  {renderSelectInfo(selectedRequest?.companyData, "Email")}
                </p>
                <p>
                  <span>Email Notes:</span>
                  {renderSelectInfo(selectedRequest?.companyData, "RequestedData")}
                </p>
                <p>
                  <span>Contact Name:</span>
                  {renderSelectInfo(selectedRequest?.companyData, "DM Name")}
                </p>
                <div className="dialod-card-data-email">
                  {!(selectedRequest.RequestedData === 'Pricing (Same Day, Last Day)' ||
                    selectedRequest.RequestedData === 'Pricing (1 Day Before, Last Day)' ||
                    selectedRequest.RequestedData === 'Contact Card for Future Reference') && (
                      <>
                        <p>
                          <span>Check-In:</span>
                          {renderSelectInfo(selectedRequest?.companyData, "Check-in")}
                        </p>
                        <p>
                          <span>Check-Out:</span>
                          {renderSelectInfo(selectedRequest?.companyData, "Check-out")}
                        </p>
                      </>
                    )}
                  <p>
                    <span>No Of Rooms:</span>
                    {selectedRequest?.companyData?.Rooms || 0}
                  </p>
                </div>
              </div>
            </>)}
          </div>
          <div className="dialog-hotels-table-container-sales">
            <TableContainer
              component={Paper}
              sx={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
                borderRadius: "16px",
              }}
            >
              <Table sx={{ minWidth: 650 }} aria-label="email requests table">
                <TableHead>
                  <TableRow
                    sx={{
                      background: "linear-gradient(to right, #fed766, #e8b6ea)",
                    }}
                  >
                    {[
                      "Hotel Name",
                      "Address",
                      "Stars",
                      "Selling price",
                      "Boarding Type",
                      "Distance",
                      "Room Type",
                      "Free Cancellation Until",
                    ].map((header) => (
                      <TableCell
                        key={header}
                        sx={{
                          fontSize: 18,
                          fontFamily: '"Playfair Display", serif',
                          letterSpacing: "1.2px",
                          fontWeight: 700,
                          textAlign: "center",
                          whiteSpace: "nowrap",
                          wordBreak: "keep-all",
                          borderRight: "2px solid #fff",
                        }}
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedRequest?.emailPricingFeedback.length === 0 ? (
                    <TableRow>
                      <TableCell
                        colSpan={8}
                        sx={{
                          fontSize: 16,
                          fontWeight: 700,
                          textAlign: "center",
                          borderRight: "2px solid #e0e0e0",
                          padding: "10px",
                        }}
                      >
                        No email requests found
                      </TableCell>
                    </TableRow>
                  ) : (
                    selectedRequest?.emailPricingFeedback?.map((hotel, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell style={{ ...callStyle, textAlign: "center" }}>
                            {renderSelectInfo(hotel, "Hotel Name")}
                          </TableCell>
                          <TableCell style={{ ...callStyle, textAlign: "center" }}> {renderSelectInfo(hotel, "Address")}</TableCell>
                          <TableCell style={{ ...callStyle, textAlign: "center" }}>
                            {renderSelectInfo(hotel, "Stars")}</TableCell>
                          <TableCell style={{ ...callStyle, textAlign: "center" }}>
                            {renderSelectInfo(hotel, "SellingPrice")}</TableCell>
                          <TableCell style={{ ...callStyle, textAlign: "center" }}>
                            {renderSelectInfo(hotel, "Room Type")}</TableCell>
                          <TableCell style={{ ...callStyle, textAlign: "center" }}>
                            {renderSelectInfo(hotel, "Distance")}</TableCell>
                          <TableCell style={{ ...callStyle, textAlign: "center" }}>
                            {renderSelectInfo(hotel, "Boarding Type")}</TableCell>
                          <TableCell style={{ ...callStyle, textAlign: "center" }}>
                            {renderSelectInfo(hotel, "Free Cancellation Until")}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default HistoryEmailReqSales

