import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children, allowedRoles }) => {
    const token = localStorage.getItem("token");
    const userRole = localStorage.getItem("role");
  
    // Redirect to login if no token exists
    if (!token) {
      return <Navigate to="/" replace />;
    }
  
    // Redirect if the user's role doesn't match the allowed roles
    if (allowedRoles && !allowedRoles.includes(userRole)) {
      return <Navigate to="/not-authorized" replace />;
    }
  
    return children;
}

export default ProtectedRoute