import React from "react";
import "./ShowDataToLocator.css";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Grid, Box, Typography } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const ShowDataToLocator = ({ handleBackTable, rowOfLocator }) => {
  const leads = rowOfLocator.map((lead) => lead);
  const headers = [
    "Show Name",
    "Lead",
    "Phone",
    "Email",
    "Timezone",
    "Status",
    "Closer Note",
    "Closing Date",
    "Closer Name",
    "Hidden",
  ];

  const getStatusCounts = (leads) => {
    const statusCounts = {};
    leads.forEach((lead) => {
      const { Status } = lead;
      if (statusCounts[Status]) {
        statusCounts[Status]++;
      } else {
        statusCounts[Status] = 1;
      }
    });
    return Object.keys(statusCounts).map((status) => ({
      status,
      count: statusCounts[status],
    }));
  };

  const StatusArray = getStatusCounts(leads);

  return (
    <div className="ShowDataToLocator-section">
      <div className="show-data-locator-header">
        <FaArrowLeftLong
          onClick={handleBackTable}
          style={{ fontSize: "20px", fontWeight: 600 }}
        />
      </div>
      <Grid container spacing={1} className="user-panel-counter">
        {StatusArray.map((user, index) => (
          <Grid item xs={12} sm={4} md={3} lg={2} key={index}>
            <Box
              sx={{
                padding: 0.5,
                textAlign: "center",
                border: "1px solid #e0e0e0",
                borderRadius: 1,
              }}
            >
              <Typography variant="subtitle1" sx={{ lineHeight: 1.4 }}>
                {user.status}
              </Typography>
              <Typography variant="h6" sx={{ lineHeight: 1.4 }}>
                {user.count}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      <div className="show-data-locator-table">
        <TableContainer component={Paper} sx={{ maxHeight: "68vh" }}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  background:
                    "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
                  color: "#fff",
                }}
              >
                {headers.map((header, index) => (
                  <TableCell
                    key={index}
                    style={{ fontWeight: "bold" }}
                    sx={{
                      color: "#fff",
                      borderRight: "2px solid #e0e0e0",
                      borderLeft: "2px solid #e0e0e0",
                      fontSize: "16px",
                      fontFamily: '"Playfair Display", serif',
                      whiteSpace: "nowrap",
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowOfLocator.map((lead) => (
                <TableRow key={lead._id}>
                  <TableCell
                    sx={{
                      borderRight: "2px solid #e0e0e0",
                      fontSize: "14px",
                      fontWeight: 550,
                    }}
                  >
                    {lead.Show}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "2px solid #e0e0e0",
                      fontSize: "14px",
                      fontWeight: 550,
                    }}
                  >
                    {lead.Lead}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "2px solid #e0e0e0",
                      fontSize: "14px",
                      fontWeight: 550,
                    }}
                  >
                    {lead.Phone}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "2px solid #e0e0e0",
                      fontSize: "14px",
                      fontWeight: 550,
                    }}
                  >
                    {lead.Email}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "2px solid #e0e0e0",
                      fontSize: "14px",
                      fontWeight: 550,
                    }}
                  >
                    {lead.Timezone}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "2px solid #e0e0e0",
                      fontSize: "14px",
                      fontWeight: 550,
                    }}
                  >
                    {lead.Status}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "2px solid #e0e0e0",
                      fontSize: "14px",
                      fontWeight: 550,
                    }}
                  >
                    {lead["Closer Note"]}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "2px solid #e0e0e0",
                      fontSize: "14px",
                      fontWeight: 550,
                    }}
                  >
                    {lead["Closing Date"]}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "2px solid #e0e0e0",
                      fontSize: "14px",
                      fontWeight: 550,
                    }}
                  >
                    {lead["Closer Assigned"].closerName}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "2px solid #e0e0e0",
                      fontSize: "14px",
                      fontWeight: 550,
                    }}
                  >
                    {lead.Hidden ? "Yes" : "No"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default ShowDataToLocator;
