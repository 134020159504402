import React, { useState } from "react";
import "./LeadsManager.css";
import Layout from "../../components/layout/layout";
import SideBar from "../../components/SideBar/SideBar";
import MainContainer from "../../mainContainer/MainContainer";
import IncompleteLeadsTable from "../../components/IncompleteLeadsTable/IncompleteLeadsTable";
import UploadLeadsBulk from "../../components/UploadLeadsBulk/UploadLeadsBulk";
import CreateRawLead from "../../components/CreateRawLead/CreateRawLead";

const LeadsManager = () => {
  //sidebar logic ------------>
  const [currentComponent, setCurrentComponent] = useState(
    <IncompleteLeadsTable />
  );
  const [currentPageTitle, setCurrentPageTitle] = useState(
    "Incomplete Leads Table"
  );

  //sidebar logic ------------>
  const sidebarButtons = [
    {
      label: "Incomplete Leads",
      component: <IncompleteLeadsTable />,
      pageTitle: "Incomplete Leads Table",
    },
    {
      label: "Create Raw Lead",
      component: <CreateRawLead />,
      pageTitle: "Create Raw Lead",
    }
  ];
  
  //sidebar logic ------------>
  const handleComponentChange = (component, pageTitle) => {
    setCurrentComponent(component);
    setCurrentPageTitle(pageTitle);
  };

  return (
    <Layout>
      <SideBar
        buttons={sidebarButtons}
        onComponentChange={handleComponentChange}
      />
      <MainContainer pageTitle={currentPageTitle}>
        <h2>Leads Generator View</h2>
        {currentComponent}
      </MainContainer>
    </Layout>
  );
};

export default LeadsManager;
